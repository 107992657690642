import type { ReactElement } from 'react';
import React from 'react';

import { ClaimContextProvider } from '~/components/ClaimContainer';
import type { CommunicationSuggestionClaimNotificationModel } from '~/components/ClaimNotificationsCard/types';
import SendEmailCommunicationCardContainer from '~/components/communications/EmailCommunicationCard/SendEmailCommunicationCardContainer';
import { reportErrorInProductionOrThrow } from '~/Utils';
import { useFetchClaim } from '~/Utils/ClaimUtils';

interface Props {
  id: string;
  onClose: () => void;
  claimNotification: CommunicationSuggestionClaimNotificationModel;
  onUpdate: () => void;
  AdditionalDialogHeader: ReactElement;
}
const ClaimNotificationCommunicationSuggestion: React.FC<Props> = ({
  onClose,
  claimNotification,
  onUpdate,
  AdditionalDialogHeader = <React.Fragment />,
}) => {
  const [claim, isLoadingClaim, isError, reloadClaim] = useFetchClaim(claimNotification.claim_id);

  if (isLoadingClaim) {
    return null;
  }

  if (!claim || isError) {
    throw Error('No claim for claim notification suggestion');
  }
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-expect-error TS2339
  const contact = claim?.contacts?.find((c) => c.id === claimNotification.to_contact_id);

  // NGTPA-17457 add sms as supported
  if (claimNotification?.subtype !== 'email') {
    reportErrorInProductionOrThrow(
      `Claim notification communication channel ${claimNotification.communication?.channel} is not supported`
    );
  }
  return (
    <ClaimContextProvider claim={claim} refreshData={reloadClaim}>
      <SendEmailCommunicationCardContainer
        contact={contact}
        onClose={onClose}
        onSendEmail={() => {
          onUpdate();
          onClose();
        }}
        emailTitle={claimNotification.subject}
        emailText={claimNotification.body}
        communicationHeaderAdditionalProps={{ isDynamicContact: true }}
        exposureIds={[claimNotification?.exposure_id ?? 0]}
        communicationSuggestionNotificationId={claimNotification.id}
        AdditionalDialogHeader={AdditionalDialogHeader}
      />
      {/* NGTPA-17457 SMS */}
    </ClaimContextProvider>
  );
};

export default ClaimNotificationCommunicationSuggestion;
