import React from 'react';
import { Formik, useFormikContext } from 'formik';
import { noop, snakeCase } from 'lodash';
import * as Yup from 'yup';

import CardDialog from '~/components/CardDialog';
import MenuItem from '~/components/core/Atomic/MenuItem';
import DialogFooterActions from '~/components/core/DialogFooterActions';
import ColoredDot from '~/components/SystemConfiguration/NotificationsConfiguration/ColoredDot';
import type { CategoryModel } from '~/components/SystemConfiguration/NotificationsConfiguration/NotificationsCategories';
import { CategoriesColors } from '~/components/SystemConfiguration/NotificationsConfiguration/NotificationsCategories';
import TextFieldFormik, { useSetDefaultFieldsOnChange } from '~/components/TextFieldFormik';

interface EditCategoryDialogProps {
  category?: CategoryModel;
  onCancel: () => void;
  onSubmit: (category: Partial<CategoryModel>) => Promise<void>;
  existingKeys: string[];
}

const AddEditCategoryDialog: React.FC<EditCategoryDialogProps> = ({
  category,
  onCancel = noop,
  onSubmit,
  existingKeys,
}) => {
  const isEdit = !!category;

  const validationSchema = Yup.object().shape({
    color: Yup.string().required('Required'),
    key: Yup.string()
      .required('Required')
      .test('unique-ids', 'Keys must be unique', (value) => (!value || isEdit ? true : !existingKeys.includes(value))),

    label: Yup.string().required('Required'),
  });

  return (
    <Formik
      initialValues={{
        id: category?.id,
        color: category?.color ?? 'teal-600',
        key: category?.key ?? '',
        label: category?.label ?? '',
      }}
      validationSchema={validationSchema}
      onSubmit={onSubmit}
    >
      <AddEditCategoryInner isEdit={isEdit} onCancel={onCancel} />
    </Formik>
  );
};

interface AddEditCategoryInnerProps {
  isEdit: boolean;
  onCancel: () => void;
}

const AddEditCategoryInner: React.FC<AddEditCategoryInnerProps> = ({ isEdit, onCancel }) => {
  const { isSubmitting, handleSubmit, values } = useFormikContext<Partial<CategoryModel>>();

  useSetDefaultFieldsOnChange(values.label, { key: snakeCase(values.label) }, noop, isEdit);

  return (
    <CardDialog
      title={`${isEdit ? 'Edit' : 'Add'} Category`}
      isDialog
      maxWidth="sm"
      fullWidth
      onClose={onCancel}
      preventClose={isSubmitting}
      footerActions={
        <DialogFooterActions
          primaryLabel="Save"
          onClickPrimary={handleSubmit}
          onClickSecondary={onCancel}
          disabled={isSubmitting}
        />
      }
    >
      <div className="grid grid-cols-2 gap-8">
        <TextFieldFormik id="color" label="Color" select disabled={isSubmitting}>
          {/* eslint-disable-next-line @typescript-eslint/ban-ts-comment */}
          {/*@ts-ignore*/}
          {Object.keys(CategoriesColors).map((color: keyof typeof CategoriesColors) => (
            <MenuItem key={color} value={color}>
              <ColoredDot color={color} />
            </MenuItem>
          ))}
        </TextFieldFormik>
        <TextFieldFormik id="label" label="Label" disabled={isSubmitting} />
        <TextFieldFormik id="key" label="Key" disabled={isSubmitting || isEdit} />
      </div>
    </CardDialog>
  );
};

export default AddEditCategoryDialog;
