import React, { useEffect, useState } from 'react';
import { useFormikContext } from 'formik';

import { useClaim } from '~/components/ClaimContainer';
import ArrayMultiselectFieldWithChipsFormik from '~/components/core/ArrayMultiselectFieldWithChipsFormik';
import LabeledValue from '~/components/core/Molecules/LabledValue';
import { isExposureWriteDisabled } from '~/components/exposures/ExposureUtils';
import type { MoiFormikValues } from '~/components/exposures/moi/MoiStepper/types';
import type { UseMoiMethodsResults } from '~/components/exposures/moi/MoiStepper/useMoiMethods';
import {
  GENERAL_EXPOSURE_FAKE_ID,
  GENERAL_EXPOSURE_LABEL,
  GENERAL_EXPOSURE_OPTION,
} from '~/components/exposures/moi/MoiUtils';
import { useCms } from '~/components/hooks/useCms';
import { useAllOptionOnMultiSelect } from '~/components/TextFieldFormik';
import type { ExposureModel } from '~/components/types/exposure-types';

interface MoiExposuresSelectFieldProps {
  exposure?: ExposureModel;
  disabledAllFieldsButMoiMethod: boolean;
  moiMethodsReturn: UseMoiMethodsResults;
}

const MoiExposuresSelectField: React.FC<MoiExposuresSelectFieldProps> = ({
  exposure,
  disabledAllFieldsButMoiMethod,
  moiMethodsReturn,
}) => {
  const [isClaimLevel, setIsClaimLevel] = useState(exposure?.isGeneralExpenses || false);
  const { user, userOrganization } = useCms();
  const { values, setFieldValue, isSubmitting } = useFormikContext<MoiFormikValues>();
  const { claim } = useClaim();
  const { selectedMoiMethod } = moiMethodsReturn;

  const claimLevelMoiEnabledForOrg = userOrganization?.configuration?.moi_configuration?.claim_level_moi_enabled;
  const isClaimLevelMoiEnabledForMethod = selectedMoiMethod?.is_claim_level_allowed !== false;

  const multipleExposuresMoiEnabledForOrg =
    userOrganization?.configuration?.moi_configuration?.multiple_exposures_moi_enabled;
  const isMultipleExposuresEnabledForMethod = selectedMoiMethod?.is_multiple_exposures_allowed !== false;

  const exposures =
    claim?.exposures
      ?.filter(
        (exposure: ExposureModel) => !isExposureWriteDisabled(exposure, user) && !exposure?.is_coverage_issue_exists
      )
      ?.map((exposure: ExposureModel) => ({
        id: exposure?.id,
        label: exposure?.label_text,
        value: exposure?.id,
      })) || [];

  if (claimLevelMoiEnabledForOrg && isClaimLevelMoiEnabledForMethod) {
    exposures.unshift(GENERAL_EXPOSURE_OPTION);
  }

  useAllOptionOnMultiSelect(values?.exposure_ids || [], 'exposure_ids', GENERAL_EXPOSURE_FAKE_ID);

  // Allow us to change the info banner when it's a claim level MOI
  useEffect(() => {
    if (!isClaimLevel && values?.exposure_ids?.includes(GENERAL_EXPOSURE_FAKE_ID)) {
      setIsClaimLevel(true);
    }
    if (isClaimLevel && !values?.exposure_ids?.includes(GENERAL_EXPOSURE_FAKE_ID)) {
      setIsClaimLevel(false);
    }
  }, [isClaimLevel, values?.exposure_ids]);

  // Don't allow to remove the current exposure unless the claim level MOI (General) is selected
  useEffect(() => {
    if (
      exposure &&
      !values?.exposure_ids?.includes(GENERAL_EXPOSURE_FAKE_ID) &&
      !values?.exposure_ids?.includes(exposure.id)
    ) {
      setFieldValue('exposure_ids', [...values?.exposure_ids, exposure.id]);
    }
  }, [exposure, exposure?.id, setFieldValue, values?.exposure_ids]);

  return (
    <div>
      {exposure?.isGeneralExpenses ? (
        <LabeledValue value={GENERAL_EXPOSURE_LABEL} label="Exposure" type="Chip" withOverflowTextWithToolTip={false} />
      ) : multipleExposuresMoiEnabledForOrg && isMultipleExposuresEnabledForMethod ? (
        <ArrayMultiselectFieldWithChipsFormik
          disabled={disabledAllFieldsButMoiMethod}
          showOnly={isSubmitting}
          id="exposure_ids"
          label="Exposures"
          options={exposures}
        />
      ) : (
        <LabeledValue
          value={exposure?.label_text || ''}
          label="Exposure"
          type="Chip"
          withOverflowTextWithToolTip={false}
        />
      )}
    </div>
  );
};

export default MoiExposuresSelectField;
