import axios from 'axios';

import type { CoveredItemRow, StatusType } from '~/components/AiChat/SideBarDialog/InvoiceRecommendationAnalysis/types';
import { useClaim } from '~/components/ClaimContainer';
import { useMinimizedDialogs } from '~/components/core/MinimizedBar/Context';
import { MinimizedPaymentRequestContainer } from '~/components/exposures/PaymentRequestContainer/PaymentRequestContainer';
import useFetchExposures from '~/components/exposures/useFetchExposures';
import { extractError, reportAxiosError } from '~/Utils';

interface ApplyInvoiceItemReturn {
  status: StatusType;
  message?: string;
}

interface ApplyInvoiceItemProps {
  item: CoveredItemRow;
  claimId: number;
  analysisResultsId: string;
  onUpdate: ({ status, message }: ApplyInvoiceItemReturn) => void;
}

interface UseCmsEmbeddedReturn {
  applyInvoiceItem: (props: ApplyInvoiceItemProps) => Promise<void>;
}

interface ExposureCreateResponse {
  exposure_id: string;
  reserve: number;
}

const createExposure = async ({
  item,
  claimId,
  analysisResultsId,
}: ApplyInvoiceItemProps): Promise<ExposureCreateResponse> => {
  const { data } = await axios.post(
    `/api/v1/claims/${claimId}/ai_embedded_recommendations/invoice_analysis/${analysisResultsId}/apply_covered_item`,
    { coverage_item: item }
  );
  return data;
};

const openPaymentDialog = ({
  add,
  claim,
  exposure,
  payableType = 'indemnity',
  onUpdate,
}: {
  // eslint-disable-next-line
  add: (props: any) => { handleCloseDialog: () => void };
  claim: { id: number };
  // eslint-disable-next-line
  exposure: Record<string, any>;
  payableType?: string;
  onUpdate: unknown;
}) => {
  const payableWithReserve = exposure[payableType];

  const paymentRequestDialogProps = {
    claim,
    exposure,
    payableWithReserve,
    payableType,
    onUpdate,
    cardDialogProps: {
      isDialog: true,
      maxWidth: 'md',
      fullWidth: true,
    },
    defaultAmount: payableWithReserve.reserve,
  };

  if (payableWithReserve.reserve === 0) {
    return;
  }

  const openPaymentRequestDialog = () => {
    const { handleCloseDialog } = add({
      barHeader: 'New Payment Request',
      type: 'PAYMENT',
      dialogComponent: MinimizedPaymentRequestContainer,
      dialogProps: {
        ...paymentRequestDialogProps,
        disableMinimized: true,
        onClose: () => handleCloseDialog(),
      },
    });
  };
  openPaymentRequestDialog();
  return;
};

export const useCmsEmbedded = (): UseCmsEmbeddedReturn => {
  const { claim, onClaimUpdate } = useClaim();
  const { reloadData: exposuresReloadData } = useFetchExposures();
  const { add } = useMinimizedDialogs();

  const applyInvoiceItem = async ({
    item,
    claimId,
    analysisResultsId,
    onUpdate,
  }: ApplyInvoiceItemProps): Promise<void> => {
    try {
      // Create exposure
      const { exposure_id } = await createExposure({ item, claimId, analysisResultsId, onUpdate });

      // Trigger claim update
      onClaimUpdate();

      // Reload exposures to verify and open payment
      const { exposures } = await exposuresReloadData();

      onUpdate({
        status: 'success',
        message: 'Exposure created',
      });

      // Find the newly created exposure
      const exposure = exposures.find(({ id }: { id: number | string }) => id === exposure_id);

      // Handle case where exposure is not found
      if (!exposure) {
        onUpdate({
          status: 'warning',
          message: "Exposure created but couldn't find the exposure details",
        });
        return;
      }

      onClaimUpdate();

      // Open payment dialog
      openPaymentDialog({
        add,
        claim,
        exposure,
        onUpdate: () => {
          onClaimUpdate();
          onUpdate({
            status: 'success',
            message: 'Payment request created',
          });
        },
      });
    } catch (error) {
      // Centralized error reporting and handling
      await reportAxiosError(error);

      const { message } = extractError(error);
      onUpdate({
        status: 'error',
        message: message ? message : String(error),
      });
    }
  };

  return { applyInvoiceItem };
};
