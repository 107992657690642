import React from 'react';
import ArrowDownwardIcon from '@material-ui/icons/ArrowDownward';
import ArrowUpwardIcon from '@material-ui/icons/ArrowUpward';

import { getCommunicationIconSettings } from '~/components/communications/CommunicationCard/utils';
import cn from '~/Utils/cn';

import { TooltipIcon } from '../core';
import {
  EmailErrorIcon,
  EmailIcon,
  PhoneIcon,
  PhysicalMailIcon,
  SmsErrorIcon,
  SmsIcon,
  VendorIcon,
  VideoIcon,
} from '../icons/notifications';
import ApiIcon from '../icons/notifications/ApiIcon';

export function getCommunicationChannelIconComponent(communicationChannel, error = false, prefix = '') {
  switch (communicationChannel) {
    case 'email':
      return (
        <TooltipIcon title={prefix + (error ? 'Email Error' : 'Email')}>
          {error ? <EmailErrorIcon /> : <EmailIcon />}
        </TooltipIcon>
      );
    case 'sms':
      return (
        <TooltipIcon title={prefix + (error ? 'Sms Error' : 'Sms')}>
          {error ? <SmsErrorIcon /> : <SmsIcon />}
        </TooltipIcon>
      );
    case 'phone_call':
      return (
        <TooltipIcon title={prefix + 'Phone'}>
          <PhoneIcon />
        </TooltipIcon>
      );
    case 'physical_mail':
      return (
        <TooltipIcon title={prefix + 'Physical Mail'}>
          <PhysicalMailIcon />
        </TooltipIcon>
      );
    case 'video_call':
      return (
        <TooltipIcon title={prefix + 'Video'}>
          <span>
            <VideoIcon />
          </span>
        </TooltipIcon>
      );
    case 'vendor':
      return (
        <TooltipIcon title={prefix + 'API'}>
          <ApiIcon />
        </TooltipIcon>
      );
    default:
      throw Error(`Unknown communication channel ${communicationChannel}`);
  }
}

export function getCommunicationChannelIconComponentWithDotIndication({
  communicationChannel,
  claimNotification,
  getIconWrapperWithDotIndication,
  size,
}) {
  switch (communicationChannel) {
    case 'email':
      return getIconWrapperWithDotIndication(claimNotification, <EmailIcon size={size} />);
    case 'sms':
      return getIconWrapperWithDotIndication(claimNotification, <SmsIcon size={size} />);
    case 'phone_call':
      return getIconWrapperWithDotIndication(claimNotification, <PhoneIcon size={size} />);
    case 'physical_mail':
      return getIconWrapperWithDotIndication(claimNotification, <PhysicalMailIcon size={size} />);
    case 'video_call':
      return getIconWrapperWithDotIndication(claimNotification, <VideoIcon size={size} />);
    case 'vendor':
      return getIconWrapperWithDotIndication(claimNotification, <VendorIcon size={size} />);
    default:
      throw Error(`Unknown communication channel ${communicationChannel}`);
  }
}

export function getCommunicationDirectionIconComponent(communicationDirection) {
  const IconComponent = getCommunicationDirectionIcon(communicationDirection);
  return <IconComponent style={{ color: getCommunicationDirectionIconColor(communicationDirection) }} />;
}

export function getCommunicationDirectionIcon(communicationDirection) {
  const communicationDirectionIcon = {
    Incoming: ArrowDownwardIcon,
    Outgoing: ArrowUpwardIcon,
  };

  return communicationDirectionIcon[communicationDirection];
}

export function getCommunicationDirectionIconColor(communicationDirection) {
  const communicationDirectionIcon = {
    Incoming: 'red',
    Outgoing: 'green',
  };

  return communicationDirectionIcon[communicationDirection];
}

export const getChannelDirectionColumns = ({ isNewUIEnabled, showCLabel }) => {
  return isNewUIEnabled
    ? [
        {
          id: 'channel',
          width: 60,
          leftPaddingOnly: showCLabel && !isNewUIEnabled,
          rightPaddingAndDisableXAxis: isNewUIEnabled,
          numeric: false,
          label: showCLabel ? 'C' : '',
          disableSort: true,
          specialCell: (communication) => getCommunicationIcon(communication),
        },
      ]
    : [
        {
          id: 'channel',
          width: 30,
          leftPaddingOnly: showCLabel,
          numeric: false,
          label: showCLabel ? 'C' : '',
          disableSort: true,
          specialCell: (comm) =>
            getCommunicationChannelIconComponent(
              comm.channel,
              ['email', 'sms'].includes(comm.channel) && comm.is_delivery_issue_error_exists
            ),
        },
        {
          id: 'direction',
          width: 30,
          leftPaddingOnly: true,
          numeric: false,
          label: 'D',
          disableSort: true,
          specialCell: (comm) => getCommunicationDirectionIconComponent(comm.direction),
        },
      ];
};

export const getCommunicationIcon = (communication) => {
  const { channel, direction } = communication;
  if (!channel) return null;

  const settings = getCommunicationIconSettings(communication, channel);
  const DirectionComponent = getCommunicationDirectionIcon(direction);

  return (
    <div className="flex items-center rounded-full border-1 border-solid border-blue-200 p-2 px-4">
      <div
        className={cn(
          'box-content rounded-full p-[3px] text-2xl',
          settings.iconClassName,
          settings.textColor,
          settings.bgColor
        )}
      />
      <DirectionComponent className="ml-2 text-xl text-blue-800" />
    </div>
  );
};
