import React from 'react';

import { useFnolAiInboxData } from '~/components/Fnol/FnolAiInbox/hooks/useFnolAiInboxData';
import type { InboxStatus } from '~/components/Fnol/FnolAiInbox/hooks/useFnolAiInboxStatus';
import { useFnolAiInboxStatus } from '~/components/Fnol/FnolAiInbox/hooks/useFnolAiInboxStatus';
import { useFnolAiPermissions } from '~/components/Fnol/FnolAiInbox/hooks/useFnolAiPermissions';
import type { FnolAiInboxRecord, InboxType } from '~/components/Fnol/FnolAiInbox/types';

export interface IFnolAiInbox {
  isAllowedForUser: boolean;
  inboxItems: FnolAiInboxRecord[];
  isLoading: boolean;
  isError: boolean;
  reloadInboxItems: () => Promise<void>;
  reloadInboxStatus: () => Promise<void>;
  inboxStatus?: InboxStatus;
  updateInboxType: (inboxType?: InboxType) => void;
}

export const FnolAiInboxContext = React.createContext<IFnolAiInbox>({
  isAllowedForUser: false,
  inboxItems: [],
  isLoading: false,
  isError: false,
  reloadInboxItems: () => Promise.resolve(),
  reloadInboxStatus: () => Promise.resolve(),
  inboxStatus: undefined,
  updateInboxType: () => null,
});

export const FnolAiInboxContextProvider: React.FC = ({ children }) => {
  const [inboxType, setInboxType] = React.useState<InboxType | undefined>('in-queue');
  const { isAllowedForUser } = useFnolAiPermissions();
  const { inboxStatus, reloadInboxStatus } = useFnolAiInboxStatus({ shouldFetch: isAllowedForUser });
  const { inboxItems, isLoading, isError, reloadInboxItems } = useFnolAiInboxData({
    inboxType,
    shouldFetch: isAllowedForUser,
  });

  const updateInboxType = (inboxType?: InboxType) => {
    setInboxType(inboxType);
  };

  return (
    <FnolAiInboxContext.Provider
      value={{
        isAllowedForUser,
        inboxItems,
        isLoading,
        isError,
        inboxStatus,
        reloadInboxItems,
        reloadInboxStatus,
        updateInboxType,
      }}
    >
      {children}
    </FnolAiInboxContext.Provider>
  );
};

export const useFnolAiInbox = ({ inboxType }: { inboxType?: InboxType }): IFnolAiInbox => {
  const context = React.useContext(FnolAiInboxContext);

  React.useEffect(() => {
    if (inboxType) {
      context.updateInboxType(inboxType);
    }
  }, [inboxType, context]);

  return context;
};
