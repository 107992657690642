import React, { useCallback, useEffect, useState } from 'react';
import { Fab } from '@mui/material';
import axios from 'axios';
import { isEmpty } from 'lodash';

import { ClaimChatDialog } from '~/components/AiChat/ClaimChat/ClaimChatDialog';
import { JusterImage } from '~/components/AiChat/components/JusterImage';
import { ChatProvider } from '~/components/AiChat/hooks/useChat';
import { useClaimChatAuth } from '~/components/AiChat/hooks/useClaimChatAuth';
import { usePollingWithFailureTracking } from '~/components/AiChat/hooks/usePollingWithFailureTracking';
import { ActionsContextProvider } from '~/components/AiChat/SideBarDialog/Actions/useActions';
import { useActionsFeatureFlags } from '~/components/AiChat/SideBarDialog/Actions/useActionsFeatureFlags';
import { InsightsContextProvider } from '~/components/AiChat/SideBarDialog/Insights/useInsights';
import { useClaim } from '~/components/ClaimContainer';
import { useMinimizeBars } from '~/components/core/MinimizedBar/Context/ToastifyBarContext';
import Text from '~/components/core/TextComponents/Text';
import { useCms } from '~/components/hooks/useCms';
import { CONFIGURATION_FEATURES_NAMES } from '~/Types';
import { isFeatureEnabled } from '~/Utils';

const POLLING_DELAY = 5000;
const IMAGE_SIZE = 72;

export const AiChat: React.FC = () => {
  const { isClaimChatEnabled } = useClaimChatAuth();

  // this is for preventing collision between AiChat icon and MinimizedBar
  const { setIsChatEnabled } = useMinimizeBars();
  setIsChatEnabled(isClaimChatEnabled);

  return isClaimChatEnabled ? <AiChatInner /> : null;
};

export const AiChatInner: React.FC = () => {
  const [isAiChatOpen, setIsAiChatOpen] = React.useState(false);

  return isAiChatOpen ? (
    <ChatProvider routeSuffix="/chat">
      <InsightsContextProvider>
        <ActionsContextProvider>
          {isAiChatOpen ? <ClaimChatDialog onClose={() => setIsAiChatOpen(false)} /> : null}
        </ActionsContextProvider>
      </InsightsContextProvider>
    </ChatProvider>
  ) : (
    <CliveFloatingComponent onClick={() => setIsAiChatOpen(true)} />
  );
};

interface CliveFloatingComponentProps {
  onClick: () => void;
}

interface ActivityStats {
  [key: string]: {
    active: number;
    not_active: number;
  };
}

const CliveFloatingComponent: React.FC<CliveFloatingComponentProps> = ({ onClick }) => {
  const [actionsNotificationCount, setActionsNotificationCount] = useState(0);
  const [claimActionsCount, setClaimActionsCount] = useState<ActivityStats>({});
  const [undismissedInsightsCount, setUndismissedInsightsCount] = useState(0);

  const { userOrganization } = useCms();
  const { claim } = useClaim();
  const { actionFlagEnabledMapping, isActionsTabEnabled } = useActionsFeatureFlags();
  const isInsightsTabEnabled = isFeatureEnabled(
    userOrganization,
    CONFIGURATION_FEATURES_NAMES.AI_SERVICE_UI_INSIGHTS_TAB_DISPLAY
  );

  const fetchCounts = useCallback(async () => {
    try {
      const apiCallsPromises = [];
      const getActionsCount = async () => {
        const res = await axios.get(`/api/v1/claims/${claim.id}/actions/statuses/count`);
        if (res) {
          setClaimActionsCount(res.data);
        }
      };

      const getInsightsCount = async () => {
        const res = await axios.get(`/api/v1/claims/${claim.id}/chat/insights/statuses/count`);
        setUndismissedInsightsCount(res.data.undismissed_count);
      };

      // Only add API calls if their respective features are enabled
      if (isActionsTabEnabled) {
        apiCallsPromises.push(getActionsCount());
      }

      if (isInsightsTabEnabled) {
        apiCallsPromises.push(getInsightsCount());
      }

      // Only make the API calls if at least one feature is enabled
      if (apiCallsPromises.length > 0) {
        await Promise.all(apiCallsPromises);
      }
    } catch (error) {
      // Re-throw to trigger failure tracking
      throw new Error('Failed to fetch counts');
    }
  }, [claim.id, isActionsTabEnabled, isInsightsTabEnabled]);

  usePollingWithFailureTracking(fetchCounts, {
    pollingDelay: POLLING_DELAY,
    enabled: isActionsTabEnabled,
  });

  useEffect(() => {
    let total = undismissedInsightsCount;

    if (!isEmpty(claimActionsCount)) {
      Object.keys(actionFlagEnabledMapping).forEach((flagName) => {
        total += actionFlagEnabledMapping[flagName] ? claimActionsCount[flagName]?.active ?? 0 : 0;
      });
    }

    setActionsNotificationCount(total);
  }, [claimActionsCount, actionFlagEnabledMapping, undismissedInsightsCount]);

  return (
    <Fab
      className="z-1000 fixed bottom-32 right-32 h-[72px] w-[72px] transition-opacity hover:bg-slate-400 hover:bg-opacity-50 "
      onClick={onClick}
    >
      {actionsNotificationCount > 0 ? (
        <span className="absolute mb-[52px] mr-[52px] inline-flex h-[26px] w-[26px] items-center justify-center rounded-full border-2 border-solid border-white bg-green-700 ">
          <Text variant={Text.VARIANTS.SM} weight={Text.WEIGHTS.SEMI_BOLD} colorVariant={Text.COLOR_VARIANTS.WHITE}>
            {actionsNotificationCount}
          </Text>
        </span>
      ) : null}
      <JusterImage width={IMAGE_SIZE} height={IMAGE_SIZE} />
    </Fab>
  );
};
