import React from 'react';
import requiredIf from 'react-required-if';
import PropTypes from 'prop-types';
import { ButtonGroup, Checkbox, Menu } from '@material-ui/core';
import _, { capitalize, noop } from 'lodash';

import Button from '~/components/core/Atomic/Buttons/Button';
import Grid from '~/components/core/Atomic/Grid/Grid';
import Tooltip from '~/components/core/Atomic/Tooltip';
import Typography from '~/components/core/Atomic/Typography';
import StatusChip from '~/components/core/StatusChip';
import { COLUMN_KEYS, EXTENDABLE_COLUMN_KEYS } from '~/components/Finances/FinancesTable/constants';
import useAdditionalFinanceTableMenuOptions from '~/components/Finances/FinancesTable/useAdditionalFinanceTableMenuOptions';
import { usePaymentsConfiguration } from '~/components/hooks/usePaymentsConfiguration';
import HoverActionField from '~/components/HoverActionField';
import { ThreeDotsIcon } from '~/components/icons';
import { isLocaleRegionIsUK } from '~/Utils/regionUtils';

import { serverDateTimeToLocal } from '../../../DateTimeUtils';
import { PAYMENT_MIXPANEL_EVENTS, PAYMENT_MIXPANEL_SOURCE } from '../../../pocs/mixpanel';
import { PAYMENT_METHODS, SUPERVISOR_APPROVAL_STATUS_DICT } from '../../../Types';
import ClaimLink from '../../ClaimLink';
import mixpanel from '../../CmsMain/mixpanel';
import { PERMISSION_VERBS, RestrictedPermissions, SortableTable } from '../../core';
import { CurrencyFormatterContextProvider, useCurrencyFormatter } from '../../CurrencyFormatterContext';
import OverflowTextWithToolTip from '../../OverflowTextWithToolTip';
import ApprovalRequestDecisionIconContainer from '../ApprovalRequestDecisionIconContainer';
import AttachedDocumentsContainer from '../AttachedDocumentsContainer';
import CancelTransactionActionContainer from '../CancelTransactionActionContainer';
import { paymentTypeToPermissionAction } from '../paymentsUtils';

import { useStyles } from '../../../assets/styles';
import styles from './financesTable.module.scss';

const AmountSpecialCell = ({ amount }) => {
  const { currencyFormatter } = useCurrencyFormatter();

  return currencyFormatter.format(amount);
};

function AdditionalFinanceTableMenu({ financeRow, onReloadFinances }) {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const filteredMenuOptions = useAdditionalFinanceTableMenuOptions(financeRow);

  if (filteredMenuOptions.length === 0) {
    return null;
  }

  return (
    <>
      <HoverActionField icon={ThreeDotsIcon} onAction={(event) => setAnchorEl(event.currentTarget)} permanent />
      <Menu anchorEl={anchorEl} open={Boolean(anchorEl)} onClose={() => setAnchorEl(null)}>
        {filteredMenuOptions.map((opt) => (
          <opt.MenuItemComponent key={opt.id} financeRecord={financeRow} onReloadFinances={onReloadFinances} />
        ))}
      </Menu>
    </>
  );
}

AdditionalFinanceTableMenu.propTypes = {
  financeRow: PropTypes.object.isRequired,
  onReloadFinances: PropTypes.func.isRequired,
};

const ActionsSpecialCell = ({ row, onReloadFinances }) => {
  const action = paymentTypeToPermissionAction(row.exposure_label ? row.payable_with_reserve_type : 'general_expenses');
  return (
    <div className={styles.actionsColumnContainer}>
      <AttachedDocumentsContainer row={row} />
      <RestrictedPermissions action={action} verb={PERMISSION_VERBS.WRITE}>
        <CancelTransactionActionContainer
          claimId={row.claim_id}
          isClaimClosed={row.claim_is_closed}
          isCancellable={row.is_cancellable}
          paymentRequestId={row.id}
          exposureFinanceMetadata={row.exposure_finance_metadata}
          paymentType={row.payable_with_reserve_type}
          mixpanelSource={PAYMENT_MIXPANEL_SOURCE.FINANCE_SCREEN}
          onCancelTransaction={onReloadFinances}
        />
      </RestrictedPermissions>
      <ApprovalRequestDecisionIconContainer
        status={row.financial_status}
        exposureId={row.exposure_id}
        paymentRequest={row.payment_request}
        claimId={row.claim_id}
        onUpdate={onReloadFinances}
      />
      <AdditionalFinanceTableMenu financeRow={row} onReloadFinances={onReloadFinances} />
    </div>
  );
};

ActionsSpecialCell.propTypes = {
  row: PropTypes.object.isRequired,
  onReloadFinances: PropTypes.func.isRequired,
};

const FinancesTable = ({
  finances,
  displayColumnIds,
  sortByColumn,
  onSortByColumn,
  paginationProps,
  onReloadFinances,
  enableSelection,
  selectedFinances,
  onUpdatedSelectedFinances,
  onSelectRow = noop,
}) => {
  const { isPaymentLabelsEnabledForAny, isSubReservesEnabled } = usePaymentsConfiguration();
  const classes = useStyles();
  const handleExpand = () => {
    mixpanel.track(PAYMENT_MIXPANEL_EVENTS.PAYMENT_EXPANDED);
  };

  const columnData = [
    {
      id: COLUMN_KEYS.request_date,
      label: 'Creation date',
      specialCell: (row) => row.request_date && serverDateTimeToLocal(row.request_date),
    },
    {
      id: COLUMN_KEYS.claim_id_display,
      label: 'Claim',
      specialCell: (row) => <ClaimLink claimId={row.claim_id} linkText={row.claim_id_display} />,
    },
    {
      id: COLUMN_KEYS.requesting_user,
      label: 'Requesting user',
      disableSort: true,
      specialCell: (row) => row.issued_by,
    },
    {
      id: COLUMN_KEYS.financial_status_last_update,
      label: 'Last modified date',
      specialCell: (row) => row.financial_status_last_update && serverDateTimeToLocal(row.financial_status_last_update),
    },
    {
      id: COLUMN_KEYS.exposure_label,
      label: 'Exposure',
      disableSort: true,
      specialCell: (row) => (row.exposure_label ? row.exposure_label : 'General Expenses'),
    },
    {
      id: COLUMN_KEYS.payable_with_reserve_type,
      label: 'Payable type',
      disableSort: true,
      specialCell: (row) => capitalize(row.payable_with_reserve_type),
    },
    {
      id: COLUMN_KEYS.request_status,
      label: 'Approval status',
      disableSort: true,
      specialCell: (row) => (
        <Tooltip title={row.decision_date ? serverDateTimeToLocal(row.decision_date) : ''}>
          <span>{SUPERVISOR_APPROVAL_STATUS_DICT[row.request_status].desc}</span>
        </Tooltip>
      ),
    },
    {
      id: COLUMN_KEYS.financial_status,
      label: 'Financial status',
      disableSort: true,
      specialCell: (row) => <StatusChip status={StatusChip.STATUSES[row.financial_status.toUpperCase()]} withIcon />,
    },
    {
      id: COLUMN_KEYS.amount,
      label: 'Amount',
      numeric: true,
      disableSort: true,
      specialCell: (row) => {
        return row.request_currency && row.amount_orig_currency ? (
          <CurrencyFormatterContextProvider currency={row.request_currency}>
            <AmountSpecialCell amount={row.amount_orig_currency} />
          </CurrencyFormatterContextProvider>
        ) : (
          <AmountSpecialCell amount={row.amount} />
        );
      },
    },
    {
      id: COLUMN_KEYS.actions,
      label: 'Actions',
      disableSort: true,
      align: 'right',
      specialCell: (row) => <ActionsSpecialCell row={row} onReloadFinances={onReloadFinances} />,
    },
    {
      id: COLUMN_KEYS.select_row_button,
      label: '',
      disableSort: true,
      align: 'right',
      specialCell: (row) => (
        <Button color="primary" onClick={() => onSelectRow(row)}>
          Select
        </Button>
      ),
    },
    {
      id: EXTENDABLE_COLUMN_KEYS.sub_reserves,
      label: 'Sub Reserves',
      disableSort: true,
      expandable: true,
      specialCell: (row) => {
        return Array.isArray(row?.sub_reserves) ? (
          <div>
            {row?.sub_reserves?.map(({ type_key, amount, desc }) => {
              return (
                <div key={type_key}>
                  <span>{desc}: </span>
                  <CurrencyFormatterContextProvider currency={row.request_currency}>
                    <AmountSpecialCell amount={amount} />
                  </CurrencyFormatterContextProvider>
                </div>
              );
            })}
          </div>
        ) : null;
      },
      isHidden: !isSubReservesEnabled,
    },
    {
      id: EXTENDABLE_COLUMN_KEYS.issued_by,
      label: 'Issued by',
      disableSort: true,
      expandable: true,
    },
    {
      id: EXTENDABLE_COLUMN_KEYS.payment_type,
      label: 'Payment type',
      disableSort: true,
      expandable: true,
      isHidden: !isPaymentLabelsEnabledForAny,
    },
    {
      id: EXTENDABLE_COLUMN_KEYS.payees,
      label: 'Payees',
      disableSort: true,
      expandable: true,
      specialCell: (row) => row.payees.map((payee) => payee.contact.full_name).join(', '),
    },
    {
      id: EXTENDABLE_COLUMN_KEYS.sent_to,
      label: 'Sent to',
      disableSort: true,
      expandable: true,
    },
    {
      id: EXTENDABLE_COLUMN_KEYS.reference,
      label: 'Reference',
      disableSort: true,
      expandable: true,
    },
    {
      id: EXTENDABLE_COLUMN_KEYS.note,
      label: 'Note',
      disableSort: true,
      expandable: true,
      specialCell: (row) => <OverflowTextWithToolTip maxWidth="300px">{row.note}</OverflowTextWithToolTip>,
    },
    {
      id: EXTENDABLE_COLUMN_KEYS.deductible_applied,
      label: isLocaleRegionIsUK() ? 'Excess applied' : 'Deductible applied',
      disableSort: true,
      expandable: true,
      specialCell: (row) => <AmountSpecialCell amount={row.deductible_applied} />,
    },
    {
      id: EXTENDABLE_COLUMN_KEYS.payment_method,
      label: 'Method',
      disableSort: true,
      expandable: true,
      specialCell: (row) =>
        row.payment_request.custom_method_display_name ||
        (row.payment_request.payment_method ? PAYMENT_METHODS[row.payment_request.payment_method] : ''),
    },
  ];

  const isFinanceSelected = (finance) => {
    return !_.isEmpty(_.find(selectedFinances, (f) => f.id === finance.id));
  };

  const withoutFinance = (finance) => {
    return selectedFinances.filter((f) => f.id !== finance.id);
  };

  if (enableSelection) {
    const selectionColumn = {
      id: COLUMN_KEYS.select_finance,
      disableSort: true,
      width: 20,
      disablePadding: true,
      // eslint-disable-next-line react/display-name
      specialCell: (finance) => (
        <Checkbox
          // eslint-disable-next-line react/prop-types
          checked={isFinanceSelected(finance)}
          color="primary"
        />
      ),
    };
    columnData.unshift(selectionColumn);
  }

  const handleFinancialClicked = (financeId) => {
    const finance = _.find(finances, (f) => f.id === financeId);
    if (enableSelection) {
      onUpdatedSelectedFinances(isFinanceSelected(finance) ? withoutFinance(finance) : [...selectedFinances, finance]);
    }
  };

  return (
    <>
      {enableSelection && (
        <div className={classes.cardDivRow}>
          <Grid container>
            <Grid item md={8}>
              <Typography variant="subtitle1">
                <strong>{selectedFinances.length}</strong>&nbsp; payments selected
              </Typography>
            </Grid>
            <Grid item md={4}>
              <ButtonGroup variant="text" size="small" className={classes.inLineButtonsContainer}>
                <Button onClick={() => onUpdatedSelectedFinances(_.union(selectedFinances, finances))}>All</Button>
                <Button
                  onClick={() =>
                    onUpdatedSelectedFinances(
                      selectedFinances.filter((f) => !finances.find((finance) => finance.id === f.id))
                    )
                  }
                >
                  None
                </Button>
              </ButtonGroup>
            </Grid>
          </Grid>
        </div>
      )}
      <SortableTable
        rows={finances || []}
        columns={columnData
          .filter(({ id }) => displayColumnIds.includes(id))
          .sort((a, b) => displayColumnIds.indexOf(a.id) - displayColumnIds.indexOf(b.id))}
        defaultOrderColumn={columnData.findIndex((column) => column.id === 'request_date')}
        order={sortByColumn.order}
        paginationProps={paginationProps}
        onSortByColumn={onSortByColumn}
        shouldRenderExpandAsTable={false}
        onExpandColumns={handleExpand}
        onRowClick={handleFinancialClicked}
        selectedRowsIds={enableSelection ? selectedFinances : []}
      />
    </>
  );
};

FinancesTable.propTypes = {
  finances: PropTypes.array.isRequired,
  sortByColumn: PropTypes.object.isRequired,
  onSortByColumn: PropTypes.func.isRequired,
  paginationProps: PropTypes.object.isRequired,
  displayColumnIds: PropTypes.arrayOf(PropTypes.string),
  onReloadFinances: PropTypes.func,
  enableSelection: PropTypes.bool,
  selectedFinances: requiredIf(PropTypes.array, (props) => props.enableSelection),
  onUpdatedSelectedFinances: requiredIf(PropTypes.func, (props) => props.enableSelection),
  onSelectRow: PropTypes.func,
};

export default FinancesTable;
