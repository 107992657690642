import type { SyntheticEvent } from 'react';
import React, { useEffect } from 'react';
import { useFormikContext } from 'formik';
import { intersection, isEmpty } from 'lodash';

import MenuItem from '~/components/core/Atomic/MenuItem';
import LoadingSwitch from '~/components/core/Loading/LoadingSwitch';
import FormikDocumentsAttachment from '~/components/Documents/FormikDocumentsAttachment';
import type { MoiFormikValues, MoiMethod, MoiStepProps } from '~/components/exposures/moi/MoiStepper/types';
import useMethodSpecific from '~/components/exposures/moi/MoiStepper/useMethodSpecific';
import { useCms } from '~/components/hooks/useCms';
import TextFieldFormik from '~/components/TextFieldFormik';
import { CONFIGURATION_FEATURES_NAMES } from '~/Types';
import { isFeatureEnabled } from '~/Utils';

import MoiExposuresSelectField from './MoiExposuresSelectField';

const SelectMethodStep: React.FC<MoiStepProps> = ({
  exposure,
  selectedContact,
  setSelectedContact,
  setCurrentValidation,
  currentValidation,
  moiMethodsReturn,
}) => {
  const { userOrganization } = useCms();
  const { setFieldValue, setFieldTouched, handleChange, values, isSubmitting } = useFormikContext<MoiFormikValues>();
  const disabledAllFieldsButMoiMethod = isSubmitting || !values['moi_method_id'];
  const {
    isMoiMethodOnlyForServiceProviders,
    isLoading,
    isError,
    getMoiMethodExpertises,
    moiMethodsById,
    moiMethodsOptions,
    selectedMoiMethod,
  } = moiMethodsReturn;

  const isAttachDocumentsEnabled = selectedMoiMethod?.is_attached_documents_allowed;
  const enableAttachedDocuments =
    isFeatureEnabled(userOrganization, CONFIGURATION_FEATURES_NAMES.XACT_MOI_DOCUMENTS) && isAttachDocumentsEnabled;

  const { fields, validationsMergedWithExistingSchema, fieldsValidations } = useMethodSpecific(
    moiMethodsById[values['moi_method_id']],
    disabledAllFieldsButMoiMethod
  );

  useEffect(() => {
    if (fieldsValidations) {
      validationsMergedWithExistingSchema(currentValidation, setCurrentValidation);
    }
  }, [fieldsValidations, currentValidation, setCurrentValidation, validationsMergedWithExistingSchema]);

  return (
    <LoadingSwitch isLoading={isLoading} isError={isError}>
      <div>
        <div className="grid grid-cols-3 items-end gap-32">
          <div>
            <TextFieldFormik
              id="moi_method_id"
              label="Method of Inspection"
              className="mt-3 mp-3"
              fullWidth
              disabled={isSubmitting}
              select
              // eslint-disable-next-line @typescript-eslint/ban-ts-comment
              // @ts-ignore
              onChange={(e: SyntheticEvent) => {
                const method_id = (e.target as HTMLInputElement).value;
                const acceptedExpertises = getMoiMethodExpertises(method_id);

                if (
                  selectedContact &&
                  isEmpty(intersection(acceptedExpertises, selectedContact?.moi_expertises || []))
                ) {
                  setFieldValue('contact_id', undefined);
                  setFieldTouched('contact_id', true);
                  setSelectedContact(undefined);
                }

                if (isMoiMethodOnlyForServiceProviders(moiMethodsById[method_id] as MoiMethod)) {
                  setFieldValue('assignee_type', 'service_provider');
                }

                handleChange(e);
              }}
            >
              {/*eslint-disable-next-line @typescript-eslint/ban-ts-comment*/}
              {/*@ts-ignore*/}
              {moiMethodsOptions.map(({ id, key, display_name }) => (
                <MenuItem key={key} value={id}>
                  {display_name}
                </MenuItem>
              ))}
            </TextFieldFormik>
          </div>
          <div className="mb-[-4px]">
            <MoiExposuresSelectField
              exposure={exposure}
              disabledAllFieldsButMoiMethod={disabledAllFieldsButMoiMethod}
              moiMethodsReturn={moiMethodsReturn}
            />
          </div>
          {fields}
        </div>
        <div>{enableAttachedDocuments && <FormikDocumentsAttachment id="attached_documents" />}</div>
      </div>
    </LoadingSwitch>
  );
};

export default SelectMethodStep;
