import React from 'react';
import PropTypes from 'prop-types';

import SvgIcon from '../../core/SvgIcon';

const InternalCommunicationIcon = ({ iconColor = '#202020', ...props }) => (
  <SvgIcon viewBox="0 0 22 19" fill="none" stroke="none" {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M19.1566 0.710797C19.4099 0.612604 19.6851 0.585037 19.9528 0.63104C20.2205 0.677041 20.4708 0.794885 20.6767 0.971992C20.8827 1.1491 21.0367 1.37882 21.1224 1.63663C21.208 1.89443 21.222 2.17065 21.1629 2.4358L21.1619 2.43999L17.6369 17.8337C17.5787 18.0873 17.4554 18.3218 17.2794 18.5134C17.1034 18.705 16.8805 18.8475 16.6328 18.9269C16.385 19.0063 16.1209 19.02 15.8663 18.9664C15.6121 18.913 15.3761 18.7945 15.1815 18.6225L15.1806 18.6216L6.90498 11.3402L2.10508 10.3767L2.10199 10.376C0.660278 10.0803 0.438729 8.06087 1.84779 7.50867L19.1535 0.711995L19.1566 0.710797ZM2.40027 8.90594L2.39981 8.9034L19.6988 2.10938L16.1748 17.4985L16.1731 17.497L7.745 10.0814C7.64619 9.99444 7.52624 9.93504 7.39719 9.90913L2.40341 8.90663L2.40027 8.90594Z"
      fill={iconColor}
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M20.6169 0.989702C20.8595 1.32549 20.7839 1.79431 20.4481 2.03683L7.68871 11.2525C7.35292 11.495 6.8841 11.4194 6.64158 11.0836C6.39905 10.7478 6.47465 10.279 6.81044 10.0365L19.5698 0.820838C19.9056 0.578311 20.3744 0.653914 20.6169 0.989702Z"
      fill={iconColor}
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M7.24958 9.89446C7.66379 9.89446 7.99958 10.2302 7.99958 10.6445L7.99957 16.7463L8.0036 16.7422L10.9286 13.8173C11.2215 13.5244 11.6964 13.5244 11.9893 13.8173C12.2822 14.1101 12.2822 14.585 11.9893 14.8779L9.06596 17.8012C8.85761 18.0119 8.59153 18.1561 8.30127 18.2157C8.00991 18.2755 7.70735 18.2474 7.43202 18.1348C7.15669 18.0223 6.92104 17.8304 6.75502 17.5836C6.58899 17.3368 6.50007 17.0463 6.49958 16.7488L6.49958 10.6445C6.49958 10.2302 6.83536 9.89446 7.24958 9.89446Z"
      fill={iconColor}
    />
  </SvgIcon>
);

InternalCommunicationIcon.propTypes = {
  iconColor: PropTypes.string,
};

export default InternalCommunicationIcon;
