const PAYMENT_METHODS_DETAILS_DICT = {
  custom_method: {
    display_name: 'Custom',
    is_custom_method: true,
    reference: {
      should_include: true,
      max_length: 30,
    },
    include_force_mortgagee_option: false,
    are_multiple_payees_supported: false,
  },
  check: {
    display_name: 'Check',
    pay_to_line: {
      should_include: true,
      default_max_length: 120,
    },
    check_description: {
      should_include: true,
      max_length: 60,
    },
    mail_to_contact: {
      should_include: true,
    },
    include_force_mortgagee_option: true,
    are_multiple_payees_supported: true,
  },
  one_inc: {
    display_name: 'One Inc',
    pay_to_line: {
      should_include: true,
    },
    mail_to_contact: {
      should_include: true,
    },
    include_force_mortgagee_option: false,
    are_multiple_payees_supported: true,
    always_enable_documents: true,
    excluded_required_fields: ['phone_number', 'email', 'date_of_birth'],
    always_require_permissions_to_send_communication: true,
  },
  uk_bank_transfer: {
    display_name: 'UK Bank Transfer',
    accountNumber: {
      should_include: true,
    },
    sortCode: {
      should_include: true,
    },
    reference: {
      should_include: true,
      max_length: 18,
    },
    include_force_mortgagee_option: false,
    are_multiple_payees_supported: false,
  },
  iban_bank_transfer: {
    display_name: 'IBAN',
    iban: {
      should_include: true,
    },
    reference: {
      should_include: true,
      max_length: 30,
    },
    include_force_mortgagee_option: false,
    are_multiple_payees_supported: false,
  },
  commerce_bank: {
    display_name: 'Commerce Bank',
    pay_to_line: {
      should_include: true,
    },
    mail_to_contact: {
      should_include: true,
    },
    include_force_mortgagee_option: false,
    are_multiple_payees_supported: true,
    always_enable_documents: true,
    should_move_pending_cancel_upon_cancellation: true,
    feature_flag: 'commerce_bank_payment',
  },
  au_bank_transfer: {
    display_name: 'AU Bank Transfer',
    accountNumber: {
      should_include: true,
    },
    bsbNumber: {
      should_include: true,
    },
    reference: {
      should_include: true,
    },
    include_force_mortgagee_option: false,
    are_multiple_payees_supported: false,
  },
} as const;

export default PAYMENT_METHODS_DETAILS_DICT;
