import type { CSSProperties, ForwardedRef, ReactElement } from 'react';
import React, { forwardRef } from 'react';
import type { InputLabelProps, InputProps, SelectProps } from '@material-ui/core';
import { TextField as MuiTextField } from '@material-ui/core';
import type { InputBaseComponentProps } from '@material-ui/core/InputBase/InputBase';
import type { OutlinedTextFieldProps } from '@material-ui/core/TextField/TextField';

import { isLocalEnv, reportErrorInProductionOrThrow } from '~/Utils';

export interface TextFieldProps<T> {
  disabled?: boolean;
  error?: boolean;
  fullWidth?: boolean;
  helperText?: React.ReactNode;
  id?: string;
  InputLabelProps?: Partial<InputLabelProps>;
  multiline?: boolean;
  rows?: string | number;
  value?: T;
  onChange?: (newValue: T, event: React.ChangeEvent) => void;
  onBlur?: React.FocusEventHandler<HTMLInputElement | HTMLTextAreaElement>;
  label?: string;
  className?: string;
  autoFocus?: boolean;
  ref?: React.Ref<HTMLDivElement>;
  inputRef?: React.Ref<HTMLDivElement>;
  style?: CSSProperties;
  onFocus?: React.FocusEventHandler<HTMLInputElement | HTMLTextAreaElement>;
  placeholder?: string;
  onClick?: React.MouseEventHandler<HTMLDivElement>;
  inputProps?: InputBaseComponentProps;
  name?: string;
  variant?: 'standard' | 'filled' | 'outlined';
  rowsMax?: number;
  type?: 'number';
  preventOverrideOnChange?: boolean;
  inlineEditableField?: boolean;
  onKeyDown?: React.KeyboardEventHandler<HTMLDivElement>;
  onMouseUp?: React.MouseEventHandler<HTMLDivElement>;
  showOnly?: boolean;
  size?: 'small' | 'medium';
  fullWidthField?: boolean;

  // select only props
  select?: boolean; // should be used from the select component only
  SelectProps?: Partial<SelectProps>; // should be used from the select component only
  children?: React.ReactNode; // should be used from the select component only
  InputProps?: Partial<InputProps>; // should be used from the select component only
  IconComponent?: React.ReactNode;
}

const TextFieldInner = <T,>(
  {
    value,
    showOnly,
    disabled,
    multiline,
    rows,
    fullWidth,
    helperText,
    onChange,
    onBlur,
    error,
    id,
    variant,
    InputLabelProps,
    label,
    className,
    autoFocus,
    inputRef,
    style,
    onFocus,
    placeholder,
    onClick,
    inputProps,
    name,
    rowsMax,
    select,
    SelectProps,
    children,
    InputProps,
    type,
    inlineEditableField,
    size,
    preventOverrideOnChange,
    onKeyDown,
    onMouseUp,
    fullWidthField,
    ...rest
  }: TextFieldProps<T>,
  ref: React.ForwardedRef<HTMLDivElement>
): ReactElement => {
  if (Object.keys(rest).length > 0 && isLocalEnv()) {
    reportErrorInProductionOrThrow(
      `Additional unimplemented props was passed to TextField component (directly or from Multi/Single select fields), ${Object.keys(
        rest
      )}`,
      true
    );
  }

  const selectProps = {
    select,
    SelectProps,
    children,
    InputProps,
  };
  const calcId = select && !SelectProps?.multiple ? undefined : id;

  const muiVariant = inlineEditableField ? 'outlined' : variant || 'standard';

  const editableDisplayProps = inlineEditableField ? (select ? selectEditableFieldProps : editableFieldProps) : {};

  const fullWidthDisplayProps = fullWidthField ? fullWidthProps : {};

  return (
    <MuiTextField
      disabled={disabled || showOnly}
      value={value}
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      onChange={preventOverrideOnChange ? onChange : (event) => (onChange ? onChange(event.target.value, event) : null)}
      onBlur={onBlur}
      error={error}
      helperText={helperText}
      id={calcId}
      name={name || id}
      multiline={multiline}
      rows={rows}
      fullWidth={fullWidth}
      size={size || 'small'}
      InputLabelProps={InputLabelProps || { htmlFor: calcId }}
      variant={muiVariant}
      label={label}
      className={className}
      autoFocus={autoFocus}
      ref={ref}
      inputRef={inputRef}
      style={style}
      onFocus={onFocus}
      placeholder={placeholder}
      onClick={onClick}
      inputProps={inputProps}
      rowsMax={rowsMax}
      type={type}
      onKeyDown={onKeyDown}
      onMouseUp={onMouseUp}
      {...selectProps}
      {...editableDisplayProps}
      {...fullWidthDisplayProps}
      {...rest}
    />
  );
};

const TextField = forwardRef(TextFieldInner) as <T>(
  props: TextFieldProps<T> & { ref?: ForwardedRef<HTMLDivElement> }
) => ReactElement;

const editableFieldProps: Partial<OutlinedTextFieldProps> = {
  InputProps: {
    classes: {
      notchedOutline: 'border-0 focus:border-1 rounded-3xl',
    },
  },
};

const selectEditableFieldProps: Partial<OutlinedTextFieldProps> = {
  InputProps: {
    classes: {
      notchedOutline: 'border-0 focus:border-1 rounded-3xl',
    },
  },
  InputLabelProps: {
    className: 'text-slate-800',
  },
  SelectProps: {
    classes: {
      outlined: 'focus:bg-transparent',
      iconOutlined: 'hidden',
    },
  },
};

const fullWidthProps = {
  classes: {
    root: 'w-full',
  },
};

export default TextField;
