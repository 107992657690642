import React from 'react';

import { useClaim } from '~/components/ClaimContainer';
import { ContactEntity } from '~/components/Contact';
import type { InvolvedPersonType } from '~/components/Contacts/ContactMiniCard/types';
import Text from '~/components/core/TextComponents/Text';
import type { ContactFullModel } from '~/components/types/contact-types';

interface ContactRepresentativeLinkPropsType {
  contact: ContactFullModel;
}

const RepresentativeLink: React.FC<ContactRepresentativeLinkPropsType> = ({ contact }) => {
  const { claim } = useClaim();

  return (
    <>
      {claim.incident.involved_persons
        .filter(
          (involved_person: InvolvedPersonType) =>
            involved_person.contact_id === contact.id &&
            (involved_person.representative_contact_id || involved_person.attorney_contact_id)
        )
        .map((involved_person: InvolvedPersonType) => (
          <div className="inline-flex" key={involved_person.id}>
            <Text variant={Text.VARIANTS.SM} colorVariant={Text.COLOR_VARIANTS.ERROR} weight={Text.WEIGHTS.REGULAR}>
              Represented
            </Text>
            <span className="ml-4">
              {involved_person.representative_contact_id && (
                <ContactEntity contactId={involved_person.representative_contact_id} hideDisplayName />
              )}
              {involved_person.attorney_contact_id && (
                <ContactEntity contactId={involved_person.attorney_contact_id} hideDisplayName />
              )}
            </span>
          </div>
        ))}
    </>
  );
};

export default RepresentativeLink;
