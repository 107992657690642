import React from 'react';
import PropTypes from 'prop-types';
import { Formik } from 'formik';
import * as Yup from 'yup';

import Button from '~/components/core/Atomic/Buttons/Button';

import CardDialog from '../../CardDialog';
import { TextFieldFormik } from '../../TextFieldFormik';

import PaymentRequestDisplay from './PaymentRequestDisplay';

import { useStyles } from '../../../assets/styles';

function PaymentRequestCancelDialog(props) {
  const {
    paymentRequest,
    payableType,
    onCancelPaymentRequest,
    cardDialogProps,
    onCancel,
    claim,
    exposure,
    payableWithReserve,
    AdditionalDialogHeader,
  } = props;
  const classes = useStyles();

  return (
    <Formik
      initialValues={{
        ...paymentRequest,
        note: '',
      }}
      validationSchema={Yup.object().shape({
        note: Yup.string().required('Required'),
      })}
      enableReinitialize
      onSubmit={(values, formikProps) => {
        onCancelPaymentRequest(values)
          .then(() => formikProps.resetForm())
          .catch(() => {
            formikProps.setSubmitting(false);
          });
      }}
    >
      {(formikProps) => {
        const { isSubmitting, handleSubmit } = formikProps;

        return (
          <CardDialog
            title="Cancel Payment"
            {...cardDialogProps}
            onClose={() => {
              formikProps.handleReset();
              onCancel();
            }}
          >
            {AdditionalDialogHeader}
            <PaymentRequestDisplay
              paymentRequest={paymentRequest}
              exposure={exposure}
              claim={claim}
              payableType={payableType}
              payableWithReserve={payableWithReserve}
            />

            <div className={classes.inputContainer}>
              <TextFieldFormik id="note" label="Note" rows="2" className={classes.textField} fullWidth multiline />
            </div>
            <div className={classes.buttonsContainer}>
              <Button variant="contained" color="primary" onClick={handleSubmit} disabled={isSubmitting}>
                Cancel Payment Request
              </Button>
            </div>
          </CardDialog>
        );
      }}
    </Formik>
  );
}
PaymentRequestCancelDialog.propTypes = {
  cardDialogProps: PropTypes.object.isRequired,
  paymentRequest: PropTypes.object.isRequired,
  payableType: PropTypes.string.isRequired,
  onCancelPaymentRequest: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
  claim: PropTypes.object.isRequired,
  exposure: PropTypes.object.isRequired,
  payableWithReserve: PropTypes.object.isRequired,
  AdditionalDialogHeader: PropTypes.node,
};

export default PaymentRequestCancelDialog;
