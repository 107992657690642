import React, { useState } from 'react';
import axios from 'axios';

import CardDialog from '~/components/CardDialog';
import AlertBanner from '~/components/core/AlertBanner';
import Button from '~/components/core/Atomic/Buttons/Button';
import { AddIcon } from '~/components/deprecatedMuiIcons';
import useOrganization from '~/components/OrganizationContext';
import CategoriesTable from '~/components/SystemConfiguration/NotificationsConfiguration/CategoriesTable';
import AddEditCategoryDialog from '~/components/SystemConfiguration/NotificationsConfiguration/EditCateogryDialog';
import { useSysconfig } from '~/components/SystemConfiguration/SystemConfigurationScreen';
import useDataFetcher from '~/components/useDataFetcher';
import { reportAxiosError } from '~/Utils';

export const CategoriesColors = {
  'berry-600': 'berry-600',
  'berry-700': 'berry-700',
  'blue-600': 'blue-600',
  'blueSky-700': 'blueSky-700',
  'grey-600': 'grey-600',
  'orange-700': 'orange-700',
  'pink-600': 'pink-600',
  'purple-600': 'purple-600',
  'purple-700': 'purple-700',
  'teal-600': 'teal-600',
  'yellow-800': 'yellow-800',
};

export interface CategoryModel {
  id: number;
  color: keyof typeof CategoriesColors;
  key: string;
  label: string;
  is_disabled?: boolean;
}
const getBaseRoute = (organization_id: number) => `/sysconfig/api/v1/organizations/${organization_id}/categories`;

const NotificationsCategories: React.FC = () => {
  const [isUpdating, setIsUpdating] = useState<boolean>(false);
  const [categoryToUpdate, setCategoryToUpdate] = useState<CategoryModel | undefined>(undefined);
  const [showAddEditDialog, setShowAddEditDialog] = useState<boolean>(false);
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  const { organization } = useSysconfig();
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  const { reloadCategories } = useOrganization();
  const {
    isError,
    isLoading,
    data: categories,
    reloadData,
  } = useDataFetcher(getBaseRoute(organization.id), {
    params: { category_type: 'notification_category' },
  });

  const handleEditClicked = (category: CategoryModel) => {
    setShowAddEditDialog(true);
    setCategoryToUpdate(category);
  };

  const handleAddEdit = async (category: Partial<CategoryModel>) => {
    try {
      setIsUpdating(true);
      if (category.id) {
        await axios.patch(`${getBaseRoute(organization.id)}/${category.id}`, category);
      } else {
        await axios.post(getBaseRoute(organization.id), category);
      }
      await reloadData();
      await reloadCategories();
      setCategoryToUpdate(undefined);
      setShowAddEditDialog(false);
    } catch (err) {
      await reportAxiosError(err);
    } finally {
      setIsUpdating(false);
    }
  };

  const handleToggleDisabled = async (categoryId: number, isDisabled: boolean) => {
    await handleAddEdit({ id: categoryId, is_disabled: isDisabled });
  };

  return (
    <CardDialog title="Manage Notification's Categories">
      <AlertBanner
        alertType={AlertBanner.ALERT_TYPES.INFO}
        title="Please Note"
        note="Deleting or changing a category will affect the related notifications"
        withIcon
      />
      <div className="mt-20 flex h-full flex-row items-end justify-end pb-20">
        <Button
          color="primary"
          onClick={() => setShowAddEditDialog(true)}
          disabled={isUpdating || isError || isLoading}
        >
          <AddIcon />
          Add Category
        </Button>
      </div>
      <CategoriesTable
        categories={categories}
        isLoading={isLoading}
        isError={isError}
        isUpdating={isUpdating}
        handleEditClicked={handleEditClicked}
        handleToggleDisabled={handleToggleDisabled}
      />
      {showAddEditDialog && (
        <AddEditCategoryDialog
          category={categoryToUpdate}
          onCancel={() => {
            setCategoryToUpdate(undefined);
            setShowAddEditDialog(false);
          }}
          onSubmit={handleAddEdit}
          existingKeys={categories.map(({ key }: { key: string }) => key)}
        />
      )}
    </CardDialog>
  );
};

export default NotificationsCategories;
