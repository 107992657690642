import React from 'react';

import type { FnolAiInboxRecord, InboxType } from '~/components/Fnol/FnolAiInbox/types';
import { useCms } from '~/components/hooks/useCms';
import useDataFetcher from '~/components/useDataFetcher';

export interface IFnolAiInboxData {
  inboxItems: FnolAiInboxRecord[];
  isLoading: boolean;
  isError: boolean;
  reloadInboxItems: () => Promise<void>;
}

interface FnolAiInboxDataProps {
  inboxType?: InboxType;
  shouldFetch?: boolean;
}

export const useFnolAiInboxData = ({ inboxType, shouldFetch = false }: FnolAiInboxDataProps): IFnolAiInboxData => {
  const { userOrganization } = useCms();
  const [inboxItems, setInboxItems] = React.useState<IFnolAiInboxData['inboxItems']>([]);
  const url = '/api/v1/claims/fnol_ai/inbox';
  const { isLoading, isError, data, reloadData } = useDataFetcher(
    url,
    { params: { inbox_type: inboxType } },
    !!inboxType && shouldFetch
  );

  const tryParseIncidentTypes = React.useCallback(
    (record: FnolAiInboxRecord): FnolAiInboxRecord => {
      const { sub_organization_id, line_of_business, type, sub_type } = record;
      const subOrganization = userOrganization?.sub_organizations?.find(
        (subOrganization) => subOrganization.id === sub_organization_id
      );

      const lobIncidentConfigurationsDict = subOrganization
        ? subOrganization.lob_incident_configurations_dict
        : userOrganization.lob_incident_configurations_dict;
      const incidentConfiguration = lobIncidentConfigurationsDict[line_of_business];
      const typeObject = incidentConfiguration?.incident_details?.types?.find(
        (incidentType: { id: string }) => incidentType.id === type
      );
      const subTypeObject = typeObject?.sub_types?.find((subType: { id: string }) => subType.id === sub_type);
      const typeName = typeObject?.desc || type?.replace(/_/g, ' ');
      const subTypeName = subTypeObject?.desc || sub_type?.replace(/_/g, ' ');
      return { ...record, type: typeName, sub_type: subTypeName };
    },
    [userOrganization]
  );

  React.useEffect(() => {
    if (data?.inbox_items) {
      const parseInboxItems = data.inbox_items.map(tryParseIncidentTypes);
      setInboxItems(parseInboxItems);
    }
  }, [data, userOrganization, tryParseIncidentTypes]);

  return {
    inboxItems,
    isLoading,
    isError,
    reloadInboxItems: reloadData,
  };
};
