import React from 'react';
import type { FormikProps, FormikValues } from 'formik';
import { useFormikContext } from 'formik';

import useFormikChangeListener from '~/components/core/Formik/FormikChangeListener';
import StateMultiselectFormik from '~/components/core/Formik/StatesMultiselectFormik/StateMultiSelectFormik';
import CountryAutocompleteFormik from '~/components/CountryAutocompleteFormik';
import { useSysconfig } from '~/components/SystemConfiguration/SystemConfigurationScreen';
import SubOrganizationMultiselectFormik from '~/components/TPA/SubOrganizations/SubOrganizationMultiselectFormik';
import { getOrganizationCountryCode, isCountryWithStates } from '~/Utils';

export interface CountriesStatesSubOrgsAutocompleteFormikProps {
  shouldDisplayCountries?: boolean;
  shouldDisplayStates?: boolean;
  shouldDisplaySubOrgs?: boolean;
  countryFieldId: string;
  statesFieldId: string;
  allStatesFieldId?: string;
  subOrgsFieldId: string;
  allSubOrgsFieldId?: string;
  specificSubOrgIdOptions?: number[];
}
const StatReserveUpsertDialogConditionFormik: React.FC<CountriesStatesSubOrgsAutocompleteFormikProps> = ({
  shouldDisplayCountries,
  shouldDisplayStates,
  shouldDisplaySubOrgs,
  countryFieldId,
  statesFieldId,
  allStatesFieldId,
  subOrgsFieldId,
  allSubOrgsFieldId,
  specificSubOrgIdOptions,
}) => {
  const { values, isSubmitting, setFieldValue }: FormikProps<FormikValues> = useFormikContext();
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  const { organization } = useSysconfig();
  useFormikChangeListener({
    listenForKeys: subOrgsFieldId,
    onChange: () => {
      if (specificSubOrgIdOptions && specificSubOrgIdOptions.length === 1) {
        setFieldValue(subOrgsFieldId, [...specificSubOrgIdOptions]);
      }
    },
    runOnFirstRender: true,
  });
  return (
    <div className="grid grid-cols-1 gap-16">
      {shouldDisplayCountries ? (
        <div>
          <CountryAutocompleteFormik
            id={countryFieldId}
            countriesConfigurationKey="contact_location"
            statesFieldIdToResetOnChange={statesFieldId}
          />
        </div>
      ) : null}
      {shouldDisplayStates ? (
        <div>
          <StateMultiselectFormik
            statesFieldId={statesFieldId}
            allSelectedFieldId={allStatesFieldId}
            disabled={isSubmitting || !(shouldDisplayCountries ? isCountryWithStates(values.country) : true)}
            showAllInRenderedSelectedOptions
            shouldDisplayAllOption={shouldDisplaySubOrgs}
            country={
              shouldDisplayCountries && isCountryWithStates(values.country)
                ? values.country
                : getOrganizationCountryCode(organization)
            }
          />
        </div>
      ) : null}
      {shouldDisplaySubOrgs ? (
        <div>
          <SubOrganizationMultiselectFormik
            subOrganizationsFieldId={subOrgsFieldId}
            allSelectedFieldId={allSubOrgsFieldId}
            disabled={isSubmitting}
            shouldDisplayAllOption={shouldDisplayStates}
            showAllInRenderedSelectedOptions
            specificSubOrgIdOptions={specificSubOrgIdOptions}
          />
        </div>
      ) : null}
    </div>
  );
};

export default StatReserveUpsertDialogConditionFormik;
