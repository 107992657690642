import React, { useCallback, useEffect, useState } from 'react';
import axios from 'axios';

import { usePollingWithFailureTracking } from '~/components/AiChat/hooks/usePollingWithFailureTracking';
import {
  generateAssessDamageActionData,
  generateCommunicationActionData,
} from '~/components/AiChat/SideBarDialog/Actions/demoActions';
import { useActionsFeatureFlags } from '~/components/AiChat/SideBarDialog/Actions/useActionsFeatureFlags';
import type { AiAction } from '~/components/AiChat/types';
import { AI_ACTION_TYPES } from '~/components/AiChat/types';
import { useClaim } from '~/components/ClaimContainer';

const POLLING_DELAY = 5000;

export interface IActionsContextData {
  actions: AiAction[];
  isLoading: boolean;
  isError: boolean;
  reloadData: () => Promise<void>;
}

const ActionsContext = React.createContext<IActionsContextData>({
  actions: [],
  isLoading: true,
  isError: false,
  reloadData: Promise.resolve,
});

export const ActionsContextProvider: React.FC = ({ children }) => {
  const [actions, setActions] = useState<AiAction[]>([]);
  const [isLoading, setIsLoading] = useState(false);
  const [isError, setIsError] = useState(false);
  const [demoActions, setDemoActions] = useState<AiAction[]>([]);
  const { claim } = useClaim();
  const { isActionsTabEnabled, actionFlagEnabledMapping } = useActionsFeatureFlags();

  const generateDemoActions = useCallback((): AiAction[] => {
    const allowedDemoActions = [] as AiAction[];
    if (!actionFlagEnabledMapping) return allowedDemoActions;

    if (actionFlagEnabledMapping[AI_ACTION_TYPES.COMMUNICATE]) {
      allowedDemoActions.push(generateCommunicationActionData());
    }

    if (actionFlagEnabledMapping[AI_ACTION_TYPES.ASSESS_DAMAGE]) {
      allowedDemoActions.push(generateAssessDamageActionData());
    }

    return allowedDemoActions;
  }, [actionFlagEnabledMapping]);

  const fetchActions = useCallback(async () => {
    if (isActionsTabEnabled) {
      try {
        setIsLoading(true);
        const res = await axios.get(`/api/v1/claims/${claim.id}/actions`);
        if (res) {
          // add demo actions only if there are regular actions - a requirement from product
          const actions = res.data?.actions ? [...demoActions, ...(res.data?.actions || [])] : [];
          setActions(actions);
        }
      } catch {
        setIsError(true);
        // Re-throw to trigger failure tracking
        throw new Error('Failed to fetch total statuses count');
      } finally {
        setIsLoading(false);
      }
    }
  }, [claim.id, isActionsTabEnabled, demoActions]);

  usePollingWithFailureTracking(fetchActions, {
    pollingDelay: POLLING_DELAY,
    enabled: isActionsTabEnabled,
  });

  useEffect(() => {
    // this useEffect should run only once when the component is mounted
    setDemoActions(generateDemoActions());
    fetchActions();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <ActionsContext.Provider value={{ actions, isLoading, isError, reloadData: fetchActions }}>
      {children}
    </ActionsContext.Provider>
  );
};

export const useActions = (): IActionsContextData => {
  return React.useContext(ActionsContext);
};
