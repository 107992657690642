import React from 'react';
import { Remarkable } from 'remarkable';

import { useSanitizer } from '~/components/hooks/useSanitizer';
import cn from '~/Utils/cn';

import './MarkdownSection.css';

export const MarkdownSection: React.FC<{ markdown: string; className?: string }> = ({ markdown, className }) => {
  const { sanitize } = useSanitizer();
  const md = new Remarkable({ breaks: true });
  const parsedMarkdown = md.render(markdown);
  const sanitizedHtml = sanitize(parsedMarkdown);

  if (!sanitizedHtml) {
    return null;
  }

  return (
    <div
      className={cn('task-markdown flex flex-col px-12 text-sm', className)}
      dangerouslySetInnerHTML={{ __html: sanitizedHtml }}
    />
  );
};
