import useDataFetcher from '~/components/useDataFetcher';

export interface InboxStatus {
  new_items_count: number;
}

export interface IFnolAiInboxStatus {
  inboxStatus: InboxStatus;
  reloadInboxStatus: () => Promise<void>;
  isInboxStatusLoading: boolean;
  isInboxStatusError: boolean;
}

interface FnolAiInboxStatusProps {
  shouldFetch: boolean;
}

export const useFnolAiInboxStatus = ({ shouldFetch }: FnolAiInboxStatusProps): IFnolAiInboxStatus => {
  const {
    data: inboxStatus,
    reloadData: reloadInboxStatus,
    isLoading: isInboxStatusLoading,
    isError: isInboxStatusError,
  } = useDataFetcher('/api/v1/claims/fnol_ai/inbox/status', {}, shouldFetch);

  return {
    inboxStatus,
    reloadInboxStatus,
    isInboxStatusLoading,
    isInboxStatusError,
  };
};
