import React, { useState } from 'react';

import { useStyles } from '~/assets/styles';
import CardDialog from '~/components/CardDialog';
import ClaimSearchContainer from '~/components/ClaimSearch';
import { SelectClaim } from '~/components/GenericTemplates/GenericTemplatesConfiguration/GenericTemplateCard/GenericTemplatePreview/GenericTemplatePreviewContainer';
import LoadingIndicator from '~/components/LoadingIndicator';
import type { EmailConfigurationPreviewContainerProps } from '~/components/SystemConfiguration/EmailConfiguration/emailConfigurationTypes';
import { useSysconfig } from '~/components/SystemConfiguration/SystemConfigurationScreen';
import useDataFetcher from '~/components/useDataFetcher';
import { useFetchClaim } from '~/Utils/ClaimUtils';

import EmailConfigurationInnerPreview from './EmailConfigurationInnerPreview';

type handleResultClick = (claimId: number) => void;

const EmailConfigurationPreviewContainer: React.FC<
  EmailConfigurationPreviewContainerProps & { setDialogTitle: React.Dispatch<React.SetStateAction<string>> }
> = ({ emailConfiguration, organizationSubOrgIds }) => {
  const [claimId, setClaimId] = useState<null | number>(null);
  const [claim, isLoadingClaim, isErrorClaim] = useFetchClaim(claimId);

  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  const { organization } = useSysconfig();
  const classes = useStyles();

  const { isLoading, isError, data } = useDataFetcher(
    `/api/v1/email_configuration/organizations/${organization.id}/${emailConfiguration?.id}/${claimId}/generate_email_configuration_example`,
    {},
    !!claim
  );

  const handleResultClick: handleResultClick = (claimIdFromSearch) => {
    setClaimId(claimIdFromSearch);
  };
  const subOrganizationIds = emailConfiguration?.sub_organization_id
    ? [emailConfiguration?.sub_organization_id]
    : organizationSubOrgIds;

  if (!claimId) {
    return (
      <ClaimSearchContainer
        classes={classes}
        SelectComponent={SelectClaim}
        selectComponentProps={{ handleResultClick }}
        subOrganizationIds={subOrganizationIds}
      />
    );
  }
  if (!claim || isLoadingClaim || isLoading || isErrorClaim || isError) {
    return <LoadingIndicator isError={isError || isErrorClaim} isLoading />;
  }

  if (claim && data) {
    return <EmailConfigurationInnerPreview title={data.email_title} signature={data.user_email_signature_html} />;
  }
  return null;
};

const EmailConfigurationPreviewContainerCardDialogWrapper: React.FC<EmailConfigurationPreviewContainerProps> = (
  props
) => {
  const [dialogTitle, setDialogTitle] = useState<string>('Choose Claim to generate email title and signature example');
  return (
    <CardDialog isDialog title={dialogTitle} onClose={props.onClose} fullWidth maxWidth="md">
      <EmailConfigurationPreviewContainer {...props} setDialogTitle={setDialogTitle} />
    </CardDialog>
  );
};

export default EmailConfigurationPreviewContainerCardDialogWrapper;
