import axios from 'axios';

import { useClaim } from '../../ClaimContainer';
import { useCms } from '../../hooks/useCms';

export type RouteGetter = (id: number) => string;

type UploadUrlGetter = (file: File) => Promise<{
  uploadUrl: string;
  storageFilename: string;
}>;

export const useLegalActionsBackendRoutes = (
  legalActionId?: number
): {
  legalActionsConfiguration: string;
  legalActionsBase: string;
  legalAction: {
    base: string;
    close: string;
    reopen: string;
    parties: string;
    party: {
      base: RouteGetter;
      terminate: RouteGetter;
    };
    logs: {
      base: string;
      legal_action_logs: string;
      pros_and_cons: string;
      legal_action_log: RouteGetter;
      pro_and_con: RouteGetter;
      negotiations: string;
      negotiation: RouteGetter;
    };
    documents: string;
    document: RouteGetter;
    details: string;
    jurisdiction: string;
    dates_of_service: string;
    date_of_service: RouteGetter;
    dispute_resolution_professionals: string;
    dispute_resolution_professional: RouteGetter;
    alternative_dispute_resolution: string;
    upload_url: string;
  };
  getUploadUrl: UploadUrlGetter;
} => {
  const { claim } = useClaim();
  const { userOrganization } = useCms();

  const legalActionsConfiguration = `/api/v1/organizations/${userOrganization?.id}/legal_actions_configuration`;
  const legalActionsBase = `/api/v1/claims/${claim?.id}/legal_actions`;
  const legalActionBase = `${legalActionsBase}/${legalActionId}`;

  const logsBase = `${legalActionBase}/logs`;
  const logsLegalActionLogs = `${logsBase}/legal_action_logs`;
  const logsProsAndCons = `${logsBase}/pros_and_cons`;

  const partyBase = (partyId: number) => `${legalActionBase}/parties/${partyId}`;

  const legalAction = {
    base: legalActionBase,
    close: `${legalActionBase}/close`,
    reopen: `${legalActionBase}/reopen`,
    parties: `${legalActionBase}/parties`,
    party: {
      base: partyBase,
      terminate: (partyId: number) => `${partyBase(partyId)}/terminate_representation`,
    },
    logs: {
      base: `${legalActionBase}/logs`,
      legal_action_logs: logsLegalActionLogs,
      pros_and_cons: logsProsAndCons,
      legal_action_log: (legalActionLogId: number) => `${logsLegalActionLogs}/${legalActionLogId}`,
      pro_and_con: (proAndConId: number) => `${logsProsAndCons}/${proAndConId}`,
      negotiations: `${logsBase}/negotiations`,
      negotiation: (negotiationId: number) => `${logsBase}/negotiations/${negotiationId}`,
    },
    documents: `${legalActionBase}/documents`,
    document: (documentId: number) => `${legalActionBase}/documents/${documentId}`,
    details: `${legalActionBase}/details`,
    jurisdiction: `${legalActionBase}/jurisdiction`,
    dates_of_service: `${legalActionBase}/dates_of_service`,
    date_of_service: (dateOfServiceId: number) => `${legalActionBase}/dates_of_service/${dateOfServiceId}`,
    dispute_resolution_professionals: `${legalActionBase}/dispute_resolution_professionals`,
    dispute_resolution_professional: (disputeResolutionProfessionalId: number) =>
      `${legalActionBase}/dispute_resolution_professionals/${disputeResolutionProfessionalId}`,
    alternative_dispute_resolution: `${legalActionBase}/adr`,
    upload_url: `${legalActionBase}/upload_url`,
  };

  const getUploadUrl: UploadUrlGetter = async (file: File) => {
    const response = await axios.post(`${legalAction.base}/upload_url`, {
      file_type: file.type,
      file_size: file.size,
    });
    return {
      uploadUrl: response.data.upload_url,
      storageFilename: response.data.storage_filename,
    };
  };

  return {
    legalActionsBase,
    legalAction,
    legalActionsConfiguration,
    getUploadUrl,
  };
};
