import React from 'react';
import CircularProgress from '@material-ui/core/CircularProgress';

import Button from '~/components/core/Atomic/Buttons/Button';

export const LoadingButton: React.FC<{
  text: string;
  onClick: () => Promise<void>;
  variant?: 'text' | 'outlined' | 'contained';
  icon?: React.ReactNode;
  className?: string;
  disabled?: boolean;
}> = ({ text, onClick, variant, icon, className, disabled }) => {
  const [isLoading, setIsLoading] = React.useState(false);

  const onClickHandler = async () => {
    setIsLoading(true);
    await onClick();
    setIsLoading(false);
  };

  return (
    <Button
      onClick={onClickHandler}
      variant={variant}
      color="primary"
      disabled={disabled || isLoading}
      className={className}
      classes={{ label: 'leading-none' }}
      startIcon={isLoading ? <CircularProgress classes={{ colorPrimary: 'text-slate-700' }} size={14} /> : icon}
    >
      {text}
    </Button>
  );
};
