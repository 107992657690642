import * as SentryPack from '@sentry/react';

import { getFrontendVersion, isProductionOrUat, isRemote } from './Utils';

class Sentry {
  initialized;

  constructor() {
    this.initialized = false;
  }

  initialize(environment) {
    if (this.initialized) {
      return;
    }

    const replaysOnErrorSampleRate = isProductionOrUat() ? 1.0 : 0;
    try {
      if (isRemote()) {
        SentryPack.init({
          dsn: 'https://14d4983f51854a85902d400819bd4a60@o4504669249732608.ingest.sentry.io/4505204663320576',
          release: import.meta.env.VITE_SENTRY_RELEASE,
          integrations: [
            new SentryPack.BrowserTracing(),
            new SentryPack.Replay({ maskAllText: false, blockAllMedia: false }),
          ], // Performance Monitoring
          tracesSampleRate: 0.02, // Capture 100% of the transactions, reduce in production!
          // Session Replay
          replaysOnErrorSampleRate, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
          environment: environment.toLowerCase(),
        });
      }
      this.initialized = true;
    } catch (error) {
      // todo decide on this
      if (!isProductionOrUat()) {
        // eslint-disable-next-line no-console
        console.error(error);
      }
    }
  }

  setUser(user) {
    if (!this.initialized) {
      return;
    }
    try {
      if (isRemote()) {
        if (user?.impersonating_user_name) {
          SentryPack.setUser({
            username: user?.impersonating_user_name,
          });
          SentryPack.setTag('impersonated_user', user?.username ?? '');
          SentryPack.setTag('impersonated_user_email', user?.email ?? '');
        } else {
          SentryPack.setUser({
            username: user?.username ?? '',
            email: user?.email ?? '',
          });
          SentryPack.setTag('user_email', user?.email ?? '');
        }

        SentryPack.setTag('organization_name', user?.organization_name ?? '');
        SentryPack.setTag('organization_id', user?.organization_id ?? '');
        SentryPack.setTag('frontend_version', getFrontendVersion());
      }
    } catch (error) {
      if (!isProductionOrUat()) {
        // eslint-disable-next-line no-console
        console.error(error);
      }
    }
  }
}

export default Sentry;
