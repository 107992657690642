import React from 'react';

import CommunicationNote from '~/components/Notes/NoteContent/CommunicationNote';
import DocumentNote from '~/components/Notes/NoteContent/DocumentNote';
import DocumentsNote from '~/components/Notes/NoteContent/DocumentsNote';
import GeneralNote from '~/components/Notes/NoteContent/GeneralNote';
import InternalCommunicationNote from '~/components/Notes/NoteContent/InternalCommunicationNote';
import PhotosNote from '~/components/Notes/NoteContent/PhotosNote';
import type { NoteContentProps } from '~/components/Notes/types';

const MAX_NOTE_ROWS = 2;

const NoteContent: React.FC<NoteContentProps> = ({ note, isExpanded, onCollapseClick, isCollapsible }) => {
  let NoteComponent;
  switch (note.type) {
    case 'communication_note':
      NoteComponent = CommunicationNote;
      break;
    case 'document_note':
      NoteComponent = DocumentNote;
      break;
    case 'multiple_documents_note':
      NoteComponent = DocumentsNote;
      break;
    case 'photos_note':
      NoteComponent = PhotosNote;
      break;
    case 'internal_communication_note':
      NoteComponent = InternalCommunicationNote;
      break;
    default:
      NoteComponent = GeneralNote;
      break;
  }

  return (
    <NoteComponent
      note={note}
      isCollapsible={isCollapsible}
      onCollapseClick={onCollapseClick}
      isExpanded={isExpanded}
      maxRows={MAX_NOTE_ROWS}
    />
  );
};

export default NoteContent;
