import React from 'react';
import PropTypes from 'prop-types';

import LoadingIndicator from '../../LoadingIndicator';

const LoadingSwitch = ({ isLoading, isError, children, LoadingComponent, ErrorComponent }) => {
  return isLoading || isError ? (
    <LoadingIndicator isError={isError} LoadingComponent={LoadingComponent} ErrorComponent={ErrorComponent} />
  ) : (
    children
  );
};

LoadingSwitch.propTypes = {
  isLoading: PropTypes.bool.isRequired,
  isError: PropTypes.bool,
  children: PropTypes.node,
  LoadingComponent: PropTypes.elementType,
  ErrorComponent: PropTypes.elementType,
};

export default LoadingSwitch;
