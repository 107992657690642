import React from 'react';

import SkeletonTable from '~/components/core/Skeletons/SkeletonTable';
import useNotesConfiguration from '~/components/hooks/useNotesConfiguration';
import NotesInner from '~/components/Notes/NotesInner';
import type { NotesProps } from '~/components/Notes/types';

const Notes: React.FC<NotesProps> = ({ claim, noCard, hideTypeFilter, isActive, exposureOptionsIds }) => {
  const { configuration, isLoading, isError } = useNotesConfiguration();

  if (isLoading || isError || !configuration) {
    return <SkeletonTable rowsCount={10} columnsCount={1} cellClassName="h-[120px]" isError={isError} />;
  }

  return (
    <NotesInner
      claim={claim}
      noCard={noCard}
      hideTypeFilter={hideTypeFilter}
      isActive={isActive}
      exposureOptionsIds={exposureOptionsIds}
      notesConfiguration={configuration}
    />
  );
};

export default Notes;
