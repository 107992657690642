import React, { forwardRef } from 'react';
import { IconButton as MuiIconButton } from '@material-ui/core';

/**
 This Component is a wrapper on MUI component.
 This component should be imported and not MUI directly!
**/
export interface IconButtonProps {
  // Add any additional custom props here
  onClick?: React.MouseEventHandler<HTMLButtonElement>;
  className?: string;
  disabled?: boolean;
  variant?: 'text' | 'outlined' | 'contained';
  size?: 'small' | 'medium';
  color?: 'inherit' | 'primary' | 'secondary' | 'default';
  key?: React.Key;
  style?: React.CSSProperties;
  title?: string;
  component?: React.ElementType;
  to?: string;
  target?: React.HTMLAttributeAnchorTarget;
  href?: string;
}

const IconButton: React.FC<React.PropsWithChildren<IconButtonProps>> = forwardRef<
  HTMLButtonElement,
  React.PropsWithChildren<IconButtonProps>
>(({ children, ...buttonProps }, ref) => {
  return (
    <MuiIconButton {...buttonProps} ref={ref}>
      {children}
    </MuiIconButton>
  );
});

IconButton.displayName = 'IconButton';

export default IconButton;
