import React from 'react';
import axios from 'axios';

import CardDialog from '~/components/CardDialog';
import { ConfirmModal } from '~/components/ConfirmModal';
import Button from '~/components/core/Atomic/Buttons/Button';
import IconButton from '~/components/core/Atomic/Buttons/IconButton';
import SortableTable from '~/components/core/Tables/SortableTable';
import Text from '~/components/core/TextComponents/Text';
import useCurrencyFormatter from '~/components/CurrencyFormatterContext';
import { AddIcon } from '~/components/deprecatedMuiIcons';
import type {
  StatReserveConfiguration,
  StatReserveConfigurationSubRule,
} from '~/components/SystemConfiguration/ClaimConfiguration/StatReserveConfiguration/StatReserveConfigurationPage';
import type { StatReserveConfigurationRowWithSortableId } from '~/components/SystemConfiguration/ClaimConfiguration/StatReserveConfiguration/StatReserveConfigurationTable';
import StatReserveUpsertDialog from '~/components/SystemConfiguration/ClaimConfiguration/StatReserveConfiguration/StatReserveUpsertDialog';
import { useSysconfig } from '~/components/SystemConfiguration/SystemConfigurationScreen';
import { CONFIGURATION_FEATURES_NAMES } from '~/Types';
import { isCountryWithStates, isFeatureEnabled, isOrganizationWithStates, reportAxiosError } from '~/Utils';

import OverflowArrayTextDisplayWithTooltip from '../../../core/OverflowArrayTextWithTooltip/OverflowArrayTextDisplayWithTooltip';
import PencilIcon from '../../../icons/PencilIcon';
import TrashIcon from '../../../icons/TrashIcon';

export interface StatReserveConfigurationUpsertData extends StatReserveConfiguration {
  isNewRule?: boolean;
}

enum SubRulesTableColumns {
  country = 'Country',
  state = 'State',
  SubOrg = 'Sub-org',
  indemnityReserve = 'Indemnity Reserve',
  expenseReserve = 'Expense Reserve',
  editDelete = 'EditDelete',
}

export interface StatReserveConfigurationSubRulesTableProps {
  subRulesReservesList: StatReserveConfigurationSubRule[];
  statReserveConfiguration: StatReserveConfigurationRowWithSortableId;
  specificSubOrgIdOptions?: number[];
  reloadData: () => void;
}
const StatReserveConfigurationSubRulesTable: React.FC<StatReserveConfigurationSubRulesTableProps> = ({
  subRulesReservesList,
  statReserveConfiguration,
  specificSubOrgIdOptions,
  reloadData,
}) => {
  const { currencyFormatter } = useCurrencyFormatter();
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  const { organization } = useSysconfig();
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  const organizationId = organization.id;
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  const subOrganizationEnabled: boolean = organization.sub_organizations_enabled;
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  const subOrganizations: { id: number; name: string }[] = organization.sub_organizations;
  const [deleteConfirmSelectedRow, setDeleteConfirmSelectedRow] =
    React.useState<StatReserveConfigurationSubRule | null>(null);
  const [selectedRow, setSelectedRow] = React.useState<StatReserveConfigurationUpsertData | null>(null);
  const multiCountryOrganization = isFeatureEnabled(
    organization,
    CONFIGURATION_FEATURES_NAMES.MULTI_COUNTRY_ORGANIZATION
  );
  const handleAddRuleClicked = () => {
    setSelectedRow({
      ...statReserveConfiguration.statReserveConfiguration,
      sub_organization_ids: undefined,
      states: undefined,
      isNewRule: true,
    });
  };

  const handleEditRuleClicked = (row: StatReserveConfigurationSubRule) => {
    setSelectedRow({ ...row });
  };

  const handleDeleteRuleClicked = (row: StatReserveConfigurationSubRule) => {
    setDeleteConfirmSelectedRow(row);
  };

  const handleConfirmDeleteRuleClicked = async (row: StatReserveConfigurationSubRule) => {
    try {
      await axios.delete(`/api/v1/organizations/${organizationId}/stat_reserve_configuration/${row.id}`);
      reloadData();
      setDeleteConfirmSelectedRow(null);
    } catch (error) {
      await reportAxiosError(error);
    }
  };

  const handleSubmit = () => {
    reloadData();
    setSelectedRow(null);
  };
  const handleClose = () => {
    setSelectedRow(null);
  };

  const subOrgsNames = (ids: number[]): (undefined | null | string)[] | null => {
    if (ids.length === 0) {
      return ['All'];
    }
    return ids.map((id) => subOrganizations.find((subOrg: { id: number }) => id === subOrg.id)?.name);
  };

  const subColumnsData = [
    {
      id: SubRulesTableColumns.country,
      label: SubRulesTableColumns.country,
      numeric: false,
      isHidden: !multiCountryOrganization,
      specialCell: (row: StatReserveConfigurationSubRule) => {
        return (
          <div>
            <Text variant={Text.VARIANTS.SM}>{row.country || 'All'}</Text>
          </div>
        );
      },
    },
    {
      id: SubRulesTableColumns.state,
      label: SubRulesTableColumns.state,
      numeric: false,
      isHidden: !isOrganizationWithStates(organization) && !multiCountryOrganization,
      specialCell: (row: StatReserveConfigurationSubRule) => {
        return (
          <div>
            {!row.states || row.states.length === 0 ? (
              !multiCountryOrganization || isCountryWithStates(row.country) ? (
                <Text variant={Text.VARIANTS.SM}>All</Text>
              ) : null
            ) : (
              <OverflowArrayTextDisplayWithTooltip value={row.states} />
            )}
          </div>
        );
      },
    },
    {
      id: SubRulesTableColumns.SubOrg,
      label: SubRulesTableColumns.SubOrg,
      isHidden: !subOrganizationEnabled,
      numeric: false,
      specialCell: (row: StatReserveConfigurationSubRule) => {
        return (
          <div>
            {!row.sub_organization_ids || row.sub_organization_ids.length === 0 ? (
              <Text variant={Text.VARIANTS.SM}>All</Text>
            ) : (
              <OverflowArrayTextDisplayWithTooltip value={subOrgsNames(row.sub_organization_ids)} />
            )}
          </div>
        );
      },
    },
    {
      id: SubRulesTableColumns.indemnityReserve,
      label: SubRulesTableColumns.indemnityReserve,
      numeric: false,
      specialCell: (row: StatReserveConfigurationSubRule) => currencyFormatter.format(row.indemnity_reserve_amount),
    },
    {
      id: SubRulesTableColumns.expenseReserve,
      label: SubRulesTableColumns.expenseReserve,
      numeric: false,
      specialCell: (row: StatReserveConfigurationSubRule) => currencyFormatter.format(row.expenses_reserve_amount),
    },
    {
      id: SubRulesTableColumns.editDelete,
      label: '',
      numeric: false,
      specialCell: (row: StatReserveConfigurationSubRule) => {
        return (
          <div className="flex h-full flex-row items-end justify-end">
            <IconButton onClick={() => handleEditRuleClicked(row)} className="text-slate-900 hover:text-teal-700">
              <PencilIcon iconColor="currentColor" />
            </IconButton>
            <IconButton onClick={() => handleDeleteRuleClicked(row)} className="text-slate-900 hover:text-teal-700">
              <TrashIcon iconColor="currentColor" />
            </IconButton>
          </div>
        );
      },
    },
  ];
  return (
    <div>
      <CardDialog title="Reserve Rules">
        <div className="flex justify-end">
          <Button color="primary" onClick={handleAddRuleClicked}>
            <AddIcon />
            Add Rule
          </Button>
        </div>
        {subRulesReservesList.length > 0 ? (
          <SortableTable columns={subColumnsData} rows={subRulesReservesList} disableSortByUser={true} />
        ) : null}
      </CardDialog>
      {selectedRow ? (
        <StatReserveUpsertDialog
          statReserveConfig={selectedRow}
          onSubmit={handleSubmit}
          onClose={handleClose}
          shouldDisplayCountries={multiCountryOrganization}
          shouldDisplayStates={isOrganizationWithStates(organization) || multiCountryOrganization}
          shouldDisplaySubOrgs={subOrganizationEnabled}
          specificSubOrgIdOptions={specificSubOrgIdOptions}
        />
      ) : null}
      {deleteConfirmSelectedRow ? (
        <ConfirmModal
          title="Delete Rule?"
          contentText={`Are you sure you want to delete rule for coverage "${deleteConfirmSelectedRow.coverage_display_name}"?`}
          primaryButtonName="Delete"
          onClose={() => {
            setDeleteConfirmSelectedRow(null);
          }}
          isOpen
          onPrimaryBtnClick={() => handleConfirmDeleteRuleClicked(deleteConfirmSelectedRow)}
        />
      ) : null}
    </div>
  );
};

export default StatReserveConfigurationSubRulesTable;
