import axios from 'axios';

import type { ClaimNotificationModel } from '~/components/ClaimNotificationsCard/types';
import { isoDateToUs, serverDateToLocal } from '~/DateTimeUtils';
import type { CLAIM_NOTIFICATIONS_PRIORITY } from '~/Types';
import { reportAxiosError } from '~/Utils';

export const getClaimNotificationDateStr = (notification: ClaimNotificationModel): string => {
  return notification.due_date ? isoDateToUs(notification.due_date) : serverDateToLocal(notification.datetime);
};

export const changeClaimNotificationState = async (
  notification: ClaimNotificationModel,
  setNotificationAsSubmitting: (id: number) => void,
  setNotificationAsNotSubmitting: (id: number) => void,
  onUpdate: () => Promise<void> | undefined
): Promise<void> => {
  const action = notification.datetime_dismissed ? 'undismiss' : 'dismiss';
  setNotificationAsSubmitting(notification.id);

  const notificationsResourceLocation = notification.claim_id
    ? `${notification.claim_id}/notifications`
    : 'claims_notifications';

  try {
    await axios.post(`/api/v1/claims/${notificationsResourceLocation}/${notification.id}/${action}`);
    await onUpdate();
    setNotificationAsNotSubmitting(notification.id);
  } catch (error) {
    await reportAxiosError(error);
    setNotificationAsNotSubmitting(notification.id);
    throw error;
  }
};

export const isRestrictedPredicate = (notification: ClaimNotificationModel): boolean | undefined =>
  notification.is_restricted;

export const restrictedContentMessage = `This is attached to a sub-org you don't have permissions for.
  Please contact your system administrator`;

export const getNotificationPriorityOrDefault = (
  notification: ClaimNotificationModel
): keyof typeof CLAIM_NOTIFICATIONS_PRIORITY => notification?.priority ?? 'low';
