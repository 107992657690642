import React from 'react';

import { replaceDatesWithLocaleString } from '~/components/AiChat/SideBarDialog/utils';
import type { AiChatReference } from '~/components/AiChat/types';
import CardDialog from '~/components/CardDialog';
import { MarkdownSection } from '~/components/core/MarkdownSection';

interface IDocumentReferenceDialogProps {
  documentReference: AiChatReference;
  onClose: () => void;
  claimId: string;
}

export const DocumentReferenceDialog: React.FC<IDocumentReferenceDialogProps> = ({
  documentReference,
  onClose,
  claimId,
}) => {
  const { id, sub_type, summary, name, claim_internal_id } = documentReference;
  const docType = sub_type?.replace('_', ' ');
  return (
    <CardDialog open={true} onClose={onClose} isDialog title="Document Details">
      <div className="flex w-[500px] flex-col gap-12">
        <span className="font-semibold">
          Link:{' '}
          <a href={`/api/v1/claims/${claimId}/documents/${id}`} target="_blank" rel="noopener noreferrer">
            {`${name} #${claim_internal_id}`}
          </a>
        </span>

        <div>
          <span className="font-semibold">Type: </span> {docType}
        </div>
        <div className="flex flex-col gap-4">
          <span className="font-semibold">Summary:</span>
          <MarkdownSection markdown={replaceDatesWithLocaleString(summary)} />
        </div>
      </div>
    </CardDialog>
  );
};
