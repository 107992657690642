import React from 'react';
import PropTypes from 'prop-types';
import { useFormikContext } from 'formik';

import { useStyles } from '~/assets/styles';
import AlertBanner from '~/components/core/AlertBanner';
import Grid from '~/components/core/Atomic/Grid/Grid';
import CancelButton from '~/components/core/Buttons/CancelButton';
import InnerCard from '~/components/core/Cards/InnerCard';

import { FsButton } from '../../../../../core';
import ArrayFieldFormik from '../../../../../core/Formik/NestedFieldFormik/ArrayFieldFormik';

import styles from '../../PaymentsConfigurationForm.module.scss';

const NEW_FIELD_INITIAL_VALUES = { type_key: '', desc: '', is_hidden: false };

const SubReservesTypesDialog = ({ fieldId, handleSubmit, handleCancel }) => {
  const classes = useStyles();
  const { isSubmitting } = useFormikContext();

  return (
    <>
      <div className="my-12">
        <AlertBanner
          note="The hidden sub reserves types will continue to be displayed in the exposures where sub reserves amount of the hidden sub reserve type is bigger than zero"
          alertType={AlertBanner.ALERT_TYPES.INFO}
        />
      </div>
      <SubReservesTypesDialogInner fieldId={fieldId} />
      <div className={`${classes.buttonsContainer} ${styles.retryConfirmationButtons}`}>
        <CancelButton disabled={isSubmitting} onClick={handleCancel} />
        <FsButton variant="contained" color="primary" disabled={isSubmitting} onClick={handleSubmit}>
          Save
        </FsButton>
      </div>
    </>
  );
};

SubReservesTypesDialog.propTypes = {
  fieldId: PropTypes.string.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  handleCancel: PropTypes.func.isRequired,
};

export const SubReservesTypesDialogInner = ({ fieldId }) => {
  const { isSubmitting } = useFormikContext();

  const SUB_RESERVES_FIELDS_CONFIG = [
    {
      id: 'type_key',
      label: 'Type Key',
      type: 'text',
      disableEditAfterInitialSet: true,
    },
    { id: 'desc', label: 'Type Name', type: 'text' },
    {
      id: 'is_hidden',
      label: 'Is hidden',
      type: 'switch',
      additionalProps: { hideErrorGap: true },
      disableEditAfterInitialSet: false,
    },
  ];

  return (
    <InnerCard className={styles.section}>
      <Grid item xs={12}>
        <ArrayFieldFormik
          fieldId={fieldId}
          label="Sub Reserves Types"
          innerObjectConfig={SUB_RESERVES_FIELDS_CONFIG}
          addNewItemButtonText="Add new sub reserve type +"
          disabled={isSubmitting}
          initialNewItemValues={NEW_FIELD_INITIAL_VALUES}
          allowDeletingInitialValues={false}
        />
      </Grid>
    </InnerCard>
  );
};

SubReservesTypesDialogInner.propTypes = {
  fieldId: PropTypes.string.isRequired,
};

export default SubReservesTypesDialog;
