import { useCms } from '~/components/hooks/useCms';
import useDataFetcher from '~/components/useDataFetcher';
import { CONFIGURATION_FEATURES_NAMES } from '~/Types';
import { isFeatureEnabled } from '~/Utils';

interface IFnolAiPermissions {
  isAllowedForUser: boolean;
  isFnolAiInboxPolicyEnabled: boolean;
}

export const useFnolAiPermissions = (): IFnolAiPermissions => {
  const { userOrganization } = useCms();
  const isFnolAiInboxEnabled = isFeatureEnabled(userOrganization, CONFIGURATION_FEATURES_NAMES.FNOL_AI_INBOX);
  const isFnolAiInboxPolicyEnabled = isFeatureEnabled(
    userOrganization,
    CONFIGURATION_FEATURES_NAMES.FNOL_AI_INBOX_POLICY
  );

  const { data: fnolPermission } = useDataFetcher('/api/v1/claims/fnol_ai/inbox/is_allowed', {}, isFnolAiInboxEnabled);

  return {
    isAllowedForUser: !!fnolPermission?.is_allowed && isFnolAiInboxEnabled,
    isFnolAiInboxPolicyEnabled,
  };
};
