import React from 'react';

import type {
  CoveredItemRow,
  InvoiceAnalysisResponse,
  LineItemRow,
} from '~/components/AiChat/SideBarDialog/InvoiceRecommendationAnalysis/types';
import mixpanel from '~/components/CmsMain/mixpanel';
import Button from '~/components/core/Atomic/Buttons/Button';
import ButtonsContainer from '~/components/core/Atomic/Buttons/ButtonsContainer';
import { CLIVE_MIXPANEL_EVENTS } from '~/pocs/mixpanel';

interface FooterProps {
  disabled: boolean;
  onClose: () => void;
  onSubmit: (data: InvoiceAnalysisResponse) => void;
  onSave: (data: InvoiceAnalysisResponse) => void;
  lineItems: LineItemRow[];
  aggregateCoverages: CoveredItemRow[];
}

export const Footer: React.FC<FooterProps> = ({
  disabled,
  onClose,
  onSubmit,
  onSave,
  aggregateCoverages,
  lineItems,
}) => (
  <ButtonsContainer>
    {disabled ? (
      <Button
        variant="outlined"
        color="primary"
        className="mr-20"
        onClick={() => {
          mixpanel.track(CLIVE_MIXPANEL_EVENTS.ACTION_INVOICE_PAGE_CLOSE_CLICKED);

          onClose();
        }}
      >
        Close
      </Button>
    ) : (
      <>
        <Button
          disabled={disabled}
          variant="outlined"
          color="primary"
          className="mr-20"
          onClick={() => {
            mixpanel.track(CLIVE_MIXPANEL_EVENTS.ACTION_INVOICE_PAGE_SAVE_CLICKED);

            onSave({ line_items: lineItems, coverages: aggregateCoverages });
            onClose();
          }}
        >
          Save
        </Button>
        <Button
          disabled={disabled}
          variant="contained"
          color="primary"
          className="mr-20"
          onClick={() => {
            mixpanel.track(CLIVE_MIXPANEL_EVENTS.ACTION_INVOICE_PAGE_SAVE_AND_FINISH_CLICKED);

            onSubmit({ line_items: lineItems, coverages: aggregateCoverages });
            onClose();
          }}
        >
          Save & Finish
        </Button>
      </>
    )}
  </ButtonsContainer>
);
