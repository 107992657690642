import React from 'react';
import PropTypes from 'prop-types';
import { useFormikContext } from 'formik';
import * as Yup from 'yup';

import Grid from '~/components/core/Atomic/Grid/Grid';
import { useIncidentConfiguration } from '~/components/hooks/useIncidentConfiguration';
import useIsConfigurationFieldSupportedBySubtype from '~/components/hooks/useIsConfigurationFieldSupportedBySubtype';

import CardDialog from '../../../CardDialog';
import ConfiguredFields, {
  getConfiguredFieldsEmptyFormikInitialValues,
  PreDefinedField,
} from '../../../IncidentConfiguration/ConfiguredFields';
import ConfiguredIncidentTypeAndSubtypeFormik from '../../../IncidentConfiguration/ConfiguredIncidentTypeAndSubtypeFormik';
import { getAdditionalDataValidations } from '../../../IncidentConfiguration/CustomFieldsContext';

import CatCodeFieldFormik from './CatCodeFieldFormik';

const incidentDetailsInitialValues = (claimValues, policy) => ({
  date_of_loss: claimValues?.date_of_loss || '',
  time_of_loss: '',
  incident_type: '',
  incident_sub_type: '',
  reporter_contact_id: policy?.insured_contact_id || '',
  preferred_contact_id: '',
  primary_contact_id: '',
  loss_location: null,
  description: '',
  special_internal_note: '',
  cat_code_name: '',
});

const getIncidentDetailsValidationSchema = () => ({
  date_of_loss: Yup.date().max(new Date()).nullable().required('Required'),
  incident_type: Yup.string().required('Required'),
  incident_sub_type: Yup.string().required('Required'),
});

// this function is the reflection of fnol_api_to_fe_converter.py::_extract_top_level_values_from_incident_details in the backend, please change in tandem
const mapValuesToIncidentDetails = (values) => {
  const {
    date_of_loss,
    time_of_loss,
    incident_type,
    incident_sub_type,
    reporter_contact_id,
    reporter_contact,
    preferred_contact_id,
    preferred_contact,
    loss_location,
    description,
    special_internal_note,
    were_there_any_witnesses,
    witnesses_note,
    cat_code_name,
  } = values;
  return {
    date_of_loss,
    time_of_loss,
    incident_type,
    incident_sub_type,
    reporter_contact_id,
    reporter_contact,
    preferred_contact_id,
    preferred_contact,
    loss_location: {
      country_in_uk: '',
      ...loss_location,
    },
    description,
    special_internal_note,
    were_there_any_witnesses,
    witnesses_note,
    cat_code_name,
  };
};

const PRE_DEFINED_INCIDENT_DETAILS_ADDITIONAL_FIELDS = [
  'date_of_loss',
  'time_of_loss',
  'preferred_contact_id',
  'reporter_contact_id',
  'special_internal_note',
  'description',
  'loss_location',
  'submission_mode',
  'reported_date',
];

const getPredefinedIncidentDetailsFieldsValidations = (incidentConfiguration) => {
  const predefinedFields = Object.entries(incidentConfiguration.incident_details.pre_defined_fields || {})
    .map(([id, field]) => ({ id, ...field }))
    .filter((field) => PRE_DEFINED_INCIDENT_DETAILS_ADDITIONAL_FIELDS.includes(field.id));

  return predefinedFields ? getAdditionalDataValidations(predefinedFields) : {};
};

const getPredefinedIncidentDetailsFieldsInitialValues = (innerIncidentConfiguration) => {
  const predefinedFields = Object.entries(innerIncidentConfiguration || {})
    .map(([id, field]) => ({ id, ...field }))
    .filter((field) => PRE_DEFINED_INCIDENT_DETAILS_ADDITIONAL_FIELDS.includes(field.id));

  return getConfiguredFieldsEmptyFormikInitialValues(predefinedFields);
};

const IncidentDetailsCardFormik = ({ onSubTypeChange }) => {
  const { setFieldValue } = useFormikContext();
  const { incidentConfiguration } = useIncidentConfiguration();
  const { isFieldSupportedBySubtype } = useIsConfigurationFieldSupportedBySubtype();
  const preDefinedFields = incidentConfiguration.incident_details.pre_defined_fields;
  const configurableFields = incidentConfiguration.incident_details.configured_fields.filter((field) =>
    isFieldSupportedBySubtype(field)
  );

  const handleDateOfLossChange = (newDateOfLoss) => {
    const formattedDate = newDateOfLoss && newDateOfLoss.isValid() ? newDateOfLoss.format('YYYY-MM-DD') : '';

    setFieldValue('cat_code_name', undefined);
    setFieldValue('date_of_loss', formattedDate);
  };

  return (
    <>
      <CardDialog title="Incident Details" containerStyle={{ borderRadius: '6px' }}>
        <Grid container spacing={1}>
          <ConfiguredIncidentTypeAndSubtypeFormik
            configuration={incidentConfiguration}
            onSubTypeChange={onSubTypeChange}
          />
          <PreDefinedField
            id="date_of_loss"
            fields={preDefinedFields}
            inline
            onChange={handleDateOfLossChange}
            disableFuture
          />
          <PreDefinedField id="time_of_loss" fields={preDefinedFields} inline saveGrid />
          <PreDefinedField
            id="reporter_contact_id"
            fields={preDefinedFields}
            inline
            contactSearchProps={{ newContactRole: 'claimant' }}
          />
          <PreDefinedField id="preferred_contact_id" fields={preDefinedFields} inline />
          <PreDefinedField id="loss_location" fields={preDefinedFields} inline gridXs={12} />
          <PreDefinedField id="description" fields={preDefinedFields} inline rows={4} />
          <PreDefinedField id="special_internal_note" fields={preDefinedFields} inline rows={3} />
          <CatCodeFieldFormik />
          <ConfiguredFields
            values={{}}
            customFields={configurableFields.filter((field) => field.section === 'incident_details' || !field.section)}
            inline
          />
        </Grid>
      </CardDialog>
    </>
  );
};

IncidentDetailsCardFormik.propTypes = {
  onSubTypeChange: PropTypes.func,
};

export {
  getIncidentDetailsValidationSchema,
  getPredefinedIncidentDetailsFieldsInitialValues,
  getPredefinedIncidentDetailsFieldsValidations,
  IncidentDetailsCardFormik,
  incidentDetailsInitialValues,
  mapValuesToIncidentDetails,
};
