import React from 'react';
import PropTypes from 'prop-types';

import { ClaimContextProvider } from '~/components/ClaimContainer';
import { NewPhoneCallCommunicationCard } from '~/components/communications/PhoneCall/components/PhoneCallCommunicationCard/PhoneCallCommunicationCard';
import LoadingDialog from '~/components/LoadingDialog';
import useDataFetcher from '~/components/useDataFetcher';
import { useFetchClaim } from '~/Utils/ClaimUtils';

const NewPhoneCallCommunicationCardWithClaimContext = ({ claimId, contact, handleCloseDialog, contactPhoneId }) => {
  const [claim, isLoadingClaim, isClaimError, reloadClaim] = useFetchClaim(claimId);

  const {
    isLoading: isLoadingContact,
    isError: isErrorContact,
    data: contactToCall,
  } = useDataFetcher(`/api/v1/claims/${claimId}/contacts/${contact.id}`);

  const isLoading = isLoadingClaim || isLoadingContact;
  const isError = isClaimError || isErrorContact;

  if (isLoading || isError) {
    return <LoadingDialog isError={isError} onClose={handleCloseDialog} track="Callback" />;
  }
  return (
    <ClaimContextProvider claim={claim} refreshData={reloadClaim}>
      <NewPhoneCallCommunicationCard
        contactPhoneId={contactPhoneId}
        contact={contactToCall}
        onCancel={handleCloseDialog}
        onNewPhoneCallCreated={handleCloseDialog}
      />
    </ClaimContextProvider>
  );
};

NewPhoneCallCommunicationCardWithClaimContext.propTypes = {
  claimId: PropTypes.number.isRequired,
  contact: PropTypes.object.isRequired,
  handleCloseDialog: PropTypes.func.isRequired,
  contactPhoneId: PropTypes.number.isRequired,
};

export default NewPhoneCallCommunicationCardWithClaimContext;
