import React from 'react';
import PropTypes from 'prop-types';
import axios from 'axios';
import { noop } from 'lodash';

import { useHasPermission } from '~/components/hooks/useHasPermission';
import { usePaymentsConfiguration } from '~/components/hooks/usePaymentsConfiguration';

import { reportAxiosError } from '../../../Utils';
import { PERMISSION_ACTIONS, PERMISSION_VERBS } from '../../core';
import { getPolicyExposureLimit, getPolicyExposureLimitPerAccidentIfExists } from '../ExposureUtils';

import MakePaymentRequestDialog from './MakePaymentRequestDialog/MakePaymentRequestDialog';
import PaymentRequestApprovalDialog from './PaymentRequestApprovalDialog';
import PaymentRequestCancelDialog from './PaymentRequestCancelDialog';
import PaymentRequestDecisionDialog from './PaymentRequestDecisionDialog';

export const PaymentRequestContainer = ({
  claim,
  exposure,
  payableWithReserve,
  payableType,
  paymentRequest,
  cardDialogProps,
  onClose,
  onUpdate,
  setIsSubmitting = noop,
  overridePaymentRequestStore,
  defaultAmount,
  overrideInitialValues,
  forceManualDataInit = false,
  paymentSuggestion,
  Header = React.Fragment,
}) => {
  const { canCurrentUserApprovePayment } = usePaymentsConfiguration();

  const userHasPermissionsOfacOverride = useHasPermission({
    action: PERMISSION_ACTIONS.OFAC_OVERRIDE,
    verb: PERMISSION_VERBS.WRITE,
  });

  const policyExposureLimit = getPolicyExposureLimit(claim, exposure);
  const policyExposureLimitPerAccident = getPolicyExposureLimitPerAccidentIfExists(exposure);

  function _handleSubmit(path, values, skipReportAxiosError = false) {
    setIsSubmitting(true);

    const valuesWithSuggestion = paymentSuggestion ? { ...values, payment_suggestion: paymentSuggestion } : values;
    return axios
      .post(path, valuesWithSuggestion)
      .then((res) => onUpdate(res.data))
      .catch((error) => {
        if (!skipReportAxiosError) {
          reportAxiosError(error);
          setIsSubmitting(false);
        }
        throw error;
      })
      .then(onClose);
  }

  const payableRequestsUrl = exposure.isGeneralExpenses
    ? `/api/v1/claims/${claim.id}/general_expenses/payment_requests`
    : `/api/v1/claims/${claim.id}/exposures/${exposure.id}/${payableType}/payment_requests`;

  function handleSubmitPaymentRequest(values, skipReportAxiosError = false) {
    return _handleSubmit(payableRequestsUrl, values, skipReportAxiosError);
  }

  function handleCancelPaymentRequest(values) {
    return _handleSubmit(`${payableRequestsUrl}/${paymentRequest.id}/decide`, { ...values, decision: 'cancel' });
  }

  function handleDecidePaymentRequest(values) {
    return _handleSubmit(`${payableRequestsUrl}/${paymentRequest.id}/decide`, values);
  }

  function getDialog() {
    if (!paymentRequest) {
      return (
        <MakePaymentRequestDialog
          cardDialogProps={cardDialogProps}
          onSubmitPaymentRequest={handleSubmitPaymentRequest}
          onCancel={onClose}
          claim={claim}
          exposure={exposure}
          payableWithReserve={payableWithReserve}
          payableType={payableType}
          policyExposureLimit={policyExposureLimit}
          policyExposureLimitPerAccident={policyExposureLimitPerAccident}
          overridePaymentRequestStore={overridePaymentRequestStore}
          defaultAmount={defaultAmount}
          overrideInitialValues={overrideInitialValues}
          forceManualDataInit={forceManualDataInit}
          Header={Header}
        />
      );
    }

    // paymentRequest
    if (paymentRequest.request_status !== 'pending') {
      return (
        <PaymentRequestDecisionDialog
          cardDialogProps={cardDialogProps}
          payableWithReserve={payableWithReserve}
          paymentRequest={paymentRequest}
          payableType={payableType}
          onClose={onClose}
          claim={claim}
          exposure={exposure}
          Header={Header}
        />
      );
    }

    // reserve_change_request is pending
    if (
      canCurrentUserApprovePayment(
        paymentRequest.requester_user?.id,
        paymentRequest.was_ofac_hit_found,
        paymentRequest.was_ofac_hit_approved,
        userHasPermissionsOfacOverride
      )
    ) {
      return (
        <PaymentRequestApprovalDialog
          cardDialogProps={cardDialogProps}
          payableWithReserve={payableWithReserve}
          payableType={payableType}
          paymentRequest={paymentRequest}
          onDecidePaymentRequest={handleDecidePaymentRequest}
          onCancel={onClose}
          claim={claim}
          exposure={exposure}
          Header={Header}
        />
      );
    } else {
      return (
        <PaymentRequestCancelDialog
          cardDialogProps={cardDialogProps}
          payableWithReserve={payableWithReserve}
          payableType={payableType}
          paymentRequest={paymentRequest}
          onCancelPaymentRequest={handleCancelPaymentRequest}
          onCancel={onClose}
          claim={claim}
          exposure={exposure}
          Header={Header}
        />
      );
    }
  }

  return <>{getDialog()}</>;
};

const propTypes = {
  claim: PropTypes.object.isRequired,
  onUpdate: PropTypes.func.isRequired,
  setIsSubmitting: PropTypes.func,
  exposure: PropTypes.object.isRequired,
  payableWithReserve: PropTypes.object.isRequired,
  payableType: PropTypes.string.isRequired,
  paymentRequest: PropTypes.object,
  cardDialogProps: PropTypes.object.isRequired,
  onClose: PropTypes.func,
  overridePaymentRequestStore: PropTypes.array,
  defaultAmount: PropTypes.number,
  overrideInitialValues: PropTypes.object,
  forceManualDataInit: PropTypes.bool,
  paymentSuggestion: PropTypes.object,
  Header: PropTypes.node,
};

PaymentRequestContainer.propTypes = propTypes;

export const MinimizedPaymentRequestContainer = ({ onMinimized, disableMinimized, open, cardDialogProps, ...rest }) => (
  <PaymentRequestContainer {...rest} cardDialogProps={{ ...cardDialogProps, onMinimized, disableMinimized, open }} />
);

MinimizedPaymentRequestContainer.propTypes = {
  ...propTypes,
  onMinimized: PropTypes.func,
  disableMinimized: PropTypes.bool,
  open: PropTypes.bool,
};

export default PaymentRequestContainer;
