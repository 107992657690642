import moment from 'moment';

import { serverDateTimeToLocalMoment } from '~/DateTimeUtils';

export const getEmailCommunicationSpecificIdentifier = (communication) => {
  return communication.specific_identifier;
};

export const getNamesOfMissingEmailsFromRecipients = (recipientsList) => {
  // removed emails are not sent as part of contact.emails - so if we can't find the contact_email it was probably removed
  return recipientsList
    .filter((ccContact) => !ccContact.contact.emails.find((contactEmail) => contactEmail.id === ccContact.email_id))
    .map((ccContact) => ccContact.contact_full_name);
};

const getContactEmailsArrayAsString = (contacts) => {
  return contacts && contacts.length !== 0
    ? contacts.map((contact) => `${contact.contact_full_name} &lt;${contact.email}&gt;`).join(', ')
    : '';
};

export const getEmailText = ({
  userSignatureHtml,
  origEmailSender,
  origEmailTarget,
  communication,
  ccContacts,
  emailHtmlBody,
  toContacts,
  adjusterRecipientType,
}) => {
  const adjusterRecipientTypeIsTo = adjusterRecipientType === 'to';
  const adjusterRecipientTypeIsCc = adjusterRecipientType === 'cc';
  const emailText =
    `<div><br><br>${userSignatureHtml}</div><br/>` +
    '<span>------------Forwarded message------------</span><br/>' +
    `<span>From: ${origEmailSender}</span><br/>` +
    `<span>Date: ${moment(serverDateTimeToLocalMoment(communication.datetime)).format(
      'dddd, MMMM D, YYYY [at] h:mm:ss A'
    )}</span><br/>` +
    `<span>Subject: ${communication.subject}</span><br/>` +
    `<span>To: ${adjusterRecipientTypeIsTo ? origEmailTarget : ''}${
      toContacts && toContacts.length !== 0
        ? (adjusterRecipientTypeIsTo ? ', ' : '') + getContactEmailsArrayAsString(toContacts)
        : ''
    }</span><br/>` +
    ((ccContacts && ccContacts.length !== 0) || adjusterRecipientTypeIsCc
      ? `<span>Cc: ${adjusterRecipientType === 'cc' ? origEmailTarget : ''}${
          (adjusterRecipientTypeIsCc ? ', ' : '') + getContactEmailsArrayAsString(ccContacts)
        }</span><br/>`
      : '') +
    `<br/><br/>${emailHtmlBody}`;

  return emailText;
};
