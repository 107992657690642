import React from 'react';

import { useClaim } from '~/components/ClaimContainer';
import type { ContactFullModel } from '~/components/types/contact-types';

import { useCms } from '../../hooks/useCms';

import type { ChannelType } from './constants';
import type { PartialReminder } from './types';
import { calcExposureIds, getCommunicationReminderDefaultValues } from './utils';

interface DefaultParams {
  summary?: string;
  contact?: ContactFullModel;
  exposure_ids?: number[];
}
interface CommunicationCommonInitialValues {
  channel: ChannelType;
  summary: string;
  contact?: ContactFullModel;
  contact_id?: number | '';
  exposure_ids?: number[];
  exposure_ids_specific_requested: boolean;
  add_follow_up_reminder: boolean;
  reminder: PartialReminder;
}

const useCreateCommunication = (
  channel: ChannelType,
  defaultParams: DefaultParams = {}
): { communicationCommonInitialValues: CommunicationCommonInitialValues } => {
  const { summary, contact, exposure_ids } = defaultParams;

  const { claim } = useClaim();
  const { user } = useCms();

  const communicationCommonInitialValues: CommunicationCommonInitialValues = React.useMemo(
    () => ({
      channel,
      summary: summary || '',
      contact: contact ? contact : undefined,
      contact_id: contact ? contact.id : '',
      exposure_ids: exposure_ids ? exposure_ids : calcExposureIds(claim, user, contact) || [],
      exposure_ids_specific_requested: !!exposure_ids,
      add_follow_up_reminder: false,
      reminder: getCommunicationReminderDefaultValues(channel, contact, user, claim),
    }),
    [channel, summary, contact, exposure_ids, user, claim]
  );

  return { communicationCommonInitialValues };
};

export default useCreateCommunication;
