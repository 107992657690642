import React from 'react';

import { PERMISSION_ACTIONS, PERMISSION_VERBS } from '~/components/core';
import { useCms } from '~/components/hooks/useCms';
import { useHasPermission } from '~/components/hooks/useHasPermission';
import { CONFIGURATION_FEATURES_NAMES } from '~/Types';
import { isFeatureEnabled } from '~/Utils';

import useDataFetcher from '../../useDataFetcher';
import type { LegalActionApiResponse } from '../types';

import { useLegalActionsBackendRoutes } from './useLegalActionsBackendRoutes';

interface LegalActionsContextValue {
  legalActions: LegalActionApiResponse[] | never;
  isLegalActionsLoading: boolean;
  isLegalActionsError: boolean;
  reloadLegalActions: () => Promise<void> | never;
  isLegalActionsFeatureEnabled: boolean;
  hasLegalActionsPermissions: boolean;
}

export const LegalActionsContext = React.createContext<LegalActionsContextValue>({
  legalActions: undefined as never,
  isLegalActionsLoading: false,
  isLegalActionsError: false,
  reloadLegalActions: undefined as never,
  isLegalActionsFeatureEnabled: false,
  hasLegalActionsPermissions: false,
});

export const useLegalActions = (): LegalActionsContextValue => React.useContext(LegalActionsContext);

export const LegalActionsContextProvider: React.FC = ({ children }) => {
  const { userOrganization } = useCms();

  const isLegalActionsFeatureEnabled = isFeatureEnabled(userOrganization, CONFIGURATION_FEATURES_NAMES.LEGAL_ACTIONS);
  const hasLegalActionsPermissions =
    useHasPermission({ action: PERMISSION_ACTIONS.LEGAL_ACTIONS, verb: PERMISSION_VERBS.READ }) &&
    isFeatureEnabled(userOrganization, CONFIGURATION_FEATURES_NAMES.LEGAL_ACTIONS);

  const legalActionsRoutes = useLegalActionsBackendRoutes();
  const {
    data: legalActions,
    isLoading: isLegalActionsLoading,
    isError: isLegalActionsError,
    reloadData: reloadLegalActions,
  } = useDataFetcher(
    legalActionsRoutes.legalActionsBase,
    {},
    hasLegalActionsPermissions && isLegalActionsFeatureEnabled
  );

  const _reloadLegalAction = async () => {
    if (hasLegalActionsPermissions && isLegalActionsFeatureEnabled) {
      await reloadLegalActions();
    }
  };

  return (
    <LegalActionsContext.Provider
      value={{
        legalActions,
        isLegalActionsLoading,
        isLegalActionsError,
        reloadLegalActions: _reloadLegalAction,
        isLegalActionsFeatureEnabled,
        hasLegalActionsPermissions: hasLegalActionsPermissions && isLegalActionsFeatureEnabled,
      }}
    >
      {children}
    </LegalActionsContext.Provider>
  );
};
