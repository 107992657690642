import React, { Component } from 'react';
import { hotjar } from 'react-hotjar';
import { Redirect, withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core';
import axios from 'axios';
import axiosRetry, { exponentialDelay } from 'axios-retry';
import { v4 as uuidv4 } from 'uuid';

import { localeDetails, updateOrganizationLocaleDetails } from '~/components/CmsMain/localeGlobals';
import withMinimizedDialog from '~/components/core/MinimizedBar/Context';
import { MinimizedBarContextProvider } from '~/components/core/MinimizedBar/Context/ToastifyBarContext';
import ALL_DEPLOY_ENV from '~/server_shared/generated-types/ALL_DEPLOY_ENV';
import { isUserImpersonated } from '~/UserUtils';
import {
  CACHE_TTL,
  checkFrontendVersionAndReload,
  compose,
  isProductionOrUat,
  logoutCurrentUser,
  trackLandingPage,
  trackPage,
  wrapAsyncApiCallWithCache,
} from '~/Utils';
import { getRegionCode } from '~/Utils/regionUtils';

import rootStyles from '../../assets/rootStyles';
import { MissingProfilePage } from '../core/PermissionError/PermissionDeniedPage';
import { isPermissionsEnabled } from '../core/Permissions/PermissionUtils';
import { CurrencyFormatterContextProvider } from '../CurrencyFormatterContext';
import { CmsContext, withCmsContext } from '../hooks/useCms';
import ZenDesk from '../hooks/useZenDesk';
import LoadingIndicator from '../LoadingIndicator';
import LoginContainer, { LoginSsoPage } from '../Login';
import { OrganizationContextProvider } from '../OrganizationContext';
import TopLevelDialog from '../TopLevelDialog/TopLevelDialog';

import CmsMainAuthenticatedInner from './CmsMainAuthenticatedInner';
import { globalDeployEnv, setGlobalDeployEnv, setTopLevelDialogSetFunc } from './globals';
import mixpanel from './mixpanel';
import sentry from './sentry';

axios.defaults.headers.common['X-FiveSigma-Instance'] = uuidv4();

const CmsMainAuthenticated = compose(
  withStyles(rootStyles),
  withCmsContext,
  withRouter,
  withMinimizedDialog
)(CmsMainAuthenticatedInner);

class CmsMainInner extends Component {
  state = {
    user: null,
    lastLoginTimeCheck: null,
    isLoggedIn: false,
    isLoading: true,
    isError: false,
    isLoadingTwilioDetails: true,
    pathToRedirectOnSuccess: '/',
    topLevelDialogOpen: false,
    topLevelDialogTitle: '',
    topLevelDialogMessage: '',
    topLevelDialogContentComponent: null,
    showTopLevelDialogOkButton: false,
    callInProgress: this.props.location.pathname.startsWith('/call_in_progress')
      ? { direction: 'Incoming' }
      : undefined,
    pageTitle: '',
    showLoading: false,
  };

  unListenHistory = null;

  componentDidMount() {
    const { history } = this.props;
    const { callInProgress } = this.state;
    axiosRetry(axios, { retries: 3, retryDelay: exponentialDelay });
    this.initSession();
    this.initTwilioDetails();
    trackLandingPage(history.location);

    this.unListenHistory = history.listen((location) => {
      if (!callInProgress) {
        checkFrontendVersionAndReload();
      }

      // TODO: Generalize all trackers into "TrackingService" once POC is over
      try {
        mixpanel?.trackPageView();
      } catch (error) {
        if (!isProductionOrUat()) {
          // eslint-disable-next-line no-console
          console.error(error);
        }
      }

      trackPage(location);
    });

    setTopLevelDialogSetFunc(this.setTopLevelDialog);
  }

  componentDidUpdate() {
    const { isLoggedIn, lastLoginTimeCheck, isError } = this.state;
    const TIME_BETWEEN_LOGIN_CHECKS_MSEC = 60 * 1000;
    const now = Date.now();
    // every TIME_BETWEEN_LOGIN_CHECKS verify user is still logged in
    if (isLoggedIn && lastLoginTimeCheck && now - lastLoginTimeCheck > TIME_BETWEEN_LOGIN_CHECKS_MSEC) {
      if (isError !== true) {
        this.initSession();
        this.initTwilioDetails();
        this.setState({ lastLoginTimeCheck: now });
      } else {
        window.location.reload();
      }
    }
  }

  componentWillUnmount() {
    if (this.unListenHistory) {
      this.unListenHistory();
    }
  }

  setTopLevelDialog = ({ title, message, contentComponent, showOkButton }) => {
    this.setState({
      topLevelDialogOpen: true,
      topLevelDialogTitle: title,
      topLevelDialogMessage: message,
      topLevelDialogContentComponent: contentComponent,
      showTopLevelDialogOkButton: showOkButton,
    });
  };

  closeTopLevelDialog = () => {
    this.setState({
      topLevelDialogOpen: false,
      topLevelDialogTitle: '',
      topLevelDialogMessage: '',
      topLevelDialogComponent: null,
      showTopLevelDialogOkButton: false,
    });
  };

  initSession = (pathToRedirectOnSuccess = '/') => {
    // Set timeout to delay the loading component
    const timer = setTimeout(() => {
      if (this.state.isLoading) {
        this.setState({ showLoading: true });
      }
    }, 1000);

    return Promise.allSettled([
      axios.get('/api/v1/users/session'),
      wrapAsyncApiCallWithCache({
        fn: () => axios.get('/api/v1/users/user_organization_details'),
        cacheKey: 'user_organization',
        cacheTtl: CACHE_TTL,
        shouldCacheResultFunc: (data) => data?.is_logged_in,
      }),
    ]).then(
      ([
        { status: sessionStatus, value: response, reason: sessionReason },
        { status: userOrganizationStatus, value: userOrganizationResponse, reason: userOrganizationReason },
      ]) => {
        // Cleanup timeout if the promise is resolved before the timeout
        clearTimeout(timer);
        if (sessionStatus === 'rejected' || userOrganizationStatus === 'rejected') {
          if (sessionReason.response && sessionReason.response.status === 401) {
            this.setState({ isLoggedIn: false, isLoading: false, showLoading: false });
            return;
          }

          if (
            userOrganizationReason?.errors &&
            userOrganizationReason.errors.some((error) => error?.response?.status === 401)
          ) {
            this.setState({ isLoggedIn: false, isLoading: false, showLoading: false });
            return;
          }

          this.setState({ isError: true, showLoading: false });
          return;
        }

        if (response.data.is_logged_in) {
          const updatingMethod = ['post', 'put', 'patch', 'delete'];
          for (const methodIdx in updatingMethod) {
            axios.defaults.headers[updatingMethod[methodIdx]]['X-CSRFToken'] = response.data.csrf_token;
          }
        }

        const resDeployEnv = response.data.deploy_env.toUpperCase();

        if (!ALL_DEPLOY_ENV.includes(resDeployEnv)) {
          throw Error(`Invalid deploy environment: ${resDeployEnv}`);
        }

        setGlobalDeployEnv(resDeployEnv);

        sentry?.initialize(resDeployEnv);

        try {
          mixpanel?.initialize(resDeployEnv);
          mixpanel?.setBaseProperties({
            environment: globalDeployEnv,
            db_region: getRegionCode(),
          });
        } catch (error) {
          if (!isProductionOrUat()) {
            // eslint-disable-next-line no-console
            console.error(error);
          }
        }

        if (resDeployEnv === 'UAT') {
          hotjar.initialize(1659665, 6);
        } else if (resDeployEnv === 'PROD') {
          hotjar.initialize(1659668, 6);
        }

        if (!response.data.is_logged_in || !userOrganizationResponse.is_logged_in || !response.data.user) {
          this.setState({ isLoggedIn: false, isLoading: false, showLoading: false });
          return;
        }

        const user = response.data.user;

        const userOrganization = userOrganizationResponse.user_organization;
        updateOrganizationLocaleDetails(userOrganization, user);

        sentry?.setUser(user);

        this.setState({
          user,
          userOrganization,
          userProfile: response.data.user_profile,
          isLoggedIn: true,
          isLoading: false,
          showLoading: false,
          lastLoginTimeCheck: Date.now(),
          pathToRedirectOnSuccess,
          isMissingProfile: isPermissionsEnabled(userOrganization) && !response.data.user_profile,
        });

        try {
          mixpanel?.identifyUser(user.id);
          mixpanel?.setBaseProperties({
            $name: user?.username,
            user_role_type: user?.role?.role_type,
            impersonated: isUserImpersonated(user),
            organization: userOrganization?.name,
            organization_id: userOrganization?.id,
            environment: globalDeployEnv,
            db_region: getRegionCode(),
          });
        } catch (error) {
          if (!isProductionOrUat()) {
            // eslint-disable-next-line no-console
            console.error(error);
          }
        }
      }
    );
  };

  initTwilioDetails = async () => {
    try {
      const { data: twilioDetails } = await axios.get('/api/v1/users/user_twilio_details');
      this.setState({
        isLoadingTwilioDetails: false,
        twilioWorkspaceDetails: twilioDetails,
      });
    } catch {
      this.setState({
        isLoadingTwilioDetails: false,
        twilioWorkspaceDetails: null,
      });
    }
  };

  setCallInProgress = (newState) => {
    this.setState({ callInProgress: newState });
  };

  setPageTitle = (newPageTitle, documentTitle = undefined) => {
    this.setState({ pageTitle: newPageTitle });
    document.title = documentTitle || newPageTitle;
  };

  render() {
    // eslint-disable-next-line react/prop-types
    const { location } = this.props;
    const {
      user,
      userOrganization,
      userProfile,
      isLoggedIn,
      isLoading,
      isError,
      pathToRedirectOnSuccess,
      topLevelDialogOpen,
      topLevelDialogTitle,
      topLevelDialogMessage,
      topLevelDialogContentComponent,
      showTopLevelDialogOkButton,
      callInProgress,
      twilioWorkspaceDetails,
      pageTitle,
      isMissingProfile,
      showLoading,
    } = this.state;

    if (!user && isLoading) {
      return showLoading ? <LoadingIndicator isError={isError} /> : null;
    }

    if (isMissingProfile) {
      return <MissingProfilePage />;
    }

    if (isLoggedIn) {
      // on successful login (or if someone manually tried reaching here), redirect to original path
      if (location.pathname.startsWith('/login')) {
        return <Redirect to={pathToRedirectOnSuccess} />;
      }

      return (
        <CmsContext.Provider
          value={{
            user,
            userOrganization,
            userProfile,
            userReLogin: this.initSession,
            userLogout: logoutCurrentUser,
            callInProgress,
            setCallInProgress: this.setCallInProgress,
            userReloadTwilioDetails: this.initTwilioDetails,
            twilioWorkspaceDetails,
            pageTitle,
            setPageTitle: this.setPageTitle,
          }}
        >
          <OrganizationContextProvider>
            <CurrencyFormatterContextProvider currency={localeDetails.currency}>
              <MinimizedBarContextProvider>
                <CmsMainAuthenticated />
                <TopLevelDialog
                  isOpen={topLevelDialogOpen}
                  title={topLevelDialogTitle}
                  message={topLevelDialogMessage}
                  contentComponent={topLevelDialogContentComponent}
                  showOkButton={showTopLevelDialogOkButton}
                  onClose={this.closeTopLevelDialog}
                />
              </MinimizedBarContextProvider>
            </CurrencyFormatterContextProvider>
          </OrganizationContextProvider>
          <ZenDesk />
        </CmsContext.Provider>
      );
    }

    if (!location.pathname.startsWith('/login')) {
      return <Redirect to={{ pathname: '/login', state: { from: location.pathname } }} />;
    }

    if (location.pathname === '/login_sso') {
      return <LoginSsoPage />;
    } else {
      return (
        <LoginContainer
          onLoginSuccess={() => this.initSession(location.state && location.state.from ? location.state.from : '/')}
        />
      );
    }
  }
}

CmsMainInner.propTypes = {
  history: PropTypes.object.isRequired, // comes from withRouter
  location: PropTypes.object.isRequired, // comes from withRouter
};

const CmsMain = withRouter(CmsMainInner);

export { withCmsContext };
export default CmsMain;
