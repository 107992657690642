import { capitalize, get, isEmpty } from 'lodash';
import mixpanel from 'mixpanel-browser';

import { getTabName, isProductionEnv } from '../Utils';

// TODO: Extract after the POC is finished
const MIXPANEL_TOKENS = {
  PROD: 'd49cc7e3a541a5eae58c5e167c730567',
  DEV: '07ad65c10a020ecb3d911cf6732debf4',
};
export const MIXPANEL_EVENTS = {
  SIDE_PANEL_PAGE_VIEWED: 'side_panel_page_viewed',
  CLAIM_PANEL_PAGE_VIEWED: 'claim_panel_page_viewed',
  SIDE_PANEL_CLICK: 'side_panel_clicked',
  CLAIM_PANEL_CLICK: 'claim_panel_clicked',
  DOCUMENT_TEMPLATE_OPENED: 'document_template_opened',
  DOCUMENT_PREVIEWED: 'document_previewed',
  DOCUMENT_DOWNLOADED: 'document_downloaded',
  DOCUMENT_UPLOADED_TO_CLAIM: 'document_uploaded_to_claim',
  NEW_EMAIL_COMMUNICATION_CLICKED: 'new_email_communication_clicked',
  EMAIL_COMMUNICATION_SENT: 'email_communication_sent',
  NEW_PHONE_CALL_COMMUNICATION_CLICKED: 'new_phone_call_communication_clicked',
  DOCUMENT_NEW_PHONE_CALL_COMMUNICATION_CLICKED: 'document_new_phone_call_communication_clicked',
  PHONE_CALL_COMMUNICATION_MADE: 'phone_call_communication_made',
  NEW_SMS_COMMUNICATION_CLICKED: 'new_sms_communication_clicked',
  SMS_COMMUNICATION_SENT: 'sms_communication_sent',
  NEW_PHYSICAL_MAIL_COMMUNICATION_CLICKED: 'new_physical_mail_communication_clicked',
  PHYSICAL_COMMUNICATION_MAIL_SENT: 'physical_mail_communication_sent',
  COMMUNICATION_AVAILABILITY_STATUS_OPENED: 'communication_availability_status_opened',
  COMMUNICATION_AVAILABILITY_STATUS_UPDATED: 'communication_availability_status_updated',
  INCOMING_PHONE_CALL_COMMUNICATION_ACCEPTED: 'incoming_phone_call_communication_accepted',
  INCOMING_PHONE_CALL_COMMUNICATION_DECLINED: 'incoming_phone_call_communication_declined',
  LOGIN_PAGE_VIEWED: 'login_page_viewed',
  LOGIN_CLICKED: 'login_clicked',
  REPORTING_ENTITY_TAB_VIEWED: 'reporting_entity_tab_viewed',
  REPORTING_ENTITY_TAB_CLICKED: 'reporting_entity_tab_clicked',
  REPORTING_ENTITY_FILTER_APPLIED: 'reporting_entity_filter_applied',
  REPORTING_ENTITY_EXPORT_CLICKED: 'reporting_entity_export_clicked',
  DOCUMENTATION_LINK_CLICKED: 'documentation_link_clicked',
  SUPPORT_LINK_CLICKED: 'support_link_clicked',
  NEW_MOI_CLICKED: 'new_moi_clicked',
  NEW_MOI_SUBMITTED: 'new_moi_submitted',
  MOI_VIEW_DETAILS_CLICKED: 'moi_view_details_clicked',
  DATA_EXPORT_SUBSCRIPTION_DELETED: 'data_export_subscription_deleted',
  DATA_EXPORT_SUBSCRIPTION_RULE_UPDATED: 'data_export_subscription_rule_updated',
  NEW_DATA_EXPORT_SUBSCRIPTION_RULE_SUBMITTED: 'new_data_export_subscription_rule_submitted',
  ONE_TIME_DATA_EXPORT_EMAIL_SUBMITTED: 'one_time_data_export_email_submitted',
  ONE_TIME_DATA_EXPORT_EMAIL_CLICKED: 'one_time_data_export_email_clicked',
  NEW_DATA_EXPORT_SUBSCRIPTION_RULE_CLICKED: 'new_data_export_subscription_rule_clicked',
  DATA_EXPORT_SUBSCRIPTION_DE_ACTIVATED: 'data_export_subscription_de_activated',
  DATA_EXPORT_SUBSCRIPTION_RULE_EDITED: 'data_export_subscription_rule_edited',
  DATA_EXPORT_INCLUDE_NOTES_AND_NOTIFICATIONS_CLICKED: 'data_export_include_notes_and_notifications_clicked',
  OPEN_CLAIM_IN_NEW_TAB_CLICKED: 'open_claim_in_new_tab_clicked',
  OPEN_CLAIM_LINK_CLICKED: 'open_claim_link_clicked',
  MINIMIZED_DIALOG: 'dialog_minimized_clicked',
};

export const FNOL_MIXPANEL_EVENTS = {
  FNOL_TAKE_LOSS_WITHOUT_POLICY_CLICKED_FNOL: 'fnol_take_loss_without_policy_clicked_fnol',
  TAKE_A_LOSS_CLICKED_NO_POLICY_FNOL: 'take_a_loss_clicked_no_policy_fnol',
  START_CREATING_FNOL_CLICKED_AFTER_INSURED_DETAILS: 'start_creating_fnol_clicked_after_insured_details',
  CREATE_FNOL_CLICKED: 'create_fnol_clicked',
  REPORT_A_NEW_LOSS_CLICKED_WITH_POLICY_FNOL: 'report_a_new_loss_clicked_with_policy_fnol',
  FNOL_SECTION_DETAILS_UPDATE_CLICKED: 'fnol_section_details_update_clicked',
  INCIDENT_DETAILS_FNOL_SECTION_UPDATE_CLICKED: 'incident_details_fnol_section_update_clicked',
  LOAD_DRAFT_CLICKED: 'load_draft_clicked',
  SAVE_FNOL_CONFIGURATION_CLICKED: 'save_fnol_configuration_clicked',
  EDIT_FNOL_CONFIGURATION_CLICKED: 'edit_fnol_configuration_clicked',
  SAVE_POLICY_CONFIGURATION_CLICKED: 'save_policy_configuration_clicked',
  EDIT_POLICY_CONFIGURATION_CLICKED: 'edit_policy_configuration_clicked',
};

export const NOTIFICATION_MIXPANEL_EVENTS = {
  ADD_NOTIFICATION_CLICKED: 'add_notification_clicked',
  SAVE_NOTIFICATION_CLICKED: 'save_notification_clicked',
  ADD_NOTIFICATION_TOKEN_CLICKED: 'add_notification_token_clicked',
  NOTIFICATION_TOKEN_VALUE_CHOSEN: 'notification_token_value_chosen ',
  EDIT_NOTIFICATION_CLICKED: 'edit_notification_clicked',
  VIEW_NOTIFICATION_CLICKED: 'view_notification_clicked',
  DUPLICATE_NOTIFICATION_CLICKED: 'duplicate_notification_clicked',
  ENABLE_DISABLE_NOTIFICATION_CLICKED: 'enable_disable_notification_clicked',
  FILTER_NOTIFICATION_APPLIED: 'filter_notification_applied',
};

export const PAYMENT_MIXPANEL_EVENTS = {
  PAYMENTS_FINANCES_PAGE_FILTER_APPLIED: 'payments_finances_page_filter_applied',
  PAYMENT_EXPANDED: 'payment_expanded',
  CANCEL_PAYMENT_CLICKED: 'cancel_payment_clicked',
  CANCEL_PAYMENT_SUBMITTED: 'cancel_payment_submitted',
  SET_PAYMENT_CLICKED: 'set_payment_clicked',
  SET_PAYMENT_SUBMITTED: 'set_payment_submitted',
};

export const PAYMENT_MIXPANEL_SOURCE = {
  FINANCE_SCREEN: 'finance_screen',
  TRANSACTION_HISTORY: 'transaction_history',
};

export const OPERATIONS_MIXPANEL_EVENTS = {
  OPERATIONS_SCREEN_VIEWED: 'operations_screen_viewed',
  OPERATIONS_COMPONENT_CLICKED: 'operations_component_clicked',
};

export const MIXPANEL_EVENT_SOURCES = {
  // SMS
  CLAIM_ACTIONS_NEW_SMS: 'Claim - Actions - New sms',
  CONTACT_MINI_CARD_NEW_SMS_COMMUNICATION_CLICKED: 'Contact Mini Card - New SMS Communication Clicked',
  NEW_SMS_COMMUNICATION_CLICKED_FROM_ADJUSTER_VIDEO_CALL: 'New SMS Communication Clicked from Adjuster Video Call',
  REPLY_SMS_CLICKED: 'Reply SMS clicked in SMS Communication Card',
  CLAIM_CONTACTS_TAB_NEW_MS_COMMUNICATION_CLICKED: 'Claim`s Contacts Tab - New SMS Communication Clicked',
  // EMAIL
  CLAIM_ACTIONS_NEW_EMAIL: 'Claim - Actions - New email',
  CONTACT_MINI_CARD_NEW_EMAIL_COMMUNICATION_CLICKED: 'Contact Mini Card - New Email Communication Clicked',
  COMMUNICATIONS_ACTIONS_MENU_REPLY: 'Communications Actions Menu - Reply ',
  COMMUNICATIONS_ACTIONS_MENU_REPLY_ALL: 'Communications Actions Menu - Reply all',
  COMMUNICATIONS_ACTIONS_MENU_FORWARD: 'Communications Actions Menu - Forward',
  COMMUNICATIONS_ACTIONS_MENU_INTERNAL_COMMUNICATION: 'Communications Actions Menu - New Internal Communication',
  EMAIL_COMMUNICATION_CARD_NEW_EMAIL_COMMUNICATION_CLICKED:
    'Email Communication Card - New Email Communication Clicked',
  CLAIM_CONTACTS_TAB_NEW_EMAIL_COMMUNICATION_CLICKED: 'Claim`s Contacts Tab - New Email Communication Clicked',
  // PHYSICAL MAIL
  CLAIM_ACTIONS_NEW_PHYSICAL_MAIL: 'Claim - Actions - New physical mail',
  SEND_PHYSICAL_MAIL_CLICK: 'Click on Send Physical Mail',
  CONTACT_MINI_CARD_NEW_PHYSICAL_MAIL_COMMUNICATION_CLICKED:
    'Contact Mini Card - New Physical Mail Communication Clicked',
  NEW_PHYSICAL_MAIL_COMMUNICATION_CLICKED:
    'New Physical Mail Clicked in Physical Mail Communication Communication Card',
  NEW_PHYSICAL_MAIL_COMMUNICATION_CLICKED_FROM_PIP_DASHBOARD:
    'New Physical Mail Communication Clicked from PIP Dashboard',
  // PHONE
  CLAIM_ACTIONS_NEW_PHONE_CALL: 'Claim - Actions - New phone call',
  CLAIM_ACTIONS_DOCUMENT_NEW_PHONE_CALL: 'Claim - Actions - Document phone call',
  NEW_PHONE_CALL: 'New Phone Call',
  CONTACT_MINI_CARD_NEW_PHONE_CALL: 'Contact Mini Card - New Phone Call Clicked',
  CONTACT_MINI_CARD_NEW_PHONE_CALL_FROM_DROPDOWN: 'Contact Mini Card - New Phone Call from Dropdown Clicked',
  NEW_PHONE_CALL_FROM_ICON_IN_AVAILABILITY_BAR: 'New Phone Call from Icon in the Availability Bar',
  CLAIM_CONTACTS_TAB_NEW_NEW_PHONE_CALL: 'Claim`s Contacts Tab - New Phone Call Clicked',
  // DOCUMENTS
  DOCUMENT_TEMPLATE_OPENED_FROM_DOCUMENT_CONTAINER: 'Document Template Opened from Document Container',
  DOCUMENT_PREVIEW_DOWNLOADED_FOR_EDITING: 'Document Preview Downloaded for Editing',
  DOCUMENT_PREVIEW_FROM_TEMPLATE: 'Document Preview from Template',
  DOCUMENT_PREVIEW_UPLOAD_TO_CLAIM: 'Document Preview Upload to Claim',
  // OTHER
  CHANGE_AVAILABILITY_STATUS_CLICKED: 'Change Availability Status Clicked',
};

export const NOTIFICATION_MIXPANEL_EVENT_SOURCES = {
  NOTIFICATIONS: 'notifications',
  ADD_NOTIFICATION: 'add_notification',
  EDIT_NOTIFICATION: 'edit_notification',
  DUPLICATE_NOTIFICATION: 'duplicate_notification',
};

export const SEARCH_MIXPANEL_EVENTS = {
  SEARCH_BUTTON_CLICKED: 'search_button_clicked',
  SEARCH_RESULTS_RETURNED: 'search_results_returned',
  CLAIM_OPENED_FROM_SEARCH_RESULTS: 'claim_opened_from_search_results',
};

export const CLAIM_SEARCH_MIXPANEL_EVENTS = {
  CLAIM_SEARCH_LINK_CLICKED: 'claim_search_link_clicked',
  VIEW_ALL_BUTTON_CLICKED: 'view_all_button_clicked',
  CLAIM_SEARCH_OUTDATED_TOOLTIP_PRESENTED: 'claim_search_outdated_tooltip_presented',
};

export const CLAIM_SEARCH_MIXPANEL_SOURCES = {
  NOTIFICATION: 'notification',
  TOP_CLAIM_PANEL: 'top claim panel',
};

export const ASSIGNMENTS_MIXPANEL_EVENTS = {
  ADD_ASSIGNMENT_RULE_CLICKED: 'add_assignment_rule_clicked',
  ASSIGNMENT_RULE_ADDED: 'assignment_rule_added',
  EDIT_ASSIGNMENT_RULE_CLICKED: 'edit_assignment_rule_clicked',
  ASSIGNMENT_RULE_EDITED: 'assignment_rule_edited',
  DELETE_ASSIGNMENT_RULE_CLICKED: 'delete_assignment_rule_clicked',
  ASSIGNMENT_RULE_DELETED: 'assignment_rule_deleted',
  ASSIGNMENT_RULE_MOVED_UP_IN_ORDER: 'assignment_rule_moved_up_in_order',
  ASSIGNMENT_RULE_MOVED_DOWN_ORDER: 'assignment_rule_moved_down_order',
  ASSIGNMENT_DEFAULT_USER_CLICKED: 'assignment_default_user_clicked',
  ASSIGNMENT_DEFAULT_USER_SUBMITTED: 'assignment_default_user_submitted',
  ADD_ASSIGNMENT_GROUP_CLICKED: 'add_assignment_group_clicked',
  ASSIGNMENT_GROUP_ADDED: 'assignment_group_added',
  EDIT_ASSIGNMENT_GROUP_CLICKED: 'edit_assignment_group_clicked',
  ASSIGNMENT_GROUP_EDITED: 'assignment_group_edited',
  DELETE_ASSIGNMENT_GROUP_CLICKED: 'delete_assignment_group_clicked',
  ASSIGNMENT_GROUP_DELETED: 'assignment_group_deleted',
};

export const CLIVE_MIXPANEL_EVENTS = {
  TAB_CLICKED: 'clive_tab_clicked',
  ACTION_EXPEND_CLICKED: 'clive_action_expend_clicked',
  ACTION_OPEN_CLICKED: 'clive_action_open_clicked',
  ACTION_INVOICE_PAGE_VIEWED: 'clive_action_invoice_page_viewed',
  ACTION_INVOICE_PAGE_CLOSE_CLICKED: 'clive_action_invoice_page_close_clicked',
  ACTION_INVOICE_PAGE_SAVE_CLICKED: 'clive_action_invoice_page_save_clicked',
  ACTION_INVOICE_PAGE_SAVE_AND_FINISH_CLICKED: 'clive_action_invoice_page_save_and_finish_clicked',
  ACTION_INVOICE_PAGE_BACK_CLICKED: 'clive_action_invoice_page_back_clicked',
  ACTION_INVOICE_PAGE_LINE_ITEM_FOCUSED: 'clive_action_invoice_page_line_item_focused',
  ACTION_INVOICE_PAGE_LINE_ITEM_DELETE_CLICKED: 'clive_action_invoice_page_line_item_delete_clicked',
  ACTION_INVOICE_PAGE_LINE_ITEM_ADD_ROW_CLICKED: 'clive_action_invoice_page_line_item_add_row_clicked',
  ACTION_INVOICE_PAGE_COVERAGE_APPLY_CLICKED: 'clive_action_invoice_page_coverage_apply_clicked',
};

// TODO: Add a tracking service after POC
class Mixpanel {
  instance;
  baseProperties;
  userId;
  initialized;

  constructor() {
    try {
      this.instance = mixpanel;
      this.initialize = this.initialize.bind(this);
      this.identifyUser = this.identifyUser.bind(this);
      this.setBaseProperties = this.setBaseProperties.bind(this);
      this.trackPageView = this.trackPageView.bind(this);
      this.track = this.track.bind(this);
      this.initialized = false;
    } catch (error) {
      if (!isProductionEnv()) {
        // eslint-disable-next-line no-console
        console.error(error);
      }

      return;
    }
  }

  initialize(environment, debug = false) {
    try {
      let token;

      if (environment === 'PROD') {
        token = MIXPANEL_TOKENS.PROD;
      } else {
        token = MIXPANEL_TOKENS.DEV;
      }

      this.instance.init(token, { debug });
      this.initialized = true;
    } catch (error) {
      if (!isProductionEnv()) {
        // eslint-disable-next-line no-console
        console.error(error);
      }
    }
  }

  identifyUser(userId) {
    try {
      if (!this.initialized) {
        return;
      }

      this.userId = userId;

      if (!this.userId) {
        return;
      }

      this.instance.identify(this.userId);
    } catch (error) {
      if (!isProductionEnv()) {
        // eslint-disable-next-line no-console
        console.error(error);
      }
    }
  }

  setBaseProperties(baseProperties = {}) {
    this.baseProperties = baseProperties;
  }

  // TODO: Extract location parse to a util after POC is finished
  trackPageView() {
    try {
      if (!this.initialized) {
        return;
      }

      const path = window.location.pathname;
      const pathParams = path.split('/').filter((path) => !isEmpty(path));
      const pageName = !isEmpty(pathParams) ? capitalize(pathParams[0].replace(/_|-/g, ' ')) : '';

      if (path === '/') {
        this.track(MIXPANEL_EVENTS.SIDE_PANEL_PAGE_VIEWED, {
          page: 'Home',
        });

        return;
      }

      if (pageName === 'Login') {
        this.track(MIXPANEL_EVENTS.LOGIN_PAGE_VIEWED);
        return;
      }

      // Claims panel page views
      if (pageName === 'Claims' && pathParams.length === 3) {
        const claimId = get(pathParams, '[1]');
        const subPageName = capitalize(get(pathParams, '[2]'));

        this.track(MIXPANEL_EVENTS.CLAIM_PANEL_PAGE_VIEWED, {
          page: subPageName,
          claim_id: claimId,
        });

        return;
      }

      if (pageName === 'Reports') {
        const { parentTab, tabName } = getTabName();

        if (tabName) {
          this.track(MIXPANEL_EVENTS.REPORTING_ENTITY_TAB_VIEWED, {
            reporting_entity: parentTab,
            tab_name: tabName,
          });
        }

        return;
      }

      this.track(MIXPANEL_EVENTS.SIDE_PANEL_PAGE_VIEWED, {
        page: pageName,
      });
    } catch (error) {
      if (!isProductionEnv()) {
        // eslint-disable-next-line no-console
        console.error(error);
      }
    }
  }

  track(eventName, properties = {}) {
    try {
      if (!this.initialized) {
        return;
      }

      const payload = {
        ...this.baseProperties,
        timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
        timestamp_local: new Date().toString(),
        ...properties,
      };

      this.instance.track(eventName, payload);
    } catch (error) {
      if (!isProductionEnv()) {
        // eslint-disable-next-line no-console
        console.error(error);
      }
    }
  }

  getMixpanelAttributes(claim, exposures = [], additionalParams = {}) {
    return {
      claim_id: claim.id,
      claim_id_display: claim.claim_id_display,
      LOB: claim.type,
      exposure_types: [...new Set(exposures.map((exposure) => exposure.coverage_type))],
      exposure_labels: [
        ...exposures.map((exposure) => {
          return { id: exposure.id, label: exposure.label_text };
        }),
      ],
      exposure_ids: [...exposures.map((exposure) => exposure.id)],
      ...additionalParams,
    };
  }
}

export default Mixpanel;
