import React from 'react';
import PropTypes from 'prop-types';
import { some } from 'lodash';

import {
  FORMIK_FINANCE_FIELD_IDS,
  SUPPORTED_IBAN_LANGUAGES,
} from '~/components/Contacts/UpsertContact/Tabs/Finance/constants';
import MenuItem from '~/components/core/Atomic/MenuItem';
import { usePaymentsConfiguration } from '~/components/hooks/usePaymentsConfiguration';
import { TextFieldFormik } from '~/components/TextFieldFormik';
import { LANGUAGES_DICT } from '~/Types';
import { stringCmp } from '~/Utils';

import { getFullFinancePath } from './utils';

const Bank = ({ disabled }) => {
  const { isUkBankTransferEnabledForAny, isIbanBankTransferEnabledForAny, isAuBankTransferEnabledForAny } =
    usePaymentsConfiguration();

  if (!some([isUkBankTransferEnabledForAny, isIbanBankTransferEnabledForAny, isAuBankTransferEnabledForAny])) {
    return null;
  }

  return (
    <div className="grid grid-cols-2 gap-20">
      {isIbanBankTransferEnabledForAny ? (
        <>
          <TextFieldFormik
            id={getFullFinancePath(FORMIK_FINANCE_FIELD_IDS.IBAN_LANGUAGE)}
            label="Language"
            fullWidth
            disabled={disabled}
            select
          >
            {SUPPORTED_IBAN_LANGUAGES.sort((lang1, lang2) =>
              stringCmp(LANGUAGES_DICT[lang1], LANGUAGES_DICT[lang2])
            ).map((language) => (
              <MenuItem key={language} value={language}>
                {LANGUAGES_DICT[language]}
              </MenuItem>
            ))}

            <MenuItem value="other">Other</MenuItem>
          </TextFieldFormik>
          <TextFieldFormik
            id={getFullFinancePath(FORMIK_FINANCE_FIELD_IDS.IBAN)}
            label="IBAN"
            fullWidth
            disabled={disabled}
          />
        </>
      ) : null}
      {isUkBankTransferEnabledForAny ? (
        <>
          <TextFieldFormik
            id={getFullFinancePath(FORMIK_FINANCE_FIELD_IDS.UK_BANK_SORT_CODE)}
            label="Sort Code"
            fullWidth
            disabled={disabled}
          />
          <TextFieldFormik
            id={getFullFinancePath(FORMIK_FINANCE_FIELD_IDS.UK_BANK_ACCOUNT_NUMBER)}
            label="Account Number"
            fullWidth
            disabled={disabled}
          />
        </>
      ) : null}
      {isAuBankTransferEnabledForAny ? (
        <>
          <TextFieldFormik
            id={getFullFinancePath(FORMIK_FINANCE_FIELD_IDS.AU_BSB_NUMBER)}
            label="BSB Number"
            fullWidth
            disabled={disabled}
          />
          <TextFieldFormik
            id={getFullFinancePath(FORMIK_FINANCE_FIELD_IDS.AU_BANK_ACCOUNT_NUMBER)}
            label="Account Number"
            fullWidth
            disabled={disabled}
          />
        </>
      ) : null}
    </div>
  );
};

Bank.propTypes = {
  disabled: PropTypes.bool,
};

export default Bank;
