import React from 'react';
import PropTypes from 'prop-types';

import TimeframeFieldSelect from '~/components/SystemConfiguration/AutomaticRulesConfiguration/Notifications/NotificationRuleDialog/TimeframeFieldSelect';
import { NOTIFICATIONS_RULES_TIMEFRAMES } from '~/Types';

export const EventScheduleSelect = ({ isSubmitting, selectedEventConfig }) => {
  const isSelectedEventTimeBased = selectedEventConfig?.is_time_based;

  const timeframeOptions = Object.entries(NOTIFICATIONS_RULES_TIMEFRAMES)
    .filter(([, option]) => (isSelectedEventTimeBased ? option.is_available_for_time_based : true))
    .map(([key, option]) => ({
      key,
      label: option.desc,
    }));

  return (
    <TimeframeFieldSelect
      isSubmitting={isSubmitting}
      timeframeOptions={timeframeOptions}
      immediate_or_custom_field="schedule_immediate_or_custom"
      number_field="schedule_number"
      timeframe_field="schedule_timeframe"
      forceScheduleFields={isSelectedEventTimeBased}
      label="Schedule"
    />
  );
};

EventScheduleSelect.propTypes = {
  isSubmitting: PropTypes.bool,
  selectedEventConfig: PropTypes.shape({
    is_time_based: PropTypes.bool,
  }),
};
