import React, { useContext, useState } from 'react';

import type { SpecialRoleBehaviorType } from '~/components/Contacts/UpsertContact/types';
import type { ContactFullModel } from '~/components/types/contact-types';

interface ContactContextType {
  isCreation: boolean;
  contactId?: number;
  originalContact?: ContactFullModel;
  isCompany: boolean;
  setIsCompany: React.Dispatch<React.SetStateAction<boolean>>;
  isPolicyContact?: boolean;
  currentRole?: string;
  setCurrentRole: React.Dispatch<React.SetStateAction<string | undefined>>;
  currentIsMoiServiceProvider?: boolean;
  setCurrentIsMoiServiceProvider: React.Dispatch<React.SetStateAction<boolean | undefined>>;
  currentCountry?: string;
  setCurrentCountry: React.Dispatch<React.SetStateAction<string | undefined>>;
  specialRoleBehavior?: SpecialRoleBehaviorType;
}

// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
const ContactContext = React.createContext<ContactContextType>({});

interface ContactContextProviderProps {
  contact?: ContactFullModel;
  children: React.ReactNode;
  specialRoleBehavior?: SpecialRoleBehaviorType;
}

export const ContactContextProvider: React.FC<ContactContextProviderProps> = ({
  contact,
  children,
  specialRoleBehavior,
}) => {
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  const [isCompany, setIsCompany] = useState<boolean>(contact?.is_company || false);
  const [currentRole, setCurrentRole] = useState<string | undefined>(contact?.role);
  const [currentIsMoiServiceProvider, setCurrentIsMoiServiceProvider] = useState<boolean | undefined>(
    contact?.is_moi_service_provider
  );
  const [currentCountry, setCurrentCountry] = useState<string | undefined>(contact?.country);

  const contextValues = {
    isCreation: !contact,
    contactId: contact?.id,
    originalContact: contact,
    isCompany,
    setIsCompany,
    isPolicyContact: contact?.is_policy_contact,
    currentRole,
    setCurrentRole,
    currentIsMoiServiceProvider,
    setCurrentIsMoiServiceProvider,
    currentCountry,
    setCurrentCountry,
    specialRoleBehavior,
  };
  return <ContactContext.Provider value={contextValues}>{children}</ContactContext.Provider>;
};

export const useContact = (): ContactContextType => {
  return useContext(ContactContext);
};
