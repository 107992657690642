import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Switch } from '@material-ui/core';
import axios from 'axios';

import { FsButton } from '~/components/core';
import IconButton from '~/components/core/Atomic/Buttons/IconButton';
import Tooltip from '~/components/core/Atomic/Tooltip';
import { PencilIcon } from '~/components/icons';
import { useSysconfig } from '~/components/SystemConfiguration/SystemConfigurationScreen';
import EditCallbackUrls from '~/components/SystemConfiguration/Tabs/DeveloperTools/sections/EventSettings/EditCallbackUrls';
import { ShowOnlyTextField } from '~/components/TextFieldFormik';
import WEBHOOK_CATEGORIES from '~/server_shared/generated-types/WEBHOOK_CATEGORIES';
import { reportAxiosError } from '~/Utils';

import PlainTable from '../../../../../PlainTable';

import { useStyles } from '../../../../../../assets/styles';

const hasSingleEventCallbackUrls = (webhookEventCategory, eventName) => {
  return (
    webhookEventCategory?.event_specific_callback_urls && webhookEventCategory.event_specific_callback_urls[eventName]
  );
};

const WebhooksInnerTable = ({
  onUpdate,
  webhookEventCategory,
  eventsData,
  category,
  showOnly,
  reloadCategories,
  simulateWebhookEvent,
}) => {
  const classes = useStyles();
  const eventsForCategory = eventsData ? eventsData.filter((event) => event.category === category) : [];
  const [singleEventToEdit, setSingleEventToEdit] = useState('');
  const { organization } = useSysconfig();
  const [isSubmitting, setIsSubmitting] = useState(false);

  const handleSwitchChange = (currentEventName, checked) => {
    if (checked) {
      onUpdate({ events: [...(webhookEventCategory?.events ?? []), currentEventName] }, category);
    } else {
      const filteredEvents = webhookEventCategory?.events.filter((event) => event !== currentEventName);
      onUpdate({ events: filteredEvents }, category);
    }
  };

  const updateSingleEventCallbackUrls = async (data) => {
    try {
      setIsSubmitting(true);
      const update = { callback_urls: data };
      await axios(
        `/api/v1/claim_events_webhook/organizations/${organization.id}/event_categories/${category}/events/${singleEventToEdit}`,
        {
          method: 'put',
          data: update,
        }
      );
    } catch (e) {
      await reportAxiosError(e);
      throw e;
    }
    setSingleEventToEdit('');
    setIsSubmitting(false);
    await reloadCategories();
  };

  const columns = [
    { id: 'desc', label: `${WEBHOOK_CATEGORIES[category]} Event Name`, width: '80%' },
    {
      id: 'testButton',
      label: '',
      specialCell: (row) =>
        hasSingleEventCallbackUrls(webhookEventCategory, row.name) ? (
          <FsButton
            size="small"
            variant="text"
            color="primary"
            disabled={isSubmitting}
            onClick={() => simulateWebhookEvent(row.name)}
          >
            TEST
          </FsButton>
        ) : null,
    },
    {
      id: 'editSingleEvent',
      label: 'Callback URLs',
      width: '100%',
      specialCell: (row) => (
        <ShowOnlyTextField
          classes={classes}
          label=""
          showOnlyValueComponent={
            hasSingleEventCallbackUrls(webhookEventCategory, row.name)
              ? webhookEventCategory.event_specific_callback_urls[row.name]?.join(', ')
              : ''
          }
        />
      ),
    },
    {
      id: '_',
      label: 'Enable',
      width: '100%',
      specialCell: (row) => (
        <Tooltip title={showOnly ? 'No default URL Defined' : ''}>
          <div className="flex h-full flex-row items-end justify-end">
            <Switch
              checked={webhookEventCategory?.events?.includes(row.name)}
              onChange={(e, checked) => {
                handleSwitchChange(row.name, checked);
              }}
              className={classes.formsSwitch}
              disabled={showOnly}
            />
            <IconButton
              onClick={() => setSingleEventToEdit(row.name)}
              className="text-slate-900 hover:text-teal-700"
              disabled={showOnly}
            >
              <PencilIcon iconColor="currentColor" />
            </IconButton>
          </div>
        </Tooltip>
      ),
    },
  ];

  return (
    <>
      <PlainTable rows={eventsForCategory} columns={columns} order="desc" keepRowsOrder />
      {singleEventToEdit && (
        <EditCallbackUrls
          callbackUrls={
            hasSingleEventCallbackUrls(webhookEventCategory, singleEventToEdit)
              ? webhookEventCategory.event_specific_callback_urls[singleEventToEdit]
              : []
          }
          onSubmit={updateSingleEventCallbackUrls}
          onCancel={() => setSingleEventToEdit(false)}
          title="Edit Specific Event Callback URLs"
          atLeastOne={false}
        />
      )}
    </>
  );
};

WebhooksInnerTable.propTypes = {
  onUpdate: PropTypes.func,
  webhookEventCategory: PropTypes.object,
  eventsData: PropTypes.array,
  category: PropTypes.string,
  showOnly: PropTypes.bool,
  reloadCategories: PropTypes.func,
  simulateWebhookEvent: PropTypes.func,
};

export default WebhooksInnerTable;
