export interface AiChatReference {
  id: string;
  name: string;
  type: string;
  sub_type: string;
  date: string;
  summary: string;
  claim_internal_id: string;
}

export interface AiChatReferences {
  [key: string]: AiChatReference;
}

export interface AiChatApiResponse {
  id: number;
  greeting: string;
  chat_title?: string;
  exchanges: AiChatExchangeApiResponse[];
  references: AiChatReferences;
}

export interface AiChatExchangeApiResponse {
  id: number;
  chat_id: number;
  user_input: string;
  response: string | null;
  user_feedback_score?: number;
  user_feedback_text?: string;
  documents_used: string[];
  created_at: Date;
}

export type UpdateExchangeUserFeedbackCallback = (
  exchangeId: number,
  feedbackScore: number,
  feedbackText: string
) => Promise<boolean>;

export interface AiChatReferenceApiResponse {
  id: string;
  name: string;
  type: 'note' | 'document' | 'communication';
  date?: Date;
}

export interface AiChatExchangesAndReferencesApiResponse {
  exchanges: AiChatExchangeApiResponse[];
  references: AiChatReferences;
}

export interface AiChatStatusApiResponse {
  status?: 'SUCCESS' | 'FAILED' | 'PENDING';
  type?: 'communication' | 'document' | 'note';
  updated_at?: Date;
}

export interface InsightDocument {
  document_name: string;
  document_id?: string;
  document_type?: string;
  document_url?: string;
}

export interface Insight {
  id: string;
  insight_key: string;
  claim_number: string;
  insight_type: 'data_extraction' | 'data_point' | 'warning' | 'general' | 'financial';
  title: string;
  sub_title: string;
  insight_text: string;
  related_documents: InsightDocument[];
  is_dismissed: boolean;
}

export interface AiDoneActionNote {
  action_type?: string;
  note_text: string;
}

export type CoverageIssueType =
  | 'incident_date_outside_policy_period'
  | 'incident_date_close_to_policy_boundaries'
  | 'loss_not_covered'
  | 'loss_meets_exclusion_criteria'
  | 'other';

export type CoverageDecisionRecommendation = 'approve' | 'deny';

export interface CoverageIssue {
  issue_type: CoverageIssueType;
  issue_description: string;
  recommended_action: CoverageDecisionRecommendation;
  explanation: string;
  quote_from_incident?: string;
  quote_from_policy?: string;
}

export interface CoverageTestResult {
  test_description: string;
  test_passed: boolean;
  issue?: CoverageIssue;
}

export interface PaymentTestResult {
  test_description: string;
  test_passed: boolean;
}

export const AI_ACTION_TYPES = {
  COVERAGE_DECISION: 'decide_on_coverage',
  RESERVE_UPDATE: 'update_reserve',
  PAYMENT: 'payment',
  INVOICE_ANALYSIS_RECOMMENDATION: 'invoice_analysis_recommendation',
  ASSESS_DAMAGE: 'assess_damage',
  COMMUNICATE: 'communicate',
};

export type AiActionType = (typeof AI_ACTION_TYPES)[keyof typeof AI_ACTION_TYPES];

export interface ActionTaskData {
  task_id?: string;
  task_text: string;
  explanation: string;
  additional_actions?: string;
  done_action?: AiDoneActionNote;
  related_exposure_id?: string;
  task_type?: string;
}

export interface CoverageDecisionTaskData extends ActionTaskData {
  recommended_action: CoverageDecisionRecommendation;
  coverage_tests?: CoverageTestResult[];
  coverage_issues: CoverageIssue[];
}

export interface ReserveUpdateTaskData extends ActionTaskData {
  payable_type: string;
  reserve_amount: number;
  reason: string;
}

export interface PaymentTaskData extends ActionTaskData {
  payment_tests?: PaymentTestResult[];
  invoice_doc_id: string;
  invoice_number: string;
  payment_amount: number;
  payment_description: string;
}

export interface InvoiceAnalysisRecommendationTaskData extends ActionTaskData {
  task_type: ClaimTaskType;
  analysis_results_id: string;
  claim_document_source_id?: string;
  invoice_date?: string;
  invoice_number?: string;
  document_type?: string;
  vendor_name?: string;
  tax_rate?: number;
  tax_amount?: number;
  total_amount?: number;
  subtotal?: number;
  file_path?: string;
  line_items: InvoiceAnalysisRecommendationLineItem[];
  coverages: CoverageType[];
}

interface InvoiceAnalysisRecommendationLineItem {
  id: string;
  quantity?: number;
  unit_price?: number;
  description?: string;
  total_price?: number;
  coverage_decision?: string;
  coverage_key?: string;
  explanation?: string;
}

interface CoverageType {
  coverage_key: string;
  coverage_display_name: string;
}

export interface AssessDamageTaskData extends ActionTaskData {
  related_exposure_id?: string;
}

export interface CommunicateTaskData extends ActionTaskData {
  subject?: string;
  content?: string;
  recipient_name?: string;
  recipient_email?: string;
  recipient_id?: string;
}

type ClaimTaskType = 'invoice_analysis_recommendation';

type TaskData =
  | CoverageDecisionTaskData
  | ReserveUpdateTaskData
  | PaymentTaskData
  | InvoiceAnalysisRecommendationTaskData
  | CommunicateTaskData
  | AssessDamageTaskData;

export interface AiAction {
  is_executed: boolean;
  is_ignored: boolean;
  action_id: string;
  related_exposure_id?: string;
  created_at: Date;
  updated_at: Date;
  task_data: TaskData;
}

export interface AiActions {
  actions: AiAction[];
}
