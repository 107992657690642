import React from 'react';
import { Divider } from '@material-ui/core';

import { useClaim } from '~/components/ClaimContainer';
import { areActionsEnabled } from '~/components/Contacts/contactActionsUtils';
import Address from '~/components/Contacts/ContactMiniCard/Details/Address';
import COI from '~/components/Contacts/ContactMiniCard/Details/COI';
import ContractOfService from '~/components/Contacts/ContactMiniCard/Details/ContractOfService';
import DyteVideo from '~/components/Contacts/ContactMiniCard/Details/DyteVideo';
import Emails from '~/components/Contacts/ContactMiniCard/Details/Emails';
import Exposures from '~/components/Contacts/ContactMiniCard/Details/Exposures';
import Note from '~/components/Contacts/ContactMiniCard/Details/Note';
import Phones from '~/components/Contacts/ContactMiniCard/Details/Phones';
import W9 from '~/components/Contacts/ContactMiniCard/Details/W9';
import type { ContactDetailsPropsType } from '~/components/Contacts/ContactMiniCard/types';
import { useCms } from '~/components/hooks/useCms';

const ContactDetails: React.FC<ContactDetailsPropsType> = ({ contact, onUpdate }) => {
  const { user } = useCms();
  const { claim, onAsyncClaimUpdate } = useClaim();

  const actionsEnabled = areActionsEnabled(user, claim);

  const updateIfPossible = async () => {
    if (onUpdate) {
      await onUpdate();
    } else if (claim) {
      await onAsyncClaimUpdate();
    }
  };

  return (
    <div>
      <Address contact={contact} onUpdate={updateIfPossible} actionsEnabled={actionsEnabled} />
      <Divider className="my-12" />
      <Emails contact={contact} onUpdate={updateIfPossible} actionsEnabled={actionsEnabled} />
      <Divider className="my-12" />
      <Phones contact={contact} onUpdate={updateIfPossible} actionsEnabled={actionsEnabled} />
      <DyteVideo contact={contact} onUpdate={updateIfPossible} actionsEnabled={actionsEnabled} />
      <Exposures contact={contact} />
      <Divider className="my-12" />
      <Note contact={contact} />
      <W9 contact={contact} />
      <COI contact={contact} />
      <ContractOfService contact={contact} />
    </div>
  );
};

export default ContactDetails;
