import React, { forwardRef } from 'react';
import type { TooltipProps as MuiTooltipProps } from '@material-ui/core';
import { Tooltip as MuiTooltip } from '@material-ui/core';

export interface TooltipProps extends Omit<MuiTooltipProps, 'ref'> {
  arrow?: boolean;
  placement?:
    | 'bottom-end'
    | 'bottom-start'
    | 'bottom'
    | 'left-end'
    | 'left-start'
    | 'left'
    | 'right-end'
    | 'right-start'
    | 'right'
    | 'top-end'
    | 'top-start'
    | 'top';
  title: NonNullable<React.ReactNode>;
  disabled?: boolean;
  children: React.ReactElement;
}

const Tooltip = forwardRef<HTMLElement, TooltipProps>(({ title, disabled = false, children, ...rest }, ref) => {
  if (disabled) {
    return children;
  }

  return (
    <MuiTooltip title={title} {...rest} ref={ref}>
      {children}
    </MuiTooltip>
  );
});

Tooltip.displayName = 'Tooltip';
export default Tooltip;
