import React, { useEffect, useState } from 'react';
import axios from 'axios';

import type { ClaimNotificationModel } from '~/components/ClaimNotificationsCard/types';
import type { EditableChipOption } from '~/components/core/Molecules/EditableChip';
import EditableChip from '~/components/core/Molecules/EditableChip';
import { getNotificationPriorityOrDefault } from '~/components/Notifications/utils';
import type { CLAIM_NOTIFICATIONS_PRIORITY } from '~/Types';
import { reportAxiosError } from '~/Utils';

interface PriorityEditableChipProps {
  notification: ClaimNotificationModel;
  disabled?: boolean;
  setIsUpdating: (isUpdating: boolean) => void;
  onUpdate: () => void | Promise<void>;
}

const PriorityEditableChip: React.FC<PriorityEditableChipProps> = ({
  notification,
  disabled,
  setIsUpdating,
  onUpdate,
}) => {
  const [priorityToDisplay, setPriorityToDisplay] = useState(getNotificationPriorityOrDefault(notification));

  const notificationPriority = notification?.priority;
  useEffect(() => {
    if (!disabled && notificationPriority) {
      setPriorityToDisplay(notificationPriority);
    }
  }, [disabled, notificationPriority]);

  const handlePriorityUpdate = async (priority: keyof typeof CLAIM_NOTIFICATIONS_PRIORITY) => {
    try {
      setIsUpdating(true);
      setPriorityToDisplay(priority);

      await axios.patch(`/api/v1/notifications/${notification.id}/priority`, {
        claim_id: notification.claim_id,
        priority,
      });
      await onUpdate();
    } catch (err) {
      await reportAxiosError(err);
      setPriorityToDisplay(getNotificationPriorityOrDefault(notification));
    } finally {
      setIsUpdating(false);
    }
  };

  const options: EditableChipOption[] = [
    { id: 'low', description: 'Low', color: 'green-700' },
    { id: 'medium', description: 'Medium', color: 'yellow-700' },
    { id: 'high', description: 'High', color: 'red-700' },
  ];

  return (
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    <EditableChip value={priorityToDisplay} onChange={handlePriorityUpdate} options={options} disabled={disabled} />
  );
};

export default PriorityEditableChip;
