import React, { useState } from 'react';
import axios from 'axios';

import Chip from '~/components/core/Atomic/Chip/Chip';
import Tooltip from '~/components/core/Atomic/Tooltip';
import EmptyState from '~/components/core/EmptyState';
import LoadingSwitch from '~/components/core/Loading/LoadingSwitch';
import OverflowArrayTextDisplayWithTooltip from '~/components/core/OverflowArrayTextWithTooltip/OverflowArrayTextDisplayWithTooltip';
import SortableTable from '~/components/core/Tables/SortableTable';
import { useLobConfiguration } from '~/components/hooks/useLobConfiguration';
import { AsyncSwitch } from '~/components/SystemConfiguration/Tabs/DeveloperTools/sections/VendorAPIProfiles/VendorAPIProfilesTable/AsyncSwitch';
import type { OrganizationModel } from '~/components/types/organization-types';
import type { SubOrganization } from '~/components/types/sub-organization-types';
import useDataFetcher from '~/components/useDataFetcher';
import { reportAxiosError } from '~/Utils';
import { getLobDescription } from '~/Utils/lobUtils';

import { useSysconfig } from '../SystemConfigurationScreen';

const SubOrganizationConfiguration: React.FC = () => {
  const { organization } = useSysconfig() as { organization: OrganizationModel };
  const { lobConfigurationsDict } = useLobConfiguration();
  const [isSubmitting, setIsSubmitting] = useState(false);

  const baseConfigsRoute = `/sysconfig/api/v1/organizations/${organization?.id}/service_ai/configuration`;

  const {
    isLoading,
    isError,
    data: subOrganizationsConfigs,
    reloadData: reloadSubOrganizationsConfig,
  } = useDataFetcher(baseConfigsRoute);

  const handleSwitchChange = async (row: SubOrganization, field: string, value: boolean): Promise<void> => {
    try {
      setIsSubmitting(true);
      const updateConfig = subOrganizationsConfigs[row.id] || {};
      updateConfig[field] = value;

      await axios.put(`${baseConfigsRoute}/${row.id}`, updateConfig);
      await reloadSubOrganizationsConfig();
    } catch (error) {
      await reportAxiosError(error);
    } finally {
      setIsSubmitting(false);
    }
  };

  const columns = [
    {
      id: 'id',
      label: 'Id',
    },
    {
      id: 'description',
      label: 'Name',
    },
    {
      id: 'external_id',
      label: 'External Id',
    },
    {
      id: 'lobs',
      label: 'Lines of Business',
      specialCell: (row: SubOrganization) => (
        <OverflowArrayTextDisplayWithTooltip
          value={row.supported_lobs.map((supported_lob) => getLobDescription(supported_lob.lob, lobConfigurationsDict))}
          renderItem={(label) => (
            <span key={label}>
              <Chip label={label} size="small" className="mr-12" />
            </span>
          )}
        />
      ),
      disableSort: true,
    },
    {
      id: 'enable_chat',
      label: 'Enabled',
      disabled: isSubmitting,
      specialCell: (row: SubOrganization) => (
        <Tooltip title="Enable Chat">
          <span>
            <AsyncSwitch
              checked={subOrganizationsConfigs[row.id]?.claim_chat}
              onChange={(e) => handleSwitchChange(row, 'claim_chat', e.target.checked)}
            />
          </span>
        </Tooltip>
      ),
    },
    {
      id: 'enable_send_ai_data',
      label: 'Send AI Data',
      disabled: isSubmitting,
      specialCell: (row: SubOrganization) => (
        <Tooltip title="Send AI Data">
          <span>
            <AsyncSwitch
              checked={subOrganizationsConfigs[row.id]?.send_ai_data}
              onChange={(e) => handleSwitchChange(row, 'send_ai_data', e.target.checked)}
            />
          </span>
        </Tooltip>
      ),
    },
    {
      id: 'enable_process_email',
      label: 'Process Email',
      disabled: isSubmitting,
      specialCell: (row: SubOrganization) => (
        <Tooltip title="Process Email Attachments">
          <span>
            <AsyncSwitch
              checked={subOrganizationsConfigs[row.id]?.process_email_attachments}
              onChange={(e) => handleSwitchChange(row, 'process_email_attachments', e.target.checked)}
            />
          </span>
        </Tooltip>
      ),
    },
  ];

  return (
    <LoadingSwitch isLoading={isLoading} isError={isError}>
      <div className="my-20">
        <SortableTable
          columns={columns}
          rows={organization.sub_organizations || []}
          stickyHeader
          emptyStateComponent={<EmptyState title="No Sub-Organizations" />}
        />
      </div>
    </LoadingSwitch>
  );
};
export default SubOrganizationConfiguration;
