import type { ReactElement } from 'react';
import React from 'react';

import CardDialog from '~/components/CardDialog';
import ClaimLink from '~/components/ClaimLink';
import type { ClaimOwnerChangeClaimNotificationModel } from '~/components/ClaimNotificationsCard/types';
import LabeledValue from '~/components/core/Molecules/LabledValue';
import { ExposureDetailedTitle } from '~/components/exposures/ExposureDetailedTitle';
import type { ExposureModel } from '~/components/types/exposure-types';

import Caption from '../core/TextComponents/Caption';

interface ClaimOwnerChangedNotificationDisplayProps {
  onClose: () => void;
  notification: ClaimOwnerChangeClaimNotificationModel;
  exposuresMap?: { [id: number]: ExposureModel };
  AdditionalDialogHeader?: ReactElement;
}

const ClaimOwnerChangedNotificationDisplay: React.FC<ClaimOwnerChangedNotificationDisplayProps> = ({
  onClose,
  notification,
  exposuresMap,
  AdditionalDialogHeader = <React.Fragment />,
}) => {
  const title = `${!notification.exposure_id ? 'Claim' : 'Exposure'} owner updated to ${
    notification.recipient_sub_label
  }`;
  return (
    <CardDialog isDialog fullWidth maxWidth="sm" onClose={onClose} title={title}>
      {AdditionalDialogHeader}
      <div className="grid grid-cols-2 gap-24 gap-y-40">
        <div>
          <Caption variant={Caption.VARIANTS.LABEL} className="mb-4">
            Claim
          </Caption>
          {notification.claim_id && notification.claim_id_display && (
            <ClaimLink claimId={notification.claim_id} linkText={notification.claim_id_display} />
          )}
        </div>
        {notification.exposure_id !== 0 && exposuresMap && (
          <div>
            <Caption variant={Caption.VARIANTS.LABEL} className="mb-4">
              Exposure
            </Caption>
            {notification.exposure_id && exposuresMap[notification.exposure_id] && (
              <ExposureDetailedTitle exposure={exposuresMap[notification.exposure_id]} />
            )}
          </div>
        )}

        {notification.prev_owner && <LabeledValue value={notification.prev_owner} label="Original Owner" />}
        <LabeledValue
          value={notification.recipient_sub_label ?? ''}
          label={notification.prev_owner ? 'New Owner' : 'Original Owner'}
        />
      </div>
    </CardDialog>
  );
};

export default ClaimOwnerChangedNotificationDisplay;
