import React from 'react';
import PropTypes from 'prop-types';

import { EllipseIcon } from '../icons/notifications';

import TooltipIcon from './TooltipIcon';

import { useStyles } from '../../assets/styles';

function IconWrapperWithDotIndication({ icon, title, shouldBeMarked, className, disabled }) {
  const classes = useStyles();

  // to make sure DisabledChildrenWrapperWithTooltip passing disabled and it is actually passed on to the icon
  let possiblyDisabledIcon = icon;
  if (disabled) {
    possiblyDisabledIcon = React.cloneElement(icon, {
      disabled,
    });
  }

  return (
    <TooltipIcon title={title} disabled={disabled}>
      {possiblyDisabledIcon}
      {shouldBeMarked && (
        <div className={`${classes.dotIconContainer} ${className}`}>
          <EllipseIcon />
        </div>
      )}
    </TooltipIcon>
  );
}

IconWrapperWithDotIndication.propTypes = {
  icon: PropTypes.oneOfType([PropTypes.object, PropTypes.func]),
  title: PropTypes.string,
  shouldBeMarked: PropTypes.bool,
  className: PropTypes.string,
  disabled: PropTypes.bool,
};

export default IconWrapperWithDotIndication;
