import React from 'react';
import PropTypes from 'prop-types';
import { FieldArray, Formik, getIn } from 'formik';
import * as Yup from 'yup';

import { useStyles } from '~/assets/styles';
import CardDialog from '~/components/CardDialog';
import Button from '~/components/core/Atomic/Buttons/Button';
import CancelButton from '~/components/core/Buttons/CancelButton';
import { ErrorHelperTextFormik } from '~/components/core/Formik/ErrorHelperTextFormik';
import { AddIcon } from '~/components/deprecatedMuiIcons';
import { TrashIcon } from '~/components/icons';
import InlineIconButton from '~/components/InlineIconButton';
import TextFieldFormik from '~/components/TextFieldFormik';

const EditCallbackUrls = ({
  callbackUrls,
  onSubmit,
  onCancel,
  title = 'Edit Category Callbacks',
  atLeastOne = true,
}) => {
  const CALLBACK_URLS_FIELD_ID = 'callback_urls';

  const classes = useStyles();
  return (
    <Formik
      initialValues={{ [CALLBACK_URLS_FIELD_ID]: callbackUrls || [] }}
      onSubmit={(values) => onSubmit(values[CALLBACK_URLS_FIELD_ID])}
      validationSchema={Yup.object().shape({
        [CALLBACK_URLS_FIELD_ID]: Yup.array()
          .of(
            Yup.string()
              .required('callback url is required')
              .test('is-unique', 'Domain must be unique', (value, context) => {
                return context.parent.filter((item) => item === value).length < 2 || !atLeastOne;
              })
          )
          .required()
          .min(atLeastOne ? 1 : 0, 'At least one callback required'),
      })}
    >
      {({ isSubmitting, handleSubmit, values }) => {
        return (
          <CardDialog isDialog title={title} maxWidth="sm" onClose={onCancel} fullWidth preventClose={isSubmitting}>
            <FieldArray
              name="callback_urls"
              render={({ remove, push }) => (
                <>
                  {getIn(values, CALLBACK_URLS_FIELD_ID).map((_, idx) => (
                    <div className="grid grid-cols-6 items-center gap-20" key={idx}>
                      <div className="col-span-5">
                        <TextFieldFormik
                          className={classes.textField}
                          id={`${CALLBACK_URLS_FIELD_ID}.${idx}`}
                          label="Callback URL"
                          fullWidth
                        />
                      </div>
                      {idx > 0 || !atLeastOne ? (
                        <div>
                          <InlineIconButton
                            className={classes.hoverableNonFilledIcon}
                            icon={TrashIcon}
                            onClick={() => remove(idx)}
                          />
                        </div>
                      ) : null}
                    </div>
                  ))}
                  <ErrorHelperTextFormik id={CALLBACK_URLS_FIELD_ID} withoutChildren />
                  <div className="left">
                    <Button
                      color="primary"
                      startIcon={<AddIcon />}
                      onClick={() => push('')}
                      style={{ display: 'flex' }}
                    >
                      Add callback URL
                    </Button>
                  </div>
                </>
              )}
            />
            <div className={classes.buttonsContainer}>
              <CancelButton disabled={isSubmitting} onClick={onCancel} />
              <Button variant="contained" color="primary" disabled={isSubmitting} onClick={handleSubmit}>
                Save
              </Button>
            </div>
          </CardDialog>
        );
      }}
    </Formik>
  );
};

EditCallbackUrls.propTypes = {
  callbackUrls: PropTypes.array,
  onSubmit: PropTypes.func,
  onCancel: PropTypes.func,
  title: PropTypes.string,
  atLeastOne: PropTypes.bool,
};

export default EditCallbackUrls;
