import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { getIn, useFormikContext } from 'formik';
import { get } from 'lodash';
import * as Yup from 'yup';

import AlertBanner from '~/components/core/AlertBanner';
import Typography from '~/components/core/Atomic/Typography';
import CheckBanner from '~/components/core/CheckBanner';
import { CliveAlertBanner } from '~/components/Fnol/FnolAiInbox/CliveAlertBanner/CliveAlertBanner';
import { useCms } from '~/components/hooks/useCms';
import { getPolicyIssueNote } from '~/PolicyUtils';

import { ErrorHelperTextFormik } from '../../../core/Formik/ErrorHelperTextFormik';
import { useIncidentConfiguration } from '../../../hooks/useIncidentConfiguration';
import { usePolicy } from '../../../PolicyContainer';
import InsuredInfoVerificationCard from '../../InsuredInfoVerificationCard';
import SectionWrapper from '../SectionWrapper';

const DEFAULT_INSTRUCTIONS =
  'Please complete the relevant sections with the applicable incident details.\nIf any are not applicable, please write N/A.';

const getVerifiedInsuredInfoValidation = (incidentConfiguration) =>
  incidentConfiguration?.fnol?.fnol_policyholder_contact_id?.mandatory &&
  incidentConfiguration?.fnol?.fnol_policyholder_contact_id?.active !== false
    ? Yup.boolean().oneOf([true], 'Must verify policyholder details')
    : undefined;

const FnolHeadline = ({ showCliveWarning }) => {
  const { policy } = usePolicy();
  const { user } = useCms();
  const { incidentConfiguration } = useIncidentConfiguration();
  const { setFieldValue, values } = useFormikContext();
  const [checked, setChecked] = useState(getIn(values, 'verified_insured_info') || false);
  const [showPolicyHolderVerificationCard, setShowPolicyHolderVerificationCard] = useState(false);
  const [shouldShowCliveWarning, setShouldShowCliveWarning] = useState(showCliveWarning);
  const isTimeOfLossMandatory =
    incidentConfiguration?.incident_details?.pre_defined_fields?.time_of_loss?.mandatory &&
    incidentConfiguration?.incident_details?.pre_defined_fields?.time_of_loss?.active !== false;
  const shouldShowPolicyIssueNote = !(isTimeOfLossMandatory && !values?.time_of_loss);
  const policyIssueNote = shouldShowPolicyIssueNote ? getPolicyIssueNote({ values, policy }) : null;
  const configurableFnolInstructions = incidentConfiguration?.fnol?.instructions;
  const isFnolInstructionsConfigured = configurableFnolInstructions?.active || false;
  const fnolInstructions =
    isFnolInstructionsConfigured && configurableFnolInstructions?.desc
      ? configurableFnolInstructions?.desc
      : DEFAULT_INSTRUCTIONS;
  const shouldShowVerifiedInsuredInfo = get(incidentConfiguration, 'fnol.fnol_policyholder_contact_id.active', false);

  return (
    <>
      <SectionWrapper>
        <span className="flex flex-col gap-12">
          <Typography display="block" variant="h5" className="pb-4 pt-40 font-semibold">
            First Notice of Loss
          </Typography>

          {shouldShowCliveWarning && (
            <CliveAlertBanner
              note={`Hi ${user.first_name}, unfortunately, I couldn’t complete the email analysis and extract the relevant details. `}
              withDismiss
              onCLose={() => setShouldShowCliveWarning(false)}
            />
          )}
          <Typography display="block" variant="body2" color="textSecondary">
            <pre style={{ fontFamily: 'inherit' }} className="m-0">
              {fnolInstructions}
            </pre>
          </Typography>
        </span>
      </SectionWrapper>
      {policyIssueNote && (
        <SectionWrapper>
          <AlertBanner alertType={AlertBanner.ALERT_TYPES.WARNING} note={policyIssueNote} withIcon />
        </SectionWrapper>
      )}
      {shouldShowVerifiedInsuredInfo && (
        <SectionWrapper>
          <CheckBanner
            withIcon
            withBold
            note="Verify Policyholder Details"
            checked={checked}
            onClick={() => setShowPolicyHolderVerificationCard(true)}
          />
          <ErrorHelperTextFormik id="verified_insured_info" />
        </SectionWrapper>
      )}

      {showPolicyHolderVerificationCard && (
        <InsuredInfoVerificationCard
          insuredContactId={values.policy?.insured_contact?.id}
          onDetailsVerified={() =>
            setFieldValue('verified_insured_info', true) &&
            setFieldValue('fnol_policyholder_contact_id', policy?.insured_contact?.id) &&
            setChecked(true)
          }
          onClose={() => setShowPolicyHolderVerificationCard(false)}
        />
      )}
    </>
  );
};

export { getVerifiedInsuredInfoValidation };

export default FnolHeadline;

FnolHeadline.propTypes = {
  showCliveWarning: PropTypes.bool,
};
