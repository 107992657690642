import React from 'react';
import { getIn, useFormikContext } from 'formik';
import type { FormikValues } from 'formik/dist/types';
import { get, omit, set } from 'lodash';
import * as Yup from 'yup';

import type { SpecialValidationRequirementsType, Tab } from '~/components/Contacts/UpsertContact/types';
import { useContact } from '~/components/Contacts/UpsertContact/useContact';
import type { ContactFullModel } from '~/components/types/contact-types';
import CONTACT_COMMUNICATION_CHANGE_REASONS from '~/server_shared/generated-types/CONTACT_COMMUNICATION_CHANGE_REASONS';
import { yupPhoneValidation } from '~/Utils/phoneValidationUtils';

import ChangeReason from '../../ChangeReason';
import { getInitializedValues, getReasonValidationSchema } from '../../utils';
import TabWrapper from '../TabWrapper';

import CommunicationMethod from './CommunicationMethod';
import {
  COMMUNICATION_TAB_KEY,
  FORMIK_COMMUNICATION_FIELD_IDS,
  FORMIK_COMMUNICATION_METHOD_FIELD_IDS,
  PHONE_TYPES,
} from './constants';
import Permissions from './Permissions';
import { getFullCommunicationPath } from './utils';

const CommunicationTab: React.FC = () => {
  const { isSubmitting } = useFormikContext();
  const { isCreation } = useContact();

  const sharedProps = {
    disabled: isSubmitting,
  };

  return (
    <TabWrapper>
      <div className="mb-32 grid grid-cols-1 gap-20">
        <CommunicationMethod
          label="Email"
          fieldId={FORMIK_COMMUNICATION_FIELD_IDS.EMAILS}
          communicationMethodName={FORMIK_COMMUNICATION_METHOD_FIELD_IDS.EMAIL}
          {...sharedProps}
        />
        <CommunicationMethod
          label="Phone"
          fieldId={FORMIK_COMMUNICATION_FIELD_IDS.PHONES}
          communicationMethodName={FORMIK_COMMUNICATION_METHOD_FIELD_IDS.PHONE}
          typeFieldId={FORMIK_COMMUNICATION_METHOD_FIELD_IDS.PHONE_TYPE}
          typeOptions={PHONE_TYPES}
          {...sharedProps}
        />
        <Permissions {...sharedProps} />
        {!isCreation ? (
          <ChangeReason
            className="col-span-full"
            reasonFieldId={getFullCommunicationPath(FORMIK_COMMUNICATION_FIELD_IDS.REASON)}
            explanationFieldId={getFullCommunicationPath(FORMIK_COMMUNICATION_FIELD_IDS.EXPLANATION)}
            reasonsDict={CONTACT_COMMUNICATION_CHANGE_REASONS}
            {...sharedProps}
          />
        ) : null}
      </div>
    </TabWrapper>
  );
};

const useCommunicationTab = (): Tab => {
  // No Formik Context
  const { originalContact, isCreation } = useContact();

  const getInitialValues = (contact?: ContactFullModel) => {
    const values = {};

    const setInitialValue = (fieldId: string, initialValue: unknown, defaultInitialValue: unknown = '') =>
      set(values, fieldId, initialValue ?? defaultInitialValue);

    setInitialValue(FORMIK_COMMUNICATION_FIELD_IDS.EMAILS, contact?.emails ? [...contact.emails] : []);
    setInitialValue(FORMIK_COMMUNICATION_FIELD_IDS.PHONES, contact?.phones ? [...contact.phones] : []);

    setInitialValue(FORMIK_COMMUNICATION_FIELD_IDS.IS_SMSING_ALLOWED, contact?.is_smsing_allowed);
    setInitialValue(FORMIK_COMMUNICATION_FIELD_IDS.IS_EMAILING_ALLOWED, contact?.is_emailing_allowed);

    if (!isCreation) {
      setInitialValue(FORMIK_COMMUNICATION_FIELD_IDS.REASON, '');
      setInitialValue(FORMIK_COMMUNICATION_FIELD_IDS.EXPLANATION, '');
    }

    return values;
  };

  const getValidationSchema = (specialValidationRequirements?: SpecialValidationRequirementsType) => {
    const validations = {};

    const addValidation = (fieldId: string, fieldValidation: Yup.AnySchema) =>
      set(validations, fieldId, fieldValidation);

    addValidation(
      FORMIK_COMMUNICATION_FIELD_IDS.EMAILS,
      Yup.array().of(
        Yup.object().shape({
          [FORMIK_COMMUNICATION_METHOD_FIELD_IDS.EMAIL]: Yup.string()
            .trim()
            .max(256, 'Cannot exceed 256 characters')
            .email('Must be a valid email address')
            .required('Required'),
          [FORMIK_COMMUNICATION_METHOD_FIELD_IDS.DESCRIPTION]: Yup.string()
            .max(32, 'Cannot exceed 32 characters')
            .nullable(),
        })
      )
    );

    addValidation(
      FORMIK_COMMUNICATION_FIELD_IDS.PHONES,
      Yup.array().of(
        Yup.object().shape({
          [FORMIK_COMMUNICATION_METHOD_FIELD_IDS.PHONE_TYPE]: Yup.string().when(
            FORMIK_COMMUNICATION_METHOD_FIELD_IDS.ID,
            {
              // validate a phone only when it is a new phone being added
              is: (id: string) => !id,
              then: Yup.string().required('Required').oneOf(PHONE_TYPES),
              otherwise: Yup.string().nullable(),
            }
          ),
          [FORMIK_COMMUNICATION_METHOD_FIELD_IDS.PHONE]: yupPhoneValidation
            .trim()
            .max(32, 'Cannot exceed 32 characters')
            .required('Required'),
          [FORMIK_COMMUNICATION_METHOD_FIELD_IDS.DESCRIPTION]: Yup.string()
            .max(32, 'Cannot exceed 32 characters')
            .nullable(),
        })
      )
    );

    const getCommunicationPermissionSchema = (communicationsFieldId: string) =>
      Yup.bool().when(communicationsFieldId, {
        is: (communications: unknown[]) =>
          specialValidationRequirements?.requireCommunicationPrivileges && communications.length > 0,
        then: Yup.bool().required('Required'),
        otherwise: Yup.bool(),
      });

    addValidation(
      FORMIK_COMMUNICATION_FIELD_IDS.IS_SMSING_ALLOWED,
      getCommunicationPermissionSchema(FORMIK_COMMUNICATION_FIELD_IDS.PHONES)
    );
    addValidation(
      FORMIK_COMMUNICATION_FIELD_IDS.IS_EMAILING_ALLOWED,
      getCommunicationPermissionSchema(FORMIK_COMMUNICATION_FIELD_IDS.EMAILS)
    );

    if (!isCreation) {
      addValidation(
        FORMIK_COMMUNICATION_FIELD_IDS.REASON,
        Yup.string().test(
          'communication-reason',
          'Required',
          getReasonValidationSchema(originalContact, getValuesToCompare, getInitialValues)
        )
      );
      addValidation(
        FORMIK_COMMUNICATION_FIELD_IDS.EXPLANATION,
        Yup.string().when(FORMIK_COMMUNICATION_FIELD_IDS.REASON, {
          is: 'other',
          then: Yup.string().required('Required'),
          otherwise: Yup.string().strip(),
        })
      );
    }

    return validations;
  };

  const getValuesToCompare = (values: FormikValues) => {
    const omittedValues = omit(values, [
      FORMIK_COMMUNICATION_FIELD_IDS.REASON,
      FORMIK_COMMUNICATION_FIELD_IDS.EXPLANATION,
    ]);
    const valuesToCompare = getInitializedValues(omittedValues);

    const emails = get(valuesToCompare, FORMIK_COMMUNICATION_FIELD_IDS.EMAILS);
    const comparableEmails = emails.map((commMethod: Record<string, unknown>) => ({
      ...commMethod,
      [FORMIK_COMMUNICATION_METHOD_FIELD_IDS.DESCRIPTION]:
        commMethod[FORMIK_COMMUNICATION_METHOD_FIELD_IDS.DESCRIPTION] ?? '',
    }));

    set(valuesToCompare, FORMIK_COMMUNICATION_FIELD_IDS.EMAILS, comparableEmails);

    const phones = get(valuesToCompare, FORMIK_COMMUNICATION_FIELD_IDS.PHONES);
    const comparablePhones = phones.map((commMethod: Record<string, unknown>) => ({
      ...commMethod,
      [FORMIK_COMMUNICATION_METHOD_FIELD_IDS.DESCRIPTION]:
        commMethod[FORMIK_COMMUNICATION_METHOD_FIELD_IDS.DESCRIPTION] ?? '',
    }));

    set(valuesToCompare, FORMIK_COMMUNICATION_FIELD_IDS.PHONES, comparablePhones);

    return valuesToCompare;
  };

  const getDuplicateCheckParams = (values: FormikValues) => {
    return {
      phones: getIn(values, getFullCommunicationPath(FORMIK_COMMUNICATION_FIELD_IDS.PHONES))
        .filter((p: Record<string, unknown>) => !p.id)
        .map((p: Record<string, unknown>) => p.phone),
      emails: getIn(values, getFullCommunicationPath(FORMIK_COMMUNICATION_FIELD_IDS.EMAILS))
        .filter((e: Record<string, unknown>) => !e.id)
        .map((e: Record<string, unknown>) => e.email),
    };
  };

  return {
    label: 'Communication',
    tabKey: COMMUNICATION_TAB_KEY,
    tabComponent: CommunicationTab,
    getInitialValues,
    getValidationSchema,
    getValuesToCompare,
    getDuplicateCheckParams,
  };
};

export default useCommunicationTab;
