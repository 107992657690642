import React from 'react';

import { getAllSearchableContactRoles } from '~/components/communications/ContactUtils';
import ContactTextFieldFormik from '~/components/ContactTextFieldFormik';
import DocumentTextFieldFormik from '~/components/Documents/DocumentTextFieldFormik';
import PaymentSelectionFieldFormik from '~/components/Finances/PaymentSelectionFieldFormik';
import useOrganization from '~/components/OrganizationContext';
import GENERIC_TEMPLATES_DYNAMIC_TOKEN_TYPES from '~/server_shared/generated-types/GENERIC_TEMPLATES_DYNAMIC_TOKEN_TYPES';

interface Props {
  dynamicTokenFields: string[];
  exposureId?: number;
}

const DynamicTemplateFields: React.FC<Props> = ({ dynamicTokenFields, exposureId }) => {
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  const { organizationContactRolesDict } = useOrganization();
  return (
    <div>
      {dynamicTokenFields.includes(GENERIC_TEMPLATES_DYNAMIC_TOKEN_TYPES.RECIPIENT) ? (
        <div className="my-12 w-full">
          <ContactTextFieldFormik
            id="recipient_contact"
            label="Recipient Contact"
            acceptedRoles={getAllSearchableContactRoles(organizationContactRolesDict)}
            fullWidth
            fixedSearchResults
            contactSearchProps={{
              disallowOutOfClaimContacts: true,
            }}
          />
        </div>
      ) : null}
      {dynamicTokenFields.includes(GENERIC_TEMPLATES_DYNAMIC_TOKEN_TYPES.CONTACT) ? (
        <div className="my-12 w-full">
          <ContactTextFieldFormik
            id="contact"
            label="Contact"
            acceptedRoles={getAllSearchableContactRoles(organizationContactRolesDict)}
            fullWidth
            fixedSearchResults
            contactSearchProps={{
              disallowOutOfClaimContacts: true,
            }}
          />
        </div>
      ) : null}
      {dynamicTokenFields.includes(GENERIC_TEMPLATES_DYNAMIC_TOKEN_TYPES.DOCUMENT) ? (
        <div className="my-12 w-full">
          <DocumentTextFieldFormik id="document_id" label="Document" />
        </div>
      ) : null}
      {dynamicTokenFields.includes(GENERIC_TEMPLATES_DYNAMIC_TOKEN_TYPES.PAYMENT) ? (
        <div className="my-12 w-full">
          <PaymentSelectionFieldFormik id="payment_request_id" label="Payment" exposureId={exposureId} />
        </div>
      ) : null}
    </div>
  );
};

export default DynamicTemplateFields;
