import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Divider } from '@material-ui/core';
import axios from 'axios';

import CardDialog from '~/components/CardDialog';
import Chip from '~/components/core/Atomic/Chip/Chip';
import Grid from '~/components/core/Atomic/Grid/Grid';
import { useCms } from '~/components/hooks/useCms';
import { useLob } from '~/components/hooks/useLob';

import { CONFIGURATION_FEATURES_NAMES } from '../../../../Types';
import { isFeatureEnabled, reportAxiosError } from '../../../../Utils';
import { ControlFileClaimSummaryChip, DeferredFileClaimSummaryChip, SiuClaimSummaryChip } from '../../../ClaimSummary';
import { ConfirmModal } from '../../../ConfirmModal';
import { MainCard, PERMISSION_ACTIONS, PERMISSION_VERBS, RestrictedPermissions } from '../../../core';
import ConfiguredFields, { PreDefinedField } from '../../../IncidentConfiguration/ConfiguredFields';
import IncidentMoreActionsContainer from '../../../IncidentMoreActionsContainer';
import IncidentMutualChips from '../../../IncidentMutualChips';
import IncidentTypeUpdateDialog from '../../../IncidentTypeUpdateDialog';
import { ShowOnlyTextField } from '../../../TextFieldFormik';

import IncidentMoreDetailsMenu from './IncidentMoreDetailsMenu';

import { useStyles } from '../../../../assets/styles';
import styles from './NewFnolIncidentDetailsCard.module.scss';

const NewFnolIncidentDetailsCard = ({ claim, onUpdate, readOnly }) => {
  const { lob, claimType } = useLob();
  const classes = useStyles();
  const [isFetching, setIsFetching] = useState(false);
  const [showIncidentTypeUpdateDialog, setShowIncidentTypeUpdateDialog] = useState(false);
  const [showConfirmModal, setShowConfirmModal] = useState(false);
  const { userOrganization } = useCms();
  const preDefinedFields = claim.incident_configuration
    ? {
        ...claim.incident_configuration?.incident_details?.pre_defined_fields,
        ...claim.incident_configuration?.fnol,
        primary_contact_id: claim.incident_configuration?.incident_details?.pre_defined_fields?.preferred_contact_id,
      }
    : {};
  const { incident } = claim;
  const disabled = readOnly || isFetching;

  const isPermissionsEnabled = isFeatureEnabled(userOrganization, CONFIGURATION_FEATURES_NAMES.PERMISSIONS_ENFORCEMENT);
  const isReporterContactEditable = isFeatureEnabled(
    userOrganization,
    CONFIGURATION_FEATURES_NAMES.REPORTER_CONTACT_EDITABLE
  );
  const isNewSummaryLayoutEnabled = isFeatureEnabled(
    userOrganization,
    CONFIGURATION_FEATURES_NAMES.CLAIM_SUMMARY_NEW_LAYOUT
  );

  const handleConfiguredFieldValue = async (fieldId, updatedValue) => {
    await handleUpdateIncidentFields({
      configured_fields_values: { ...incident?.configured_fields_values, [fieldId]: updatedValue },
    });
  };

  const handleUpdateIncidentField = async (fieldName, fieldValue) => {
    await handleUpdateFields({ incident: { [fieldName]: fieldValue } });
  };

  const handleUpdateIncidentDateOfLoss = async (fieldName, fieldValue) => {
    await updateWrapper(axios.put, `/api/v1/claims/${claim.id}/date_of_loss`, {
      [fieldName]: fieldValue,
    });
  };

  const handleUpdateIncidentTimeOfLoss = async (fieldName, fieldValue) => {
    await updateWrapper(axios.put, `/api/v1/claims/${claim.id}/time_of_loss`, {
      [fieldName]: fieldValue,
    });
  };

  const handleUpdateClaimReportedDate = async (fieldName, fieldValue) => {
    await updateWrapper(axios.put, `/api/v1/claims/${claim.id}/reported_date`, {
      [fieldName]: fieldValue,
    });
  };

  const handleUpdateClaimReporterContact = async (fieldName, fieldValue) => {
    await updateWrapper(axios.put, `/api/v1/claims/${claim.id}/reporter_contact`, {
      [fieldName]: fieldValue,
    });
  };

  const handleUpdateIncidentFields = async (updateObject) => {
    await handleUpdateFields({ incident: updateObject });
  };

  const handleUpdateClaimField = async (fieldName, fieldValue) => {
    await handleUpdateFields({ [fieldName]: fieldValue });
  };

  const updateWrapper = async (axiosVerb, url, updateObject) => {
    try {
      setIsFetching(true);
      await axiosVerb(url, updateObject);
      await onUpdate();
    } catch (error) {
      await reportAxiosError(error);
    }
    setIsFetching(false);
  };

  const handleUpdateFields = async (updateObject) => {
    const url = claimType === 'general_claim' ? `/api/v1/claims/${claim.id}` : `/api/v1/${lob}s/${claim.id}`;
    await updateWrapper(axios.patch, url, updateObject);
  };

  const DetailsAction = !readOnly && (
    <div className={styles.incidentDetailsActions}>
      <IncidentMoreDetailsMenu incident={incident} onUpdateFields={handleUpdateIncidentFields} />
      <Divider orientation="vertical" flexItem className={styles.incidentDetailsActionsDivider} />
      <IncidentMoreActionsContainer incident={incident} onUpdateFields={handleUpdateIncidentFields} />
    </div>
  );

  const DetailsCardContent = (
    <>
      <div>
        <IncidentMutualChips claim={claim} />
        {incident.is_siu && <SiuClaimSummaryChip />}
        {incident.is_control_file && <ControlFileClaimSummaryChip />}
        {incident.is_deferred_file && <DeferredFileClaimSummaryChip />}
        {incident.is_uw && <Chip size="small" color="primary" label="UW" className={classes.chip} />}
      </div>
      <Grid container alignItems="stretch" spacing={1}>
        <Grid item xs={isNewSummaryLayoutEnabled ? 3 : 6}>
          <ShowOnlyTextField
            classes={classes}
            showOnlyValueComponent={claim.incident_type_desc}
            label={lob === 'home_claim' ? 'Peril' : 'Incident type'}
            onEdit={disabled ? undefined : () => setShowConfirmModal(true)}
          />
        </Grid>
        <Grid item xs={isNewSummaryLayoutEnabled ? 3 : 6}>
          <ShowOnlyTextField
            classes={classes}
            showOnlyValueComponent={claim.incident_sub_type_desc}
            label={lob === 'home_claim' ? 'Cause of Loss' : 'Incident sub-type'}
            onEdit={disabled ? undefined : () => setShowConfirmModal(true)}
          />
        </Grid>
        <RestrictedPermissions action={PERMISSION_ACTIONS.DATE_OF_LOSS} verb={PERMISSION_VERBS.WRITE}>
          <PreDefinedField
            id="date_of_loss"
            fields={preDefinedFields}
            value={claim.incident.date_of_loss}
            onUpdate={handleUpdateIncidentDateOfLoss}
            disabled={disabled}
            disableFuture
            gridXs={isNewSummaryLayoutEnabled ? 3 : undefined}
          />
        </RestrictedPermissions>
        <RestrictedPermissions action={PERMISSION_ACTIONS.TIME_OF_LOSS} verb={PERMISSION_VERBS.WRITE}>
          <PreDefinedField
            id="time_of_loss"
            fields={preDefinedFields}
            value={claim.incident?.time_of_loss}
            onUpdate={handleUpdateIncidentTimeOfLoss}
            disabled={disabled}
            gridXs={isNewSummaryLayoutEnabled ? 3 : undefined}
          />
        </RestrictedPermissions>
        <RestrictedPermissions action={PERMISSION_ACTIONS.REPORTER} verb={PERMISSION_VERBS.WRITE}>
          <PreDefinedField
            id="reporter_contact_id"
            fields={preDefinedFields}
            value={claim.reporter_contact_id}
            contactDisplayName={claim?.reporter_contact_full_name}
            onUpdate={handleUpdateClaimReporterContact}
            showOnly={!isReporterContactEditable}
            readOnly={!isReporterContactEditable}
            disabled={disabled}
            gridXs={isNewSummaryLayoutEnabled ? 3 : undefined}
          />
        </RestrictedPermissions>
        <PreDefinedField
          id="primary_contact_id"
          fields={preDefinedFields}
          value={claim?.primary_contact_id}
          contactDisplayName={claim?.primary_contact_full_name}
          onUpdate={handleUpdateClaimField}
          disabled={disabled}
          gridXs={isNewSummaryLayoutEnabled ? 3 : undefined}
        />
        <RestrictedPermissions action={PERMISSION_ACTIONS.DATE_OF_REPORT} verb={PERMISSION_VERBS.WRITE}>
          <PreDefinedField
            id="reported_date"
            fields={preDefinedFields}
            value={claim.reported_date}
            onUpdate={isPermissionsEnabled ? handleUpdateClaimReportedDate : undefined}
            disabled={disabled}
            showOnly={!isPermissionsEnabled}
            readOnly={!isPermissionsEnabled}
            disableFuture
            gridXs={isNewSummaryLayoutEnabled ? 3 : undefined}
          />
        </RestrictedPermissions>
        <PreDefinedField
          id="submission_mode"
          fields={preDefinedFields}
          value={claim.submission_mode}
          showOnly
          readOnly
          gridXs={isNewSummaryLayoutEnabled ? 3 : undefined}
        />
        <PreDefinedField
          id="loss_location"
          fields={preDefinedFields}
          value={claim.incident?.loss_location}
          onUpdate={handleUpdateIncidentField}
          disabled={disabled}
          gridXs={isNewSummaryLayoutEnabled ? 3 : 12}
        />
        <ConfiguredFields
          values={incident}
          customFields={claim.incident_configuration.incident_details.configured_fields.filter(
            (field) => field.section === 'incident_details' || !field.section
          )}
          onFieldUpdate={handleConfiguredFieldValue}
          newLayoutEnabled={isNewSummaryLayoutEnabled}
        />
        <Grid container spacing={1}>
          <PreDefinedField
            id="description"
            fields={preDefinedFields}
            value={claim.incident?.description}
            onUpdate={handleUpdateIncidentField}
            rows={5}
            width="100%"
            hoverActionWidth="450px"
            disabled={disabled}
            gridXs={isNewSummaryLayoutEnabled ? 6 : undefined}
          />
          <PreDefinedField
            id="special_internal_note"
            fields={preDefinedFields}
            value={claim?.special_internal_note}
            onUpdate={handleUpdateClaimField}
            rows={5}
            width="100%"
            hoverActionWidth="450px"
            disabled={disabled}
            gridXs={isNewSummaryLayoutEnabled ? 6 : undefined}
          />
        </Grid>
      </Grid>
    </>
  );

  const mutualCardProps = {
    title: 'Incident Details',
    headerStyle: { padding: '16px' },
    action: DetailsAction,
  };

  return (
    <>
      {isNewSummaryLayoutEnabled && (
        <MainCard collapsible type="elevated" openByDefault {...mutualCardProps}>
          {DetailsCardContent}
        </MainCard>
      )}
      {!isNewSummaryLayoutEnabled && <CardDialog {...mutualCardProps}>{DetailsCardContent}</CardDialog>}

      {showIncidentTypeUpdateDialog && (
        <IncidentTypeUpdateDialog
          incidentTypesDict={claim.incident_types_dict}
          incidentType={claim.incident.incident_type}
          incidentSubType={claim.incident.incident_sub_type}
          onUpdate={async (values) => await handleUpdateFields({ incident: values })}
          onClose={() => setShowIncidentTypeUpdateDialog(false)}
        />
      )}

      {showConfirmModal && (
        <ConfirmModal
          isOpen={showConfirmModal}
          title="Change the incident type?"
          contentText="Changing the incident type or sub type of the claim may change additional data of it. If you choose to proceed, please review the involved parties, the additional claim data and the exposures, and update as applicable."
          primaryButtonName="CHANGE"
          onClose={() => {
            setShowConfirmModal(false);
            setShowIncidentTypeUpdateDialog(false);
          }}
          onPrimaryBtnClick={() => {
            setShowConfirmModal(false);
            setShowIncidentTypeUpdateDialog(true);
          }}
        />
      )}
    </>
  );
};

NewFnolIncidentDetailsCard.propTypes = {
  claim: PropTypes.object.isRequired,
  onUpdate: PropTypes.func,
  readOnly: PropTypes.bool,
};

export default NewFnolIncidentDetailsCard;
