import React, { useMemo } from 'react';

import CardDialog from '~/components/CardDialog';
import { CliveAlertBanner } from '~/components/Fnol/FnolAiInbox/CliveAlertBanner/CliveAlertBanner';
import { PolicyCard } from '~/components/Fnol/FnolAiInbox/PolicySelectionCard/PolicyCard';
import { PolicySearchCard } from '~/components/Fnol/FnolAiInbox/PolicySelectionCard/PolicySearchCard';
import type { Policy } from '~/components/Fnol/FnolAiInbox/types';
import LoadingIndicator from '~/components/LoadingIndicator';
import useDataFetcher from '~/components/useDataFetcher';

type PolicySelectionCardState = 'loading' | 'show-policy' | 'search-policy' | 'change-policy-message';

interface PolicySelectionCardProps {
  inboxItemId: string;
  policyNumber?: string;
  onCancel: () => void;
  onSubmit: (inboxItemId: string, policy?: Policy) => void;
  viewOnly: boolean;
}

export const PolicySelectionCard: React.FC<PolicySelectionCardProps> = ({
  inboxItemId,
  policyNumber,
  onCancel,
  onSubmit,
  viewOnly = false,
}) => {
  const { data, isLoading, isError } = useDataFetcher(
    `/api/v1/claims/fnol_ai/inbox/${inboxItemId}`,
    {},
    !!policyNumber
  );

  const [cardState, setCardState] = React.useState<PolicySelectionCardState>(
    policyNumber ? 'show-policy' : 'search-policy'
  );

  const policy = useMemo<Policy | undefined>(() => data?.policy?.policy_data, [data]);

  const onShowSearchPolicyHandler = () => setCardState('search-policy');

  const onSelectPolicyHandler = (policy?: Policy) => {
    setCardState('change-policy-message');
    onSubmit(inboxItemId, policy);
  };

  React.useEffect(() => {
    if (isLoading) {
      setCardState('loading');
      return;
    }
    setCardState(policyNumber ? 'show-policy' : 'search-policy');
  }, [isLoading, policyNumber]);

  return (
    <>
      {isLoading || isError ? (
        <CardDialog isDialog title="Loading Policy..." onClose={onCancel} maxWidth="sm">
          <LoadingIndicator isError={isError} />
        </CardDialog>
      ) : (
        <>
          {cardState === 'show-policy' && policy && (
            <PolicyCard
              policy={policy}
              onChangePolicy={onShowSearchPolicyHandler}
              onRemove={onSelectPolicyHandler}
              onCancel={onCancel}
              viewOnly={viewOnly}
            />
          )}

          {cardState === 'search-policy' && (
            <PolicySearchCard onSelectPolicy={onSelectPolicyHandler} onCancel={onCancel} />
          )}

          {cardState === 'change-policy-message' && (
            <CliveAlertBanner
              isDialog
              note="I’m processing the policy change and updating the FNOL accordingly, this might take a few minutes.
          Once It’s done, the status will be updated."
              onCLose={onCancel}
            />
          )}
        </>
      )}
    </>
  );
};
