import React from 'react';
import PropTypes from 'prop-types';

import SvgIcon from '../../core/SvgIcon';

const ClaimOwnerChangeIcon = ({ iconColor = '#202020', ...props }) => (
  <SvgIcon stroke="none" fill="none" {...props}>
    <path
      d="M6 9C7.65685 9 9 7.65685 9 6C9 4.34315 7.65685 3 6 3C4.34315 3 3 4.34315 3 6C3 7.65685 4.34315 9 6 9Z"
      strokeMiterlimit="10"
      stroke={iconColor}
      fill="none"
    />
    <path
      d="M1 13C1.50667 11.7839 2.23548 10.774 3.11317 10.0718C3.99086 9.36966 4.9865 9 6 9C7.0135 9 8.00914 9.36966 8.88683 10.0718C9.76452 10.774 10.4933 11.7839 11 13"
      strokeLinecap="round"
      strokeLinejoin="round"
      stroke={iconColor}
      fill="none"
    />
    <path
      d="M15 9.8501L13.5 11.3501L12 9.8501"
      strokeLinecap="round"
      strokeLinejoin="round"
      stroke={iconColor}
      fill="none"
    />
    <path
      d="M12 6.1001L13.5 4.6001L15 6.1001"
      strokeLinecap="round"
      strokeLinejoin="round"
      stroke={iconColor}
      fill="none"
    />
    <line x1="13.5" y1="10.1001" x2="13.5" y2="5.1001" strokeLinecap="round" stroke={iconColor} fill="none" />
  </SvgIcon>
);

ClaimOwnerChangeIcon.propTypes = {
  iconColor: PropTypes.string,
};

export default ClaimOwnerChangeIcon;
