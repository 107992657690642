import React from 'react';
import { useFormikContext } from 'formik';

import { Text } from '~/components/core';
import MenuItem from '~/components/core/Atomic/MenuItem';
import { FeatureEnabled } from '~/components/core/FeatureFlagLayoutSwitch/FeatureFlagSwitch';
import useOrganization from '~/components/OrganizationContext';
import { StatesConfigurationFormik } from '~/components/SystemConfiguration/AutomaticRulesConfiguration/Notifications/NotificationRuleDialog/StatesConfigurationFormik';
import TimeframeFieldSelect from '~/components/SystemConfiguration/AutomaticRulesConfiguration/Notifications/NotificationRuleDialog/TimeframeFieldSelect';
import type { CategoryModel } from '~/components/SystemConfiguration/NotificationsConfiguration/NotificationsCategories';
import { useSysconfig } from '~/components/SystemConfiguration/SystemConfigurationScreen';
import TextFieldFormik from '~/components/TextFieldFormik';
import { CLAIM_NOTIFICATIONS_PRIORITY, CONFIGURATION_FEATURES_NAMES, NOTIFICATIONS_RULES_TIMEFRAMES } from '~/Types';
import { isOrganizationUsOrg } from '~/Utils';

const NotificationConfigurationFieldsFormikInner: React.FC = () => {
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  const { organization } = useSysconfig();
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  const { organizationCategories, isLoadingCategories, isErrorCategories } = useOrganization();
  const { isSubmitting } = useFormikContext();

  const timeframeOptions = Object.entries(NOTIFICATIONS_RULES_TIMEFRAMES)
    .filter(([key]) => key !== 'hours')
    .map(([key, option]) => ({
      key,
      label: option.desc,
    }));

  return (
    <div>
      {isOrganizationUsOrg(organization) ? (
        <FeatureEnabled
          featureFlag={CONFIGURATION_FEATURES_NAMES.MULTI_STATE_NOTIFICATIONS}
          organization={organization}
        >
          <Text variant={Text.VARIANTS.SM} weight={Text.WEIGHTS.REGULAR}>
            Select Loss Location by States
          </Text>
          <StatesConfigurationFormik />
        </FeatureEnabled>
      ) : null}
      <div className="grid grid-cols-2 gap-8">
        <TextFieldFormik id="priority" label="Priority" disabled={isSubmitting} className="my-4" fullWidth select>
          {/* eslint-disable-next-line @typescript-eslint/ban-ts-comment */}
          {/*@ts-ignore*/}
          {Object.keys(CLAIM_NOTIFICATIONS_PRIORITY).map((priority: keyof typeof CLAIM_NOTIFICATIONS_PRIORITY) => (
            <MenuItem key={priority} value={priority}>
              {CLAIM_NOTIFICATIONS_PRIORITY[priority]}
            </MenuItem>
          ))}
        </TextFieldFormik>
        {!isLoadingCategories && !isErrorCategories && (
          <TextFieldFormik id="category_id" label="Category" disabled={isSubmitting} className="my-4" fullWidth select>
            {/* eslint-disable-next-line @typescript-eslint/ban-ts-comment */}
            {/*@ts-ignore*/}
            {organizationCategories.map((category: CategoryModel) => (
              <MenuItem key={category.key} value={category.id}>
                {category.label}
              </MenuItem>
            ))}
          </TextFieldFormik>
        )}
        <div className="col-span-2">
          <TimeframeFieldSelect
            isSubmitting={isSubmitting}
            timeframeOptions={timeframeOptions}
            immediate_or_custom_field="due_date_immediate_or_custom"
            number_field="due_date_number"
            timeframe_field="due_date_timeframe"
            label="Due Date"
          />
        </div>
      </div>
    </div>
  );
};

export default NotificationConfigurationFieldsFormikInner;
