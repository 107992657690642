import type { BasicConfigurationHookResponse } from '~/components/hooks/types';
import useCachedDataFetcher from '~/components/hooks/useCachedDataFetcher';
import { useCms } from '~/components/hooks/useCms';
import type { NotesConfiguration } from '~/components/SystemConfiguration/NotesConfiguration/types';
import { useSysconfig } from '~/components/SystemConfiguration/SystemConfigurationScreen';

interface UseNotesConfigurationResponse extends BasicConfigurationHookResponse<NotesConfiguration> {
  notesConfigurationRoute: string;
}

const useNotesConfiguration = (inAdminPanel = false): UseNotesConfigurationResponse => {
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  const { organization: adminPanelOrganization } = useSysconfig();
  const { userOrganization } = useCms();
  const organizationId = inAdminPanel ? adminPanelOrganization?.id : userOrganization?.id;

  const notesConfigurationRoute = `/api/v1/notes_configuration/organizations/${organizationId}`;

  const { data, isLoading, isError, reloadData } = useCachedDataFetcher({
    url: notesConfigurationRoute,
    shouldUseCache: true,
    localCacheKey: `notes_configuration_${organizationId}`,
  });

  return {
    notesConfigurationRoute,
    configuration: data,
    isLoading,
    isError,
    reloadData,
  };
};

export default useNotesConfiguration;
