import React, { useCallback, useEffect, useState } from 'react';
import { InputAdornment, Link, Switch } from '@material-ui/core';
import axios from 'axios';
import _ from 'lodash';

import Button from '~/components/core/Atomic/Buttons/Button';
import IconButton from '~/components/core/Atomic/Buttons/IconButton';
import Grid from '~/components/core/Atomic/Grid/Grid';
import Typography from '~/components/core/Atomic/Typography';
import InnerCard from '~/components/core/Cards/InnerCard';
import { FeatureEnabled } from '~/components/core/FeatureFlagLayoutSwitch/FeatureFlagSwitch';
import TextField from '~/components/core/Molecules/Fields/TextField';
import ThreeDotsMenu from '~/components/core/ThreeDotsMenu';
import { AddIcon } from '~/components/deprecatedMuiIcons';
import PreDefinedNotificationEditDialog from '~/components/SystemConfiguration/AutomaticRulesConfiguration/Notifications/NotificationRuleDialog/PreDefinedNotificationEditDialog';
import cn from '~/Utils/cn';

import { NOTIFICATION_MIXPANEL_EVENT_SOURCES, NOTIFICATION_MIXPANEL_EVENTS } from '../../../../pocs/mixpanel';
import { CONFIGURATION_FEATURES_NAMES, NOTIFICATIONS_SCOPES } from '../../../../Types';
import { isFeatureEnabled, isOrganizationUsOrg, reportAxiosError, stringCmp } from '../../../../Utils';
import CardDialog from '../../../CardDialog';
import mixpanel from '../../../CmsMain/mixpanel';
import { MultiSelectFilter, SortableTable } from '../../../core';
import { CancelIcon, PencilIcon, RemoveIcon } from '../../../icons';
import InlineIconButton from '../../../InlineIconButton';
import LoadingIndicator from '../../../LoadingIndicator';
import useDataFetcher from '../../../useDataFetcher';
import OperationsBreadcrumbs from '../../OperationsBreadcrumbs';
import { useSysconfig } from '../../SystemConfigurationScreen';

import { EditStatesDialog } from './EditStatesDialog/EditStatesDialog';
import { ViewMoreDetailsDialog } from './MoreDetailsDialog/ViewMoreDetailsDialog';
import { NotificationDialog } from './NotificationRuleDialog/NotificationDialog';
import { NotificationRuleIcon } from './TableCells/NotificationRuleIcon';
import { StatesCell } from './TableCells/StatesCell';
import { TextCell } from './TableCells/TextCell';
import { TitleAndContentWithTokens } from './TableCells/TitleAndContentWithTokens';
import {
  convertServerPreDefinedToFormikValues,
  convertServerRuleToFormikValues,
  getScopeTypeDescription,
  INITIAL_SEARCH_FILTER,
  NOTIFICATION_TYPE,
  RULE_TYPE_OPTIONS,
  STATES_SELECT_OPTIONS,
  useNotificationConfigurationTool,
} from './utils';

import { useStyles } from '../../../../assets/styles';
import styles from './styles.module.scss';

const STATES_FILTER_OPTIONS = [
  {
    value: 'All',
    label: 'All',
  },
  ...STATES_SELECT_OPTIONS,
];

const NotificationsConfigurationTool = () => {
  const [searchNotificationText, setSearchNotificationText] = useState(INITIAL_SEARCH_FILTER.searchNotificationText);
  const [selectedRuleType, setSelectedRuleType] = useState(INITIAL_SEARCH_FILTER.selectedRuleType);
  const [selectedSubOrgs, setSelectedSubOrgs] = useState(INITIAL_SEARCH_FILTER.selectedSubOrgs);
  const [subOrgsOptions, setSubOrgsOptions] = useState([]);
  const [selectedScope, setSelectedScope] = useState(INITIAL_SEARCH_FILTER.selectedScope);
  const [selectedScopeType, setSelectedScopeType] = useState(INITIAL_SEARCH_FILTER.selectedScopeType);
  const [scopeTypeOptions, setScopeTypeOptions] = useState([]);
  const [selectedRecipient, setSelectedRecipient] = useState(INITIAL_SEARCH_FILTER.selectedRecipient);
  const [recipientOptions, setRecipientOptions] = useState([]);
  const [hideDisabled, setHideDisabled] = useState(INITIAL_SEARCH_FILTER.hideDisabled);
  const [notificationsDisabledMap, setNotificationsDisabledMap] = useState({});
  const [disabledNotificationsDict, setDisabledNotificationsDict] = useState({});
  const [notificationToEdit, setNotificationToEdit] = useState(null);
  const [notificationsToShow, setNotificationsToShow] = useState([]);
  const [showNotificationDialog, setShowNotificationDialog] = useState(false);
  const [isDuplicatedNotificationRule, setIsDuplicatedNotificationRule] = useState(false);
  const [notificationToShow, setNotificationToShow] = useState(null);
  const [amountOfAllNotifications, setAmountOfAllNotifications] = useState(0);
  const [selectedStates, setSelectedStates] = useState([]);
  const [statesEditDialogNotification, setStatesEditDialogNotification] = useState(null);

  const { organization } = useSysconfig();
  const { recipientTypes } = useNotificationConfigurationTool();
  const classes = useStyles();

  const isNotifications2Enabled = isFeatureEnabled(organization, CONFIGURATION_FEATURES_NAMES.NOTIFICATIONS_2);

  const baseNotificationsRoute = `/api/v1/organizations/${organization.id}/notifications`;

  const {
    isLoading,
    isError,
    data: notifications,
    reloadData: reloadPreDefined,
  } = useDataFetcher(`${baseNotificationsRoute}/pre_defined`);
  const {
    isLoading: isLoadingDisabledMap,
    isError: isErrorDisabledMap,
    data: disabledNotifications,
    reloadData: reloadDisabledNotifications,
  } = useDataFetcher(`${baseNotificationsRoute}/disabled_map`);
  const {
    isLoading: isLoadingRules,
    isError: isErrorRules,
    data: notificationsRules,
    reloadData: reloadRules,
  } = useDataFetcher(`${baseNotificationsRoute}/rules`);
  const {
    isLoading: isLoadingConditions,
    isError: isErrorConditions,
    data: conditions,
  } = useDataFetcher(`${baseNotificationsRoute}/rules_conditions`);
  const {
    isLoading: isLoadingEvents,
    isError: isErrorEvents,
    data: events,
  } = useDataFetcher(`${baseNotificationsRoute}/events`);
  const {
    isLoading: isLoadingIncidentTypesMap,
    isError: isErrorIncidentTypesMap,
    data: incidentTypesMap,
  } = useDataFetcher(`/api/v1/organizations/${organization.id}/incident_types_map`);
  const {
    isLoading: isLoadingCoveragesMap,
    isError: isErrorCoveragesMap,
    data: coveragesMap,
  } = useDataFetcher(`/api/v1/organizations/${organization.id}/coverages_map`);

  const getIsEnabled = useCallback(
    (rule) => {
      if (rule.notification_type === NOTIFICATION_TYPE.SELF_CONFIGURED) {
        return !rule.is_disabled;
      }
      const isDisabled = notificationsDisabledMap[rule.notification_key]?.is_disabled;
      if (isDisabled === undefined) {
        return true; // enabled by default
      }
      return !isDisabled; // the table shows is enabled and not is disabled
    },
    [notificationsDisabledMap]
  );

  const isNotificationFitFilters = useCallback(
    (notification) => {
      if (!conditions) {
        return true;
      }

      // checks if a value is in a list,
      // valueMapping enables to compare a calculated value and not the value it self
      // only for self configured rules
      //   - valueIsListForRules enables to compare two lists (both value and list are lists in this case)
      //   - valueMappingForRules enables to calculate different value only for self configured rules
      //   - mapEmptyToAll enables to include empty value in all filter
      const includedInList = (list, value, valueMapping, valueIsListForRules, valueMappingForRules, mapEmptyToAll) => {
        const getValueToCheck = (val) => {
          let valueToCheck = valueMapping ? valueMapping[val?.trim()] : val?.trim();
          if (notification.notification_type === 'self_configured') {
            if (valueMappingForRules) {
              valueToCheck = valueMappingForRules[val?.trim()];
            }
            if (mapEmptyToAll && !val) {
              valueToCheck = 'all';
            }
          }
          return valueToCheck;
        };
        if (list.length) {
          if (valueIsListForRules && notification.notification_type === 'self_configured') {
            const mutual = _.intersectionBy(
              list,
              value.split(', ').map((val) => getValueToCheck(val)),
              (str) => str.toLowerCase()
            );
            return mutual.length > 0;
          } else {
            return !!list.find((listValue) => listValue.toLowerCase() === (getValueToCheck(value) || '').toLowerCase());
          }
        }
        return true;
      };

      const doesMatchText = `${notification.title.toLowerCase() || ''} ${notification.content.toLowerCase() || ''} ${
        notification.rule_name?.toLowerCase() || ''
      }`.includes(searchNotificationText.toLowerCase());
      const doesMatchRuleType = includedInList(
        selectedRuleType,
        notification.notification_type,
        _.mapValues(RULE_TYPE_OPTIONS, (option) => option.title)
      );
      const doesMatchSubOrgs = includedInList(selectedSubOrgs, notification.sub_orgs, undefined, true);
      const doesMatchScope = includedInList(
        selectedScope,
        notification.scope,
        _.mapValues(NOTIFICATIONS_SCOPES, (option) => option.desc)
      );
      const doesMatchScopeType = includedInList(
        selectedScopeType,
        notification.scope_types,
        undefined,
        true,
        { ...incidentTypesMap, ...coveragesMap },
        true
      );
      const doesMatchRecipient = includedInList(
        selectedRecipient,
        notification.recipient,
        undefined,
        false,
        _.mapValues(recipientTypes, (option) => option.desc)
      );
      const doesMatchDisabled = hideDisabled ? getIsEnabled(notification) : true;

      const doesStatesMatch =
        selectedStates.length === 0 ||
        notification.notification_type !== NOTIFICATION_TYPE.SELF_CONFIGURED ||
        notification.is_all_states ||
        notification.states?.some((state) => selectedStates.includes(state)) ||
        (notification.is_all_states && selectedStates.includes('All'));

      return (
        doesMatchText &&
        doesMatchRuleType &&
        doesMatchSubOrgs &&
        doesMatchScope &&
        doesMatchScopeType &&
        doesMatchRecipient &&
        doesMatchDisabled &&
        doesStatesMatch
      );
    },
    [
      conditions,
      searchNotificationText,
      selectedRuleType,
      selectedSubOrgs,
      selectedScope,
      selectedScopeType,
      incidentTypesMap,
      coveragesMap,
      selectedRecipient,
      hideDisabled,
      getIsEnabled,
      selectedStates,
      recipientTypes,
    ]
  );

  // creating a disabled map ({[notification_key/rule_id]: true/false}) for all notifications (pre defined and self configured)
  useEffect(() => {
    const disabledMap = _.keyBy(disabledNotifications, 'notification_key');
    setNotificationsDisabledMap(disabledMap);
  }, [disabledNotifications]);

  // creating sub_orgs, scope_types and recipient filters dropdowns option based on existing notifications
  useEffect(() => {
    if (conditions && incidentTypesMap && coveragesMap) {
      const notificationsToMap = notifications || [];
      const notificationsRulesToMap = notificationsRules || [];
      const createOptionsByAttribute = (attribute, isListForRules, mapRulesValues) => {
        const preDefined = notificationsToMap.map((notification) =>
          _.capitalize(notification[attribute].toLowerCase())
        );
        const selfConfigured = notificationsRulesToMap.reduce((values, notification) => {
          const valueOrDesc = (val) =>
            _.capitalize((mapRulesValues ? mapRulesValues(val, notification) : val).toLowerCase());
          const valuesToAdd = isListForRules
            ? notification[attribute].split(', ').map((val) => valueOrDesc(val))
            : valueOrDesc(notification[attribute]);
          return values.concat(valuesToAdd);
        }, []);
        return Array.from(new Set(preDefined.concat(selfConfigured)))
          .filter((val) => !!val)
          .sort();
      };
      setSubOrgsOptions(createOptionsByAttribute('sub_orgs', true));
      setScopeTypeOptions(
        createOptionsByAttribute(
          'scope_types',
          true,
          (val, notification) =>
            getScopeTypeDescription(val, notification.scope, incidentTypesMap, coveragesMap) || 'all'
        )
      );
      setRecipientOptions(
        createOptionsByAttribute('recipient', false, (recipientType) => recipientTypes[recipientType]?.desc || '')
      );
    }
  }, [notifications, notificationsRules, conditions, incidentTypesMap, coveragesMap, recipientTypes]);

  // filtering notifications (pre defined and self configured) based on filters selections
  useEffect(() => {
    if (notifications && notificationsRules) {
      setAmountOfAllNotifications(notifications.length + notificationsRules.length);
      setNotificationsToShow(
        notificationsRules
          .concat(notifications.map((notification) => ({ ...notification, id: notification.notification_key })))
          .filter(isNotificationFitFilters)
      );
    }
  }, [notifications, notificationsRules, isNotificationFitFilters, setNotificationsToShow]);

  const handleToggleDisableNotification = async (rule, isDisabled) => {
    const notificationType = rule.notification_type;
    try {
      setDisabledNotificationsDict({ ...disabledNotificationsDict, [rule.notification_key]: true });
      if (notificationType === NOTIFICATION_TYPE.SELF_CONFIGURED) {
        await axios.post(`${baseNotificationsRoute}/toggle_disabled_self_configured`, {
          is_disabled: isDisabled,
          notification_id: rule.id,
        });
      } else {
        await axios.post(`${baseNotificationsRoute}/toggle_disabled_pre_configured`, {
          is_disabled: isDisabled,
          notification_key: rule.notification_key,
        });
      }
    } catch (error) {
      await reportAxiosError(error);
    } finally {
      if (notificationType === NOTIFICATION_TYPE.SELF_CONFIGURED) {
        await reloadRules();
      } else {
        await reloadDisabledNotifications();
      }
      setDisabledNotificationsDict({});
    }
  };

  const handleUpdatePreDefinedNotificationConfiguration = async (values, notification_key) => {
    try {
      await axios.patch(`${baseNotificationsRoute}/pre_defined/${notification_key}`, {
        ...values,
        notification_key,
      });
      await reloadPreDefined();
    } catch (error) {
      await reportAxiosError(error);
      throw error;
    }
  };

  const callAPI = async (method, url, values) => {
    await axios({ method, url, data: values });
    await reloadRules();
  };

  const callAPIWithCatch = async (method, url, values) => {
    try {
      await callAPI(method, url, values);
    } catch (error) {
      await reportAxiosError(error);
    } finally {
      await reloadRules();
    }
  };

  const onClose = () => {
    setNotificationToEdit(null);
    setShowNotificationDialog(false);
    setIsDuplicatedNotificationRule(false);
  };

  const handleDeleteRule = async (rule) => {
    await callAPIWithCatch('delete', `${baseNotificationsRoute}/rule/${rule.id}`);
  };

  const handleCreateRule = async (values) => {
    await callAPI('post', `${baseNotificationsRoute}/rules`, values);
    mixpanel.track(NOTIFICATION_MIXPANEL_EVENTS.SAVE_NOTIFICATION_CLICKED, {
      source: isDuplicatedNotificationRule
        ? NOTIFICATION_MIXPANEL_EVENT_SOURCES.DUPLICATE_NOTIFICATION
        : NOTIFICATION_MIXPANEL_EVENT_SOURCES.ADD_NOTIFICATION,
      rule_name: values.rule_name,
      notification_title: values.title,
      notification_text: values.content,
      recipient: values.recipient,
      sub_org: values.sub_organization_ids,
    });
  };

  const handleUpdateRule = async (values, id) => {
    await callAPI('put', `${baseNotificationsRoute}/rule/${id}`, values);
    mixpanel.track(NOTIFICATION_MIXPANEL_EVENTS.SAVE_NOTIFICATION_CLICKED, {
      source: NOTIFICATION_MIXPANEL_EVENT_SOURCES.EDIT_NOTIFICATION,
      rule_name: values.rule_name,
      notification_title: values.title,
      notification_text: values.content,
      recipient: values.recipient,
      sub_org: values.sub_organization_ids,
    });
  };

  const actionOptions = (rule) => {
    const baseActions = [
      {
        onClick: () => {
          setNotificationToShow(
            rule.notification_type === NOTIFICATION_TYPE.SELF_CONFIGURED
              ? convertServerRuleToFormikValues(rule, conditions, events, incidentTypesMap, coveragesMap)
              : rule.notification_type === NOTIFICATION_TYPE.OPTIONAL
              ? convertServerPreDefinedToFormikValues(rule)
              : rule
          );
          mixpanel.track(NOTIFICATION_MIXPANEL_EVENTS.VIEW_NOTIFICATION_CLICKED, { rule_name: rule.rule_name });
        },
        node: 'View more details',
        key: 'viewMoreDetails',
      },
    ];

    if (rule.notification_type === NOTIFICATION_TYPE.SELF_CONFIGURED) {
      baseActions.unshift({
        onClick: () => {
          setIsDuplicatedNotificationRule(true);
          setNotificationToEdit({
            ...convertServerRuleToFormikValues(rule, conditions, events, incidentTypesMap, coveragesMap),
            id: null,
            rule_name: null,
          });
          setShowNotificationDialog(true);
          mixpanel.track(NOTIFICATION_MIXPANEL_EVENTS.DUPLICATE_NOTIFICATION_CLICKED, { rule_name: rule.rule_name });
        },
        node: 'Duplicate',
        key: 'duplicate',
        withConfirmProps: {
          title: 'Duplicate Notification?',
          contentText:
            'To save this notification as a new notification - at least one field in the dialog needs to be changed.',
          primaryButtonName: 'OK',
        },
      });

      baseActions.push({
        onClick: () => handleDeleteRule(rule),
        node: 'Delete',
        key: 'delete',
        withConfirmProps: {
          title: 'Delete Notification?',
          contentText: `Are you sure you want to delete ${rule.rule_name}?`,
          primaryButtonName: 'Yes, Delete',
        },
      });
    }

    if (rule.notification_type === NOTIFICATION_TYPE.OPTIONAL && !isNotifications2Enabled) {
      baseActions.push({
        onClick: () => setStatesEditDialogNotification(rule),
        node: 'Configure active states',
        key: 'states',
      });
    }

    return baseActions;
  };

  const resetFilters = () => {
    setSearchNotificationText(INITIAL_SEARCH_FILTER.searchNotificationText);
    setSelectedSubOrgs(INITIAL_SEARCH_FILTER.selectedSubOrgs);
    setSelectedRuleType(INITIAL_SEARCH_FILTER.selectedRuleType);
    setSelectedScope(INITIAL_SEARCH_FILTER.selectedScope);
    setSelectedScopeType(INITIAL_SEARCH_FILTER.selectedScopeType);
    setSelectedRecipient(INITIAL_SEARCH_FILTER.selectedRecipient);
    setHideDisabled(INITIAL_SEARCH_FILTER.hideDisabled);
  };

  const FILTERS_DICT = {
    BY_RULE_TYPE: 'By Rule Type',
    BY_SUB_ORGANIZATION: 'By Sub-organization',
    BY_CLAIM_OR_EXPOSURE: 'By Claim/ Exposure',
    BY_INCIDENT_OR_EXPOSURE_TYPE: 'By Incident/ Exposure Type',
    BY_RECIPIENT: 'By Recipient',
    STATES: 'States',
    HIDE_DISABLED: 'Hide Disabled',
  };

  const createFilterMixpanelEvent = (filter_name, filter_value) => {
    mixpanel.track(NOTIFICATION_MIXPANEL_EVENTS.FILTER_NOTIFICATION_APPLIED, {
      filter_name,
      filter_value,
    });
  };

  let columnsData = [
    {
      id: 'rule_name',
      numeric: false,
      label: 'Rule Name',
      specialCell: (rule) => (
        <div style={{ display: 'flex' }}>
          <NotificationRuleIcon type={rule.notification_type} />
          <span style={{ paddingTop: '4px' }}>{rule.rule_name || ''}</span>
        </div>
      ),
      specialCmpFunc: (rule1, rule2) => {
        if (rule1.notification_type === rule2.notification_type) {
          return stringCmp(rule1.rule_name || '', rule2.rule_name || '');
        } else {
          return stringCmp(rule1.notification_type, rule2.notification_type);
        }
      },
    },
    {
      id: 'title',
      numeric: false,
      label: 'Notification Title',
      specialCell: (rule) => <TitleAndContentWithTokens title={rule.title} content={rule.content} />,
    },
    { id: 'lob', numeric: false, label: 'LOB', specialCell: (rule) => <TextCell value={rule.lob.replace('_', ' ')} /> },
    {
      id: 'states',
      numeric: false,
      label: 'States',
      specialCell: (rule) => <StatesCell row={rule} />,
      overflow: false,
    },
    { id: 'scope', numeric: false, label: 'Claims, Exposures', specialCell: (rule) => <TextCell value={rule.scope} /> },
    {
      id: 'scope_types',
      numeric: false,
      label: 'Incident/Exposure Type',
      specialCell: (rule) => {
        let scopeTypes = rule.scope_types;
        if (rule.notification_type === 'self_configured') {
          if (!rule.scope_types) {
            scopeTypes = 'all';
          } else {
            scopeTypes = rule.scope_types
              .split(', ')
              .map((type) => getScopeTypeDescription(type, rule.scope, incidentTypesMap, coveragesMap))
              .join(', ');
          }
        }
        return <TextCell value={scopeTypes} />;
      },
    },
    {
      id: 'recipient',
      numeric: false,
      label: 'Recipient',
      specialCell: (rule) => <TextCell value={recipientTypes[rule.recipient]?.desc || rule.recipient} />,
    },
    {
      id: 'enable',
      numeric: false,
      label: 'Enable',
      specialCell: (rule) => {
        if (rule.notification_type === NOTIFICATION_TYPE.MANDATORY) {
          return null;
        }
        const isEnabled = getIsEnabled(rule);
        return (
          <Switch
            checked={isEnabled}
            onChange={async () => {
              await handleToggleDisableNotification(rule, isEnabled);
              mixpanel.track(NOTIFICATION_MIXPANEL_EVENTS.ENABLE_DISABLE_NOTIFICATION_CLICKED, {
                rule_name: rule.rule_name,
                action: isEnabled ? 'disable' : 'enable',
              });
            }}
            className={classes.formsSwitch}
            size="small"
            disabled={disabledNotificationsDict[rule.notification_key]}
          />
        );
      },
      disableSort: true,
    },
    {
      id: 'actions',
      numeric: false,
      label: 'Actions',
      specialCell: (rule) => (
        <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
          {(rule.notification_type === NOTIFICATION_TYPE.SELF_CONFIGURED ||
            (rule.notification_type === NOTIFICATION_TYPE.OPTIONAL && isNotifications2Enabled)) && (
            <InlineIconButton
              icon={PencilIcon}
              tooltipTitle="Edit"
              className={`${classes.textIcon} ${classes.marginedIcon} ${classes.hoverableNonFilledIcon}`}
              onClick={() => {
                setNotificationToEdit(
                  rule.notification_type === NOTIFICATION_TYPE.SELF_CONFIGURED
                    ? convertServerRuleToFormikValues(rule, conditions, events, incidentTypesMap, coveragesMap)
                    : rule.notification_type === NOTIFICATION_TYPE.OPTIONAL
                    ? convertServerPreDefinedToFormikValues(rule)
                    : rule
                );
                setShowNotificationDialog(true);
                mixpanel.track(NOTIFICATION_MIXPANEL_EVENTS.EDIT_NOTIFICATION_CLICKED, { rule_name: rule.rule_name });
              }}
              wrapWithSpan
            />
          )}
          <ThreeDotsMenu options={actionOptions(rule)} />
        </div>
      ),
      disableSort: true,
    },
  ];

  if (organization.sub_organizations_enabled) {
    columnsData.splice(2, 0, {
      id: 'sub_orgs',
      numeric: false,
      label: 'Sub-Organization',
      specialCell: (rule) => <TextCell value={rule.sub_orgs} />,
    });
  }

  if (
    (isLoading && !notifications) ||
    (isLoadingDisabledMap && !notificationsDisabledMap) ||
    (isLoadingRules && !notificationsRules) ||
    (isLoadingConditions && !conditions) ||
    (isLoadingEvents && !events) ||
    (isLoadingIncidentTypesMap && !incidentTypesMap) ||
    (isLoadingCoveragesMap && !coveragesMap)
  ) {
    return (
      <LoadingIndicator
        isError={
          isError ||
          isErrorDisabledMap ||
          isErrorRules ||
          isErrorConditions ||
          isErrorEvents ||
          isErrorIncidentTypesMap ||
          isErrorCoveragesMap
        }
      />
    );
  }

  return (
    <CardDialog noCardTitle>
      {!isFeatureEnabled(organization, CONFIGURATION_FEATURES_NAMES.AUTOMATIC_EXPOSURES_RULES) && (
        <OperationsBreadcrumbs currentTab="Notifications" />
      )}
      <Typography variant="subtitle1" style={{ padding: '14px 0px', fontSize: '18px' }}>
        Notifications
      </Typography>
      <span>
        Allow any permitted user to define, create and deploy notifications independently, easily and efficiently, with
        no code configuration tools.
      </span>
      <Grid container direction="column" spacing={2} style={{ marginTop: '20px' }}>
        <Grid container direction="column" spacing={4}>
          <Grid item xs={3}>
            <TextField
              className={classes.textField}
              style={{ width: '220px' }}
              value={searchNotificationText}
              onChange={(value) => setSearchNotificationText(value)}
              InputProps={{
                placeholder: 'Search Notification',
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton title="Clear" onClick={() => setSearchNotificationText('')}>
                      <RemoveIcon className={classes.hoverableNonStrokedIcon} />
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />
          </Grid>
          <Grid item xs={12} className={styles.filtersWrapper}>
            <InnerCard className={styles.filtersContainer}>
              <Grid container direction="column">
                <Grid item xs={12} style={{ marginBottom: '20px' }}>
                  <Typography display="block" variant="subtitle2" style={{ fontWeight: 'bold', fontSize: '16px' }}>
                    Filters
                  </Typography>
                </Grid>
                <Grid container direction="row" justifyContent="space-around" spacing={4}>
                  <Grid item>
                    <MultiSelectFilter
                      label={FILTERS_DICT.BY_RULE_TYPE}
                      value={selectedRuleType}
                      onChange={(value) => {
                        setSelectedRuleType(value);
                        createFilterMixpanelEvent(FILTERS_DICT.BY_RULE_TYPE, value);
                      }}
                      options={Object.values(RULE_TYPE_OPTIONS).map((ruleType) => ruleType.title)}
                      renderOption={(option) => {
                        const Icon = Object.values(RULE_TYPE_OPTIONS).find(
                          (ruleType) => ruleType.title === option
                        )?.icon;
                        return (
                          <div key={option} style={{ display: 'flex', alignItems: 'center' }}>
                            <Icon style={{ marginRight: '5px' }} />
                            <span>{option}</span>
                          </div>
                        );
                      }}
                      withOptionChips
                    />
                  </Grid>
                  {organization.sub_organizations_enabled && (
                    <Grid item>
                      <MultiSelectFilter
                        label={FILTERS_DICT.BY_SUB_ORGANIZATION}
                        value={selectedSubOrgs}
                        onChange={(value) => {
                          setSelectedSubOrgs(value);
                          createFilterMixpanelEvent(FILTERS_DICT.BY_SUB_ORGANIZATION, value);
                        }}
                        options={subOrgsOptions}
                        withOptionChips
                      />
                    </Grid>
                  )}
                  <Grid item>
                    <MultiSelectFilter
                      label={FILTERS_DICT.BY_CLAIM_OR_EXPOSURE}
                      value={selectedScope}
                      onChange={(value) => {
                        setSelectedScope(value);
                        createFilterMixpanelEvent(FILTERS_DICT.BY_CLAIM_OR_EXPOSURE, value);
                      }}
                      options={Object.keys(NOTIFICATIONS_SCOPES).map((option) => NOTIFICATIONS_SCOPES[option].desc)}
                      withOptionChips
                    />
                  </Grid>
                  <Grid item>
                    <MultiSelectFilter
                      label={FILTERS_DICT.BY_INCIDENT_OR_EXPOSURE_TYPE}
                      value={selectedScopeType}
                      onChange={(value) => {
                        setSelectedScopeType(value);
                        createFilterMixpanelEvent(FILTERS_DICT.BY_INCIDENT_OR_EXPOSURE_TYPE, value);
                      }}
                      options={scopeTypeOptions}
                      withOptionChips
                    />
                  </Grid>
                  <Grid item>
                    <MultiSelectFilter
                      label={FILTERS_DICT.BY_RECIPIENT}
                      value={selectedRecipient}
                      onChange={(value) => {
                        setSelectedRecipient(value);
                        createFilterMixpanelEvent(FILTERS_DICT.BY_RECIPIENT, value);
                      }}
                      options={recipientOptions}
                      withOptionChips
                    />
                  </Grid>
                  {isOrganizationUsOrg(organization) ? (
                    <FeatureEnabled
                      featureFlag={CONFIGURATION_FEATURES_NAMES.MULTI_STATE_NOTIFICATIONS}
                      organization={organization}
                    >
                      <Grid item>
                        <MultiSelectFilter
                          label={FILTERS_DICT.STATES}
                          value={selectedStates}
                          onChange={(value) => {
                            setSelectedStates(value);
                            createFilterMixpanelEvent(FILTERS_DICT.STATES, value);
                          }}
                          options={STATES_FILTER_OPTIONS}
                          renderOption={(option) => option.label}
                          renderChipLabel={(option) => option.value}
                          withOptionChips
                        />
                      </Grid>
                    </FeatureEnabled>
                  ) : null}
                  <Grid item>
                    <Link
                      href=""
                      onClick={(e) => {
                        e.preventDefault();
                        resetFilters();
                      }}
                      className={cn(
                        classes.alignedContainerForGrid,
                        classes.secondaryLink,
                        styles.clearFiltersContainer
                      )}
                    >
                      <CancelIcon className={cn(classes.filledIcon, classes.leftButtonIcon)} />
                      Clear all filters
                    </Link>
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs={2}>
                <div className={classes.alignedContainerForGrid}>
                  <Switch
                    checked={hideDisabled}
                    onChange={() => {
                      setHideDisabled(!hideDisabled);
                      createFilterMixpanelEvent(FILTERS_DICT.HIDE_DISABLED, !hideDisabled);
                    }}
                    className={classes.formsSwitch}
                    size="small"
                  />
                  <span>Hide disabled notifications</span>
                </div>
              </Grid>
            </InnerCard>
          </Grid>
        </Grid>

        <Grid item xs={12}>
          <Button
            color="primary"
            onClick={() => {
              setShowNotificationDialog(true);
              mixpanel.track(NOTIFICATION_MIXPANEL_EVENTS.ADD_NOTIFICATION_CLICKED);
            }}
            style={{ marginRight: 10, float: 'right', marginBottom: '14px' }}
          >
            <AddIcon className={classes.leftButtonIcon} />
            ADD NOTIFICATION
          </Button>
        </Grid>
        <Grid item xs={12}>
          <Typography display="block" variant="subtitle2" style={{ paddingTop: '8px', fontSize: '16px' }}>
            {notificationsToShow.length === amountOfAllNotifications
              ? `${amountOfAllNotifications} notifications are shown`
              : `${notificationsToShow.length} notifications are shown (out of ${amountOfAllNotifications})`}
          </Typography>
        </Grid>
      </Grid>
      <SortableTable columns={columnsData} rows={notificationsToShow} stickyHeader maxHeight="50vh" />
      {showNotificationDialog &&
        (!notificationToEdit || notificationToEdit?.notification_type === NOTIFICATION_TYPE.SELF_CONFIGURED) && (
          <NotificationDialog
            rule={notificationToEdit}
            onSubmit={notificationToEdit && !isDuplicatedNotificationRule ? handleUpdateRule : handleCreateRule}
            onClose={onClose}
            existingRuleNames={(notificationsRules || [])
              .filter((rule) => rule.id !== notificationToEdit?.id)
              .map((rule) => rule.rule_name)}
            conditions={conditions}
            events={events}
          />
        )}
      {showNotificationDialog &&
        notificationToEdit?.notification_type === NOTIFICATION_TYPE.OPTIONAL &&
        isNotifications2Enabled && (
          <PreDefinedNotificationEditDialog
            notification={notificationToEdit}
            onSubmit={handleUpdatePreDefinedNotificationConfiguration}
            onClose={onClose}
          />
        )}
      {notificationToShow && (
        <ViewMoreDetailsDialog
          notificationToShow={notificationToShow}
          onClose={() => setNotificationToShow(null)}
          conditionsConfig={conditions}
          events={events}
        />
      )}
      {statesEditDialogNotification && (
        <EditStatesDialog
          notification={statesEditDialogNotification}
          onClose={() => setStatesEditDialogNotification(null)}
          onSubmit={reloadPreDefined}
        />
      )}
    </CardDialog>
  );
};

export default NotificationsConfigurationTool;
