import DOMPurify from 'dompurify';

interface ISanitizer {
  sanitize: (markdown: string) => string;
}

// this hook is used to sanitize markdown text before rendering it
export const useSanitizer = (): ISanitizer => {
  const sanitize = (markdown: string) => {
    return DOMPurify.sanitize(markdown);
  };

  return {
    sanitize,
  };
};
