import React, { useState } from 'react';
import { Select } from '@material-ui/core';
import { isEmpty } from 'lodash';

import { useStyles } from '~/assets/styles';
import CardDialog from '~/components/CardDialog';
import { ClaimContextProvider } from '~/components/ClaimContainer';
import ClaimSearchContainer from '~/components/ClaimSearch';
import Button from '~/components/core/Atomic/Buttons/Button';
import MenuItem from '~/components/core/Atomic/MenuItem';
import type { DynamicFieldsFormValues } from '~/components/GenericTemplates/FromTemplate/GenericTemplateSelectionContainerFormik';
import { DynamicFieldsFormik } from '~/components/GenericTemplates/FromTemplate/GenericTemplateSelectionContainerFormik';
import GenericTemplateContentContainer from '~/components/GenericTemplates/GenericTemplatesConfiguration/GenericTemplateCard/GenericTemplateContentContainer';
import type { TemplateFormSubmitValuesProps, TemplateMetadata } from '~/components/GenericTemplates/types';
import { GENERIC_TEMPLATES_ROUTES } from '~/components/GenericTemplates/utils/routes';
import LoadingIndicator from '~/components/LoadingIndicator';
import { useSysconfig } from '~/components/SystemConfiguration/SystemConfigurationScreen';
import type { ClaimModel } from '~/components/types/claim-types';
import type { ExposureModel } from '~/components/types/exposure-types';
import useDataFetcher from '~/components/useDataFetcher';
import { useFetchClaim } from '~/Utils/ClaimUtils';

interface GenericTemplatePreviewContainerProps {
  onClose: () => void;
  template: TemplateFormSubmitValuesProps;
}
type handleResultClick = (claimId: number) => void;

export const SelectClaim: React.FC<{ handleResultClick: handleResultClick; claim: ClaimModel }> = ({
  handleResultClick,
  claim,
}) => {
  function handleSelectClaim() {
    handleResultClick(claim.id);
  }

  return (
    <Button color="primary" onClick={handleSelectClaim}>
      Choose claim
    </Button>
  );
};

const GenericTemplatePreviewContainer: React.FC<
  GenericTemplatePreviewContainerProps & {
    setDialogTitle: React.Dispatch<React.SetStateAction<string>>;
    setDialogWidth: React.Dispatch<React.SetStateAction<'xs' | 'sm' | 'md' | 'lg' | 'xl' | false | undefined>>;
  }
> = ({ template, setDialogTitle, onClose, setDialogWidth }) => {
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  const { organization } = useSysconfig();
  const [claimId, setClaimId] = useState<null | number>(null);
  const [isDynamicFieldsFilled, setIsDynamicFieldsFilled] = useState<boolean>(false);
  const [dynamicFieldsValues, setDynamicFieldsValues] = useState<DynamicFieldsFormValues>({
    recipient_contact_id: '',
    contact_id: '',
    document_id: '',
    payment_request_id: '',
  });
  const [claim, isLoadingClaim, isErrorClaim] = useFetchClaim(claimId);
  const [chosenExposureId, setChosenExposureId] = useState<undefined | ExposureModel['id']>(undefined);
  const classes = useStyles();
  const isDynamicFieldsExist = !isEmpty(template?.dynamic_token_fields);

  const url =
    claim?.id && template.id && (isDynamicFieldsExist ? isDynamicFieldsFilled : true)
      ? GENERIC_TEMPLATES_ROUTES.GENERATE_FROM_TEMPLATE({
          organizationId: organization.id,
          templateId: template.id,
          claimId: claim.id,
          exposureId: chosenExposureId,
          recipientContactId: dynamicFieldsValues?.recipient_contact_id,
          contactId: dynamicFieldsValues?.contact_id,
          documentId: dynamicFieldsValues?.document_id,
          paymentRequestId: dynamicFieldsValues?.payment_request_id,
        })
      : '';

  const { data, isLoading, isError } = useDataFetcher(
    url,
    {},
    !!claim && (isDynamicFieldsExist ? isDynamicFieldsFilled : true)
  );

  const handleResultClick: handleResultClick = (claimIdFromSearch) => {
    setClaimId(claimIdFromSearch);
  };

  if (!claimId) {
    return (
      <ClaimSearchContainer
        classes={classes}
        SelectComponent={SelectClaim}
        selectComponentProps={{ handleResultClick }}
        label="Choose claim for example"
        lobs={template.lobs}
        subOrganizationIds={template.sub_organization_ids}
        limit={5}
        filteredOutColumns={['first_party_name_chip', 'date_of_loss', 'creation_date', 'reported_date', 'date_closed']}
      />
    );
  }

  if (!claim || isLoadingClaim || isLoading || isErrorClaim || isError) {
    return <LoadingIndicator isError={isError || isErrorClaim} isLoading />;
  }

  const shouldDisplayExposureSelect = !template.is_claim_level;
  const coverageKeys = template.coverage_keys;
  const exposures: ExposureModel[] = claim?.exposures || [];

  if (!chosenExposureId && shouldDisplayExposureSelect) {
    setDialogTitle('Choose Exposure');
    const filteredExposures =
      !coverageKeys || coverageKeys.length === 0
        ? exposures
        : exposures.filter((exposure) => coverageKeys.includes(exposure.coverage_type));

    if (filteredExposures.length === 0) {
      return <div>No exposures that fit this template exist on claim {claim.claim_id_display}</div>;
    }

    return (
      <div className="py-32">
        <Select
          onChange={(event) => {
            const value = event.target.value as number;
            setChosenExposureId(value);
          }}
          renderValue={(value) =>
            filteredExposures.find((e) => e.id === value)?.coverage_type_desc || 'Choose Exposure'
          }
          className="w-1/3"
          value={chosenExposureId || ''}
          autoWidth
          displayEmpty
          MenuProps={{
            anchorOrigin: {
              vertical: 'bottom',
              horizontal: 'left',
            },
            transformOrigin: {
              vertical: 'top',
              horizontal: 'left',
            },
            getContentAnchorEl: null,
            disableAutoFocus: true,
          }}
        >
          {filteredExposures?.map((exposure) => (
            <MenuItem key={exposure.id} value={exposure.id}>
              {exposure.coverage_type_desc} - {exposure.involved_property?.display_name}
            </MenuItem>
          ))}
        </Select>
      </div>
    );
  }

  if (!!claim && template && isDynamicFieldsExist && !isDynamicFieldsFilled) {
    setDialogTitle('Fill in dynamic fields');
    setDialogWidth('xs');
    return (
      <div>
        <ClaimContextProvider
          claim={claim}
          refreshData={() => null}
          asyncRefreshData={() => null}
          inClaimPage={false}
          reloadingUuid={null}
        >
          <DynamicFieldsFormik
            dynamicTokenFields={template?.dynamic_token_fields || []}
            handleClose={onClose}
            selectedExposureId={chosenExposureId}
            selectedTemplateId={template?.id}
            availableTemplates={[template as unknown as TemplateMetadata]}
            onSubmit={async (values: DynamicFieldsFormValues) => {
              await setDynamicFieldsValues(values);
              setIsDynamicFieldsFilled(true);
              setDialogWidth('md');
            }}
          />
        </ClaimContextProvider>
      </div>
    );
  }

  setDialogTitle(`Example template based on claim ${claim?.claim_id_display}`);
  return <GenericTemplateContentContainer titleTemplate={data?.title_template} bodyTemplate={data?.body_template} />;
};

const GenericTemplatePreviewContainerCardDialogWrapper: React.FC<GenericTemplatePreviewContainerProps> = (props) => {
  const [dialogTitle, setDialogTitle] = useState<string>('Choose Claim to generate template example');
  const [dialogWidth, setDialogWidth] = useState<'xs' | 'sm' | 'md' | 'lg' | 'xl' | false | undefined>('md');
  return (
    <CardDialog isDialog title={dialogTitle} onClose={props.onClose} fullWidth maxWidth={dialogWidth}>
      <GenericTemplatePreviewContainer {...props} setDialogTitle={setDialogTitle} setDialogWidth={setDialogWidth} />
    </CardDialog>
  );
};
export default GenericTemplatePreviewContainerCardDialogWrapper;
