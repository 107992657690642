import React from 'react';
import PropTypes from 'prop-types';
import { FormHelperText } from '@material-ui/core';
import { Grid } from '@mui/material';
import { FieldArray, getIn, useFormikContext } from 'formik';

import Button from '~/components/core/Atomic/Buttons/Button';
import Typography from '~/components/core/Atomic/Typography';
import { AddIcon } from '~/components/deprecatedMuiIcons';

import WithConfirm from '../../../ConfirmModal';
import { FsIconButton, FsMenuItem } from '../../../core';
import { TrashIcon } from '../../../icons';
import TextFieldFormik from '../../../TextFieldFormik';

import { getReactKeyFromArgs } from './arrayFieldUtils';

import colors from '../../../../assets/colors.module.scss';
import styles from './ArrayFieldFormik.module.scss';

export const ArraySelectFieldFormik = ({
  fieldId,
  label,
  plusButton,
  addNewItemButtonLabel,
  disabled,
  selectionOptions,
  placeholder,
  onRemoveItemMessage,
}) => {
  const { values, errors, touched } = useFormikContext();

  const currentSelection = getIn(values, fieldId);

  const isItemSelected = (item) => currentSelection.includes(item?.value);

  const itemsAvailableForSelection = selectionOptions.filter((item) => !isItemSelected(item));

  const getSelectionOptionByValue = (value) => {
    return selectionOptions.find((item) => item.value === value);
  };

  const getFieldSelectionOptions = (value) => {
    const selectionOptionForValue = getSelectionOptionByValue(value);
    return [...itemsAvailableForSelection, selectionOptionForValue].filter(Boolean);
  };

  const getArrayItemKey = (index) => `${fieldId}.${index}`;

  const getSelectFieldLevelError = (index) => getIn(errors, getArrayItemKey(index));

  const fieldLevelError =
    typeof getIn(errors, fieldId) === 'string' && getIn(touched, fieldId) ? getIn(errors, fieldId) : null;

  const getSelectFieldPlaceholder = (index) =>
    ![undefined, null, ''].includes(getIn(values, getArrayItemKey(index))) ? '' : placeholder;

  const isFieldSelectionEmpty = (index) => [undefined, null, ''].includes(getIn(values, getArrayItemKey(index)));

  return (
    <FieldArray
      name={fieldId}
      render={(arrayHelpers) => (
        <Grid container direction="column" className={styles.mainContainer} spacing={2}>
          {label && (
            <Grid item xs={12}>
              <Typography>{label}</Typography>
            </Grid>
          )}
          <div className={styles.errorContainer}>
            <FormHelperText error={Boolean(fieldLevelError)}>{fieldLevelError}</FormHelperText>
          </div>
          {currentSelection?.map((value, i) => (
            <Grid item key={getReactKeyFromArgs(fieldId, i)}>
              <Grid container direction="row" alignItems="center" spacing={1} className={styles.row}>
                <Grid item xs={11} className={styles.rowItem}>
                  <TextFieldFormik
                    id={getArrayItemKey(i)}
                    fullWidth
                    disabled={disabled}
                    select
                    placeholder={getSelectFieldPlaceholder(i)}
                    helperText={getSelectFieldLevelError(i)}
                    error={!!getSelectFieldLevelError(i)}
                  >
                    {getFieldSelectionOptions(value).map((item) => (
                      <FsMenuItem key={item?.value} value={item?.value} selected={isItemSelected(item)}>
                        {item.label}
                      </FsMenuItem>
                    ))}
                  </TextFieldFormik>
                </Grid>
                {plusButton && (
                  <Grid xs={1} item key={getReactKeyFromArgs(fieldId, 'add', i)}>
                    <FsIconButton tooltipText="Add" onClick={() => arrayHelpers.insert(i + 1, '')} disabled={disabled}>
                      <AddIcon iconColor={colors.buttonHoverGrey} />
                    </FsIconButton>
                  </Grid>
                )}
                <Grid xs={1} item key={getReactKeyFromArgs(fieldId, 'delete', i)} style={{ paddingLeft: '4px' }}>
                  <WithConfirm
                    title=""
                    disableConfirm={!onRemoveItemMessage || isFieldSelectionEmpty(i)}
                    contentText={onRemoveItemMessage}
                    primaryButtonName="Remove"
                    maxWidth="lg"
                  >
                    <FsIconButton tooltipText="Remove" onClick={() => arrayHelpers.remove(i)} disabled={disabled}>
                      <TrashIcon iconColor={colors.buttonHoverGrey} />
                    </FsIconButton>
                  </WithConfirm>
                </Grid>
              </Grid>
            </Grid>
          ))}
          {addNewItemButtonLabel && (
            <Grid item>
              <Button color="primary" onClick={() => arrayHelpers.push(null)} disabled={disabled}>
                {addNewItemButtonLabel}
              </Button>
            </Grid>
          )}
        </Grid>
      )}
    />
  );
};

ArraySelectFieldFormik.propTypes = {
  fieldId: PropTypes.string.isRequired,
  label: PropTypes.string,
  plusButton: PropTypes.bool,
  addNewItemButtonLabel: PropTypes.oneOf([PropTypes.string, PropTypes.node, PropTypes.element]),
  disabled: PropTypes.bool,
  selectionOptions: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.string.isRequired,
      label: PropTypes.string.isRequired,
    })
  ).isRequired,
  placeholder: PropTypes.string,
  onRemoveItemMessage: PropTypes.node,
};
