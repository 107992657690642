import React from 'react';

import Button from '~/components/core/Atomic/Buttons/Button';

interface ExecuteButtonProps {
  onDismiss: () => void;
  disabled?: boolean;
}

export const DismissButton: React.FC<ExecuteButtonProps> = ({ onDismiss, disabled = false }) => {
  return (
    <Button
      variant="text"
      classes={{
        root: 'text-slate-800 hover:text-slate-900 bg-slate-700/10 px-12',
      }}
      onClick={onDismiss}
      disabled={disabled}
    >
      Dismiss
    </Button>
  );
};
