/* eslint-disable react/display-name */
import React, { useCallback, useEffect } from 'react';
import requiredIf from 'react-required-if';
import PropTypes from 'prop-types';
import { Link, ListItemIcon, ListItemText } from '@material-ui/core';
import _, { debounce } from 'lodash';

import MultiSelectField from '~/components/core/Molecules/Fields/MultiSelectField';
import NotificationsScreen from '~/components/Notifications/NotificationsScreen';
import { CONFIGURATION_FEATURES_NAMES } from '~/Types';
import { isFeatureEnabled, reportAxiosError } from '~/Utils';

import CardDialog from '../CardDialog';
import SkeletonTable from '../core/Skeletons/SkeletonTable';
import { PAGINATION_LOCATION } from '../core/Tables/SortableTable/utils';
import { useCms } from '../hooks/useCms';
import LoadingIndicator from '../LoadingIndicator';

import { getClaimNotificationIcon, notificationTypeToLabelDict } from './ClaimNotificationCardUtils';
import { ClaimNotificationsTable } from './ClaimNotificationsTable';

import { useStyles } from '../../assets/styles';

const ClaimNotificationsCard = ({
  getClaimNotifications,
  userContext,
  openClaimInNewTab,
  showExposure,
  actionComponent,
  ...rest
}) => {
  const { userOrganization } = useCms();
  if (
    isFeatureEnabled(userOrganization, CONFIGURATION_FEATURES_NAMES.CLAIM_SUMMARY_NEW_LAYOUT) &&
    isFeatureEnabled(userOrganization, CONFIGURATION_FEATURES_NAMES.NOTIFICATIONS_2)
  ) {
    return (
      <NotificationsScreen
        getClaimNotifications={getClaimNotifications}
        displayOptions={{ showExposure, showClaim: !userContext }}
        actionComponent={actionComponent}
      />
    );
  } else {
    return (
      <OldClaimNotificationsCard
        getClaimNotifications={getClaimNotifications}
        userContext={userContext}
        openClaimInNewTab={openClaimInNewTab}
        showExposure={showExposure}
        actionComponent={actionComponent}
        {...rest}
      />
    );
  }
};

ClaimNotificationsCard.propTypes = {
  getClaimNotifications: PropTypes.func.isRequired, // (should_return_dismissed, should_return_future_reminders, pageNumber, resultsPerPage) => {count, notifications, exposures} - use React.useCallback to avoid multiple calls
  paginateDismissed: PropTypes.bool,
  AdditionalHeaderComponent: PropTypes.node,
  userContext: PropTypes.bool,
  showRecipientUser: PropTypes.bool,
  showExposure: PropTypes.bool,
  showInsuredName: PropTypes.bool,
  showNotificationDate: requiredIf(PropTypes.bool, (props) => props.defaultSortByDate),
  maxHeight: PropTypes.string,
  actionComponent: PropTypes.node,
  showFiltering: PropTypes.bool,
  openClaimInNewTab: PropTypes.bool,
  defaultSortByDate: PropTypes.bool,
  paginationLocation: PropTypes.string,
  showSubOrg: PropTypes.bool,
};

function OldClaimNotificationsCard(props) {
  const {
    getClaimNotifications,
    userContext,
    showRecipientUser,
    showExposure,
    showInsuredName,
    showNotificationDate,
    AdditionalHeaderComponent,
    maxHeight,
    actionComponent,
    showFiltering,
    defaultSortByDate,
    paginationLocation = PAGINATION_LOCATION.TOP,
    showSubOrg = true,
  } = props;
  const classes = useStyles();

  const [isLoading, setIsLoading] = React.useState(true);
  const [isError, setIsError] = React.useState(false);

  const [claimNotificationsAndExposures, setClaimNotificationsAndExposures] = React.useState({
    count: 0,
    exposures: [],
    notification_type_counts: [],
    notifications: [],
  });
  const { userOrganization } = useCms();

  const [showDismissed, setShowDismissed] = React.useState(false);
  const [dismissedClaimNotificationsAndExposures, setDismissedClaimNotificationsAndExposures] =
    React.useState(undefined);
  const [page, setPage] = React.useState(0);
  const [dismissedPage, setDismissedPage] = React.useState(0);
  const [totalNotificationCount, setTotalNotificationCount] = React.useState(undefined);
  const [totalNotDismissesNotificationCount, setTotalNotDismissesNotificationCount] = React.useState(undefined);
  const notificationsPerPage = isFeatureEnabled(userOrganization, CONFIGURATION_FEATURES_NAMES.CLAIM_SUMMARY_NEW_LAYOUT)
    ? 10
    : 15;
  const [shouldReloadAfterUndismiss, setShouldReloadAfterUndismiss] = React.useState(false);

  const defaultOrderColumnName = defaultSortByDate ? 'notification_date' : 'type';
  const defaultSortOrder = 'desc';
  const [sortByColumn, setSortByColumn] = React.useState({ order: defaultSortOrder, id: defaultOrderColumnName });
  const [showFuture, setShowFuture] = React.useState(false);
  const [futureClaimNotificationsAndExposures, setFutureClaimNotificationsAndExposures] = React.useState(undefined);

  const [selectedNotificationTypeIds, setSelectedNotificationTypeIds] = React.useState([]);

  const sortByColumnStr = sortByColumn && `${sortByColumn.order === 'asc' ? '+' : '-'}${sortByColumn.id}`; // +<colName> for ascending, -<colName> for descending;

  const callGetClaimNotifications = React.useCallback(
    async (page) => {
      try {
        const res = await getClaimNotifications(
          false,
          false,
          // page in the server is 1-based
          ...[(page ?? 0) + 1, notificationsPerPage, sortByColumnStr, selectedNotificationTypeIds]
        );

        setClaimNotificationsAndExposures(res);
        setTotalNotDismissesNotificationCount(res.count);
        setIsLoading(false);
        return res;
      } catch (error) {
        await reportAxiosError(error);
        setIsError(true);
      }
    },
    [getClaimNotifications, notificationsPerPage, sortByColumnStr, selectedNotificationTypeIds]
  );

  const callGetClaimNotificationsDebounce = React.useMemo(
    () => debounce(callGetClaimNotifications, 3000),
    [callGetClaimNotifications]
  );

  React.useEffect(() => {
    setPage(0);
    callGetClaimNotifications(0);
  }, [callGetClaimNotifications]);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
    callGetClaimNotifications(newPage);
  };

  const handleChangeDissmissedPage = (event, newPage) => {
    setDismissedPage(newPage);
  };

  const handleShowDismissed = useCallback(() => {
    // setShowDismissed(true);
    return getClaimNotifications(true, false, dismissedPage + 1, notificationsPerPage) // page in the server is 1-based
      .then((res) => {
        setDismissedClaimNotificationsAndExposures(res);
        setTotalNotificationCount(res.count);
      })
      .catch((error) => {
        reportAxiosError(error);
        setIsError(true);
      });
  }, [getClaimNotifications, dismissedPage, notificationsPerPage]);

  useEffect(() => {
    if (!showDismissed) return;
    handleShowDismissed();
    // This is used to reload the data after dismiss. we need to first update the page to zero and only then reload the data.
    // otherwise we can fetch a page that doesn't exist anymore (e.g. if dismissing the last and only notification in a page)
    setShouldReloadAfterUndismiss(false);
  }, [shouldReloadAfterUndismiss, dismissedPage, showDismissed, handleShowDismissed]);

  function handleShowFuture() {
    setShowFuture(true);
    return getClaimNotifications(false, true)
      .then((res) => {
        const claimNotifications = res.notifications;
        const onlyFutureReminders = claimNotifications
          .filter(
            (notification) =>
              (notification.type === 'reminder_claim_notification' && new Date(notification.due_date) > new Date()) ||
              (notification.postpone_date && new Date(notification.postpone_date) > new Date())
          )
          .map((notification) => ({
            ...notification,
            due_date:
              notification.type === 'reminder_claim_notification' ? notification.due_date : notification.postpone_date,
          }));

        setFutureClaimNotificationsAndExposures({ ...res, notifications: onlyFutureReminders });
      })
      .catch((error) => {
        reportAxiosError(error);
        setIsError(true);
      });
  }

  function exposuresArrayToDict(exposures) {
    let exposuresDict = {};
    for (const exposure of exposures) {
      exposuresDict[exposure.id] = exposure;
    }
    return exposuresDict;
  }

  // Since notification has both type and (possibly) subtype, the unique id is [type].[subtype]
  const getNotificationTypeId = (notification) => notification.type + '.' + (notification.subtype || '');

  const possibleNotificationById = (
    claimNotificationsAndExposures?.notification_type_counts || claimNotificationsAndExposures?.notifications
  ).reduce((acc, notificationTypeCount) => {
    const id = getNotificationTypeId(notificationTypeCount);
    return id in acc
      ? acc
      : {
          ...acc,
          [id]: {
            type: notificationTypeCount.type,
            label: notificationTypeToLabelDict[notificationTypeCount.type],
            icon: getClaimNotificationIcon(notificationTypeCount),
            subtype: notificationTypeCount.subtype,
            id,
          },
        };
  }, {});

  const possibleNotificationTypesDataList = Object.values(possibleNotificationById).sort((type1, type2) =>
    type1.id.localeCompare(type2.id)
  );

  const possibleNotificationTypeIds = possibleNotificationTypesDataList.map((notificationData) => notificationData.id);
  // If some typeId is no longer valid (because the notification was dismissed) - we don't want to filter by it
  const selectedAndPossibleNotificationTypeIds = _.intersection(
    selectedNotificationTypeIds,
    possibleNotificationTypeIds
  );

  const filteredNotifications = claimNotificationsAndExposures.notifications;

  const filteringComponent = showFiltering && (
    <div style={{ margin: '0px 8px 8px 8px' }}>
      <MultiSelectField
        id="notification_type"
        label="Notifications Types"
        className={classes.textFieldRow}
        value={selectedAndPossibleNotificationTypeIds}
        onChange={(value) =>
          value.includes('All') ? setSelectedNotificationTypeIds([]) : setSelectedNotificationTypeIds(value)
        }
        renderValue={(selectedTypes) => selectedTypes.map((id) => possibleNotificationById[id].label).join(', ')}
        renderOption={(possibleNotificationTypeId) => {
          const possibleNotificationType = possibleNotificationById[possibleNotificationTypeId];
          return (
            <div className="flex items-center">
              <ListItemIcon>{possibleNotificationType?.icon}</ListItemIcon>
              <ListItemText
                primary={possibleNotificationType?.label}
                secondary={possibleNotificationType?.subtype && possibleNotificationType.subtype.replace('_', ' ')}
              />
            </div>
          );
        }}
        options={possibleNotificationTypesDataList.map((possibleNotificationType) => possibleNotificationType.id)}
        addAllOption
        allOptionValue="All"
      />
    </div>
  );

  return (
    <>
      <CardDialog
        title="Notifications"
        contentStyle={{ padding: '0px 16px 16px 16px' }}
        headerStyle={{ padding: '12px 12px 0px 16px' }}
        action={actionComponent}
      >
        <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', paddingBottom: '8px' }}>
          <Link
            href=""
            style={{ margin: 0, textDecoration: 'underline' }}
            onClick={(e) => {
              e.preventDefault();
              setShowDismissed(true);
            }}
          >
            {'< See dismissed notifications'}
          </Link>
          {AdditionalHeaderComponent}
          {filteringComponent}
          <Link
            href=""
            style={{ margin: 0, textDecoration: 'underline' }}
            onClick={(e) => {
              e.preventDefault();
              handleShowFuture();
            }}
          >
            {'See future notifications >'}
          </Link>
        </div>
        {isLoading ? (
          <SkeletonTable rowsCount={5} columnsCount={7} isError={isError} />
        ) : (
          <ClaimNotificationsTable
            claimNotifications={filteredNotifications}
            exposuresDict={exposuresArrayToDict(claimNotificationsAndExposures.exposures)}
            onNotificationUpdate={callGetClaimNotificationsDebounce}
            userContext={userContext}
            showRecipientUser={showRecipientUser}
            showInsuredName={showInsuredName}
            showNotificationDate={showNotificationDate}
            defaultOrderColumnName={defaultSortByDate ? 'notification_date' : 'type'}
            defaultSortOrder="desc"
            onSortByColumn={(_, sortByColumn) => {
              setSortByColumn(sortByColumn);
              setPage(0);
            }}
            paginationProps={{
              page,
              rowsPerPage: notificationsPerPage,
              onChangePage: handleChangePage,
              count: totalNotDismissesNotificationCount || 0,
              rowsPerPageOptions: [notificationsPerPage],
            }}
            showExposure={showExposure}
            maxHeight={maxHeight}
            paginationLocation={paginationLocation}
            showSubOrg={userOrganization.sub_organizations_enabled && showSubOrg}
          />
        )}
      </CardDialog>
      {showDismissed && (
        <CardDialog
          title="Dismissed Notifications"
          isDialog
          onClose={() => {
            setShowDismissed(false);
            setDismissedPage(0);
            setDismissedClaimNotificationsAndExposures(undefined);
          }}
          maxWidth="lg"
          fullWidth
        >
          {!dismissedClaimNotificationsAndExposures ? (
            <LoadingIndicator isError={isError} />
          ) : (
            <ClaimNotificationsTable
              showDateDismissed
              claimNotifications={dismissedClaimNotificationsAndExposures.notifications} // We're getting it ordered from the server by datetime_dismissed
              exposuresDict={exposuresArrayToDict(dismissedClaimNotificationsAndExposures.exposures)}
              onNotificationUpdate={async () => {
                setDismissedPage(0);
                setShouldReloadAfterUndismiss(true);
                await callGetClaimNotifications(page);
              }}
              userContext={userContext}
              showInsuredName={showInsuredName}
              showNotificationDate={showNotificationDate}
              showExposure={showExposure}
              disableSort // Don't allow sorting of dismiss notifications, they returns ordered by time of dismissing from the server
              paginationProps={{
                page: dismissedPage,
                rowsPerPage: notificationsPerPage,
                onChangePage: handleChangeDissmissedPage,
                count: totalNotificationCount || 0,
                rowsPerPageOptions: [notificationsPerPage],
              }}
              showSubOrg={userOrganization.sub_organizations_enabled && showSubOrg}
              showDismissedBy
            />
          )}
        </CardDialog>
      )}
      {showFuture && (
        <CardDialog title="Future Notifications" isDialog onClose={() => setShowFuture(false)} maxWidth="lg" fullWidth>
          {!futureClaimNotificationsAndExposures ? (
            <LoadingIndicator isError={isError} />
          ) : (
            <ClaimNotificationsTable
              showDueDate
              claimNotifications={futureClaimNotificationsAndExposures.notifications}
              defaultOrderColumnName="due_date"
              defaultSortOrder="asc"
              exposuresDict={exposuresArrayToDict(futureClaimNotificationsAndExposures.exposures)}
              onNotificationUpdate={async () => {
                await handleShowFuture();
                await callGetClaimNotificationsDebounce(page);
              }}
              userContext={userContext}
              showInsuredName={showInsuredName}
              showNotificationDate={showNotificationDate}
              showExposure={showExposure}
              showSubOrg={userOrganization.sub_organizations_enabled && showSubOrg}
            />
          )}
        </CardDialog>
      )}
    </>
  );
}

OldClaimNotificationsCard.propTypes = {
  getClaimNotifications: PropTypes.func.isRequired, // (should_return_dismissed, should_return_future_reminders, pageNumber, resultsPerPage) => {count, notifications, exposures} - use React.useCallback to avoid multiple calls
  AdditionalHeaderComponent: PropTypes.node,
  userContext: PropTypes.bool,
  showRecipientUser: PropTypes.bool,
  showExposure: PropTypes.bool,
  showInsuredName: PropTypes.bool,
  showNotificationDate: requiredIf(PropTypes.bool, (props) => props.defaultSortByDate),
  maxHeight: PropTypes.string,
  actionComponent: PropTypes.node,
  showFiltering: PropTypes.bool,
  defaultSortByDate: PropTypes.bool,
  paginationLocation: PropTypes.string,
  showSubOrg: PropTypes.bool,
};

export default ClaimNotificationsCard;
