import React from 'react';

import type { ClaimNotificationModel } from '~/components/ClaimNotificationsCard/types';
import { DismissIcon } from '~/components/icons';
import cn from '~/Utils/cn';

import FsIconButton from '../core/FsWrappers/FsIconButton/FsIconButton';
import Text from '../core/TextComponents/Text';

interface DismissButtonProps {
  notification: ClaimNotificationModel;
  onClick: () => Promise<void>;
  tooltipTextDismiss?: string;
  tooltipTextUndismiss?: string;
  disabled?: boolean;
  textDismiss?: string;
  textUndismiss?: string;
}

const DismissButton: React.FC<DismissButtonProps> = ({
  notification,
  onClick,
  tooltipTextDismiss = 'Dismiss',
  tooltipTextUndismiss = 'Unismiss',
  disabled,
  textDismiss,
  textUndismiss,
}) => {
  return (
    <div className="flex items-center">
      <FsIconButton
        tooltipText={notification.datetime_dismissed ? tooltipTextUndismiss : tooltipTextDismiss}
        size="small"
        onClick={onClick}
        disabled={disabled}
        disabledClassName="cursor-auto pointer-events-auto"
      >
        <DismissIcon
          size={20}
          className={cn('cursor-pointer hover:stroke-teal-700', {
            'stroke-green-700': notification.datetime_dismissed,
          })}
        />
      </FsIconButton>
      {textDismiss && !notification.datetime_dismissed && (
        <Text variant={Text.VARIANTS.SM} weight={Text.WEIGHTS.SEMI_BOLD}>
          {textDismiss}
        </Text>
      )}
      {textUndismiss && notification.datetime_dismissed && (
        <Text variant={Text.VARIANTS.SM} weight={Text.WEIGHTS.SEMI_BOLD}>
          {textUndismiss}
        </Text>
      )}
    </div>
  );
};

export default DismissButton;
