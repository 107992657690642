import { useEffect, useMemo, useRef } from 'react';
import { noop } from 'lodash';

import { withFailureTracking } from '~/Utils/functionWrapper';

import { useInterval } from './useInterval';

interface PollingOptions {
  maxFailures?: number;
  timeWindowMs?: number;
  onSingleFailure?: () => void;
  pollingDelay: number;
  enabled?: boolean;
}

interface PollingControls {
  startPolling: () => void;
  stopPolling: () => void;
}

/**
 * Custom hook that combines interval polling with failure tracking
 *
 * @template T - Type of the function being wrapped
 * @param fn - Async function to be polled
 * @param options - Configuration options for polling and failure tracking
 * @returns Object containing start and stop polling functions
 */
export const usePollingWithFailureTracking = <T,>(fn: () => Promise<T>, options: PollingOptions): PollingControls => {
  const {
    maxFailures = 3,
    timeWindowMs = options.pollingDelay * 5,
    onSingleFailure,
    pollingDelay,
    enabled = true,
  } = options;

  const stopPollingRef = useRef(noop);

  const wrappedFnWithTracking = useMemo(
    () => withFailureTracking(fn, maxFailures, timeWindowMs, () => stopPollingRef.current(), onSingleFailure),
    [fn, maxFailures, timeWindowMs, onSingleFailure]
  );

  const { start: startPolling, stop: stopPolling } = useInterval(wrappedFnWithTracking, pollingDelay);

  useEffect(() => {
    if (!enabled) {
      stopPolling();
      return;
    }

    stopPollingRef.current = stopPolling;
    startPolling();
  }, [startPolling, stopPolling, enabled]);

  useEffect(
    () => () => stopPolling(),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  return {
    startPolling,
    stopPolling,
  };
};
