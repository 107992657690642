import React from 'react';
import axios from 'axios';

import { policyPopulate } from '~/PolicyUtils';

import { useClaim } from '../components/ClaimContainer';
import { TooltipIcon } from '../components/core';
import {
  InvolvedBikerIcon,
  InvolvedBusinessIcon,
  InvolvedCyberIcon,
  InvolvedEntityIcon,
  InvolvedPersonIcon,
  InvolvedPetIcon,
  InvolvedPropertyIcon,
  InvolvedStructureIcon,
  InvolvedTravelerIcon,
  InvolvedVehicleIcon,
} from '../components/icons';
import {
  AlienIcon,
  AutoIcon,
  BikeIcon,
  BopIcon,
  CardProtectionIcon,
  CyberIcon,
  GeneralPropertyIcon,
  GLIcon,
  HomeIcon,
  PetIcon,
  PLIcon,
  TravelIcon,
  WCIcon,
} from '../components/icons/lobs';

import { reportAxiosError, reportErrorInProductionOrThrow, reportGenericException } from '.';

export const isClaimWriteDisabled = (claim = {}, user, options = {}) => {
  return isClaimWriteDisabledIfClosed(claim.is_closed, user, options);
};

export const isClaimWriteDisabledIfClosed = (isClaimClosed, user, options = {}) => {
  const { allowOnClosedClaim = false, isAllowedForReadOnly = false } = options;

  if (isClaimClosed && !allowOnClosedClaim) {
    return true;
  }

  return !!(user.role.is_view_only && !isAllowedForReadOnly);
};

// this function mimics the old behavior where BE send us all parties in addition to involved entities
function buildClaimPartiesFromInvolvedEntities(claim) {
  // look for an indicator that this is the new load_type
  if (claim.load_type !== 'fe') {
    return {};
  }

  const incident = claim.incident;

  function getInvolvedByIncidentPartyId(involvedList, incidentPartyId) {
    return involvedList.find((x) => x.incident_party_id === incidentPartyId);
  }

  function getInvolvedByIncidentPartyIdAndType(involvedList, incidentPartyId, involvedType) {
    return involvedList.find((x) => x.incident_party_id === incidentPartyId && x.type === involvedType);
  }

  if (claim.type === 'auto_claim') {
    let other_properties_parties = [];
    let vehicle_parties = [];
    let non_motorist_parties = [];

    incident.party_types.forEach((partyType) => {
      switch (partyType.type) {
        case 'vehicle_party':
          vehicle_parties.push({
            ...partyType,
            involved_vehicle: getInvolvedByIncidentPartyId(incident.involved_properties, partyType.id),
            driver: getInvolvedByIncidentPartyIdAndType(incident.involved_persons, partyType.id, 'involved_driver'),
            passengers: incident.involved_persons.filter(
              (x) => x.incident_party_id === partyType.id && x.type === 'involved_passenger'
            ),
          });
          break;
        case 'non_motorist_party': {
          const involvedNonMotorist = getInvolvedByIncidentPartyIdAndType(
            incident.involved_persons,
            partyType.id,
            'involved_non_motorist'
          );
          non_motorist_parties.push({
            ...partyType,
            involved_non_motorist: involvedNonMotorist,
            non_motorist_type: involvedNonMotorist?.non_motorist_type,
          });
          break;
        }
        case 'generic_property_party':
          other_properties_parties.push({
            ...partyType,
            generic_property_involved: getInvolvedByIncidentPartyId(incident.involved_properties, partyType.id),
          });
          break;
        default:
          throw Error(`Unknown incident party type ${partyType.type}`);
      }
    });

    return {
      other_properties_parties,
      vehicle_parties,
      non_motorist_parties,
    };
  } else if (claim.type === 'general_claim') {
    let other_properties_parties = [];
    let involved_person_parties = [];

    incident.party_types.forEach((partyType) => {
      switch (partyType.type) {
        case 'generic_property_party':
          other_properties_parties.push({
            ...partyType,
            generic_property_involved: getInvolvedByIncidentPartyId(incident.involved_properties, partyType.id),
          });
          break;
        case 'involved_person_party':
          involved_person_parties.push({
            ...partyType,
            involved_person: getInvolvedByIncidentPartyId(incident.involved_persons, partyType.id),
          });
          break;
        default:
          throw Error(`Unknown incident party type ${partyType.type}`);
      }
    });
    return {
      other_properties_parties,
      involved_person_parties,
    };
  }
  return {};
}

export function populateClaim(claim) {
  return {
    ...claim,
    incident: {
      ...claim.incident,
      ...buildClaimPartiesFromInvolvedEntities(claim),
    },
    policy: policyPopulate(claim.policy),
  };
}

export function touchClaim(claimId) {
  return axios.post(`/api/v1/claims/${claimId}/touch`);
}

export function useFetchClaim(claimId) {
  const [isLoading, setIsLoading] = React.useState(true);
  const [isError, setIsError] = React.useState(false);
  const [fetchedClaim, setFetchedClaim] = React.useState(undefined);
  // NOTE: we could've optimized and set isLoading = !claim and fetchedClaim = claim, but than the case where claim context is changed will be almost never tested
  const { claim: claimInContext, onClaimUpdate } = useClaim();
  // HACK: If claimId is undefined and we have claimInContext we use it for backward compatibility (For example ExposureMultiSelectTextFieldFormik).
  // TODO: fix this, if claimId is null we should return null and not claim in context, but this should be tested.
  const shouldUseClaimInContext = claimInContext && (claimInContext.id === claimId || !claimId);

  const fetchClaim = React.useCallback(() => {
    return axios
      .get(`/api/v1/claims/${claimId}`)
      .then((response) => {
        setFetchedClaim(populateClaim(response.data));
      })
      .catch((error) => {
        setIsError(true);
        reportAxiosError(error);
      });
  }, [claimId]);

  React.useEffect(() => {
    if (!claimId) {
      setFetchedClaim(undefined);
      setIsLoading(false);
      return;
    }

    if (fetchedClaim && fetchedClaim.id === claimId) {
      return;
    }

    if (shouldUseClaimInContext) {
      setIsLoading(false);
      return;
    }

    fetchClaim().then(() => {
      setIsLoading(false);
    });
  }, [claimId, claimInContext, fetchedClaim, fetchClaim, shouldUseClaimInContext]);

  return [
    shouldUseClaimInContext ? claimInContext : fetchedClaim,
    isLoading,
    isError,
    shouldUseClaimInContext ? onClaimUpdate : fetchClaim,
  ];
}

export function findIncidentParty(claim, incidentPartyId) {
  const partyType = claim.incident.party_types.find((partyType) => partyType.id === incidentPartyId).type;
  let partiesList;
  switch (partyType) {
    case 'vehicle_party':
      partiesList = claim.incident.vehicle_parties;
      break;
    case 'non_motorist_party':
      partiesList = claim.incident.non_motorist_parties;
      break;
    case 'involved_person_party':
      partiesList = claim.incident.involved_person_parties;
      break;
    case 'pet_party':
      partiesList = claim.incident.pet_parties;
      break;
    case 'generic_property_party':
      partiesList = claim.incident.other_properties_parties;
      break;
    default:
      throw Error(`Unknown incident party type ${partyType}`);
  }
  return partiesList.find((party) => party.id === incidentPartyId);
}

export function findInvolvedPerson(claim, involvedPerson) {
  if (['home_claim', 'gl_claim', 'general_claim'].includes(claim.type)) {
    return claim.incident.involved_persons.find((person) => person.id === involvedPerson.id);
  } else if (claim.type === 'auto_claim') {
    if (involvedPerson.type === 'involved_non_motorist') {
      const nonMotoristParty = claim.incident.non_motorist_parties.find(
        (non_motoristParty) => non_motoristParty.id === involvedPerson.incident_party_id
      );

      return nonMotoristParty.involved_non_motorist;
    } else {
      const vehicleParty = claim.incident.vehicle_parties.find(
        (vehicleParty) => vehicleParty.id === involvedPerson.incident_party_id
      );
      if (involvedPerson.type === 'involved_driver') {
        return vehicleParty.driver;
      } else if (involvedPerson.type === 'involved_passenger') {
        return vehicleParty.passengers.find((passenger) => passenger.id === involvedPerson.id);
      } else {
        throw Error('Should not happen');
      }
    }
  } else if (claim.type === 'wc_claim') {
    // Assume involved person can only be the involved employee. We can't return it like in gl/home claim because we'll lose the employee's fields (employee_id, job_title etc)
    return claim.employee_party.involved_person;
  } else if (claim.type === 'pet_claim') {
    reportErrorInProductionOrThrow('pet claim has no involved person'); // This flow shouldn't happen and it will probably crash anyway, but lets report...
    return undefined;
  } else {
    throw Error('Should not happen');
  }
}

export function findInvolvedProperty(claim, involvedProperty) {
  if (involvedProperty.type === 'generic_involved_property') {
    const genericParty = claim.incident.other_properties_parties.find(
      (otherPropertyParty) => otherPropertyParty.id === involvedProperty.incident_party_id
    );

    return genericParty.generic_property_involved;
  } else if (claim.type === 'auto_claim' && involvedProperty.type === 'involved_vehicle') {
    const vehicleParty = claim.incident.vehicle_parties.find(
      (vehicleParty) => vehicleParty.id === involvedProperty.incident_party_id
    );

    return vehicleParty.involved_vehicle;
  } else if (claim.type === 'pet_claim' && involvedProperty.type === 'involved_pet') {
    const petParty = claim.incident.pet_parties.find((petParty) => petParty.id === involvedProperty.incident_party_id);

    return petParty.involved_pet;
  }

  throw Error(`Should not happen, unknown involved property type: ${involvedProperty.type}`);
}

export function getFirstPartyVehicleFromClaim(claim) {
  return claim.incident.vehicle_parties.find((vehicleParty) => vehicleParty.id === claim.first_incident_party_id);
}

export function getFirstPartyFromClaim(claim) {
  return findIncidentParty(claim, claim.first_incident_party_id);
}

export function getThirdPartyVehiclesFromClaim(claim) {
  let thirdPartyVehicles = [];

  claim.incident.vehicle_parties.forEach((vehicleParty) => {
    if (vehicleParty.id !== claim.first_incident_party_id) {
      thirdPartyVehicles.push(vehicleParty);
    }
  });

  return thirdPartyVehicles;
}

const CLAIM_ICON_MAP = {
  home_claim: { title: 'Home Claim', Icon: HomeIcon },
  auto_claim: { title: 'Auto Claim', Icon: AutoIcon },
  gl_claim: { title: 'GL Claim', Icon: GLIcon },
  wc_claim: { title: 'WC Claim', Icon: WCIcon },
  pet_claim: { title: 'Pet Claim', Icon: PetIcon },
  travel_claim: { title: 'Travel Claim', Icon: TravelIcon },
};

const CLAIM_ICON_DICT = {
  auto_claim_icon: AutoIcon,
  bike_claim_icon: BikeIcon,
  cyber_claim_icon: CyberIcon,
  gl_claim_icon: GLIcon,
  home_claim_icon: HomeIcon,
  pet_claim_icon: PetIcon,
  travel_claim_icon: TravelIcon,
  wc_claim_icon: WCIcon,
  bop_claim_icon: BopIcon,
  alien_claim_icon: AlienIcon,
  professional_liability_claim_icon: PLIcon,
  card_protection_icon: CardProtectionIcon,
  general_property_icon: GeneralPropertyIcon,
};

export function getClaimIcon(claimType, iconProps) {
  try {
    const { Icon, title } = CLAIM_ICON_MAP[claimType];
    return (
      <TooltipIcon title={title}>
        <Icon {...iconProps} />
      </TooltipIcon>
    );
  } catch (error) {
    reportErrorInProductionOrThrow("Can't find claim icon");
    return <></>;
  }
}

export function getConfigurableLobClaimIcon(lobConfig, iconProps = {}) {
  const Icon = CLAIM_ICON_DICT[lobConfig.icon];
  try {
    if (!Icon) {
      reportGenericException(`Can't find icon for lob: ${lobConfig.icon}`);
      return <></>;
    }

    return (
      <TooltipIcon title={lobConfig.desc}>
        <Icon {...iconProps} />
      </TooltipIcon>
    );
  } catch (error) {
    reportErrorInProductionOrThrow("Can't find claim icon");
    return <></>;
  }
}

const ENTITY_ICON_DICT = {
  person_icon: InvolvedPersonIcon,
  property_icon: InvolvedPropertyIcon,
  structure_icon: InvolvedStructureIcon,
  business_icon: InvolvedBusinessIcon,
  pet_icon: InvolvedPetIcon,
  biker_icon: InvolvedBikerIcon,
  entity_icon: InvolvedEntityIcon,
  traveler_icon: InvolvedTravelerIcon,
  cyber_icon: InvolvedCyberIcon,
  vehicle_icon: InvolvedVehicleIcon,
  card_protection_icon: CardProtectionIcon,
  general_property_icon: GeneralPropertyIcon,
};

export function getEntityIcon(entityIcon) {
  try {
    return ENTITY_ICON_DICT[entityIcon];
  } catch (error) {
    reportErrorInProductionOrThrow("Can't find entity icon");
    return InvolvedPersonIcon;
  }
}

export function getClaimExposuresWithGeneralFakeExposure(claim) {
  const FakeGeneralExpensesExposure = {
    id: 0,
    isGeneralExpenses: true,
    is_closed: claim.is_closed,
    is_pending_request_exists:
      claim.general_expenses.are_pending_payment_requests_exist ||
      claim.general_expenses.are_pending_reserve_change_requests_exist,
    expenses: claim.general_expenses,
  };

  return [...claim.exposures, FakeGeneralExpensesExposure];
}

export const getClaimTypeFromPolicy = (policy) => policy.type.replace('_policy', '_claim');
