import React from 'react';

import useWindowFocus from '~/components/hooks/useWindowFocus';

export type VoidCallback = () => void;
export type VoidAsyncCallback = () => Promise<void>;

export const useInterval = (
  callback: VoidCallback | VoidAsyncCallback | undefined,
  delay: number,
  stopWhenWindowInactive?: boolean
): {
  start: VoidCallback;
  stop: VoidCallback;
} => {
  const savedCallback = React.useRef<VoidCallback | VoidAsyncCallback>();

  const intervalRef = React.useRef<number>();

  const start = React.useCallback(() => {
    if (savedCallback.current) {
      clearInterval(intervalRef.current);
      intervalRef.current = setInterval(async () => {
        savedCallback.current && (await savedCallback.current());
      }, delay) as unknown as number;
    }
  }, [delay]);

  const stop = React.useCallback(() => {
    clearInterval(intervalRef.current);
  }, []);

  useWindowFocus({
    onFocus: start,
    onBlur: stop,
    disabled: !stopWhenWindowInactive,
  });

  React.useEffect(() => {
    savedCallback.current = callback;
  }, [callback]);

  React.useEffect(() => {
    return () => stop();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return { start, stop };
};
