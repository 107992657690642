import React from 'react';

import type { CategoriesColors } from '~/components/SystemConfiguration/NotificationsConfiguration/NotificationsCategories';
import cn from '~/Utils/cn';

const colorsToBgMap: { [K in keyof typeof CategoriesColors]: string } = {
  'berry-600': 'bg-berry-600',
  'berry-700': 'bg-berry-700',
  'blue-600': 'bg-blue-600',
  'blueSky-700': 'bg-blueSky-700',
  'grey-600': 'bg-grey-600',
  'orange-700': 'bg-orange-700',
  'pink-600': 'bg-pink-600',
  'purple-600': 'bg-purple-600',
  'purple-700': 'bg-purple-700',
  'teal-600': 'bg-teal-600',
  'yellow-800': 'bg-yellow-800',
};

interface ColoredDotProps {
  color: keyof typeof CategoriesColors;
}

const ColoredDot: React.FC<ColoredDotProps> = ({ color }) => {
  return (
    <div
      className={cn('h-20 w-20 rounded-full', {
        [colorsToBgMap[color]]: colorsToBgMap[color],
      })}
    />
  );
};

export default ColoredDot;
