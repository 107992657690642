import React from 'react';
import PropTypes from 'prop-types';
import { noop } from 'lodash';

import { useStyles } from '~/assets/styles';
import CardDialog from '~/components/CardDialog';
import { getClaimsTableColumnData } from '~/components/ClaimsTable';
import { getContact } from '~/components/Contact';
import ContactMiniCard from '~/components/Contacts/ContactMiniCard';
import { EditContactDialog } from '~/components/Contacts/UpsertContact/Edit/EditContactDialog';
import { LoadingSwitch, Text } from '~/components/core';
import { useLobConfiguration } from '~/components/hooks/useLobConfiguration';
import LoadingIndicator from '~/components/LoadingIndicator';
import PlainTable from '~/components/PlainTable';
import useDataFetcher from '~/components/useDataFetcher';

const DEFAULT_CLAIMS = 20;

const ContactCard = ({ contactId, onClose }) => {
  const [isLoadingContact, setIsLoadingContact] = React.useState(true);
  const [isErrorContact, setIsErrorContact] = React.useState(false);
  const [contact, setContact] = React.useState();
  const [isEditContactOpen, setIsEditContactOpen] = React.useState(undefined);
  const { lobConfigurationsDict = {} } = useLobConfiguration();

  const {
    isLoading: isLoadingClaims,
    isError: isErrorClaims,
    data: contactClaims,
  } = useDataFetcher(`/api/v1/contacts/${contactId}/claims`, { params: { limit: DEFAULT_CLAIMS } });

  const classes = useStyles();

  React.useEffect(() => {
    async function fetchContact() {
      try {
        setIsLoadingContact(true);

        const contact = await getContact(contactId);
        setContact(contact);

        setIsLoadingContact(false);
      } catch (error) {
        setIsErrorContact(true);
      }
    }

    fetchContact();
  }, [contactId]);

  const handleContactUpdate = async () => {
    const contact = await getContact(contactId);
    // contact === undefined if getContact failed fetching
    if (contact !== undefined) {
      setContact(contact);
    }
  };

  return (
    <LoadingSwitch isLoading={isLoadingContact} isError={isErrorContact}>
      <div className="flex flex-wrap">
        <div className=" rounded-md p-12">
          <div className="shadow-lg">
            <ContactMiniCard
              contact={contact}
              onEdit={() => {
                setIsEditContactOpen(true);
              }}
              onClose={onClose}
              onUpdate={async () => {
                const contact = await getContact(contactId);
                setContact(contact);
              }}
            />
          </div>
        </div>
        <div className="h-full p-12">
          <div className="shadow-lg">
            <CardDialog title="Claims" containerClassName="p-100 shadow-lg">
              {isLoadingClaims || isErrorClaims ? (
                <LoadingIndicator isError={isErrorClaims} />
              ) : (
                <>
                  {contactClaims?.length === DEFAULT_CLAIMS ? (
                    <Text variant={Text.VARIANTS.XS}>{`Showing only the first ${DEFAULT_CLAIMS} results.`}</Text>
                  ) : null}
                  <PlainTable
                    classes={classes}
                    columns={getClaimsTableColumnData('Contact Details', noop, lobConfigurationsDict)}
                    rows={contactClaims}
                    stickyHeader
                    maxHeight="70vh"
                  />
                </>
              )}
            </CardDialog>
          </div>
        </div>
      </div>
      {contact && isEditContactOpen && (
        <EditContactDialog
          contact={contact}
          onClose={() => setIsEditContactOpen(false)}
          onContactUpdate={handleContactUpdate}
        />
      )}
    </LoadingSwitch>
  );
};

ContactCard.propTypes = {
  contactId: PropTypes.number.isRequired,
  onClose: PropTypes.func,
};

export default ContactCard;
