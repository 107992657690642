import React from 'react';

import ClaimNotificationCommunicationSuggestion from '~/components/ClaimNotificationsCard/ClaimNotificationCommunicationSuggestion';
import type {
  ClaimNotificationModel,
  CommunicationClaimNotificationModel,
  CommunicationSuggestionClaimNotificationModel,
  FinancialRequestDecisionClaimNotificationModel,
  NotificationTypesUnion,
} from '~/components/ClaimNotificationsCard/types';
import ClaimOwnerChangedNotificationDisplay from '~/components/Notifications/ClaimOwnerChangeNotificationDisplay';
import GenericNotificationHeader from '~/components/Notifications/GenericNotificationHeader';
import type { ExposureModel } from '~/components/types/exposure-types';

import ViewCommunicationCardContainer from '../communications/ViewCommunicationCardContainer';
import PaymentRequestDynamicContainer from '../exposures/PaymentRequestContainer/PaymentRequestDynamicContainer';
import { ReserveChangeRequestDynamicContainer } from '../exposures/ReserveChangeRequestContainer';
import { InternalCommunicationDisplay } from '../InternalCommunication';
import LoadingDialog from '../LoadingDialog';
import { ReminderDisplay } from '../ReminderNotificationContainer';
import useDataFetcher from '../useDataFetcher';

interface ViewNotificationCardProps {
  notification: ClaimNotificationModel;
  onClose: () => void;
  onUpdate: () => void;
  exposuresDict: { [id: number]: ExposureModel };
}
const ViewNotificationCard: React.FC<ViewNotificationCardProps> = ({
  notification,
  onUpdate,
  onClose,
  exposuresDict,
}) => {
  const {
    isLoading,
    isError,
    data: fullNotification,
    reloadData,
  } = useDataFetcher(
    notification.claim_id
      ? `/api/v1/claims/${notification.claim_id}/notifications/${notification.id}`
      : `/api/v1/claims/claims_notifications/${notification.id}`
  );

  const handleUpdate = async () => {
    await reloadData();
    onUpdate();
  };

  const populateCommunicationClaimNotification = (
    communicationClaimNotification: CommunicationClaimNotificationModel | CommunicationSuggestionClaimNotificationModel
  ) => {
    const communication = communicationClaimNotification.communication;

    return {
      ...communicationClaimNotification,
      viewComponent: ViewCommunicationCardContainer,
      viewComponentProps: {
        claimId: communicationClaimNotification.communication?.claim_id,
        isDialog: true,
        startIsEditing: false,
        communicationId: communication?.id,
        onUpdate,
        onClose,
      },
    };
  };

  const populateFinancialRequestDecisionClaimNotification = (
    financialClaimNotification: FinancialRequestDecisionClaimNotificationModel
  ) => {
    let viewComponent = undefined;
    let viewComponentProps = undefined;

    const financialRequest = financialClaimNotification.financial_request;

    switch (financialRequest.type) {
      case 'reserve_change_request':
        viewComponent = ReserveChangeRequestDynamicContainer;
        viewComponentProps = { reserveChangeRequest: financialRequest };
        break;
      case 'payment_request':
        viewComponent = PaymentRequestDynamicContainer;
        viewComponentProps = { paymentRequest: financialRequest };
        break;
      default:
        throw Error(`Unknown financial request type: ${financialRequest.type}`);
    }

    return {
      ...financialClaimNotification,
      title: financialClaimNotification.title,
      viewComponent,
      viewComponentProps: {
        ...viewComponentProps,
        claimId: financialClaimNotification.claim_id,
        exposureId: financialClaimNotification.exposure_id,
        payableWithReserveId: financialRequest.payable_with_reserve_id,
        onUpdate,
        cardDialogProps: { isDialog: true, maxWidth: 'sm', fullWidth: true },
        onClose,
      },
    };
  };

  const populateClaimNotification = (
    claimNotification: NotificationTypesUnion,
    exposuresMap: { [id: number]: ExposureModel }
  ) => {
    switch (claimNotification.type) {
      case 'communication_suggestion_claim_notification_model':
        return claimNotification.communication_id
          ? populateCommunicationClaimNotification(claimNotification)
          : {
              ...claimNotification,
              notification_date: claimNotification.due_date,
              viewComponent: ClaimNotificationCommunicationSuggestion,
              viewComponentProps: { onUpdate, claimNotification, onClose },
            };
      case 'communication_claim_notification':
        return populateCommunicationClaimNotification(claimNotification);
      case 'financial_request_decision_claim_notification':
        return populateFinancialRequestDecisionClaimNotification(claimNotification);
      case 'claim_owner_change_claim_notification':
        return {
          ...claimNotification,
          viewComponent: ClaimOwnerChangedNotificationDisplay,
          viewComponentProps: { notification: claimNotification, onClose, exposuresMap },
        };
      case 'reminder_claim_notification':
        return {
          ...claimNotification,
          notification_date: claimNotification.due_date,
          viewComponent: ReminderDisplay,
          viewComponentProps: { onUpdate, reminder: claimNotification, open: true, onClose },
        };
      case 'internal_communication_claim_notification':
        return {
          ...claimNotification,
          viewComponent: InternalCommunicationDisplay,
          viewComponentProps: {
            showRecipient: true,
            onUpdate,
            internalCommunication: claimNotification.internal_communication,
            open: true,
            onClose,
          },
        };
    }
  };

  if (isLoading || isError) {
    return <LoadingDialog isError={isError} onClose={onClose} track="View Claim Notification" />;
  }

  const populatedClaimNotification = populateClaimNotification(fullNotification, exposuresDict);

  return (
    <populatedClaimNotification.viewComponent
      {...populatedClaimNotification.viewComponentProps}
      AdditionalDialogHeader={
        <GenericNotificationHeader
          notification={fullNotification}
          onUpdateNotification={handleUpdate}
          onClose={onClose}
        />
      }
    />
  );
};

export default ViewNotificationCard;
