import React from 'react';

import { JusterImage } from '~/components/AiChat/components/JusterImage';

interface FnolAiInboxHeaderProps {
  count: number;
}

export const FnolAiInboxCollapsibleHeader: React.FC<FnolAiInboxHeaderProps> = ({ count }) => {
  const getNewMessageText = (count: number) => {
    if (!count) return 'No new messages';
    if (count === 1) return '1 new message';
    return `${count} new messages`;
  };

  return (
    <div className="flex items-center gap-12">
      <span>FNOL Inbox</span>
      <div className="relative">
        <div className="absolute -top-20 left-0 flex items-center gap-8 text-nowrap rounded-full bg-cliveLightGradiant py-8 pl-8 pr-16 bg-blend-hard-light">
          <JusterImage width={28} height={28} />
          <span className="text-base">{getNewMessageText(count)} </span>
        </div>
      </div>
    </div>
  );
};
