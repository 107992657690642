import React from 'react';
import { useHistory } from 'react-router-dom';
import PropTypes from 'prop-types';
import { Divider } from '@material-ui/core';
import axios from 'axios';
import { Formik, useFormikContext } from 'formik';
import { isEmpty, orderBy } from 'lodash';
import * as Yup from 'yup';

import { useStyles } from '~/assets/styles';
import Button from '~/components/core/Atomic/Buttons/Button';
import IconButton from '~/components/core/Atomic/Buttons/IconButton';
import Grid from '~/components/core/Atomic/Grid/Grid';
import MenuItem from '~/components/core/Atomic/MenuItem';
import Typography from '~/components/core/Atomic/Typography';
import RadioButtonGroupFormik from '~/components/core/Formik/RadioButtonGroupFormik';
import TextField from '~/components/core/Molecules/Fields/TextField';
import { clearTableauAuthTime } from '~/tableauAuthenticationUtils';
import colors from '~/theme/tailwind/colors';
import { ORGANIZATION_TYPES } from '~/Types';
import { isUserFiveSigma, isUserImpersonated } from '~/UserUtils';
import { clearUserCache, isHiddenRole, isProductionEnv, reportAxiosError } from '~/Utils';

import CardDialog from '../../CardDialog';
import TooltipIcon from '../../core/TooltipIcon';
import { useCms } from '../../hooks/useCms';
import { CancelIcon, ImpersonationButtonIcon, StarFullIcon } from '../../icons';
import LoadingDialog from '../../LoadingDialog';
import { TextFieldFormik } from '../../TextFieldFormik';
import useDataFetcher from '../../useDataFetcher';

import styles from './ImpersonateContainer.module.scss';

function ImpersonateContainer() {
  const [isImpersonateDialogOpen, setIsImpersonateDialogOpen] = React.useState(false);
  const { user } = useCms();

  const isImpersonated = isUserImpersonated(user);

  if (!user || (isProductionEnv() && !isImpersonated && !user.is_super_user)) {
    return <></>;
  }

  const handleExitImpersonation = async () => {
    await clearUserCache();
    await axios.delete('/api/login_support');
    window.location.assign('/home');
  };

  return (
    <div className={styles.impersonateContainer}>
      {!isImpersonated ? (
        <Button
          size="small"
          variant="contained"
          onClick={setIsImpersonateDialogOpen}
          startIcon={<ImpersonationButtonIcon iconColor="white" />}
          className={styles.externalImpersonateButton}
        >
          {isProductionEnv() ? 'View As' : 'Impersonate'}
        </Button>
      ) : (
        <>
          <Grid container spacing={1}>
            <Grid item xs={6}>
              <Typography variant="caption">Impersonating</Typography>
              <Typography variant="body1">{user.organization_name}</Typography>
            </Grid>
            <Grid item xs={6} className={styles.internalImpersonatedButtonsContainer}>
              <IconButton onClick={setIsImpersonateDialogOpen} className={styles.internalButton}>
                <ImpersonationButtonIcon />
              </IconButton>
              <Divider orientation="vertical" variant="inset" className={styles.divider} flexItem />
              <IconButton onClick={handleExitImpersonation}>
                <CancelIcon />
              </IconButton>
            </Grid>
          </Grid>
        </>
      )}
      {isImpersonateDialogOpen && (
        <ImpersonateDialog onCancel={() => setIsImpersonateDialogOpen(false)} isImpersonated={isImpersonated} />
      )}
    </div>
  );
}

const SelectImpersonateUser = ({ organizationId }) => {
  const { isSubmitting } = useFormikContext();
  const classes = useStyles();
  const { user, userOrganization } = useCms();

  const {
    isLoading: isUsersLoading,
    isError: isUsersError,
    data: organizationUsers = [],
  } = useDataFetcher(
    userOrganization?.is_super_organization || isProductionEnv()
      ? `/admin/api/v1/support/organization_users/${organizationId}`
      : `/api/v1/organizations/${organizationId}/organization_users`,
    {},
    !!organizationId
  );

  const users = organizationUsers?.users || [];
  const sortedUsers = orderBy(users, ['is_active', 'username'], ['desc', 'asc']).filter(
    (user) => !isHiddenRole(user.role_type)
  );

  return (
    <TextFieldFormik
      id="user_id"
      label="User"
      fullWidth
      className={classes.textField}
      select
      disabled={
        !organizationId || isSubmitting || (organizationId && (isUsersLoading || isUsersError)) || isEmpty(sortedUsers)
      }
    >
      {sortedUsers.map((currUser) => (
        <MenuItem key={currUser.id} value={currUser.id} disabled={!currUser.is_active}>
          <div className="flex items-center">
            <span>
              {currUser.username}
              {!currUser.is_active ? ' (Locked) ' : ''}
            </span>
            {user &&
            (user.is_impersonating_super_organization || isUserFiveSigma(user)) &&
            (currUser.is_sys_config_editor || currUser.is_sys_config_editor_by_permission) ? (
              <TooltipIcon title="System Configuration Editor">
                <StarFullIcon size={16} iconColor={colors.teal['600']} className="m-4" />
              </TooltipIcon>
            ) : null}
          </div>
        </MenuItem>
      ))}
    </TextFieldFormik>
  );
};

SelectImpersonateUser.propTypes = {
  organizationId: PropTypes.number,
};

const ImpersonateDialog = ({ onCancel, isImpersonated }) => {
  const classes = useStyles();
  const { user, userOrganization } = useCms();
  const history = useHistory();

  const [organizationId, setOrganizationId] = React.useState(userOrganization?.id);

  const {
    isLoading,
    isError,
    data = {},
  } = useDataFetcher('/admin/api/v1/support/organizations', {}, !!userOrganization?.is_super_organization);

  if (isLoading || isError) {
    return <LoadingDialog onClose={onCancel} isError={isError} track="impersonation_dialog" />;
  }

  const organizations = userOrganization?.is_super_organization ? data.organizations : [userOrganization];

  const singleOrganizationId =
    organizations.length === 1 && organizations[0].id === user.organization_id && user.organization_id;

  return (
    <Formik
      initialValues={{
        organization_id: singleOrganizationId || '',
        user_id: '',
        environment: singleOrganizationId ? organizations[0].organization_type : 'operational',
      }}
      validationSchema={Yup.object().shape({
        organization_id: Yup.number().required('Required'),
        user_id: Yup.string().required('Required'),
        environment: Yup.string().required('Required'),
      })}
      enableReinitialize
      onSubmit={async (values, formikProps) => {
        try {
          await clearUserCache();
          clearTableauAuthTime();
          if (isImpersonated) {
            await axios.put('/api/login_support', values);
            history.push('/home');
          } else {
            await axios.post('/api/login_support', values);
          }
          window.location.reload();
        } catch (error) {
          reportAxiosError(error);
          formikProps.setSubmitting(false);
        }
      }}
    >
      {(formikProps) => {
        const { values, handleSubmit, isSubmitting, handleChange, resetForm, setFieldValue } = formikProps;
        // TODO - Remove empty organization_type check when after NGTPA-4684
        const filteredOrganizations = organizations.filter((org) => {
          if (values.environment === 'operational') {
            return org.organization_type === values.environment || !org.organization_type;
          } else {
            return org.organization_type === values.environment;
          }
        });
        const sortedOrganizations = orderBy(filteredOrganizations, ['name']);

        if (sortedOrganizations.length === 1) {
          values['organization_id'] = sortedOrganizations[0].id;
        }

        return (
          <CardDialog
            title="Impersonate User"
            isDialog
            onClose={onCancel}
            maxWidth="xs"
            containerClassName={styles.cardContainer}
          >
            <Grid container spacing={1}>
              {!singleOrganizationId && (
                <>
                  <Grid items xs={12} className={styles.buttonsContainer}>
                    <RadioButtonGroupFormik
                      label="Select Environment"
                      id="environment"
                      direction="row"
                      options={Object.values(ORGANIZATION_TYPES).map((type) => ({
                        text: type.desc,
                        optionValue: type.desc.toLowerCase(),
                      }))}
                      btnClassName={styles.btnClassName}
                      variant="subtitle1"
                      btnContainerClassName={styles.btnContainerClassName}
                      onClick={(optionValue, _) => {
                        resetForm();
                        setFieldValue('environment', optionValue);
                      }}
                    />
                  </Grid>
                  <Grid item xs={12} className={styles.inputContainer}>
                    <TextFieldFormik
                      id="organization_id"
                      label="Organization"
                      fullWidth
                      className={classes.textField}
                      select
                      disabled={isSubmitting || values.environment === ''}
                      onChange={(e) => {
                        if (organizationId !== e.target.value) {
                          setOrganizationId(e.target.value);
                          setFieldValue('user_id', '');
                          handleChange(e);
                        }
                      }}
                    >
                      {sortedOrganizations.map((impOrg) => (
                        <MenuItem key={impOrg.id} value={impOrg.id}>
                          {impOrg.name}
                        </MenuItem>
                      ))}
                    </TextFieldFormik>
                  </Grid>
                </>
              )}
              <Grid item xs={12} className={styles.inputContainer}>
                {(organizationId || singleOrganizationId) && values['organization_id'] ? (
                  <SelectImpersonateUser organizationId={organizationId || singleOrganizationId} />
                ) : (
                  <TextField label="User" fullWidth select disabled />
                )}
              </Grid>
              <Grid item xs={12}>
                <div className={classes.buttonsContainer}>
                  <Button variant="contained" color="primary" onClick={handleSubmit} disabled={isSubmitting}>
                    Impersonate
                  </Button>
                </div>
              </Grid>
            </Grid>
          </CardDialog>
        );
      }}
    </Formik>
  );
};

ImpersonateDialog.propTypes = {
  onCancel: PropTypes.func.isRequired,
  isImpersonated: PropTypes.bool.isRequired,
};

export default ImpersonateContainer;
