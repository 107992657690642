import React from 'react';

import CardDialog from '~/components/CardDialog';
import type { Policy } from '~/components/Fnol/FnolAiInbox/types';
import { useCms } from '~/components/hooks/useCms';
import PolicySearchContainer from '~/components/PolicySearch';

interface PolicySearchCardProps {
  onSelectPolicy: (policy: Policy) => void;
  onCancel: () => void;
}

export const PolicySearchCard: React.FC<PolicySearchCardProps> = ({ onSelectPolicy, onCancel }) => {
  const { userOrganization } = useCms();
  return (
    <CardDialog isDialog title="Policies" onClose={onCancel} maxWidth="lg" fullWidth>
      <div className="h-full overflow-y-scroll">
        <PolicySearchContainer
          onSelectPolicy={onSelectPolicy}
          policyTypes={userOrganization.supported_claim_types.map((claimType) =>
            claimType.replace('_claim', '_policy')
          )}
        />
      </div>
    </CardDialog>
  );
};
