import React from 'react';
import colors from 'tailwindcss/colors';

import Button from '~/components/core/Atomic/Buttons/Button';
import { LoadingButton } from '~/components/Fnol/FnolAiInbox/LoadingButton';
import type { FnolAiInboxRecord } from '~/components/Fnol/FnolAiInbox/types';
import { PlusIcon } from '~/components/icons';

interface FnolInboxEmailFooterProps {
  inboxType: string;
  selectedInboxItem: FnolAiInboxRecord;
  onOpenDraft: () => Promise<void>;
  onDismissClick: () => Promise<void>;
  onCreateNewLoss: () => Promise<void>;
  onOpenBack: () => Promise<void>;
  onAttachToClaim: () => void;
}

const OpenDraftButton: React.FC<{ onClick: () => Promise<void> }> = ({ onClick }) => {
  return <LoadingButton onClick={onClick} text="Open Draft" variant="contained" />;
};

const DismissButton: React.FC<{ onClick: () => Promise<void> }> = ({ onClick }) => {
  return <LoadingButton onClick={onClick} text="Dismiss" variant="text" className="mr-auto text-blue-600" />;
};

const AttachToClaimButton: React.FC<{ onClick: () => void }> = ({ onClick }) => {
  return (
    <Button onClick={onClick} variant="outlined" color="primary">
      Attach Email to Claim
    </Button>
  );
};

const NewLossButton: React.FC<{ onClick: () => Promise<void> }> = ({ onClick }) => {
  return (
    <LoadingButton
      onClick={onClick}
      text="New Loss"
      variant="contained"
      icon={<PlusIcon iconColor={colors.white} height={12} width={12} className="stroke-white" />}
    />
  );
};

export const FnolAiInboxCommunicationFooter: React.FC<FnolInboxEmailFooterProps> = ({
  inboxType,
  selectedInboxItem,
  onOpenDraft,
  onDismissClick,
  onCreateNewLoss,
  onOpenBack,
  onAttachToClaim,
}) => {
  return (
    <div className="flex w-full items-center justify-end gap-12 bg-white">
      {inboxType === 'in-queue' ? (
        <>
          <DismissButton onClick={onDismissClick} />
          <AttachToClaimButton onClick={onAttachToClaim} />
          {selectedInboxItem.draft_id && <OpenDraftButton onClick={onOpenDraft} />}
          {!selectedInboxItem.draft_id && <NewLossButton onClick={onCreateNewLoss} />}
        </>
      ) : null}

      {inboxType === 'done' ? (
        <>
          {selectedInboxItem.status === 'dismissed' && (
            <Button onClick={onOpenBack} variant="contained" color="primary">
              Re-open
            </Button>
          )}
        </>
      ) : null}
    </div>
  );
};
