import React, { useCallback, useEffect } from 'react';
import axios from 'axios';
import { isEmpty, isNil } from 'lodash';
import * as Twilio from 'twilio-client';

import { ClaimContextProvider } from '~/components/ClaimContainer';
import mixpanel from '~/components/CmsMain/mixpanel';
import { TWILIO_ERRORS_TO_IGNORE } from '~/components/communications/PhoneCall/components/CmsCallCenter';
import { MinimizedDialogContextProvider } from '~/components/core/MinimizedBar/Context';
import { useCms } from '~/components/hooks/useCms';
import LoadingDialog from '~/components/LoadingDialog';
import { MIXPANEL_EVENTS } from '~/pocs/mixpanel';
import { reportAxiosError, reportTwilioError } from '~/Utils';
import { useFetchClaim } from '~/Utils/ClaimUtils';

import CallInProgressCard from './components/CallInProgressCard';
import { CommunicationCallProvider, useCommunicationCallContext } from './Context/CommunicationCallContext';

const IncomingCallInProgressContainer = () => (
  <CommunicationCallProvider>
    <MinimizedDialogContextProvider>
      <IncomingCallInProgressContainerInner />
    </MinimizedDialogContextProvider>
  </CommunicationCallProvider>
);

export const IncomingCallInProgressContainerInner = () => {
  const deviceRef = React.useRef(undefined);
  const offlineErrorRef = React.useRef(false);
  const { callInProgress, setCallInProgress, userReloadTwilioDetails } = useCms();
  const [errorStartingCall, setErrorStartingCall] = React.useState(false);
  const { callSid, currCallSid, communicationId, claimToAttachId } = callInProgress;
  const [claim, isLoadingClaim, isErrorLoadingClaim, reloadClaim] = useFetchClaim(claimToAttachId);

  const { callStatus, communication, updateCallConnection, updateDisconnectOrCancel, updateCommunication } =
    useCommunicationCallContext();

  const setupDevice = async (device) => {
    const res = await axios.get('/api/v1/calls/twilio_access_token_accept');
    const token = res.data;
    device.setup(token, { sound: { incoming: null } });
    device.audio.incoming(false); // We answer automatically - no need for incoming call sound
  };

  const handleIncomingCall = (connection) => {
    connection.accept();
  };

  const handleCallAccepted = useCallback(
    (connection) => {
      updateCallConnection(connection);
      mixpanel.track(MIXPANEL_EVENTS.INCOMING_PHONE_CALL_COMMUNICATION_ACCEPTED, { communicationId });
    },
    [communicationId, updateCallConnection]
  );

  const handleDisconnectOrCancel = useCallback(
    (connection) => {
      updateDisconnectOrCancel(connection);

      // The reason we aren't destroying in the useEffect is to disable the ability to answer call
      // even if the user didn't click "save/finished" but already hanged up and received another call
      if (deviceRef.current) {
        deviceRef.current.destroy();
      }

      setTimeout(userReloadTwilioDetails, 5000); // So the status would be updated
    },
    [userReloadTwilioDetails, updateDisconnectOrCancel]
  );

  const handleCallFinished = useCallback(() => {
    setCallInProgress(null);
  }, [setCallInProgress]);

  const handleOffline = useCallback(() => {
    if (offlineErrorRef.current) {
      return;
    }
    offlineErrorRef.current = true;
  }, []);

  useEffect(() => {
    const handleReady = async (device) => {
      if (deviceRef.current) {
        deviceRef.current = device;
        return; // In case of connection disconnect handleReady would be called again, in the case the call would be probably already hanged up (by Twilio)
      }

      try {
        const res = await axios.post(`/api/v1/calls/twilio_accept_call/${communicationId}`, {
          call_sid: callSid,
          curr_call_sid: currCallSid,
        });
        updateCommunication(res.data);
        deviceRef.current = device;
        userReloadTwilioDetails();
      } catch (error) {
        device.destroy();
        await reportAxiosError(error);
        setErrorStartingCall(true);
        handleCallFinished(); // We want to initialize callInProgress to enable receiving new calls
      }
    };

    const initializeCallCenter = () => {
      const device = new Twilio.Device();
      setupDevice(device);

      device.on('ready', handleReady);
      device.on('cancel', handleDisconnectOrCancel);
      device.on('incoming', handleIncomingCall);
      device.on('connect', handleCallAccepted);
      device.on('disconnect', handleDisconnectOrCancel);
      device.on('offline', handleOffline);
      device.on('error', function (error) {
        if (TWILIO_ERRORS_TO_IGNORE.includes(error.code)) {
          return;
        }

        reportTwilioError(error);
      });
    };

    if (callSid && !deviceRef.current) {
      initializeCallCenter();
    }
  }, [
    callSid,
    currCallSid,
    communicationId,
    userReloadTwilioDetails,
    handleCallAccepted,
    handleDisconnectOrCancel,
    handleCallFinished,
    handleOffline,
    updateCommunication,
  ]);

  useEffect(() => {
    async function updateClaimCommunication() {
      try {
        const res = await axios.get(`/api/v1/claims/${claimToAttachId}/communications/${communicationId}`);
        updateCommunication(res.data);
      } catch (error) {
        await reportAxiosError(error);
      }
    }

    if (!claimToAttachId) {
      return;
    }

    updateClaimCommunication();
  }, [claimToAttachId, communicationId, updateCommunication]);

  if (errorStartingCall) {
    return <></>;
  }

  if (callStatus === 'pending' || isEmpty(communication) || isNil(communication) || isLoadingClaim) {
    return (
      <LoadingDialog
        isError={isErrorLoadingClaim}
        track="Initializing incoming phone call"
        text="Initializing Phone Call"
      />
    );
  }

  const callInProgressCardComponent = <CallInProgressCard isIncoming onCallHandledAndFinished={handleCallFinished} />;

  return claimToAttachId && claim ? (
    <ClaimContextProvider claim={claim} refreshData={reloadClaim} createMinimizedContext={false}>
      {callInProgressCardComponent}
    </ClaimContextProvider>
  ) : (
    callInProgressCardComponent
  );
};

export default IncomingCallInProgressContainer;
