import React, { useState } from 'react';
import PropTypes from 'prop-types';
import axios from 'axios';
import { Formik } from 'formik';
import { Phone, PhoneInTalk, PhoneOutgoing } from 'mdi-material-ui';
import * as Yup from 'yup';

import { useStyles } from '~/assets/styles';
import CardDialog from '~/components/CardDialog';
import { useClaim } from '~/components/ClaimContainer';
import mixpanel from '~/components/CmsMain/mixpanel';
import { NewPhoneCallCommunicationCard } from '~/components/communications/PhoneCall/components/PhoneCallCommunicationCard/PhoneCallCommunicationCard';
import Button from '~/components/core/Atomic/Buttons/Button';
import IconButton from '~/components/core/Atomic/Buttons/IconButton';
import Tooltip from '~/components/core/Atomic/Tooltip';
import { useCms } from '~/components/hooks/useCms';
import useInterval from '~/components/hooks/useInterval';
import RadioButtonFormik from '~/components/RadioButtonFormik';
import { MultiSelectTextFieldFormik } from '~/components/TextFieldFormik';
import { MIXPANEL_EVENT_SOURCES, MIXPANEL_EVENTS } from '~/pocs/mixpanel';
import { TWILIO_ADJUSTER_STATUS_DICT } from '~/Types';
import { reportAxiosError, reportErrorInProductionOrThrow } from '~/Utils';

function IncomingCallAvailabilityStatus() {
  const [availabilityPopoverAnchorEl, setAvailabilityPopoverAnchorEl] = useState(false);
  const [showPhoneCallCommunicationCard, setShowPhoneCallCommunicationCard] = useState(false);
  const { user, isLoadingTwilioDetails, userReloadTwilioDetails, twilioWorkspaceDetails } = useCms();

  const { claim, onClaimUpdate } = useClaim();

  const isInCallStatus = twilioWorkspaceDetails
    ? TWILIO_ADJUSTER_STATUS_DICT[twilioWorkspaceDetails.status].in_call_status
    : null;
  useInterval(userReloadTwilioDetails, isInCallStatus ? 5000 : null);

  const handleUpdateStatus = async (values) => {
    try {
      await axios.put(`/api/v1/calls/${user.organization_id}/users/${user.id}/incoming_call_availability`, {
        ...values,
      });
      mixpanel.track(MIXPANEL_EVENTS.COMMUNICATION_AVAILABILITY_STATUS_UPDATED, {
        status: values.availability,
        active_queues: values.active_queues,
      });
    } catch (error) {
      reportAxiosError(error);
      throw error;
    }

    await userReloadTwilioDetails();
    setAvailabilityPopoverAnchorEl(null);
  };

  const getStatusColor = (status) => {
    switch (status) {
      case 'available':
        return '#26a56e';
      case 'available_for_queues_only':
        return '#ffbe00';
      case 'available_for_direct_calls_only':
        return '#ee8503';
      case 'not_available':
        return '#cf0000';
      case 'wrap_up':
        return '#cf0000';
      default:
        reportErrorInProductionOrThrow(`Unknown twilio status: ${status}`);
        return 'grey';
    }
  };

  if (!user.is_call_center_allowed) {
    return <></>;
  }

  const commonStyles = { border: '2px solid #1A9C9E', width: 40, height: 40, marginRight: 12 };

  if (isLoadingTwilioDetails) {
    return (
      <IconButton size="small" style={{ ...commonStyles, backgroundColor: 'white', color: 'grey' }}>
        <Tooltip title="Loading...">
          <Phone />
        </Tooltip>
      </IconButton>
    );
  }

  if (!twilioWorkspaceDetails || (user.twilio_phone_number && !user.twilio_worker_sid)) {
    return (
      <IconButton size="small" style={{ ...commonStyles, backgroundColor: 'white', color: 'grey' }}>
        <Tooltip title="Missing details - contact support">
          <Phone />
        </Tooltip>
      </IconButton>
    );
  }

  if (isInCallStatus) {
    return (
      <Tooltip title="In Active Call">
        <IconButton size="small" style={{ ...commonStyles, backgroundColor: 'white', color: '#3f51b5' }}>
          <PhoneInTalk />
        </IconButton>
      </Tooltip>
    );
  }

  if (user.is_out_of_office) {
    return (
      <Tooltip title="Out Of Office">
        <IconButton size="small" style={{ ...commonStyles, backgroundColor: 'white', color: '#202224' }}>
          <Phone />
        </IconButton>
      </Tooltip>
    );
  }

  const twilioStatus = twilioWorkspaceDetails.status;

  const handleChangeAvailabilityClick = (e) => {
    setAvailabilityPopoverAnchorEl(e.currentTarget);
    mixpanel.track(MIXPANEL_EVENTS.COMMUNICATION_AVAILABILITY_STATUS_OPENED, {
      source: MIXPANEL_EVENT_SOURCES.CHANGE_AVAILABILITY_STATUS_CLICKED,
    });
  };

  const handleInitiateNewCall = () => {
    setShowPhoneCallCommunicationCard(true);
    setAvailabilityPopoverAnchorEl(null);
    mixpanel.track(MIXPANEL_EVENTS.NEW_PHONE_CALL_COMMUNICATION_CLICKED, {
      source: MIXPANEL_EVENT_SOURCES.NEW_PHONE_CALL_FROM_ICON_IN_AVAILABILITY_BAR,
    });
  };

  return (
    <>
      <Tooltip title={TWILIO_ADJUSTER_STATUS_DICT[twilioStatus].desc}>
        <IconButton
          style={{ ...commonStyles, backgroundColor: 'white', color: getStatusColor(twilioStatus) }}
          onClick={(e) => handleChangeAvailabilityClick(e)}
          size="small"
        >
          <Phone />
        </IconButton>
      </Tooltip>

      {availabilityPopoverAnchorEl && (
        <UpdateAvailabilityStatusPopover
          popoverAnchorEl={availabilityPopoverAnchorEl}
          twilioWorkspaceDetails={twilioWorkspaceDetails}
          onUpdate={handleUpdateStatus}
          onInitiateNewCall={handleInitiateNewCall}
          onClose={() => setAvailabilityPopoverAnchorEl(null)}
        />
      )}

      {showPhoneCallCommunicationCard && (
        <NewPhoneCallCommunicationCard
          onCancel={() => setShowPhoneCallCommunicationCard(false)}
          onNewPhoneCallCreated={async () => {
            if (claim) {
              await onClaimUpdate();
            }
            setShowPhoneCallCommunicationCard(false);
          }}
          isNotClaimRelated
        />
      )}
    </>
  );
}

function UpdateAvailabilityStatusPopover({
  twilioWorkspaceDetails,
  popoverAnchorEl,
  onUpdate,
  onInitiateNewCall,
  onClose,
}) {
  const classes = useStyles();
  const {
    possible_queues: possibleQueues,
    possible_statuses: possibleStatuses,
    is_queues_enabled: isQueuesEnabled,
  } = twilioWorkspaceDetails;

  return (
    <Formik
      initialValues={{
        availability: twilioWorkspaceDetails.status,
        active_queues: twilioWorkspaceDetails.active_queues,
        possible_queues: possibleQueues,
      }}
      validationSchema={Yup.object().shape({
        availability: Yup.string().required('Required'),
        active_queues: Yup.array()
          .nullable()
          .when(['availability', 'possible_queues'], {
            is: (availability, possible_queues) =>
              isQueuesEnabled &&
              Object.keys(TWILIO_ADJUSTER_STATUS_DICT)
                .filter((status) => TWILIO_ADJUSTER_STATUS_DICT[status].can_accept_queue_calls)
                .includes(availability) &&
              possible_queues.length > 0,
            then: Yup.array().min(1, 'At least one queue required'),
          }),
      })}
      onSubmit={async (values, formikProps) => {
        try {
          await onUpdate(values);
        } catch (error) {
          formikProps.setSubmitting(false);
        }
      }}
    >
      {(formikProps) => {
        const { isSubmitting, handleSubmit, values } = formikProps;

        return (
          <CardDialog
            noCardTitle
            isPopover
            closeOnBackdropClick
            open
            anchorEl={popoverAnchorEl}
            onClose={onClose}
            width="350px"
          >
            <div
              style={{
                display: 'flex',
                justifyContent: 'flex-end',
                width: '100%',
              }}
            >
              <Button
                size="small"
                style={{ fontWeight: 'bold', color: 'green' }}
                color="secondary"
                onClick={onInitiateNewCall}
              >
                <PhoneOutgoing />
                &nbsp;Initiate a new call
              </Button>
            </div>
            {possibleStatuses
              .filter((status) => TWILIO_ADJUSTER_STATUS_DICT[status].can_be_chosen_manually)
              .map((status) => (
                <div key={status}>
                  <RadioButtonFormik
                    id="availability"
                    label={TWILIO_ADJUSTER_STATUS_DICT[status].desc}
                    optionValue={status}
                    size="small"
                    disabled={isSubmitting}
                  />
                  {isQueuesEnabled &&
                    possibleQueues &&
                    TWILIO_ADJUSTER_STATUS_DICT[status].can_accept_queue_calls &&
                    values.availability === status && (
                      <MultiSelectTextFieldFormik
                        id="active_queues"
                        label="Active Queues"
                        options={twilioWorkspaceDetails.possible_queues}
                        renderValue={(selected) => selected.join(', ')}
                        className={classes.textField}
                        fullWidth
                        renderOption={(o) => o}
                        disabled={isSubmitting}
                      />
                    )}
                </div>
              ))}
            <div className={classes.buttonsContainer}>
              <Button size="small" disabled={isSubmitting} variant="contained" color="primary" onClick={handleSubmit}>
                Update Status
              </Button>
            </div>
          </CardDialog>
        );
      }}
    </Formik>
  );
}

UpdateAvailabilityStatusPopover.propTypes = {
  twilioWorkspaceDetails: PropTypes.object.isRequired,
  popoverAnchorEl: PropTypes.object.isRequired,
  onUpdate: PropTypes.func.isRequired,
  onInitiateNewCall: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
};

export default IncomingCallAvailabilityStatus;
