import React, { useEffect, useReducer, useRef, useState } from 'react';
import requiredIf from 'react-required-if';
import PropTypes from 'prop-types';
import CircularProgress from '@material-ui/core/CircularProgress';
import InputAdornment from '@material-ui/core/InputAdornment';
import SearchIcon from '@material-ui/icons/Search';
import axios from 'axios';
import { Formik, useFormikContext } from 'formik';
import { capitalize } from 'lodash';
import * as Yup from 'yup';

import Button from '~/components/core/Atomic/Buttons/Button';
import Chip from '~/components/core/Atomic/Chip/Chip';
import Grid from '~/components/core/Atomic/Grid/Grid';
import MenuItem from '~/components/core/Atomic/MenuItem';
import Typography from '~/components/core/Atomic/Typography';
import CancelButton from '~/components/core/Buttons/CancelButton';
import CollapsibleWrapper from '~/components/core/Collapsible/CollapsibleWrapper';
import OverflowArrayTextDisplayWithTooltip from '~/components/core/OverflowArrayTextWithTooltip/OverflowArrayTextDisplayWithTooltip';
import { useLobConfiguration } from '~/components/hooks/useLobConfiguration';
import cn from '~/Utils/cn';

import { isoDateToUs } from '../DateTimeUtils';
import { getCurrentSupportedLobIsClaimsMade, getIsClaimsMadePolicy, policyPopulate } from '../PolicyUtils';
import { CONFIGURATION_FEATURES_NAMES, CONSTANTS } from '../Types';
import { isFeatureEnabled, reportAxiosError, reportErrorInProductionOrThrow } from '../Utils';
import { getLobDescription } from '../Utils/lobUtils';

import { FsButton } from './core/FsWrappers';
import Text from './core/TextComponents/Text/index';
import { FnolDraftsTable } from './Fnol/FnolDraft';
import { useCms } from './hooks/useCms';
import useGlobalAddresses from './hooks/useGlobalAddresses';
import { LobSelectFormik } from './TPA/LOB/LobSelectFormik';
import SubOrganizationSelectFormik from './TPA/SubOrganizations/SubOrganizationSelectFormik';
import CardDialog from './CardDialog';
import { ClaimSearchResults } from './ClaimSearch';
import { PERMISSION_ACTIONS, PERMISSION_VERBS, RestrictedPermissions } from './core';
import { getLocationFields, LocationFormikInner } from './Location';
import useOrganization from './OrganizationContext';
import PlainTable from './PlainTable';
import { DatePickerTextFieldFormik, TextFieldFormik } from './TextFieldFormik';

import { useStyles } from '../assets/styles';
import styles from './policySearch.module.scss';

const spacing = 1;

function PolicySearchInner(props) {
  const {
    constSearchValues,
    onSearchByAddress,
    policySearchConfig,
    disabled,
    errorMsg,
    isSearchInProgress,
    hideSubOrg = false,
    hideDateOfLoss = false,
    hideLob = false,
    newDesign = false,
    onSetManualPolicy,
  } = props;
  const { handleSubmit, values, errors, isSubmitting } = useFormikContext();
  const classes = useStyles();
  const { userOrganization } = useCms();
  const { subOrganizationEnabled, supportedClaimTypes } = useOrganization();
  const policySearchMethod = policySearchConfig.method;
  const policyApiConfiguration = policySearchConfig.policy_api_configuration;
  const isSearchByAddressSupported = policySearchConfig.allow_search_by_address && onSearchByAddress;
  const shouldAskDateOfLoss = policySearchConfig.should_ask_date_of_loss && !hideDateOfLoss;
  const shouldAskLob = supportedClaimTypes.length > 1 && !hideLob;
  const shouldAskSubOrg = subOrganizationEnabled && !hideSubOrg;
  const endAdornment = (
    <InputAdornment position="end">
      {isSearchInProgress ? <CircularProgress size={32} /> : null}
      {newDesign ? <SearchIcon /> : null}
    </InputAdornment>
  );
  const currentSupportedLobIsClaimsMade = getCurrentSupportedLobIsClaimsMade({
    userOrganization,
    currSubOrgId: values?.sub_org_id,
    currLob: values?.lob,
  });

  let SearchFields;
  if (policySearchMethod === 'DB') {
    SearchFields = (
      <Grid item xs>
        <TextFieldFormik
          id="search"
          className={classes.textField}
          InputProps={{
            placeholder: 'Search for policy by: insured name, phone number or policy number',
            endAdornment,
          }}
          disabled={isSubmitting || disabled || !!constSearchValues.search}
          fullWidth
        />
      </Grid>
    );
  } else if (policySearchMethod === 'API') {
    if (!policySearchConfig.search_criteria) {
      SearchFields = (
        <Grid item xs>
          <TextFieldFormik
            id="search"
            className={classes.textField}
            InputProps={{
              endAdornment,
            }}
            label={
              (policyApiConfiguration?.enabled &&
                (policyApiConfiguration?.policy_search_component_description || 'Search query')) ||
              'Policy Number'
            }
            disabled={isSubmitting || disabled || !!constSearchValues.search}
            fullWidth
            error={!!errorMsg || !!errors.search}
            helperText={errorMsg || errors.search}
          />
        </Grid>
      );
    } else {
      SearchFields = (
        <>
          {policySearchConfig.search_criteria && (
            <Grid item xs>
              <TextFieldFormik
                id="search_criteria"
                className={classes.textField}
                label="Search By"
                disabled={isSubmitting || disabled || !!constSearchValues.search}
                fullWidth
                select
              >
                {Object.keys(policySearchConfig.search_criteria).map((option) => (
                  <MenuItem key={option} value={option}>
                    {policySearchConfig.search_criteria[option].desc}
                  </MenuItem>
                ))}
              </TextFieldFormik>
            </Grid>
          )}
          <Grid item xs>
            <TextFieldFormik
              id="search"
              className={classes.textField}
              InputProps={{
                endAdornment,
              }}
              label={
                values['search_criteria'] ? policySearchConfig.search_criteria[values['search_criteria']].desc : ' '
              }
              disabled={isSubmitting || disabled || !!constSearchValues.search || !values['search_criteria']}
              fullWidth
              error={!!errorMsg} // this will override the formik error - but we currently have not validation on search
              helperText={errorMsg}
            />
          </Grid>
        </>
      );
    }
  } else {
    reportErrorInProductionOrThrow(`Unknown policy search method: ${policySearchMethod}`);
  }
  const isSubOrgOrLobShown = (shouldAskSubOrg || shouldAskLob) && policyApiConfiguration?.enabled;
  return (
    <CollapsibleWrapper
      title="Search Policy"
      actionCard
      noBorder
      backgroundColor={CollapsibleWrapper.BACKGROUND_COLOR.WHITE}
      defaultState
    >
      <form onSubmit={(e) => e.preventDefault()}>
        <div className={classes.containerCentered}>
          <Grid container spacing={2}>
            {policySearchMethod === 'API' && policyApiConfiguration?.enabled && (
              <>
                {shouldAskSubOrg && (
                  <Grid item xs={2}>
                    <SubOrganizationSelectFormik fieldId="sub_org_id" disabled={disabled || isSubmitting} />
                  </Grid>
                )}
                {shouldAskLob && (
                  <Grid item xs={2}>
                    <LobSelectFormik
                      fieldId="lob"
                      subOrganizationIds={values.sub_org_id ? [values.sub_org_id] : []}
                      disabled={disabled || isSubmitting}
                    />
                  </Grid>
                )}
              </>
            )}
            {SearchFields}
            {shouldAskDateOfLoss && !currentSupportedLobIsClaimsMade && (
              <Grid item xs={isSubOrgOrLobShown ? 3 : 4}>
                <DatePickerTextFieldFormik
                  id="date_of_loss"
                  label="Date of Loss"
                  disableFuture
                  disabled={isSubmitting || disabled || !!constSearchValues.date_of_loss}
                  className={classes.textField}
                  fullWidth
                />
              </Grid>
            )}
            {currentSupportedLobIsClaimsMade && (
              <Grid item xs={isSubOrgOrLobShown ? 3 : 4}>
                <DatePickerTextFieldFormik
                  id="reported_date"
                  label="Date of Report"
                  disableFuture
                  disabled={isSubmitting || disabled || !!constSearchValues.date_of_loss}
                  className={classes.textField}
                  fullWidth
                />
              </Grid>
            )}
          </Grid>
          {!newDesign && (
            <>
              <Button
                onClick={handleSubmit}
                className={classes.button}
                disabled={isSearchInProgress || disabled}
                variant="contained"
                color="primary"
              >
                Search
              </Button>
              {isSearchByAddressSupported && (
                <div>
                  <Button
                    style={{ width: '160px' }}
                    className={classes.button}
                    onClick={onSearchByAddress}
                    disabled={isSearchInProgress || disabled}
                    variant="contained"
                    size="small"
                    color="default"
                  >
                    Search by address
                  </Button>
                </div>
              )}
            </>
          )}
        </div>
        {newDesign && (
          <div className={styles.btnContainer}>
            <Button
              onClick={handleSubmit}
              className={styles.btn}
              disabled={isSearchInProgress || disabled}
              variant="contained"
              color="primary"
            >
              Search
            </Button>
            {onSetManualPolicy && (
              <RestrictedPermissions action={PERMISSION_ACTIONS.MANUAL_POLICY} verb={PERMISSION_VERBS.WRITE}>
                <CancelButton
                  classNameOverride={cn(styles.btn, styles.setPolicyBtn)}
                  onClick={onSetManualPolicy}
                  content="Set Policy Details Manually"
                />
              </RestrictedPermissions>
            )}
          </div>
        )}
      </form>
    </CollapsibleWrapper>
  );
}

PolicySearchInner.propTypes = {
  constSearchValues: PropTypes.object,
  onSearch: PropTypes.func.isRequired,
  onSearchByAddress: PropTypes.func.isRequired,
  policySearchConfig: PropTypes.object.isRequired,
  onCouldntFindPolicy: PropTypes.func,
  policyTypes: PropTypes.array.isRequired,
  disabled: PropTypes.bool,
  errorMsg: PropTypes.string,
  isSearchInProgress: PropTypes.bool,
  hideSubOrg: PropTypes.bool,
  hideDateOfLoss: PropTypes.bool,
  hideLob: PropTypes.bool,
  newDesign: PropTypes.bool,
  onSetManualPolicy: PropTypes.func,
};

PolicySearchInner.defaultProps = {
  constSearchValues: {},
};

function PolicySearch(props) {
  const {
    constSearchValues,
    onSearch,
    policySearchConfig,
    hideSubOrg = false,
    hideDateOfLoss = false,
    hideLob = false,
  } = props;
  const { userOrganization } = useCms();
  const { subOrganizationEnabled, supportedClaimTypes } = useOrganization();
  const isClaimsMadePolicy = getIsClaimsMadePolicy(userOrganization);
  const shouldAskDateOfLoss = policySearchConfig.should_ask_date_of_loss && !hideDateOfLoss;
  const shouldAskLob = supportedClaimTypes.length > 1 && !hideLob;
  const shouldRequireLob = shouldAskLob && isClaimsMadePolicy;
  const shouldAskSubOrg = subOrganizationEnabled && !hideSubOrg;
  const shouldRequireSubOrg = shouldAskSubOrg && (policySearchConfig.should_require_sub_org || isClaimsMadePolicy);
  const shouldRequirePolicyNumber = policySearchConfig.required_policy_number;

  return (
    <Formik
      initialValues={{
        search_criteria: '',
        search: '',
        date_of_loss: '',
        sub_org_id: '',
        lob: shouldAskLob ? '' : supportedClaimTypes[0],
        reported_date: '',
        ...constSearchValues,
      }}
      validationSchema={Yup.object().shape({
        search_criteria: policySearchConfig.search_criteria ? Yup.string().required('Required') : undefined,
        search: shouldRequirePolicyNumber ? Yup.string().required('Required') : undefined,
        sub_org_id: !shouldAskSubOrg
          ? undefined
          : shouldRequireSubOrg
          ? Yup.string().required('Required')
          : Yup.string(),
        lob: !shouldAskLob ? undefined : shouldRequireLob ? Yup.string().required('Required') : Yup.string(),
        date_of_loss: Yup.date()
          .nullable()
          .max(new Date())
          .test('is-required', 'Required', (value, context) => {
            if (!shouldAskDateOfLoss) {
              return true;
            }

            const isClaimsMade = getCurrentSupportedLobIsClaimsMade({
              userOrganization,
              currSubOrgId: Number(context.parent.sub_org_id),
              currLob: context.parent.lob,
            });

            if (isClaimsMade) {
              return true;
            }

            return !!value;
          }),
        reported_date: Yup.date()
          .nullable()
          .max(new Date())
          .test('is-required', 'Required', (value, context) => {
            const isClaimsMade = getCurrentSupportedLobIsClaimsMade({
              userOrganization,
              currSubOrgId: Number(context.parent.sub_org_id),
              currLob: context.parent.lob,
            });

            if (!isClaimsMade) {
              return true;
            }

            return !!value;
          }),
      })}
      onSubmit={async (values, formikProps) => {
        try {
          await onSearch(values);
        } catch {
          formikProps.setSubmitting(false);
        }
      }}
    >
      <PolicySearchInner {...props} />
    </Formik>
  );
}

PolicySearch.propTypes = {
  constSearchValues: PropTypes.object,
  onSearch: PropTypes.func.isRequired,
  onSearchByAddress: PropTypes.func.isRequired,
  policySearchConfig: PropTypes.object.isRequired,
  onCouldntFindPolicy: PropTypes.func,
  policyTypes: PropTypes.array.isRequired,
  disabled: PropTypes.bool,
  errorMsg: PropTypes.string,
  isSearchInProgress: PropTypes.bool,
  hideSubOrg: PropTypes.bool,
  hideDateOfLoss: PropTypes.bool,
  hideLob: PropTypes.bool,
  newDesign: PropTypes.bool,
  onSetManualPolicy: PropTypes.func,
};

PolicySearch.defaultProps = {
  constSearchValues: {},
};

function policyTypeToDisplay(policyType, lobConfigurationsDict) {
  const lob = policyType.replace('_policy', '_claim');
  return getLobDescription(lob, lobConfigurationsDict);
}

export function PolicySearchResults(props) {
  const {
    classes,
    searchPoliciesResults,
    onCancel,
    onSelectPolicy,
    policyTypes,
    selectButtonText,
    disableSelectButton,
    isDialog = true,
    noCardTitle,
  } = props;

  const [isPolicySelectInProgress, setIsPolicySelectInProgress] = React.useState(false);
  const { userOrganization } = useCms();

  const showMultipleTypes = policyTypes.length > 1;
  const { policySearchConfig } = useOrganization();
  const { lobConfigurationsDict } = useLobConfiguration();
  const policyApiConfiguration = policySearchConfig.policy_api_configuration;
  const isCommercialPoliciesEnabled = isFeatureEnabled(
    userOrganization,
    CONFIGURATION_FEATURES_NAMES.COMMERCIAL_POLICIES
  );

  async function handleSelectPolicy(row) {
    setIsPolicySelectInProgress(true);
    await onSelectPolicy(row);
    setIsPolicySelectInProgress(false);
  }

  function getPolicyColumnData(policyType) {
    let columns = [
      { id: 'policy_number', numeric: false, label: 'Policy #', nowrap: true },
      {
        id: 'effective_time',
        numeric: false,
        label: 'Effective Date',
        specialCell: (row) => isoDateToUs(row.policy_effective_datetime),
      },
      {
        id: 'expiration_time',
        numeric: false,
        label: 'Expiration Date',
        specialCell: (row) => isoDateToUs(row.policy_expiration_datetime),
      },
      {
        id: 'insured_contact_full_name',
        numeric: false,
        label: 'Insured Name',
        specialCell: (row) =>
          row.insured_contact_full_name || (row.insured_contact ? row.insured_contact.full_name : ''),
      },
    ];
    const lob = policyType.replace('_policy', '_claim');
    if (lobConfigurationsDict?.[lob]) {
      const coveredObjectDisplayName = lobConfigurationsDict[lob]?.covered_objects[0].desc;

      columns = columns.concat([
        {
          id: 'insured_phone_number',
          numeric: false,
          label: 'Phone #',
          specialCell: (row) => (row.insured_contact.phones.length > 0 ? row.insured_contact.phones[0].phone : ''),
        },
        { id: 'insured_address', numeric: false, label: 'Insured Address' },
        {
          id: 'covered_object_name_chips',
          numeric: false,
          label: coveredObjectDisplayName,
          isHidden: !isCommercialPoliciesEnabled,
          specialCell: (row) => (
            <div>
              <OverflowArrayTextDisplayWithTooltip
                value={row?.display_names?.slice(0, 5)?.map((coveredObject) => coveredObject?.display_name)}
                renderItem={(label) => (
                  <span key={label}>
                    <Chip label={label} size="small" className="mr-12" />
                  </span>
                )}
              />
            </div>
          ),
          disableSort: true,
        },
        {
          id: 'covered_object_name',
          numeric: false,
          label: coveredObjectDisplayName,
          expandable: true,
          specialCell: (row) => {
            if (!isCommercialPoliciesEnabled) {
              return (
                <>
                  {policyApiConfiguration?.enabled
                    ? row.display_names.map((coveredObject) => (
                        <div key={coveredObject.id}>{coveredObject.display_name}</div>
                      ))
                    : ''}
                </>
              );
            }
            const subColumns = [{ id: 'display_name', numeric: false, label: 'Name' }];
            if (row?.display_names?.[0]?.owner) {
              subColumns.push({ id: 'owner', numeric: false, label: 'Owner' });
            }
            const additionalFields = row?.display_names?.[0]?.additional_fields;
            if (additionalFields) {
              Object.keys(additionalFields)
                ?.slice(0, 3)
                ?.forEach((key) => {
                  subColumns.push({
                    id: key,
                    numeric: false,
                    label: capitalize(key)?.replaceAll('_', ' '),
                    specialCell: (row) => JSON.stringify(row?.[key]),
                  });
                });
            }

            const rows = row.display_names
              ?.slice(0, 5)
              ?.map((coveredObject) => ({ ...coveredObject, ...coveredObject?.additional_fields }));

            return (
              <div>
                {rows?.length >= 5 && (
                  <Text className="pb-20" variant={Text.VARIANTS.SM}>
                    Showing up to 5 results. Refine your search query to find other policies.
                  </Text>
                )}
                <PlainTable columns={subColumns} rows={rows} />
              </div>
            );
          },
        },
      ]);
    } else {
      switch (policyType) {
        case 'home_policy':
          columns = columns.concat([
            {
              id: 'insured_phone_number',
              numeric: false,
              label: 'Phone #',
              specialCell: (row) =>
                policyApiConfiguration?.enabled ? row.insured_contact.phones[0]?.phone : row.insured_phone_number,
            },
            {
              id: 'insured_property_full_address',
              numeric: false,
              label: 'Insured Property Address',
              specialCell: (row) =>
                policyApiConfiguration?.enabled ? (
                  row.display_names.map((home) => <div key={home.id}>{home.display_name}</div>)
                ) : (
                  <div key={row.id}>{row.insured_property_full_address}</div>
                ),
            },
          ]);
          break;
        case 'auto_policy':
          columns = columns.concat([
            {
              id: 'insured_phone_number',
              numeric: false,
              label: 'Phone #',
              specialCell: (row) => (row.insured_contact.phones.length > 0 ? row.insured_contact.phones[0].phone : ''),
            },
            { id: 'insured_address', numeric: false, label: 'Insured Address' },
            {
              id: 'vehicle_display_name_chips',
              label: 'Covered Vehicles',
              isHidden: !isCommercialPoliciesEnabled,
              specialCell: (row) => (
                <div>
                  <OverflowArrayTextDisplayWithTooltip
                    value={row?.display_names?.slice(0, 5)?.map((vehicle) => vehicle?.display_name)}
                    renderItem={(label) => (
                      <span key={label}>
                        <Chip label={label} size="small" className="mr-12" />
                      </span>
                    )}
                  />
                </div>
              ),
              disableSort: true,
            },
            {
              id: 'vehicle_display_name',
              numeric: false,
              label: 'Vehicles',
              expandable: isCommercialPoliciesEnabled,
              specialCell: (row) => {
                if (!isCommercialPoliciesEnabled) {
                  return (
                    <>
                      {policyApiConfiguration?.enabled
                        ? row.display_names.map((vehicle) => <div key={vehicle.id}>{vehicle.display_name}</div>)
                        : row.covered_vehicles.map((vehicle) => (
                            <div key={vehicle.id}>{vehicle.vehicle.display_name}</div>
                          ))}
                    </>
                  );
                }
                const subColumns = [
                  { id: 'year', numeric: false, label: 'Year' },
                  { id: 'make', numeric: false, label: 'Make' },
                  { id: 'model', numeric: false, label: 'Model' },
                  { id: 'series', numeric: false, label: 'Series' },
                  { id: 'vin', numeric: false, label: 'VIN' },
                  { id: 'plate_number', numeric: false, label: 'Plate Number' },
                  { id: 'vehicle_plate_state', numeric: false, label: 'Registration State' },
                  { id: 'vehicle_owner_contact_full_name', numeric: false, label: 'Owner' },
                ];

                const rows = (
                  policyApiConfiguration?.enabled ? row.display_names : row.covered_vehicles?.map((cv) => cv?.vehicle)
                )?.slice(0, 5);

                return (
                  <div>
                    {rows?.length >= 5 && (
                      <Text className="pb-20" variant={Text.VARIANTS.SM}>
                        Showing up to 5 results. Refine your search query to find other policies.
                      </Text>
                    )}
                    <PlainTable columns={subColumns} rows={rows} />
                  </div>
                );
              },
            },
          ]);
          break;
        case 'pet_policy':
          columns = columns.concat([
            {
              id: 'insured_phone_number',
              numeric: false,
              label: 'Phone #',
              specialCell: (row) => (row.insured_contact.phones.length > 0 ? row.insured_contact.phones[0].phone : ''),
            },
            { id: 'insured_address', numeric: false, label: 'Insured Address' },
            // eslint-disable-next-line react/jsx-key
            {
              id: 'pet_name',
              numeric: false,
              label: 'Pet Name',
              specialCell: (row) =>
                policyApiConfiguration?.enabled
                  ? row.display_names.map((coveredPet) => <div key={coveredPet.id}>{coveredPet.display_name}</div>)
                  : row.covered_pets.map(({ pet }) => <div key={pet.id}>{pet.display_name}</div>),
            },
          ]);
          break;
        default:
          break;
      }
    }

    // So these columns always appears last
    columns = columns.concat([
      {
        id: 'select_button',
        numeric: false,
        label: '',
        // eslint-disable-next-line react/display-name
        specialCell: (row) => (
          <FsButton
            color="primary"
            disabled={disableSelectButton || isPolicySelectInProgress}
            onClick={() => handleSelectPolicy(row)}
          >
            {selectButtonText || 'Select'}
          </FsButton>
        ),
      },
    ]);

    return columns;
  }

  return (
    <CardDialog
      title="Policies"
      isDialog={isDialog}
      noCardTitle={noCardTitle}
      onClose={onCancel}
      maxWidth={isCommercialPoliciesEnabled ? 'lg' : 'md'}
      fullWidth
      preventClose={isPolicySelectInProgress}
    >
      {policyTypes.map((policyType) => {
        const searchPolicyResultsForType = searchPoliciesResults[policyType];

        return (
          <React.Fragment key={policyType}>
            {searchPolicyResultsForType.length > 0 && (
              <Grid container spacing={spacing} style={{ marginTop: '8px' }}>
                <Grid item xs={12}>
                  {showMultipleTypes && (
                    <Typography display="block" variant="subtitle1" style={{ marginBottom: '6px' }}>
                      {policyTypeToDisplay(policyType, lobConfigurationsDict)}
                    </Typography>
                  )}
                  <PlainTable
                    classes={classes}
                    columns={getPolicyColumnData(policyType)}
                    rows={searchPolicyResultsForType}
                    shouldRenderExpandAsTable={isCommercialPoliciesEnabled}
                  />
                </Grid>
              </Grid>
            )}
            {searchPolicyResultsForType.length === 10 && (
              <Typography variant="subtitle2" style={{ marginBottom: '6px' }}>
                Showing only the first 10 results. Refine your search query to find other policies
              </Typography>
            )}
          </React.Fragment>
        );
      })}
    </CardDialog>
  );
}

PolicySearchResults.propTypes = {
  classes: PropTypes.object.isRequired,
  searchPoliciesResults: PropTypes.object.isRequired,
  onSelectPolicy: PropTypes.func.isRequired,
  onCouldntFindPolicy: PropTypes.func,
  policyTypes: PropTypes.array.isRequired,
  selectButtonText: PropTypes.string,
  disableSelectButton: PropTypes.bool,
  onCancel: PropTypes.func,
  isDialog: PropTypes.bool,
  noCardTitle: PropTypes.bool,
};

function SearchByAddressDialog(props) {
  const { policySearchConfig, onSearch, onClose } = props;
  const classes = useStyles();

  const { defaultCountry } = useGlobalAddresses({ countriesConfigurationKey: 'loss_location' });

  const shouldAskDateOfLoss = policySearchConfig.should_ask_date_of_loss;
  return (
    <Formik
      initialValues={{
        ...getLocationFields(defaultCountry),
      }}
      validationSchema={Yup.object().shape({
        address1: Yup.string().required('Required'),
        date_of_loss: shouldAskDateOfLoss ? Yup.date().required('Required') : undefined,
      })}
      onSubmit={async (values, formikProps) => {
        try {
          await onSearch(values);
          await onClose(values);
        } catch {
          formikProps.setSubmitting(false);
        }
      }}
      enableReinitialize
    >
      {(formikProps) => (
        <CardDialog title="Search Policy by Address" isDialog={true} onClose={onClose} maxWidth="sm" fullWidth>
          {shouldAskDateOfLoss && (
            <DatePickerTextFieldFormik
              id="date_of_loss"
              label="Date of Loss"
              disabled={formikProps.isSubmitting}
              className={classes.textField}
              fullWidth
              disableFuture
            />
          )}
          <LocationFormikInner
            classes={classes}
            submitText="Search"
            onCancel={onClose}
            {...formikProps}
            countriesConfigurationKey="loss_location"
          />
        </CardDialog>
      )}
    </Formik>
  );
}

SearchByAddressDialog.propTypes = {
  policySearchConfig: PropTypes.object.isRequired,
  onSearch: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
};

const POLICY_SEARCH_INITIAL_VALUES = { search: '', date_of_loss: '', reported_date: '' };

function PolicySearchContainer(props) {
  const {
    enableSelectingRelatedClaim,
    constSearchValues,
    onSelectPolicy,
    onPolicyNotFound,
    disabled,
    policyTypes,
    selectButtonText,
    disableSelectButton,
    choosePolicyFromRelatedClaimsText,
    selectRelatedClaimComponent,
    onFnolDraftSelected,
    allowSearchByAddress,
    hideSubOrg = false,
    hideDateOfLoss = false,
    hideLob = false,
    newDesign = false,
    onSetManualPolicy,
  } = props;
  const isMounted = useRef();
  const { userOrganization } = useCms();

  useEffect(() => {
    isMounted.current = true;
    return () => (isMounted.current = false);
  }, []);

  const initialState = {
    isSearchInProgress: false,
    searchResultsDict: undefined,
    searchValues: POLICY_SEARCH_INITIAL_VALUES,
  };

  const [searchResultsState, dispatch] = useReducer(reducer, initialState);
  const { isSearchInProgress, searchResultsDict, searchValues } = searchResultsState;
  const [showSearchByAddressDialog, setShowSearchByAddressDialog] = useState(false);
  const [relatedClaims, setRelatedClaims] = useState([]);
  const [relatedDrafts, setRelatedDrafts] = useState(undefined);
  const [currChosenPolicy, setCurrChosenPolicy] = useState(undefined);
  const { policySearchConfig, subOrganizationEnabled, isPolicyAPIEnabled } = useOrganization();
  const [searchResultsLob, setSearchResultsLob] = useState([]);
  const classes = useStyles();
  const isCommercialPoliciesEnabled = isFeatureEnabled(
    userOrganization,
    CONFIGURATION_FEATURES_NAMES.COMMERCIAL_POLICIES
  );
  const isConfigurableFnolEnabled = isFeatureEnabled(userOrganization, CONFIGURATION_FEATURES_NAMES.CONFIGURABLE_FNOL);

  function reducer(state, action) {
    switch (action.type) {
      case 'start_searching':
        return { ...state, isSearchInProgress: true, searchValues: action.searchValues };
      case 'set_results':
        return { ...state, searchResultsDict: { ...state.searchResultsDict, [action.policyType]: action.results } };
      case 'search_finished':
        return { ...state, isSearchInProgress: false };
      case 'clean_search':
        return initialState;
      default:
        throw Error(`Dispatch unknown action type: ${action.type}`);
    }
  }

  const searchPolicies = async (url, params, policyType) => {
    try {
      const sendParmas = {
        ...params,
        lob: policyType,
      };
      const res = await axios.get(url, { params: sendParmas });
      const searchPoliciesResults = res.data.map((policy) => policyPopulate(policy));
      dispatch({ type: 'set_results', policyType, results: searchPoliciesResults });
    } catch (error) {
      dispatch({ type: 'set_results', policyType, results: [] });
      reportAxiosError(error);
    }
  };

  const searchDrafts = async (chosendPolicy) => {
    try {
      if (isPolicyAPIEnabled) {
        const res = await axios.get('/api/v1/fnol_drafts', {
          params: {
            policy_number: chosendPolicy.policy_number,
            date_of_loss: searchValues.date_of_loss,
            policy_lob: chosendPolicy.lob,
            sub_organization_id: subOrganizationEnabled ? chosendPolicy.sub_organization.id : undefined,
          },
        });
        return res.data;
      } else {
        const res = await axios.get(`/api/v1/claims/fnol/drafts/${chosendPolicy.id}`);
        if (res.data.id) {
          return [res.data];
        }
        return undefined;
      }
    } catch (error) {
      reportAxiosError(error);
    }
  };

  const reloadDrafts = async () => {
    let relatedDrafts = await searchDrafts(currChosenPolicy);
    setRelatedDrafts(relatedDrafts);
  };

  const handleSearch = async (searchValues) => {
    dispatch({ type: 'start_searching', searchValues });
    const chosenPolicyTypes = searchValues['lob'] ? [searchValues['lob'].replace('_claim', '_policy')] : policyTypes;
    const searchPolicyPromises = chosenPolicyTypes.map((policyType) => {
      let url = `/api/v1/${policyType.replace('_policy', '_policies')}`;
      if (isPolicyAPIEnabled) {
        url = '/api/v1/policies/get_policies_by_api';
      }
      return searchPolicies(url, searchValues, policyType);
    });
    await Promise.all(searchPolicyPromises);

    setSearchResultsLob(chosenPolicyTypes);
    dispatch({ type: 'search_finished' });
  };

  const handleSearchByAddress = async (searchValues) => {
    dispatch({ type: 'start_searching', searchValues });

    const searchPolicyPromises = policyTypes.map((policyType) => {
      searchPolicies(
        `/api/v1/${policyType.replace('_policy', '_policies')}/search_by_address`,
        { ...searchValues },
        policyType
      );
    });
    await Promise.all(searchPolicyPromises);

    dispatch({ type: 'search_finished' });
  };

  const handlePolicySelected = async (chosenPolicy) => {
    let chosenPolicyData = null;
    // at this point it's either actual policies or only a search result
    if (chosenPolicy.search_type) {
      if (chosenPolicy.search_type === 'DB') {
        try {
          const policyFetchRes = await axios.get(`/api/v1/policies/${chosenPolicy.id}`);
          chosenPolicyData = policyFetchRes.data;
        } catch (error) {
          reportAxiosError(error);
          return;
        }
      } else if (chosenPolicy.search_type === 'API') {
        try {
          const policyFetchRes = await axios.post('/api/v1/policies/retrieve_policy_by_api', chosenPolicy);
          chosenPolicyData = policyFetchRes.data;
        } catch (error) {
          reportAxiosError(error);
          return;
        }
      } else {
        reportErrorInProductionOrThrow(`${chosenPolicy.search_type} is not a supported policy search type`);
      }
    } else {
      chosenPolicyData = chosenPolicy;
    }

    const subOrgId = userOrganization.sub_organizations_enabled ? chosenPolicyData?.sub_organization_id : null;
    if (subOrgId) {
      const subOrg = userOrganization.sub_organizations.find((sub_org) => sub_org.id === subOrgId);
      chosenPolicyData.sub_organization = subOrg;
    }

    if (enableSelectingRelatedClaim) {
      try {
        const claimsRes = await axios.get('/api/v1/claims', {
          params: {
            policy_id: chosenPolicyData.id,
            with_first_party_involved_entities: isConfigurableFnolEnabled && isCommercialPoliciesEnabled,
          },
        });
        const relatedClaims = claimsRes.data.claims;
        let relatedDrafts = await searchDrafts(chosenPolicyData);

        if (relatedClaims.length === 0 && !(relatedDrafts && relatedDrafts.length > 0)) {
          await handlePolicyFinallyChosen(chosenPolicyData);
          return;
        }

        setRelatedDrafts(relatedDrafts);
        setRelatedClaims(relatedClaims);
        setCurrChosenPolicy(chosenPolicyData);
      } catch (error) {
        reportAxiosError(error);
      }
    } else {
      await handlePolicyFinallyChosen(chosenPolicyData);
    }
  };

  const handlePolicyFinallyChosen = async (chosenPolicyData) => {
    await onSelectPolicy(chosenPolicyData, {
      date_of_loss: searchValues?.date_of_loss,
      reported_date: searchValues?.reported_date,
    });
    if (isMounted.current) {
      dispatch({ type: 'clean_search' });
    }
  };

  const handleCancelOrClaimLinkClick = () => {
    setRelatedClaims([]);
    setRelatedDrafts(undefined);
    setCurrChosenPolicy(undefined);
  };

  return (
    <>
      <PolicySearch
        onSearch={handleSearch}
        constSearchValues={constSearchValues}
        onSearchByAddress={allowSearchByAddress ? () => setShowSearchByAddressDialog(true) : undefined}
        policySearchConfig={policySearchConfig}
        onCouldntFindPolicy={onPolicyNotFound}
        policyTypes={policyTypes}
        isSearchInProgress={isSearchInProgress}
        errorMsg={
          (!isSearchInProgress &&
            searchResultsDict &&
            Object.values(searchResultsDict).every((res) => res.length === 0) &&
            'No Policies Found. Please make sure the policy number and the DOL are correct') ||
          ''
        }
        disabled={disabled}
        hideSubOrg={hideSubOrg}
        hideDateOfLoss={hideDateOfLoss}
        hideLob={hideLob}
        newDesign={newDesign}
        onSetManualPolicy={onSetManualPolicy}
      />
      {!isSearchInProgress && searchResultsDict && Object.values(searchResultsDict).some((res) => res.length > 0) && (
        <PolicySearchResults
          classes={classes}
          searchPoliciesResults={searchResultsDict}
          onCancel={() => dispatch({ type: 'clean_search' })}
          onSelectPolicy={handlePolicySelected}
          onCouldntFindPolicy={onPolicyNotFound}
          policyTypes={searchResultsLob}
          selectButtonText={selectButtonText}
          disableSelectButton={disableSelectButton}
        />
      )}
      {enableSelectingRelatedClaim &&
        ((relatedClaims && relatedClaims.length > 0) || (relatedDrafts && relatedDrafts.length > 0)) && (
          <ClaimSearchResults
            additionalComponent={
              <>
                {onFnolDraftSelected && (
                  <div className={classes.cardDivRowInternal}>
                    {relatedDrafts && relatedDrafts.length > 0 ? (
                      <CardDialog title="Saved Drafts">
                        <FnolDraftsTable
                          drafts={relatedDrafts}
                          onSelectDraft={async (draft) => await onFnolDraftSelected(draft)}
                          onDraftDiscarded={reloadDrafts}
                        />
                      </CardDialog>
                    ) : (
                      <Typography>No related FNOL drafts found </Typography>
                    )}
                  </div>
                )}
                <div className={classes.buttonsContainer}>
                  <Button
                    onClick={() => handlePolicyFinallyChosen(currChosenPolicy)}
                    variant="contained"
                    color="primary"
                  >
                    {choosePolicyFromRelatedClaimsText}
                  </Button>
                </div>
              </>
            }
            preResultsTableComponent={
              relatedClaims.length === CONSTANTS.DEFAULT_CLAIMS_PER_PAGE && (
                <div className={classes.cardDivRow}>
                  <span
                    className={classes.textFieldRow}
                  >{`Showing only the first ${CONSTANTS.DEFAULT_CLAIMS_PER_PAGE} results. Refine your search query to find other claims or use the All Claims view`}</span>
                </div>
              )
            }
            classes={classes}
            title="Existing claims for selected policy"
            searchResults={relatedClaims}
            SelectComponent={selectRelatedClaimComponent}
            onCancel={handleCancelOrClaimLinkClick}
            onClaimLinkClick={handleCancelOrClaimLinkClick}
            hideClaimType
          />
        )}
      {showSearchByAddressDialog && (
        <SearchByAddressDialog
          policySearchConfig={policySearchConfig}
          onSearch={handleSearchByAddress}
          onClose={() => setShowSearchByAddressDialog(false)}
        />
      )}
    </>
  );
}

PolicySearchContainer.propTypes = {
  constSearchValues: PropTypes.object,
  onSelectPolicy: PropTypes.func.isRequired,
  enableSelectingRelatedClaim: PropTypes.bool,
  choosePolicyFromRelatedClaimsText: requiredIf(PropTypes.string, (props) => props.enableSelectingRelatedClaim),
  selectRelatedClaimComponent: PropTypes.func,
  onPolicyNotFound: PropTypes.func,
  disabled: PropTypes.bool,
  policyTypes: PropTypes.array.isRequired,
  selectButtonText: PropTypes.string,
  disableSelectButton: PropTypes.bool,
  onFnolDraftSelected: requiredIf(PropTypes.func, (props) => props.enableSelectingRelatedClaim),
  allowSearchByAddress: PropTypes.bool,
  hideSubOrg: PropTypes.bool,
  hideDateOfLoss: PropTypes.bool,
  hideLob: PropTypes.bool,
  newDesign: PropTypes.bool,
  onSetManualPolicy: PropTypes.bool,
};

export default PolicySearchContainer;
