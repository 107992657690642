import React, { useState } from 'react';
import { Container } from '@material-ui/core';
import axios from 'axios';

import ConfigPanel from '~/components/core/ConfigPanel/ConfigPanel';
import LoadingSwitch from '~/components/core/Loading/LoadingSwitch';
import ConfigurableOption from '~/components/core/Molecules/ConfigurableOption';
import Heading from '~/components/core/TextComponents/Heading';
import Text from '~/components/core/TextComponents/Text';
import { useCms } from '~/components/hooks/useCms';
import DyteBackgroundImageGallery from '~/components/SystemConfiguration/Communications/DyteVideoConfigurationTab/DyteBackgroundImageGallery';
import type { HandleUpdateDyteConfiguration } from '~/components/SystemConfiguration/Communications/DyteVideoConfigurationTab/types';
import VideoGenericTemplatesConfiguration from '~/components/SystemConfiguration/Communications/DyteVideoConfigurationTab/VideoGenericTemplatesConfiguration';
import { useSysconfig } from '~/components/SystemConfiguration/SystemConfigurationScreen';
import useDataFetcher from '~/components/useDataFetcher';
import { reportAxiosError } from '~/Utils';

import CardDialog from '../../../CardDialog';

const FIELD_IDS = {
  DYTE_INTEGRATION_ENABLED: 'is_integration_enabled',
  AUTO_RECORD_VIDEO_CALLS: 'is_automatic_recording_enabled',
};

const DyteVideoConfigurationTab: React.FC = () => {
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  const { organization } = useSysconfig();
  const { userOrganization } = useCms();

  const [isUpdatingDyteConfiguration, setIsUpdatingDyteConfiguration] = useState(false);

  const { data, isLoading, isError, reloadData } = useDataFetcher(
    `/api/v1/video_calls_dyte_configuration/organizations/${organization.id}/dyte_configuration`
  );

  const backgroundImages = data?.background_images || [];

  const handleUpdateConfiguration: HandleUpdateDyteConfiguration = async (values, throwOnError = true) => {
    try {
      setIsUpdatingDyteConfiguration(true);
      await axios.patch(`/api/v1/video_calls_dyte_configuration/organizations/${organization.id}/update`, values);
      await reloadData();
    } catch (error) {
      reportAxiosError(error);
      if (throwOnError) {
        throw error;
      }
    } finally {
      setIsUpdatingDyteConfiguration(false);
    }
  };

  const configurationOptions = [
    ...(userOrganization.is_super_organization
      ? [
          {
            isEnabled: data?.[FIELD_IDS.DYTE_INTEGRATION_ENABLED],
            onChangeToggle: async (newValue: boolean) =>
              await handleUpdateConfiguration({ [FIELD_IDS.DYTE_INTEGRATION_ENABLED]: newValue }, false),
            configurationView: <Heading variant={Heading.TYPES.H3}>Enable Video Calls</Heading>,
          },
        ]
      : []),
    {
      isEnabled: data?.[FIELD_IDS.AUTO_RECORD_VIDEO_CALLS],
      onChangeToggle: async (newValue: boolean) =>
        await handleUpdateConfiguration({ [FIELD_IDS.AUTO_RECORD_VIDEO_CALLS]: newValue }, false),
      configurationView: (
        <>
          <Heading variant={Heading.TYPES.H3}>Enable automatic video call recording</Heading>
          <Text variant={Text.VARIANTS.XS} colorVariant={Text.COLOR_VARIANTS.PRIMARY} className="opacity-50">
            Automatically start recording when starting a video call
          </Text>
        </>
      ),
    },
  ];

  return (
    <div className="bg-slate-100 pt-32">
      <Container maxWidth="md">
        <CardDialog width="md" noCardTitle>
          <LoadingSwitch isError={isError} isLoading={isLoading}>
            {configurationOptions.map(({ isEnabled, onChangeToggle, configurationView }, idx) => (
              <ConfigurableOption
                key={idx}
                isEnabled={isEnabled}
                onChangeToggle={onChangeToggle}
                configurationView={configurationView}
                showOnly={isUpdatingDyteConfiguration}
              />
            ))}
            <ConfigPanel
              label={<DyteBackgroundImageGallery backgroundImages={backgroundImages} reloadData={reloadData} />}
            />
            <ConfigPanel
              label={
                <VideoGenericTemplatesConfiguration
                  emailTitleTemplate={data?.email_title_template || ''}
                  emailBodyTemplate={data?.email_body_template || ''}
                  smsTemplate={data?.sms_template || ''}
                  handleUpdateConfiguration={handleUpdateConfiguration}
                />
              }
            />
          </LoadingSwitch>
        </CardDialog>
      </Container>
    </div>
  );
};

export default DyteVideoConfigurationTab;
