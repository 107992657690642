import React from 'react';

import { SortableTable } from '~/components/core';
import Switch from '~/components/core/Atomic/Switch';
import SkeletonTable from '~/components/core/Skeletons/SkeletonTable';
import { PencilIcon } from '~/components/icons';
import InlineIconButton from '~/components/InlineIconButton';
import ColoredDot from '~/components/SystemConfiguration/NotificationsConfiguration/ColoredDot';
import type { CategoryModel } from '~/components/SystemConfiguration/NotificationsConfiguration/NotificationsCategories';

interface CategoriesTableProps {
  categories: CategoryModel[];
  isLoading: boolean;
  isError: boolean;
  isUpdating: boolean;
  handleEditClicked: (category: CategoryModel) => void;
  handleToggleDisabled: (categoryId: number, isDisabled: boolean) => Promise<void>;
}

const CategoriesTable: React.FC<CategoriesTableProps> = ({
  categories,
  handleEditClicked,
  handleToggleDisabled,
  isError,
  isLoading,
  isUpdating,
}) => {
  const columns = [
    {
      id: 'color',
      label: 'Color',
      disableSort: true,
      specialCell: (category: CategoryModel) => <ColoredDot color={category.color} />,
    },
    {
      id: 'key',
      label: 'Key',
    },
    {
      id: 'label',
      label: 'Label',
    },
    {
      id: 'is_disabled',
      label: 'Enabled',
      specialCell: (category: CategoryModel) => (
        <Switch
          key={category.key}
          checked={!category.is_disabled}
          onChange={() => handleToggleDisabled(category.id, !category.is_disabled)}
          disabled={isLoading || isUpdating}
        />
      ),
    },
    {
      id: 'actions',
      label: 'Actions',
      disableSort: true,
      specialCell: (category: CategoryModel) => (
        <div>
          <InlineIconButton
            icon={PencilIcon}
            className="m-4 cursor-pointer hover:stroke-teal-700"
            onClick={() => handleEditClicked(category)}
            tooltipTitle="Edit"
            wrapWithSpan
          />
        </div>
      ),
    },
  ];

  return (
    <>
      {categories && <SortableTable columns={columns} rows={categories} />}
      {(isError || isLoading) && <SkeletonTable rowsCount={4} columnsCount={10} isError={isError} />}
    </>
  );
};

export default CategoriesTable;
