import React from 'react';

import type { ActionsPanelProps } from '~/components/AiChat/SideBarDialog/Actions/Action/ActionTypes';
import { PaymentTestResult } from '~/components/AiChat/SideBarDialog/Actions/Action/Payment/PaymentTestResult';
import { RoundedPanel } from '~/components/AiChat/SideBarDialog/components/RoundedPanel/RoundedPanel';
import type { PaymentTaskData } from '~/components/AiChat/types';
import { AI_ACTION_TYPES } from '~/components/AiChat/types';
import { reportErrorInProductionOrThrow } from '~/Utils';

export const PaymentActionInner: React.FC<ActionsPanelProps> = ({ action }) => {
  if (!action?.task_data || action?.task_data?.task_type !== AI_ACTION_TYPES.PAYMENT) {
    reportErrorInProductionOrThrow(new Error('Invalid action task data for Payment'));
  }

  const taskData = action.task_data as PaymentTaskData;

  const { payment_tests } = taskData;

  return (
    <RoundedPanel variant="clear">
      {payment_tests ? (
        <div className="m-12 flex flex-col gap-12">
          {payment_tests.map((testResult, index) => (
            <PaymentTestResult key={index} testDescription={testResult.test_description} />
          ))}
        </div>
      ) : null}
    </RoundedPanel>
  );
};
