import type { ComponentType } from 'react';
import React, { useContext } from 'react';

import type { CallInProgressType } from '~/components/types/call-in-progress-types';
import type { OrganizationModel } from '~/components/types/organization-types';
import type { UserProfileModel } from '~/components/types/user-profile-types';
import type { UserModel } from '~/components/types/user-types';

export type CmsContextType = {
  user: UserModel;
  userOrganization: OrganizationModel;
  userProfile: UserProfileModel;
  userReLogin: () => void;
  userLogout: () => void;
  callInProgress?: CallInProgressType;
  setCallInProgress: (value: boolean) => void;
  userReloadTwilioDetails: () => void;
  twilioWorkspaceDetails: {
    status: string;
    possible_queues: string[];
    active_queues: string[];
    possible_statuses: string[];
    is_queues_enabled: boolean;
  };
  pageTitle: string;
  setPageTitle: (value: string) => void;
};

export const CmsContext = React.createContext<CmsContextType>({} as CmsContextType);

export const withCmsContext = <P extends Record<string, unknown>>(
  WrappedComponent: ComponentType<P>
): ComponentType<P> => {
  class WithCmsContext extends React.Component<P> {
    static displayName = `withCmsContext(${getDisplayName(WrappedComponent)})`;

    render() {
      return (
        <CmsContext.Consumer>
          {(cmsContext) => <WrappedComponent {...cmsContext} {...this.props} />}
        </CmsContext.Consumer>
      );
    }
  }

  return WithCmsContext;
};

export const useCms = (): CmsContextType => {
  return useContext(CmsContext);
};

const getDisplayName = <P,>(WrappedComponent: ComponentType<P>): string => {
  return WrappedComponent.displayName || WrappedComponent.name || 'Component';
};
