import React from 'react';

import Button from '~/components/core/Atomic/Buttons/Button';
import IconButton from '~/components/core/Atomic/Buttons/IconButton';
import Tooltip from '~/components/core/Atomic/Tooltip';
import { EyeIcon, PencilIcon } from '~/components/icons';
import colors from '~/theme/tailwind/colors';
import cn from '~/Utils/cn';

interface GenericTemplateCardButtonsContainerProps {
  handleEditClick: () => void;
  handlePreviewClick: () => void;
}
const GenericTemplateCardButtonsContainer: React.FC<GenericTemplateCardButtonsContainerProps> = ({
  handleEditClick,
  handlePreviewClick,
}) => {
  // Generate example is only available in the external admin
  const isInternalAdminRoute = location.pathname.startsWith('/admin');

  return (
    <div className="m-12 flex items-center justify-between">
      <div>
        <Tooltip title="Generate example is only available in the external admin" disabled={!isInternalAdminRoute}>
          <div>
            <Button color="primary" disabled={isInternalAdminRoute} onClick={() => handlePreviewClick()}>
              <EyeIcon
                iconColor={!isInternalAdminRoute ? colors.teal['700'] : undefined}
                className={cn('mr-4', { 'opacity-40': isInternalAdminRoute })}
              />
              Generate example
            </Button>
          </div>
        </Tooltip>
      </div>
      <div>
        <Tooltip title="Edit">
          <IconButton onClick={() => handleEditClick()} className="p-12 hover:bg-transparent">
            <PencilIcon className="ml-1 cursor-pointer hover:stroke-teal-700" />
          </IconButton>
        </Tooltip>
      </div>
    </div>
  );
};

export default GenericTemplateCardButtonsContainer;
