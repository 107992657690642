import type { ReactElement } from 'react';
import React from 'react';

import { serverDateToLocal } from '~/DateTimeUtils';
import { reportErrorInProductionOrThrow } from '~/Utils';

import type { DocumentApiResponse, LegalActionLogApiResponse, NegotiationLogApiResponse } from '../types';

type DateCellProperty =
  | 'due_date'
  | 'date'
  | 'created_at'
  | 'document_date'
  | 'document_date_received'
  | 'datetime_updated';
export const DateCell = ({
  log,
  property,
}: {
  log: NegotiationLogApiResponse | DocumentApiResponse | LegalActionLogApiResponse;
  property: DateCellProperty;
}): ReactElement => {
  // Narrow log type and property type together

  if ('due_date' in log && (property === 'created_at' || property === 'due_date' || property === 'date')) {
    const value = log[property];
    return <div>{value ? serverDateToLocal(value) : ''}</div>;
  }

  if ('adverse_party_demand' in log && (property === 'created_at' || property === 'date')) {
    return <div>{log[property] ? serverDateToLocal(log[property]) : ''}</div>;
  }

  if (
    'document_date' in log &&
    (property === 'document_date' || property === 'document_date_received' || property === 'datetime_updated')
  ) {
    return <div>{log[property] ? serverDateToLocal(log[property]) : ''}</div>;
  }

  reportErrorInProductionOrThrow(`Invalid log or property log id: ${log.id}, property: ${property}`);
  return <div />;
};
