import React, { createContext, useCallback, useContext, useState } from 'react';
import { noop } from 'lodash';

import useInterval from '~/components/hooks/useInterval';

import type { PartialCallConnectionProps, PartialCommunicationProps, PartialConnectionProps } from '../types';

export interface CommunicationCallContextType {
  isMuted: boolean;
  isCallActive: boolean;
  callDurationSec: number;
  communication: PartialCommunicationProps;
  callStatus?: string;
  callConnection?: PartialCallConnectionProps;
  currentlyClickedDigits: string;

  updateMute: (isMute: boolean) => void;
  updateCallConnection: (connection: PartialConnectionProps) => void;
  updateDisconnectOrCancel: (connection: PartialConnectionProps) => void;
  updateCommunication: (communication: PartialCommunicationProps) => void;
  handleSendDigit: (digit: string) => void;
  handleHangup: () => void;
}

export const CommunicationCallContext = createContext<CommunicationCallContextType>({
  isMuted: false,
  isCallActive: false,
  callDurationSec: 0,
  updateMute: noop,
  communication: {},
  updateCallConnection: noop,
  updateDisconnectOrCancel: noop,
  updateCommunication: noop,
  handleSendDigit: noop,
  handleHangup: noop,
  currentlyClickedDigits: '',
});

export const CommunicationCallProvider: React.FC = ({ children }) => {
  const [callDurationSec, setCallDurationSec] = useState(0);
  const [isMuted, setIsMuted] = useState(false);
  const [communication, setCommunication] = useState<PartialCommunicationProps>({});
  const [callStatus, setCallStatus] = useState();
  const [callConnection, setCallConnection] = useState<PartialCallConnectionProps>();
  const [currentlyClickedDigits, setCurrentlyClickedDigits] = useState('');

  const isCallActive = callStatus === 'open';
  useInterval(() => setCallDurationSec(callDurationSec + 1), isCallActive ? 1000 : null);

  const updateCallConnection = useCallback((connection) => {
    setCallStatus(connection.status());
    setCallConnection(connection);
    setIsMuted(connection.isMuted());
  }, []);

  const updateDisconnectOrCancel = useCallback((connection) => {
    setCallStatus(connection.status());
    setCallConnection(undefined);
  }, []);

  const updateMute = useCallback(
    (shouldMute) => {
      callConnection && callConnection.mute(shouldMute);
      setIsMuted(callConnection?.isMuted() || shouldMute);
    },
    [callConnection]
  );

  const handleSendDigit = (digit: string) => {
    setCurrentlyClickedDigits((current) => current + digit);
    callConnection && callConnection.sendDigits(digit);
  };

  const handleHangup = () => {
    callConnection && callConnection.disconnect();
  };

  return (
    <CommunicationCallContext.Provider
      value={{
        isMuted,
        isCallActive,
        currentlyClickedDigits,
        callDurationSec,
        communication,
        callStatus,
        callConnection,
        updateMute,
        updateCallConnection,
        updateDisconnectOrCancel,
        handleSendDigit,
        handleHangup,
        updateCommunication: setCommunication,
      }}
    >
      {children}
    </CommunicationCallContext.Provider>
  );
};

export const useCommunicationCallContext = (): CommunicationCallContextType => {
  return useContext(CommunicationCallContext);
};
