import React, { useState } from 'react';

import colors from '~/assets/colors.module.scss';
import Button from '~/components/core/Atomic/Buttons/Button';
import { AddIcon } from '~/components/deprecatedMuiIcons';
import { DownloadIcon, UploadIcon } from '~/components/icons';
import ImportDocumentTemplatesZip from '~/components/SystemConfiguration/OrganizationDocumentsTemplates/ImportDocumentTemplatesZip';
import {
  AddDocumentTemplate,
  ExportDocumentTemplates,
} from '~/components/SystemConfiguration/OrganizationDocumentsTemplates/OrganizationDocumentsTemplates';

import type { TemplateMetadata } from '../../types';

interface DocumentsTemplatesActionsProps {
  organizationId: number;
  reloadDataDocumentTemplates: () => void;
  documentTemplates: TemplateMetadata[];
}

const DocumentsTemplatesActions: React.FC<DocumentsTemplatesActionsProps> = ({
  organizationId,
  documentTemplates,
  reloadDataDocumentTemplates,
}) => {
  const [newDocumentOpen, setNewDocumentOpen] = useState(false);
  const [exportOpen, setExportOpen] = useState(false);
  const [importZipOpen, setImportZipOpen] = useState(false);

  return (
    <>
      <Button color="primary" onClick={() => setNewDocumentOpen(true)}>
        <AddIcon />
        Add template
      </Button>
      <Button color="primary" onClick={() => setExportOpen(true)}>
        <DownloadIcon iconColor={colors.buttonLink} size={12} />
        <span className="ml-8">Export</span>
      </Button>
      <Button color="primary" onClick={() => setImportZipOpen(true)}>
        <UploadIcon iconColor={colors.buttonLink} size={12} />
        <span className="ml-8">Import</span>
      </Button>

      {exportOpen ? (
        <ExportDocumentTemplates onCancel={() => setExportOpen(false)} documentTemplates={documentTemplates} />
      ) : null}
      {importZipOpen && (
        <ImportDocumentTemplatesZip
          reloadDataDocumentTemplates={reloadDataDocumentTemplates}
          onCancel={() => {
            setImportZipOpen(false);
          }}
          documentTemplates={documentTemplates}
        />
      )}
      {newDocumentOpen && (
        <AddDocumentTemplate
          orgId={organizationId}
          onCancel={() => setNewDocumentOpen(false)}
          onDocumentUpload={async () => {
            await reloadDataDocumentTemplates();
            setNewDocumentOpen(false);
          }}
          documentTemplates={documentTemplates}
        />
      )}
    </>
  );
};

export default DocumentsTemplatesActions;
