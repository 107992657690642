import React, { useCallback, useState } from 'react';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import axios from 'axios';

import { AdjusterSelect } from '../Adjuster/AdjusterSelectTextFieldFormik';

import ClaimNotificationsCard from './ClaimNotificationsCard/ClaimNotificationsCard';
import { useCms } from './hooks/useCms';

import { useStyles } from '../assets/styles';

const UserClaimsNotifications = ({
  maxHeight,
  removeInsuredName,
  showOpenInFullScreen,
  showFiltering,
  defaultSortByDate,
  paginationLocation,
}) => {
  const { user } = useCms();
  const classes = useStyles();
  const [currentAdjuster, setCurrentAdjuster] = useState(user);

  const isAllowedForOtherUserNotifications =
    user.is_org_level_supervisor || user.is_manager || user.shared_notifications_user_ids.length > 0;

  let adjuster_id = undefined;
  if (user.is_org_level_supervisor || user.shared_notifications_user_ids.includes(currentAdjuster.id)) {
    adjuster_id = currentAdjuster.id;
  }

  const getClaimsNotifications = useCallback(
    async (
      should_return_dismissed,
      should_return_future_reminders,
      pageNumber,
      resultsPerPage,
      sortByColumn,
      typeSubtypeFilters
    ) => {
      const params = {
        adjuster_id,
        include_dismissed: should_return_dismissed ? 'only_dismissed' : 'not_dismissed',
        show_future_reminders: should_return_future_reminders ? true : undefined,
        include_removed_users: true,
        page_number: pageNumber ? pageNumber : undefined,
        results_per_page: resultsPerPage ? resultsPerPage : undefined,
        sort_by_column: sortByColumn ? sortByColumn : undefined,
        type_subtype_filters: typeSubtypeFilters ? typeSubtypeFilters : undefined,
      };
      const res = await axios.get('/api/v1/claims/claims_notifications', { params });

      return res.data;
    },
    [adjuster_id]
  );

  const actionComponent = showOpenInFullScreen ? (
    <Link to="/notifications" className={classes.primaryLink}>
      See all notifications
    </Link>
  ) : (
    <Link to="/" className={classes.primaryLink}>
      Back to homepage
    </Link>
  );

  return (
    <ClaimNotificationsCard
      getClaimNotifications={getClaimsNotifications}
      maxHeight={maxHeight}
      userContext
      AdditionalHeaderComponent={
        isAllowedForOtherUserNotifications && (
          <AdjusterSelect
            label="Showing notifications for"
            value={currentAdjuster}
            onChange={(_, adjuster) => setCurrentAdjuster(adjuster ? adjuster : user)}
            textFieldProps={{
              fullWidth: true,
            }}
            width={300}
            excludedAdjustersIds={[user.id]}
            extraValues={[user]}
            allowedAdjustersIds={user.is_org_level_supervisor ? undefined : user.shared_notifications_user_ids} // Supervisor can see everyone
          />
        )
      }
      showExposure
      showInsuredName={!removeInsuredName}
      showNotificationDate
      actionComponent={actionComponent}
      showFiltering={showFiltering}
      defaultSortByDate={defaultSortByDate}
      paginationLocation={paginationLocation}
    />
  );
};

UserClaimsNotifications.propTypes = {
  maxHeight: PropTypes.string,
  removeInsuredName: PropTypes.bool,
  showOpenInFullScreen: PropTypes.bool,
  showFiltering: PropTypes.bool,
  defaultSortByDate: PropTypes.bool,
  paginationLocation: PropTypes.string,
};

export default UserClaimsNotifications;
