import GenericTemplatesConfigurationScreen from '~/components/GenericTemplates/GenericTemplatesConfiguration/GenericTemplatesConfigurationScreen';
import StatReserveConfigurationPage from '~/components/SystemConfiguration/ClaimConfiguration/StatReserveConfiguration/StatReserveConfigurationPage';
import CliveConfiguration from '~/components/SystemConfiguration/CliveConfiguration';
import ContactRolesConfiguration from '~/components/SystemConfiguration/ContactRoles';
import NotesConfiguration from '~/components/SystemConfiguration/NotesConfiguration';
import { ADMIN_ROUTES } from '~/routesConstants';
import { CONFIGURATION_FEATURES_NAMES } from '~/Types';

import AutomaticRulesConfiguration from '../AutomaticRulesConfiguration';
import ClaimConfigurationPage from '../ClaimConfiguration/ClaimConfiguration';
import CommunicationConfigurations from '../Communications/CommunicationConfigurations';
import CorrespondenceTab from '../CorrespondenceTab';
import { ExposureConfigurationToolBackendWrapper } from '../ExposureConfiguration/ExposureConfigurationToolBackendWrapper';
import FeaturesTab from '../FeaturesTab';
import { InternalFnolConfiguration } from '../FnolConfiguration';
import IncidentConfiguration from '../IncidentConfiguration/IncidentConfiguration';
import IntegrationTab from '../Integrations/InternalAdmin';
import ITConfigurationTab from '../ITConfigurationTab';
import LoginConfiguration from '../LoginConfiguration';
import MoiConfig from '../MoiConfig/MoiConfig';
import OperationsTab from '../OperationsTab';
import OrganizationTab from '../OrganizationTab';
import { InternalPolicyConfiguration } from '../PolicyConfiguration';
import TableauAccounts from '../TableauAccounts';
import { AdjusterManagementTab } from '../Tabs/AdjusterManagement/AdjusterManagementTab';
import DeveloperToolsTab from '../Tabs/DeveloperTools/DeveloperToolsTab';
import PaymentsConfigurationTab from '../Tabs/PaymentsConfiguration/PaymentsConfigurationTab';
import { PermissionsProfilesPage } from '../Tabs/PermissionsProfiles/PermissionsProfilesPage';
import TriageAndReservesTab from '../TriageAndReservesTab';
import UsersManagementTab from '../Users/UsersManagementTab';
import Vendors from '../VendorsConfigurations/Vendors';

export const superOrgTabs = [
  { label: 'IT & Security', url: ADMIN_ROUTES.IT_SECURITY, component: ITConfigurationTab },
  { label: 'Permission Profiles', url: ADMIN_ROUTES.PERMISSION_PROFILES, component: PermissionsProfilesPage },
  { label: 'Developer Tools', url: ADMIN_ROUTES.DEVELOPER_TOOLS.MAIN, component: DeveloperToolsTab },
  { label: 'Features', url: ADMIN_ROUTES.FEATURES, component: FeaturesTab },
  { label: 'Organization', url: ADMIN_ROUTES.ORGANIZATION.MAIN, component: OrganizationTab },
  { label: 'Payments Configuration', url: ADMIN_ROUTES.PAYMENTS_CONFIGURATION, component: PaymentsConfigurationTab },
  { label: 'Triage & Reserves', url: ADMIN_ROUTES.TRIAGE_RESERVES, component: TriageAndReservesTab },
  { label: 'Correspondence', url: ADMIN_ROUTES.CORRESPONDENCE, component: CorrespondenceTab },
  { label: 'Integrations', url: ADMIN_ROUTES.INTEGRATIONS, component: IntegrationTab },
  { label: 'Vendors', url: ADMIN_ROUTES.VENDORS, component: Vendors },
  { label: 'Communications', url: ADMIN_ROUTES.COMMUNICATIONS, component: CommunicationConfigurations },
  {
    label: 'Automation Rules Configuration',
    url: ADMIN_ROUTES.AUTOMATION.MAIN,
    component: AutomaticRulesConfiguration,
  },
  { label: 'Login Configuration', url: ADMIN_ROUTES.LOGIN_CONFIGURATION, component: LoginConfiguration },
  { label: 'MOI Configuration', url: ADMIN_ROUTES.MOI_CONFIG, component: MoiConfig },
  {
    label: 'Coverage Configuration',
    url: ADMIN_ROUTES.EXPOSURE_CONFIG,
    component: ExposureConfigurationToolBackendWrapper,
  },
  { label: 'Incident Configuration', url: ADMIN_ROUTES.INCIDENT_CONFIG, component: IncidentConfiguration },
  { label: 'Claim Configuration', url: ADMIN_ROUTES.CLAIM_CONFIG.MAIN, component: ClaimConfigurationPage },
  {
    label: 'FNOL Configuration',
    url: ADMIN_ROUTES.FNOL_CONFIGURATION,
    component: InternalFnolConfiguration,
    featureFlag: CONFIGURATION_FEATURES_NAMES.CONFIGURABLE_FNOL,
  },
  {
    label: 'Policy Configuration',
    url: ADMIN_ROUTES.POLICY_CONFIGURATION,
    component: InternalPolicyConfiguration,
    featureFlag: CONFIGURATION_FEATURES_NAMES.CONFIGURABLE_FNOL,
  },
  { label: 'Tableau Accounts Manager', url: ADMIN_ROUTES.TABLEAU_ACCOUNTS, component: TableauAccounts },
  {
    label: 'Templates',
    url: ADMIN_ROUTES.GENERIC_TEMPLATES_CONFIGURATION,
    component: GenericTemplatesConfigurationScreen,
  },
  {
    label: 'Contact Roles',
    url: ADMIN_ROUTES.CONTACT_ROLES,
    component: ContactRolesConfiguration,
    featureFlag: CONFIGURATION_FEATURES_NAMES.CONTACT_ROLES_CONFIGURATION_SCREEN,
  },
  { label: 'Notes Configuration', url: ADMIN_ROUTES.NOTES_CONFIG, component: NotesConfiguration },
  {
    label: 'Reserve Configuration',
    url: ADMIN_ROUTES.STAT_RESERVES,
    component: StatReserveConfigurationPage,
    featureFlag: CONFIGURATION_FEATURES_NAMES.STAT_RESERVES,
  },
  {
    label: 'Clive Configuration',
    url: ADMIN_ROUTES.CLIVE_CONFIGURATION,
    component: CliveConfiguration,
  },
];

export const adminCustomerFacingTabs = [
  { label: 'User Management', url: 'user_management', component: UsersManagementTab },
  { label: 'Adjuster Management', url: 'adjuster_management', component: AdjusterManagementTab },
  { label: 'Operations', url: 'operations', component: OperationsTab },
];
