import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { getIn, useFormikContext } from 'formik';

import AutocompleteFormik from '~/components/AutocompleteFormik';
import Grid from '~/components/core/Atomic/Grid/Grid';
import MenuItem from '~/components/core/Atomic/MenuItem';
import Typography from '~/components/core/Atomic/Typography';
import CheckboxWithIcon from '~/components/core/CheckboxWithIcon';
import { FeatureDisabled, FeatureEnabled } from '~/components/core/FeatureFlagLayoutSwitch/FeatureFlagSwitch';
import { PropertyDetailsRow } from '~/components/core/NewDesignSystem/PropertyDetails/PropertyDetailsRow';
import { useLobConfiguration } from '~/components/hooks/useLobConfiguration';
import USER_LOCALES from '~/server_shared/generated-types/USER_LOCALES';

import { CONFIGURATION_FEATURES_NAMES } from '../../../../Types';
import { isFeatureEnabled } from '../../../../Utils';
import { getLobDescription, getLobIcon } from '../../../../Utils/lobUtils';
import { FsTooltip, MainCard, PropertyDetails } from '../../../core';
import { ErrorHelperTextFormik } from '../../../core/Formik/ErrorHelperTextFormik';
import HoverChangeField from '../../../HoverChangeField';
import WarningCircleIcon from '../../../icons/WarningCircle';
import useOrganization from '../../../OrganizationContext';
import TextFieldFormik, { ShowOnlyTextField } from '../../../TextFieldFormik';
import { useSysconfig } from '../../SystemConfigurationScreen';

import { SuperUserCheckbox, SystemConfigCheckbox } from './UserPermissionsSection/PermissionsCheckboxes';
import { getUserDefaultClaimsEmailUserName } from './UserDialogUtils';
import { UserPermissionsSection } from './UserPermissionsSection';

import { useStyles } from '../../../../assets/styles';

const ClaimsEmailUsernameFormik = ({ id, isManuallySetId, emailId, showOnly, organizationExternalId }) => {
  const { values, setFieldValue } = useFormikContext();
  const claimsEmailUsername = getIn(values, id);
  const isManuallySet = getIn(values, isManuallySetId);
  const userEmail = getIn(values, emailId);

  const classes = useStyles();

  useEffect(() => {
    if (isManuallySet || showOnly) {
      return;
    }

    if (userEmail) {
      setFieldValue(id, getUserDefaultClaimsEmailUserName(userEmail, organizationExternalId));
    }
  }, [id, isManuallySet, userEmail, setFieldValue, organizationExternalId, showOnly]);

  return (
    <>
      <HoverChangeField
        name={id}
        value={claimsEmailUsername}
        label="Claims Email Username"
        disabled={showOnly}
        onUpdate={({ claims_email_username }) => {
          setFieldValue(id, claims_email_username?.toLowerCase());
          setFieldValue(isManuallySetId, true);
        }}
      >
        <ShowOnlyTextField
          classes={classes}
          showOnlyValueComponent={claimsEmailUsername}
          label="Claims Email Username"
        />
      </HoverChangeField>
      <ErrorHelperTextFormik id={id} />
    </>
  );
};

ClaimsEmailUsernameFormik.propTypes = {
  id: PropTypes.string.isRequired,
  isManuallySetId: PropTypes.string.isRequired,
  emailId: PropTypes.string.isRequired,
  organizationExternalId: PropTypes.string.isRequired,
  showOnly: PropTypes.bool,
};

export const UserFragment = ({
  classes,
  roles,
  units,
  unitsLedByUser,
  isNew,
  displayLobs,
  displaySuperUser,
  displaySystemConfig,
  organizationExternalId,
  overrideOrganizationValue,
  isWizardUser,
}) => {
  // User can be added / edited in the wizard as well and may be other cases in which useSysconfig doesn't exist - we won't choose lobs / suborgs in that case
  const { organization: sysConfigOrganization } = useSysconfig();
  const { supportedClaimTypes } = useOrganization();
  const { lobConfigurationsDict } = useLobConfiguration();
  const { values, setFieldValue } = useFormikContext();

  const organization = overrideOrganizationValue || sysConfigOrganization;
  const namesOfUnitsLedByUser = unitsLedByUser?.map((unit) => unit.name) || [];

  const lobSelect = () => {
    if (
      sysConfigOrganization &&
      (supportedClaimTypes.length < 2 ||
        !isFeatureEnabled(sysConfigOrganization, CONFIGURATION_FEATURES_NAMES.LOB_USER_PERMISSIONS))
    ) {
      return <></>;
    }

    return (
      <Grid item xs={12}>
        <>
          <Typography display="block" variant="subtitle2" style={{ fontWeight: 500, marginBottom: '10px' }}>
            Choose line of business
          </Typography>
          <div style={{ display: 'flex' }}>
            {supportedClaimTypes.map((lob) => (
              <CheckboxWithIcon
                icon={getLobIcon({ lob, lobConfigurationsDict })}
                text={getLobDescription(lob, lobConfigurationsDict)}
                key={lob}
                checked={values.lobs.indexOf(lob) > -1}
                onChange={(_e, checked) => {
                  let currValue = [...values.lobs];
                  if (checked) {
                    currValue.push(lob);
                  } else {
                    currValue.splice(currValue.indexOf(lob), 1); // in place change
                  }

                  setFieldValue('lobs', currValue);
                }}
              />
            ))}
          </div>
        </>
      </Grid>
    );
  };

  return (
    <div className="flex w-full flex-col gap-12">
      <MainCard title="Personal Details" type="contained">
        <div className="flex items-center justify-between gap-32">
          <TextFieldFormik id="first_name" label="First Name" fullWidth />
          <TextFieldFormik id="last_name" label="Last Name" fullWidth />
        </div>
        <div className="flex items-center justify-between gap-32">
          <TextFieldFormik id="email" label="Email" fullWidth />
          <div className="w-full">
            <ClaimsEmailUsernameFormik
              id="claims_email_username"
              emailId="email"
              isManuallySetId="is_claims_email_username_manually_set"
              organizationExternalId={organizationExternalId}
              showOnly={!isNew}
            />
          </div>
        </div>
        <div className="flex items-center justify-between gap-32">
          <TextFieldFormik id="mobile_phone_number" label="Mobile Phone" fullWidth className={classes.textField} />
          <TextFieldFormik id="work_phone_number" label="Work Phone" fullWidth className={classes.textField} />
        </div>
        <FeatureEnabled featureFlag={CONFIGURATION_FEATURES_NAMES.USER_LOCALE} organization={organization}>
          <AutocompleteFormik
            id="locale"
            label="Locale"
            options={USER_LOCALES.map((l) => l.code)}
            getOptionLabel={(code) => USER_LOCALES.find((l) => l.code === code).name}
            sortAlphabetic
          />
        </FeatureEnabled>
      </MainCard>

      <FeatureEnabled
        featureFlag={CONFIGURATION_FEATURES_NAMES.ORG_HIERARCHY_CONFIGURATION}
        organization={organization}
      >
        <div className="w-full">
          <MainCard title="Unit Affiliation" type="contained">
            <div className="flex w-full items-end gap-32">
              <div className="flex w-full items-end gap-12">
                <TextFieldFormik
                  id="unit_id"
                  label="Unit Member"
                  fullWidth
                  select
                  disabled={!!namesOfUnitsLedByUser.length}
                >
                  <MenuItem key="-- Not Selected --" value="">
                    -- Not Selected --
                  </MenuItem>
                  {units.map((unit) => (
                    <MenuItem key={unit.id} value={unit.id}>
                      {unit.name}
                    </MenuItem>
                  ))}
                </TextFieldFormik>
                {!!namesOfUnitsLedByUser.length && (
                  <FsTooltip
                    arrow
                    title="This user is a leader of other related units and therefore cannot be changed here.
                  please go the Units tab for more options."
                  >
                    <WarningCircleIcon className="h-24 w-24 fill-slate-800" />
                  </FsTooltip>
                )}
              </div>

              {!isNew && (
                <>
                  <PropertyDetailsRow>
                    <PropertyDetails title="Unit Leader" text={namesOfUnitsLedByUser} useChips />
                  </PropertyDetailsRow>
                  <FsTooltip arrow title="To change the unit leader, go to the Units tab">
                    <WarningCircleIcon className="h-24 w-24 fill-slate-800" />
                  </FsTooltip>
                </>
              )}
            </div>
          </MainCard>
        </div>
      </FeatureEnabled>
      <FeatureDisabled featureFlag={CONFIGURATION_FEATURES_NAMES.PERMISSIONS_ENFORCEMENT} organization={organization}>
        <Grid item xs={12}>
          <Typography display="block" variant="subtitle2" style={{ paddingTop: '30px', fontWeight: 'bold' }}>
            Organization Details
          </Typography>
        </Grid>
      </FeatureDisabled>

      {displayLobs && lobSelect()}

      <UserPermissionsSection
        roles={roles}
        displaySuperUser={displaySuperUser}
        displaySystemConfig={displaySystemConfig}
        overrideOrganizationValue={overrideOrganizationValue}
        isWizardUser={isWizardUser}
      />

      <FeatureDisabled featureFlag={CONFIGURATION_FEATURES_NAMES.PERMISSIONS_ENFORCEMENT} organization={organization}>
        {displaySystemConfig && (
          <Grid item xs={12}>
            <SystemConfigCheckbox />
          </Grid>
        )}
        {displaySuperUser && (
          <Grid item xs={12}>
            <SuperUserCheckbox />
          </Grid>
        )}
      </FeatureDisabled>
    </div>
  );
};

UserFragment.propTypes = {
  classes: PropTypes.object.isRequired,
  buttonsComponent: PropTypes.object.isRequired,
  roles: PropTypes.array.isRequired,
  units: PropTypes.array.isRequired,
  unitsLedByUser: PropTypes.array,
  organizationExternalId: PropTypes.string.isRequired,
  isNew: PropTypes.bool,
  displayLobs: PropTypes.bool,
  displaySuperUser: PropTypes.bool,
  displaySystemConfig: PropTypes.bool,
  overrideOrganizationValue: PropTypes.object,
  isWizardUser: PropTypes.bool,
};

UserFragment.defaultProps = {
  unitsLedByUser: [],
  isNew: false,
  displayLobs: false,
  displaySuperUser: false,
  displaySystemConfig: false,
  overrideOrganizationValue: null,
  isWizardUser: false,
};
