import type { BasicConfigurationHookResponse } from '~/components/hooks/types';
import useCachedDataFetcher from '~/components/hooks/useCachedDataFetcher';
import { useCms } from '~/components/hooks/useCms';
import type { NotificationsPreferences } from '~/components/SystemConfiguration/NotificationsConfiguration/types';
import { useSysconfig } from '~/components/SystemConfiguration/SystemConfigurationScreen';
import { CONFIGURATION_FEATURES_NAMES } from '~/Types';
import { isFeatureEnabled } from '~/Utils';

interface UseNotificationsConfigurationResponse extends BasicConfigurationHookResponse<NotificationsPreferences> {
  notificationsPreferencesRoute: string;
}

const useNotificationsPreferences = (inAdminPanel = false): UseNotificationsConfigurationResponse => {
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  const { organization: adminPanelOrganization } = useSysconfig();
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  const { userOrganization } = useCms();
  const relevantOrganization = inAdminPanel ? adminPanelOrganization : userOrganization;

  const notificationsPreferencesRoute = `/api/v1/notifications_preferences/organizations/${relevantOrganization.id}`;

  const { data, isLoading, isError, reloadData } = useCachedDataFetcher({
    url: notificationsPreferencesRoute,
    shouldFetch: isFeatureEnabled(relevantOrganization, CONFIGURATION_FEATURES_NAMES.NOTIFICATIONS_2),
    shouldUseCache: true,
    localCacheKey: `notifications_preferences_${relevantOrganization.id}`,
  });

  return {
    notificationsPreferencesRoute,
    configuration: data,
    isLoading,
    isError,
    reloadData,
  };
};

export default useNotificationsPreferences;
