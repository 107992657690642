import React from 'react';
import { Avatar } from '@material-ui/core';

import { BaseMessage } from '~/components/AiChat/components/Message/Message';
import type { MessageProps } from '~/components/AiChat/components/Message/MessageTypes';
import { useCms } from '~/components/hooks/useCms';
import cn from '~/Utils/cn';

const HumanAvatar: React.FC<{ userName: string; className?: string }> = ({ userName, className }) => {
  return <Avatar className={cn(className, 'h-30 w-30 bg-blue-600 p-12 font-medium')}>{userName[0]}</Avatar>;
};

const HumanMessageContainer: React.FC<{ className: string }> = ({ children, className }) => {
  return <div className={cn(className, 'bg-transparent')}>{children}</div>;
};

export const HumanMessage: React.FC<MessageProps> = ({ exchange }) => {
  const { user } = useCms();

  return (
    <BaseMessage
      text={exchange.user_input}
      avatar={<HumanAvatar userName={user.first_name} />}
      sender={user.first_name + ' ' + user.last_name}
      isLoading={false}
      messageContainer={HumanMessageContainer}
    />
  );
};
