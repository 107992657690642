import React from 'react';

import { ActionFooter } from '~/components/AiChat/SideBarDialog/Actions/Action/ActionFooter';
import type { AiAction } from '~/components/AiChat/types';

interface DemoActionsFooterProps {
  isDone: boolean;
  type: string;
  action: AiAction;
}

export const DemoActionsFooter: React.FC<DemoActionsFooterProps> = ({ isDone, type, action }) => {
  return <ActionFooter isDone={isDone} type={type} isDemo action={action} />;
};
