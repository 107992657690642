import { useState } from 'react';
import axios from 'axios';

import { useCms } from '~/components/hooks/useCms';
import type { ClaimModel } from '~/components/types/claim-types';
import { CONFIGURATION_FEATURES_NAMES } from '~/Types';
import { isFeatureEnabled, reportAxiosError } from '~/Utils';

interface GenAiDocParams {
  file: File;
  fileId?: string;
  organizationId: number;
  claimId?: number;
  setFieldValue: (field: string, value: unknown, shouldValidate?: boolean) => void;
}

const useGenAiDoc = (
  claim?: ClaimModel
): {
  onFileSelectGenAiDoc: ({
    files,
    setFieldValue,
  }: {
    files: File[];
    setFieldValue: (field: string, value: unknown, shouldValidate?: boolean) => void;
  }) => Promise<void>;
  displayAIBanner: boolean;
} => {
  const [displayAIBanner, setDisplayAIBanner] = useState(false);
  const { userOrganization } = useCms();

  const onGenAiDoc = async ({ file, fileId, organizationId, claimId, setFieldValue }: GenAiDocParams) => {
    try {
      const response = await axios({
        method: 'post',
        url: `/api/v1/generative_ai/documents_ai/${organizationId}/${claimId}${fileId ? `/file/${fileId}` : ''}`,
        ...(file
          ? {
              data: file,
              headers: {
                'Content-Type': file.type,
              },
            }
          : {}),
      });
      const {
        data: { name, document_type, created_at, exposure_ids, summary },
      } = response;

      setFieldValue('document_name', name);
      setFieldValue('type', document_type);
      setFieldValue('summary', summary);
      setFieldValue('exposure_ids', exposure_ids);
      setFieldValue('document_date', created_at ? new Date(created_at).toISOString().substring(0, 10) : null);
    } catch (error) {
      await reportAxiosError(error);
    }
  };

  const onFileSelectGenAiDoc = async ({
    files,
    setFieldValue,
  }: {
    files: File[];
    setFieldValue: (field: string, value: unknown, shouldValidate?: boolean) => void;
  }) => {
    if (
      files?.length === 1 &&
      isFeatureEnabled(userOrganization, CONFIGURATION_FEATURES_NAMES.DOCUMENT_AI) &&
      !!claim?.id
    ) {
      try {
        setDisplayAIBanner(true);
        await onGenAiDoc({
          file: files?.[0],
          organizationId: userOrganization.id,
          claimId: claim?.id,
          setFieldValue,
        });
      } catch (error) {
        await reportAxiosError(error);
      }
      setDisplayAIBanner(false);
    }
  };

  return {
    onFileSelectGenAiDoc,
    displayAIBanner,
  };
};

export default useGenAiDoc;
