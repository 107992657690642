import React, { useEffect, useState } from 'react';
import Checkbox from '@material-ui/core/Checkbox';
import ListItemText from '@material-ui/core/ListItemText';
import axios from 'axios';
import { getIn, useFormikContext } from 'formik';
import { intersection, isEqual } from 'lodash';

import { useStyles } from '~/assets/styles';
import MenuItem from '~/components/core/Atomic/MenuItem';
import { ErrorHelperTextFormik } from '~/components/core/Formik/ErrorHelperTextFormik';
import SingleSelectField from '~/components/core/Molecules/Fields/SingleSelectField';
import { usePrevious } from '~/components/TextFieldFormik';
import colors from '~/theme/tailwind/colors';
import { reportAxiosError } from '~/Utils';

import useOrganization from '../../OrganizationContext';

const ALL_ITEM_VALUE = '__all__';

interface CoveragesMultiselectFormikProps {
  subOrganizationIds: number[];
  useOrgLevelCoveragesOnly?: boolean;
  lobs: string[];
  coveragesFieldId: string;
  allCoveragesFieldId?: string;
  showAllInRenderedSelectedOptions?: boolean;
  selectAllLabel?: string;
  shouldDisplayAll?: boolean;
  label?: string;
  disabled?: boolean;
  disableGeneral?: boolean;
  isAllOptionChecksAllCheckboxes?: boolean;
}

interface PartialValuesType {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  [name: string]: any;
}

export const CoveragesMultiselectFormik: React.FC<CoveragesMultiselectFormikProps> = ({
  subOrganizationIds,
  useOrgLevelCoveragesOnly = false,
  disabled = false,
  lobs,
  coveragesFieldId,
  allCoveragesFieldId,
  showAllInRenderedSelectedOptions,
  selectAllLabel = 'All',
  shouldDisplayAll = false,
  label = 'Coverages',
  disableGeneral = false,
  isAllOptionChecksAllCheckboxes = false,
}) => {
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  const { organizationId, subOrganizationEnabled } = useOrganization();
  const { setFieldValue, values, errors, touched } = useFormikContext<PartialValuesType>();

  const classes = useStyles();
  const coverages = getIn(values, coveragesFieldId);
  const prevSubOrgIdsRef = usePrevious(subOrganizationIds);
  const prevLobsRef = usePrevious(lobs);
  const prevCoveragesRef = usePrevious(coverages);
  const [coveragesOptionsDict, setCoveragesOptionsDict] = useState<PartialValuesType>({});
  const [isLoadingCoverages, setIsLoadingCoverages] = useState(false);

  useEffect(() => {
    (async () => {
      if (!isEqual(prevSubOrgIdsRef, subOrganizationIds) || !isEqual(prevLobsRef, lobs)) {
        setIsLoadingCoverages(true);

        try {
          const responses = await Promise.all(
            lobs.map((lob) =>
              axios.get(`/api/v1/organizations/${organizationId}/coverages`, {
                params: {
                  sub_orgs_ids: subOrganizationIds,
                  lob,
                  only_org_level_required: useOrgLevelCoveragesOnly,
                },
              })
            )
          );

          const results = responses.map((response) => response.data);
          const options = results.reduce(
            (options, result) => ({ ...options, ...result }),
            disableGeneral ? {} : { general: 'General' }
          );

          setCoveragesOptionsDict(options);
          setFieldValue(coveragesFieldId, Object.keys(options));
        } catch (error) {
          await reportAxiosError(error);
        }
        setIsLoadingCoverages(false);
      }
    })();
  }, [
    lobs,
    organizationId,
    subOrganizationIds,
    prevLobsRef,
    prevSubOrgIdsRef,
    useOrgLevelCoveragesOnly,
    disableGeneral,
    setFieldValue,
    coveragesFieldId,
  ]);

  useEffect(() => {
    if (!isEqual(prevCoveragesRef, coverages) && prevCoveragesRef) {
      setFieldValue(coveragesFieldId, intersection(coverages, Object.keys(coveragesOptionsDict)));
    }
  }, [coveragesOptionsDict, coveragesFieldId, setFieldValue, coverages, prevCoveragesRef]);

  const isDisabled =
    disabled ||
    isLoadingCoverages ||
    (subOrganizationEnabled && !subOrganizationIds.length) ||
    !lobs.length ||
    Object.keys(coveragesOptionsDict).length === 0;

  const selectedCoverages = getIn(values, coveragesFieldId) || [];
  const isSelectAllChecked =
    shouldDisplayAll &&
    ((allCoveragesFieldId && values[allCoveragesFieldId]) || // selectAllField was passed in, get value
      (!allCoveragesFieldId && selectedCoverages.length === 0) || // selectAllField was not passed in, is list empty
      false);

  const setAllSelectedFieldValue = (value: boolean) => {
    if (allCoveragesFieldId) {
      setFieldValue(allCoveragesFieldId, value);
    }
  };

  const handleSelectionChange = (value: string) => {
    if (!value?.includes(ALL_ITEM_VALUE)) {
      setFieldValue(coveragesFieldId, value);
      setAllSelectedFieldValue(false);
    }
  };

  const handleSelectAll = (event: { stopPropagation: () => void }) => {
    event.stopPropagation();
    const newSelectAllValue = !isSelectAllChecked;

    setAllSelectedFieldValue(newSelectAllValue);

    if (!isAllOptionChecksAllCheckboxes && newSelectAllValue) {
      setFieldValue(coveragesFieldId, []);
      return;
    }

    if (isAllOptionChecksAllCheckboxes) {
      newSelectAllValue
        ? setFieldValue(coveragesFieldId, Object.keys(coveragesOptionsDict))
        : setFieldValue(coveragesFieldId, []);
    }
  };

  const isCoverageChecked = (coverage: string) => {
    return getIn(values, coveragesFieldId).indexOf(coverage) > -1;
  };

  const renderFieldValue = (coverages: unknown): React.ReactNode => {
    if (showAllInRenderedSelectedOptions && isSelectAllChecked) {
      return selectAllLabel;
    }
    return (coverages as string[]).map((coverage: string) => coveragesOptionsDict[coverage]).join(', ');
  };

  return (
    <>
      <SingleSelectField
        id={coveragesFieldId}
        label={label}
        className={classes.textField}
        value={selectedCoverages}
        onChange={handleSelectionChange}
        disabled={isDisabled}
        error={
          (getIn(errors, coveragesFieldId) && getIn(touched, coveragesFieldId)) ||
          (allCoveragesFieldId && getIn(errors, allCoveragesFieldId) && getIn(touched, allCoveragesFieldId))
        }
        fullWidth
        InputLabelProps={{
          shrink: (showAllInRenderedSelectedOptions && isSelectAllChecked) || !!selectedCoverages.length,
        }}
        SelectProps={{
          multiple: true,
          renderValue: renderFieldValue,
          displayEmpty: true,
        }}
      >
        {shouldDisplayAll ? (
          <MenuItem value={ALL_ITEM_VALUE}>
            <Checkbox
              checked={isSelectAllChecked}
              onChange={handleSelectAll}
              style={{ color: isSelectAllChecked ? colors.teal['700'] : '' }}
            />
            <ListItemText primary={selectAllLabel} onClick={handleSelectAll} />
          </MenuItem>
        ) : null}
        {Object.keys(coveragesOptionsDict).map((coverage) => (
          <MenuItem key={coverage} value={coverage}>
            <Checkbox
              checked={isCoverageChecked(coverage)}
              style={{ color: isCoverageChecked(coverage) ? colors.teal['700'] : '' }}
            />
            <ListItemText primary={coveragesOptionsDict[coverage]} />
          </MenuItem>
        ))}
      </SingleSelectField>
      {allCoveragesFieldId && <ErrorHelperTextFormik id={allCoveragesFieldId} />}
      <ErrorHelperTextFormik id={coveragesFieldId} />
    </>
  );
};
