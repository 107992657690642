import { getParamByISO } from 'iso-country-currency';
import moment from 'moment-timezone';

import { importLocaleModule } from '~/components/CmsMain/localeGlobals/importLocaleModule';

export let localeDetails = null;

export const updateOrganizationLocaleDetails = async (organization, user) => {
  await updateLocaleDetails(organization.configuration.locale?.toLowerCase(), user?.locale?.toLowerCase());
};

export const updateLocaleDetails = async (orgLocale = 'en-us', userLocale) => {
  let locale_obj = new Intl.Locale(orgLocale);
  localeDetails = { locale: locale_obj, currency: getParamByISO(locale_obj.region, 'currency') };
  await loadLocale(userLocale || orgLocale);
};

const loadLocale = async (localeCode) => {
  const lowerCaseLocaleCode = localeCode.toLowerCase();
  try {
    if (!['en-us'].includes(lowerCaseLocaleCode)) {
      await importLocaleModule(lowerCaseLocaleCode);
      moment.locale(lowerCaseLocaleCode);
    } else {
      moment.locale(lowerCaseLocaleCode);
    }
  } catch (error) {
    // eslint-disable-next-line no-console
    console.error(`Failed to load locale: ${lowerCaseLocaleCode}`, error);
  }
};
