import React from 'react';
import PropTypes from 'prop-types';

import { usePaymentsConfiguration } from '~/components/hooks/usePaymentsConfiguration';

import { PIP_HCRA_DICT } from '../../../Types';
import { isInshurClaim, isTscClaim } from '../../../Utils';
import { ContactShowOnlyTextField } from '../../ContactTextFieldFormik';
import { ShowOnlyTextField } from '../../TextFieldFormik';

import MailToParagraph from './MailToParagraph';
import PaymentPayeesShowOnly from './PaymentPayeesShowOnly';
import PayToParagraph from './PayToParagraph';

import { useStyles } from '../../../assets/styles';

function IndemnityPayeesFormikShowOnly(props) {
  const { claim, exposure, paymentRequest } = props;
  const classes = useStyles();
  const {
    shouldUseUKBankTransfer,
    shouldUseIbanBankTransfer,
    shouldUseMail,
    shouldUsePayToLine,
    shouldUseReference,
    shouldUseAuBankTransfer,
  } = usePaymentsConfiguration(paymentRequest.payment_method);

  return (
    <>
      {shouldUseReference && (
        <ShowOnlyTextField classes={classes} showOnlyValueComponent={paymentRequest.reference} label="Reference" />
      )}
      <PaymentPayeesShowOnly paymentRequest={paymentRequest} />
      {shouldUsePayToLine && <PayToParagraph paymentRequest={paymentRequest} />}

      {shouldUseMail && (
        <>
          <ContactShowOnlyTextField
            contactId={paymentRequest.mail_to_contact_id}
            contactDisplayName={paymentRequest.mail_to_contact_full_name}
            label="Mail To"
            showOnly
          />
          <MailToParagraph paymentRequest={paymentRequest} />
        </>
      )}

      {shouldUseIbanBankTransfer && (
        <ShowOnlyTextField classes={classes} showOnlyValueComponent={paymentRequest.iban} label="IBAN" />
      )}

      {shouldUseUKBankTransfer && (
        <>
          <ShowOnlyTextField
            classes={classes}
            showOnlyValueComponent={paymentRequest.uk_sort_code}
            label="Sort Number"
          />
          <ShowOnlyTextField
            classes={classes}
            showOnlyValueComponent={paymentRequest.uk_bank_account_number}
            label="Account Number"
          />
          <ShowOnlyTextField
            label="Payment Reference"
            showOnlyValueComponent={paymentRequest.reference}
            classes={classes}
          />
        </>
      )}

      {shouldUseAuBankTransfer && (
        <>
          <ShowOnlyTextField
            classes={classes}
            showOnlyValueComponent={paymentRequest.au_bsb_number}
            label="BSB Number"
          />
          <ShowOnlyTextField
            classes={classes}
            showOnlyValueComponent={paymentRequest.au_bank_account_number}
            label="Account Number"
          />
          <ShowOnlyTextField
            label="Payment Reference"
            showOnlyValueComponent={paymentRequest.reference}
            classes={classes}
          />
        </>
      )}

      {(isTscClaim(claim) || isInshurClaim(claim)) &&
        ['coverage_pip', 'coverage_apip'].includes(exposure.coverage_type) && (
          <ShowOnlyTextField
            classes={classes}
            showOnlyValueComponent={PIP_HCRA_DICT[paymentRequest.hcra]}
            label="HCRA"
          />
        )}
    </>
  );
}

IndemnityPayeesFormikShowOnly.propTypes = {
  claim: PropTypes.object.isRequired,
  exposure: PropTypes.object.isRequired,
  paymentRequest: PropTypes.object.isRequired,
};

export default IndemnityPayeesFormikShowOnly;
