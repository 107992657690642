import React from 'react';
import PropTypes from 'prop-types';
import VerifiedUserIcon from '@material-ui/icons/VerifiedUser';

import { useStyles } from '~/assets/styles';
import { useClaim } from '~/components/ClaimContainer';
import { getContact } from '~/components/Contact';
import Chip from '~/components/core/Atomic/Chip/Chip';
import Tooltip from '~/components/core/Atomic/Tooltip';
import { InvolvedPersonInfoTitle } from '~/components/InvolvedPersonHoverInfo';
import InvolvedPropertyInfoTitle from '~/components/InvolvedPropertyHoverInfo';
import { isDatesDiffLessThan18Years } from '~/DateTimeUtils';

function ExposureDetailedTitle({ exposure }) {
  const { claim } = useClaim();
  const classes = useStyles();
  const involvedPerson = exposure.involved_person;
  const involvedProperty = exposure.involved_property;

  return (
    <div style={{ display: 'inline-flex', alignItems: 'center', flexWrap: 'wrap' }}>
      {exposure.coverage_type_desc}
      {involvedPerson && (
        <>
          &nbsp;-&nbsp;
          <InvolvedPersonInfoTitle
            claimId={exposure.claim_id}
            involvedPerson={involvedPerson}
            exposureId={exposure.id}
          />
          <MinorChip contactId={involvedPerson.contact_id} />
        </>
      )}
      {involvedProperty && (
        <>
          &nbsp;-&nbsp;
          <InvolvedPropertyInfoTitle claimId={exposure.claim_id} involvedProperty={involvedProperty} />
          <MinorChip contactId={involvedProperty.owner_contact_id} />
        </>
      )}
      {exposure.is_medicare_eligible === 'Positive' && (
        <span style={{ paddingLeft: '10px' }}>
          <Tooltip title="Medicare Eligibility Confirmed">
            <VerifiedUserIcon color="primary" />
          </Tooltip>
        </span>
      )}
      {exposure.coverage_type === 'coverage_wc_compensation' && claim?.compensation_type && (
        <Chip size="small" color="primary" label={claim.compensation_type} className={classes.chip} />
      )}
    </div>
  );
}

ExposureDetailedTitle.propTypes = {
  exposure: PropTypes.object.isRequired,
};

function MinorChip({ contactId }) {
  const { claim } = useClaim();
  const classes = useStyles();
  const [contact, setContact] = React.useState();
  const claimDateOfLoss = claim && claim.incident && claim.incident.date_of_loss;

  React.useEffect(() => {
    async function fetchContact() {
      const contact = await getContact(contactId);
      setContact(contact);
    }
    if (contactId) {
      fetchContact();
    }
  }, [contactId]);

  if (contact && contact.date_of_birth && isDatesDiffLessThan18Years(contact.date_of_birth, claimDateOfLoss)) {
    return <Chip size="small" color="primary" label="Minor" className={`${classes.chip}`} />;
  }

  return null;
}

MinorChip.propTypes = {
  contactId: PropTypes.any,
};

export { ExposureDetailedTitle };
