const PAYMENT_METHODS = {
  check: 'Check',
  compensation: 'Compensation',
  one_inc: 'One Inc',
  uk_bank_transfer: 'UK Bank Transfer',
  iban_bank_transfer: 'IBAN Bank Transfer',
  commerce_bank: 'Commerce Bank',
  au_bank_transfer: 'AU Bank Transfer',
} as const;

export default PAYMENT_METHODS;
