import React from 'react';
import PropTypes from 'prop-types';

import Button from '~/components/core/Atomic/Buttons/Button';
import Grid from '~/components/core/Atomic/Grid/Grid';
import Typography from '~/components/core/Atomic/Typography';
import { FeatureEnabled } from '~/components/core/FeatureFlagLayoutSwitch/FeatureFlagSwitch';
import TextWithChips from '~/components/core/TextWithChips';

import {
  CLAIM_NOTIFICATIONS_PRIORITY,
  CONFIGURATION_FEATURES_NAMES,
  NOTIFICATIONS_ENTITY_STATUSES,
  NOTIFICATIONS_RULES_TIMEFRAMES,
  NOTIFICATIONS_SCHEDULES,
  NOTIFICATIONS_SCOPES,
} from '../../../../../Types';
import { isFeatureEnabled, isOrganizationUsOrg, subOrgIdToNameDict } from '../../../../../Utils';
import CardDialog from '../../../../CardDialog';
import { OverflowArrayTextDisplayWithTooltip } from '../../../../core';
import { ShowOnlyTextField } from '../../../../TextFieldFormik';
import { useSysconfig } from '../../../SystemConfigurationScreen';
import { TextCell } from '../TableCells/TextCell';
import { joinDescriptionsOfObjectsList, useNotificationConfigurationTool } from '../utils';

import { ViewConditionDetails } from './ViewConditionDetails';

import { useStyles } from '../../../../../assets/styles';

export const ViewMoreDetailsDialog = ({ notificationToShow, onClose, conditionsConfig, events }) => {
  const classes = useStyles();
  const {
    organization,
    organizationOperationalDetails: { notificationsTokens },
  } = useSysconfig();
  const { recipientTypes } = useNotificationConfigurationTool();
  const subOrgIdToName = subOrgIdToNameDict(organization);

  const isUsOrg = isOrganizationUsOrg(organization);
  const isNotifications2Enabled = isFeatureEnabled(organization, CONFIGURATION_FEATURES_NAMES.NOTIFICATIONS_2);

  const isSelfConfigured = notificationToShow.notification_type === 'self_configured';
  const selectedEventConfig = events.find(
    (event) => notificationToShow.event_key && event.key === notificationToShow.event_key.key
  );

  let subOrgs = notificationToShow.sub_orgs;
  if (isSelfConfigured) {
    subOrgs = notificationToShow.is_all_sub_orgs
      ? 'All'
      : notificationToShow.sub_organization_ids.map((id) => subOrgIdToName[id]).join(', ');
  }

  return (
    <CardDialog isDialog title="View Notification" fullWidth maxWidth="md" onClose={onClose}>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Typography display="block" variant="subtitle2" style={{ paddingTop: '8px', fontWeight: 'bold' }}>
            Context
          </Typography>
        </Grid>
        <Grid container direction="row" spacing={2}>
          {organization.sub_organizations_enabled && (
            <>
              <Grid item xs={2} style={{ marginBottom: '24px' }}>
                <ShowOnlyTextField classes={classes} showOnlyValueComponent={subOrgs} label="Sub Organizations" />
              </Grid>
            </>
          )}
          <Grid item xs={2}>
            <ShowOnlyTextField
              classes={classes}
              showOnlyValueComponent={<TextCell value={notificationToShow.lob.replace('_', ' ')} />}
              label="Line of business"
            />
          </Grid>
          {isUsOrg ? (
            <FeatureEnabled
              organization={organization}
              featureFlag={CONFIGURATION_FEATURES_NAMES.MULTI_STATE_NOTIFICATIONS}
            >
              <Grid item xs={8} style={{ marginTop: '2px' }}>
                <Typography display="block" variant="caption">
                  States
                </Typography>
                {notificationToShow.is_all_states || notificationToShow.is_all_states === undefined ? (
                  'All'
                ) : (
                  <OverflowArrayTextDisplayWithTooltip value={notificationToShow.states} />
                )}
              </Grid>
            </FeatureEnabled>
          ) : null}
        </Grid>
        {isNotifications2Enabled && (
          <Grid container direction="row" spacing={2}>
            <Grid item xs={2}>
              <ShowOnlyTextField
                classes={classes}
                showOnlyValueComponent={
                  CLAIM_NOTIFICATIONS_PRIORITY[notificationToShow.priority] || (
                    <TextCell value={notificationToShow.priority} />
                  )
                }
                label="Priority"
              />
            </Grid>
            <Grid item xs={2}>
              <ShowOnlyTextField
                classes={classes}
                showOnlyValueComponent={<TextCell value={notificationToShow.category?.label} />}
                label="Category"
              />
            </Grid>
            <Grid item xs={2}>
              <ShowOnlyTextField
                classes={classes}
                showOnlyValueComponent={
                  <TextCell value={NOTIFICATIONS_SCHEDULES[notificationToShow.due_date_immediate_or_custom].desc} />
                }
                label="Due Date"
              />
            </Grid>
            {NOTIFICATIONS_SCHEDULES[notificationToShow.due_date_immediate_or_custom]?.should_show_schedule_fields && (
              <Grid item xs={2}>
                <ShowOnlyTextField
                  classes={classes}
                  showOnlyValueComponent={<TextCell value={notificationToShow.due_date_number} />}
                  label="Due Date Number"
                />
              </Grid>
            )}
            {NOTIFICATIONS_SCHEDULES[notificationToShow.due_date_immediate_or_custom]?.should_show_schedule_fields && (
              <Grid item xs={2}>
                <ShowOnlyTextField
                  classes={classes}
                  showOnlyValueComponent={
                    <TextCell value={NOTIFICATIONS_RULES_TIMEFRAMES[notificationToShow.due_date_timeframe]?.desc} />
                  }
                  label="Due Date Time Frame"
                />
              </Grid>
            )}
          </Grid>
        )}
        <Grid container direction="row" spacing={2}>
          <Grid item xs={2}>
            <ShowOnlyTextField
              classes={classes}
              showOnlyValueComponent={
                NOTIFICATIONS_SCOPES[notificationToShow.scope]?.desc || <TextCell value={notificationToShow.scope} />
              }
              label="Entity"
            />
          </Grid>
          {isSelfConfigured && (
            <Grid item xs={2}>
              <ShowOnlyTextField
                classes={classes}
                showOnlyValueComponent={
                  NOTIFICATIONS_ENTITY_STATUSES[notificationToShow.scope_status]?.desc || (
                    <TextCell value={notificationToShow.scope_status} />
                  )
                }
                label="Entity Status"
              />
            </Grid>
          )}
          <Grid item xs={2}>
            <ShowOnlyTextField
              classes={classes}
              showOnlyValueComponent={
                <TextCell
                  value={
                    typeof notificationToShow.scope_types === 'string'
                      ? notificationToShow.scope_types
                      : joinDescriptionsOfObjectsList(notificationToShow.scope_types)
                  }
                />
              }
              label="Entity Type"
            />
          </Grid>
        </Grid>
        <Grid item xs={isSelfConfigured ? 6 : 8} />
        <Grid item xs={12}>
          <Typography display="block" variant="subtitle2" style={{ paddingTop: '8px', fontWeight: 'bold' }}>
            Details
          </Typography>
        </Grid>
        {isSelfConfigured && (
          <Grid item xs={4}>
            <div>
              <ShowOnlyTextField
                classes={classes}
                showOnlyValueComponent={notificationToShow.rule_name}
                label="Rule Name"
              />
            </div>
          </Grid>
        )}
        {isSelfConfigured && <Grid item xs={8} />}
        <Grid item xs={4} style={{ marginBottom: 24 }}>
          <ShowOnlyTextField
            classes={classes}
            showOnlyValueComponent={
              recipientTypes[notificationToShow.recipient]?.desc || <TextCell value={notificationToShow.recipient} />
            }
            label="Recipient"
          />
        </Grid>
        <Grid item xs={8} />
        {isSelfConfigured && (
          <Grid item xs={12} container>
            <Grid item xs={12}>
              <Typography display="block" variant="subtitle2" style={{ paddingTop: '8px', fontWeight: 'bold' }}>
                Conditions
              </Typography>
            </Grid>
            <Grid item xs={3}>
              <ShowOnlyTextField
                classes={classes}
                showOnlyValueComponent={<TextCell value={notificationToShow.event_key.desc} />}
                label="Event"
              />
            </Grid>
            {selectedEventConfig && selectedEventConfig.has_type && (
              <Grid item xs={2}>
                <ShowOnlyTextField
                  classes={classes}
                  showOnlyValueComponent={
                    <TextCell value={joinDescriptionsOfObjectsList(notificationToShow.event_criteria_types)} />
                  }
                  label="Type"
                />
              </Grid>
            )}
            <Grid item xs={2}>
              <ShowOnlyTextField
                classes={classes}
                showOnlyValueComponent={
                  <TextCell value={NOTIFICATIONS_SCHEDULES[notificationToShow.schedule_immediate_or_custom].desc} />
                }
                label="Schedule"
              />
            </Grid>
            {NOTIFICATIONS_SCHEDULES[notificationToShow.schedule_immediate_or_custom]?.should_show_schedule_fields && (
              <Grid item xs={2}>
                <ShowOnlyTextField
                  classes={classes}
                  showOnlyValueComponent={<TextCell value={notificationToShow.schedule_number} />}
                  label="Number"
                />
              </Grid>
            )}
            {NOTIFICATIONS_SCHEDULES[notificationToShow.schedule_immediate_or_custom]?.should_show_schedule_fields && (
              <Grid item xs={2}>
                <ShowOnlyTextField
                  classes={classes}
                  showOnlyValueComponent={
                    <TextCell value={NOTIFICATIONS_RULES_TIMEFRAMES[notificationToShow.schedule_timeframe].desc} />
                  }
                  label="Time Frame"
                />
              </Grid>
            )}
            {notificationToShow.conditions.map((condition) => (
              <ViewConditionDetails key={condition.id} condition={condition} conditionsConfig={conditionsConfig} />
            ))}
          </Grid>
        )}
        {!isSelfConfigured && (
          <Grid item xs={12} container>
            <Grid item xs={12}>
              <Typography display="block" variant="subtitle2" style={{ paddingTop: '8px', fontWeight: 'bold' }}>
                Description
              </Typography>
            </Grid>
            <Grid item xs={6}>
              <ShowOnlyTextField classes={classes} showOnlyValueComponent={notificationToShow.description} label="" />
            </Grid>
            <Grid item xs={6} />
          </Grid>
        )}
        <Grid item xs={6}>
          <ShowOnlyTextField
            classes={classes}
            showOnlyValueComponent={
              <TextWithChips text={notificationToShow.title} tokensValues={notificationsTokens} />
            }
            label="Notification Title"
            doNotRenderInTypography
          />
        </Grid>
        <Grid item xs={6} />
        <Grid item xs={6}>
          <ShowOnlyTextField
            classes={classes}
            showOnlyValueComponent={
              <TextWithChips text={notificationToShow.content} tokensValues={notificationsTokens} />
            }
            label="Notification Text"
            doNotRenderInTypography
          />
        </Grid>
        <Grid item xs={6} />
      </Grid>
      <div className={classes.buttonsContainer}>
        <Button variant="contained" color="primary" onClick={onClose}>
          Close
        </Button>
      </div>
    </CardDialog>
  );
};

ViewMoreDetailsDialog.propTypes = {
  notificationToShow: PropTypes.object.isRequired,
  onClose: PropTypes.func.isRequired,
  conditionsConfig: PropTypes.object.isRequired,
  events: PropTypes.array.isRequired,
};
