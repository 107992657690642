import type React from 'react';

import type { MinimizedType } from '~/components/core/MinimizedBar/types';
import { CommunicationIcon, CreditCardIcon } from '~/components/icons';
import NoteIcon from '~/components/icons/NoteIcon';
import { ReminderIcon } from '~/components/icons/notifications';
import type { IconProps } from '~/components/icons/types';

interface TypeSettings {
  bgColor: string;
  textColor: string;
  icon?: React.FC<IconProps>;
  iconClassName?: string;
}

export const typeToSettings: Record<MinimizedType, TypeSettings> = {
  PHONE: {
    bgColor: 'bg-green-700',
    textColor: 'text-green-700',
    iconClassName: 'fs-icon-PhoneIcon',
  },
  EMAIL: {
    bgColor: 'bg-azure-500',
    textColor: 'text-azure-500',
    iconClassName: 'fs-icon-EmailIcon',
  },
  SMS: {
    bgColor: 'bg-orange-700',
    textColor: 'text-orange-700',
    iconClassName: 'fs-icon-SmsIcon',
  },
  NOTE: {
    bgColor: 'bg-blue-600',
    textColor: 'text-blue-600',
    icon: NoteIcon as React.FC<IconProps>,
  },
  REMINDER: {
    bgColor: 'bg-blue-600',
    textColor: 'text-blue-600',
    icon: ReminderIcon as React.FC<IconProps>,
  },
  PAYMENT: {
    bgColor: 'bg-blue-600',
    textColor: 'text-blue-600',
    icon: CreditCardIcon as React.FC<IconProps>,
  },
  DOCUMENT: {
    bgColor: 'bg-blue-600',
    textColor: 'text-blue-600',
    icon: NoteIcon as React.FC<IconProps>,
  },
  INTERNAL_COMMUNICATION: {
    bgColor: 'bg-blue-600',
    textColor: 'text-blue-600',
    icon: CommunicationIcon,
  },
};
