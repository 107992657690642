import React, { useCallback, useState } from 'react';
import axios from 'axios';

import { useChatContext } from '~/components/AiChat/hooks/useChat';
import { usePollingWithFailureTracking } from '~/components/AiChat/hooks/usePollingWithFailureTracking';
import type { AiChatReference, Insight } from '~/components/AiChat/types';
import { useClaim } from '~/components/ClaimContainer';

const POLLING_DELAY = 5000;

export interface IInsightsContextData {
  insights: Insight[];
  references: Record<string, AiChatReference>;
  isLoading: boolean;
  isError: boolean;
  reloadData: () => Promise<void>;
}

const InsightsContext = React.createContext<IInsightsContextData>({
  insights: [],
  references: {},
  isLoading: true,
  isError: false,
  reloadData: Promise.resolve,
});

const keyToLevel: { [key: string]: number } = {
  inconsistency: 1,
  liability_implications: 2,
  risk_factors: 3,
};

function orderInsightsByKey(a: Insight, b: Insight) {
  try {
    const aLevel = keyToLevel[a.insight_key];
    const bLevel = keyToLevel[b.insight_key];
    if (aLevel === bLevel) return 0;
    return aLevel > bLevel ? 1 : -1;
  } catch (e) {
    // eslint-disable-next-line no-console
    console.error('An error occurred while sorting the insights', e);
    return 0;
  }
}

export const InsightsContextProvider: React.FC = ({ children }) => {
  const [insightsResponse, setInsightsResponse] = useState<{
    insights: Insight[];
    references: Record<string, AiChatReference>;
  }>({ insights: [], references: {} });
  const [isLoading, setIsLoading] = useState(false);
  const [isError, setIsError] = useState(false);

  const { claim } = useClaim();
  const { isInsightsTabEnabled } = useChatContext();

  const fetchInsights = useCallback(async () => {
    if (isInsightsTabEnabled) {
      try {
        setIsLoading(true);
        const res = await axios.get(`/api/v1/claims/${claim.id}/chat/insights`);
        setInsightsResponse(res.data);
      } catch {
        setIsError(true);
        // Re-throw to trigger failure tracking
        throw new Error('Failed to fetch total statuses count');
      } finally {
        setIsLoading(false);
      }
    }
  }, [claim.id, isInsightsTabEnabled]);

  usePollingWithFailureTracking(fetchInsights, {
    pollingDelay: POLLING_DELAY,
    enabled: isInsightsTabEnabled,
  });

  const { insights, references }: { insights: Insight[]; references: Record<string, AiChatReference> } =
    insightsResponse || { insights: [], references: {} };

  const orderedInsights = insights?.sort(orderInsightsByKey);

  return (
    <InsightsContext.Provider
      value={{ insights: orderedInsights, references, isLoading, isError, reloadData: fetchInsights }}
    >
      {children}
    </InsightsContext.Provider>
  );
};

export const useInsights = (): IInsightsContextData => {
  return React.useContext(InsightsContext);
};
