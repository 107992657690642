export const FINANCE_TAB_KEY = 'finance';

export const FORMIK_FINANCE_FIELD_IDS = {
  IS_VAT_REGISTERED: 'is_vat_registered',
  VAT_NUMBER: 'vat_number',

  IBAN_LANGUAGE: 'language',
  IBAN: 'iban',

  UK_BANK_SORT_CODE: 'uk_sort_code',
  UK_BANK_ACCOUNT_NUMBER: 'uk_bank_account_number',

  AU_BSB_NUMBER: 'au_bsb_number',
  AU_BANK_ACCOUNT_NUMBER: 'au_bank_account_number',

  REASON: 'reason',
  EXPLANATION: 'explanation',
};

export const SUPPORTED_IBAN_LANGUAGES = ['fr', 'nl', 'en', 'de', 'it', 'es', 'pt', 'pl', 'ga', 'sv', 'no', 'fi', 'da'];
