import React from 'react';
import { getIn, useFormikContext } from 'formik';

import MenuItem from '~/components/core/Atomic/MenuItem';
import type { NotificationConfigurationFormikFields } from '~/components/SystemConfiguration/AutomaticRulesConfiguration/Notifications/types';
import { NumericTextFieldFormik, TextFieldFormik } from '~/components/TextFieldFormik';
import { NOTIFICATIONS_SCHEDULES } from '~/Types';

interface TimeframeFieldSelectProps {
  isSubmitting: boolean;
  immediate_or_custom_field: string;
  number_field: string;
  timeframe_field: string;
  forceScheduleFields?: boolean;
  timeframeOptions: { key: string; label: string }[];
  label: string;
}

const TimeframeFieldSelect: React.FC<TimeframeFieldSelectProps> = ({
  isSubmitting,
  immediate_or_custom_field,
  number_field,
  timeframe_field,
  forceScheduleFields,
  timeframeOptions,
  label,
}) => {
  const { values } = useFormikContext<NotificationConfigurationFormikFields>();

  const scheduleField: 'immediate' | 'custom' = getIn(values, immediate_or_custom_field);

  const shouldShowScheduleFields =
    NOTIFICATIONS_SCHEDULES[scheduleField]?.should_show_schedule_fields || forceScheduleFields;

  return (
    <div className="grid grid-cols-12 gap-8">
      {!forceScheduleFields ? (
        <div className="col-span-2">
          <TextFieldFormik
            id={immediate_or_custom_field}
            label={label}
            disabled={isSubmitting}
            className="my-4"
            fullWidth
            select
          >
            {/* eslint-disable-next-line @typescript-eslint/ban-ts-comment */}
            {/*@ts-ignore*/}
            {Object.keys(NOTIFICATIONS_SCHEDULES).map((option: keyof typeof NOTIFICATIONS_SCHEDULES) => (
              <MenuItem key={option} value={option}>
                {NOTIFICATIONS_SCHEDULES[option].desc}
              </MenuItem>
            ))}
          </TextFieldFormik>
        </div>
      ) : null}
      {shouldShowScheduleFields && (
        <NumericTextFieldFormik id={number_field} label="Number" disabled={isSubmitting} className="my-4" fullWidth />
      )}
      {shouldShowScheduleFields && (
        <div className="col-span-2">
          <TextFieldFormik
            id={timeframe_field}
            label="Time Frame"
            disabled={isSubmitting}
            className="my-4"
            fullWidth
            select
          >
            {/* eslint-disable-next-line @typescript-eslint/ban-ts-comment */}
            {/*@ts-ignore*/}
            {timeframeOptions.map(({ key, label }) => (
              <MenuItem key={key} value={key}>
                {label}
              </MenuItem>
            ))}
          </TextFieldFormik>
        </div>
      )}
    </div>
  );
};

export default TimeframeFieldSelect;
