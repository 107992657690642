import React from 'react';
import requiredIf from 'react-required-if';
import PropTypes from 'prop-types';
import axios from 'axios';
import { Formik } from 'formik';
import { noop } from 'lodash';
import { BullhornOutline } from 'mdi-material-ui';
import * as Yup from 'yup';

import Button from '~/components/core/Atomic/Buttons/Button';
import IconButton from '~/components/core/Atomic/Buttons/IconButton';
import Grid from '~/components/core/Atomic/Grid/Grid';
import MenuItem from '~/components/core/Atomic/MenuItem';
import Tooltip from '~/components/core/Atomic/Tooltip';
import Typography from '~/components/core/Atomic/Typography';
import CancelButton from '~/components/core/Buttons/CancelButton';

import AdjusterSelectTextFieldFormik from '../Adjuster/AdjusterSelectTextFieldFormik';
import { isUserClaimPrivileged } from '../UserUtils';
import { getExposuresLabels, reportAxiosError } from '../Utils';
import { isClaimWriteDisabled, useFetchClaim } from '../Utils/ClaimUtils';

import CommunicationLink from './communications/CommunicationLink';
import DocumentLink from './Documents/DocumentLink';
import { useCms } from './hooks/useCms';
import CardDialog from './CardDialog';
import { useClaim } from './ClaimContainer';
import ClaimLink from './ClaimLink';
import { PermissionsButtonWrapper } from './core';
import ExposureMultiSelectTextFieldFormik from './ExposureMultiSelectTextFieldFormik';
import InlineIconButton from './InlineIconButton';
import LoadingIndicator from './LoadingIndicator';
import { TextFieldFormik } from './TextFieldFormik';

import { useStyles } from '../assets/styles';

const getInternalCommunicationValidationSchema = (shouldSetExposure) =>
  Yup.object().shape({
    recipient_id: Yup.string().required('Required'),
    title: Yup.string().required('Required'),
    exposure_id: shouldSetExposure ? Yup.number().required('Required') : undefined,
  });

function getInternalCommunicationObjectDescription(internalCommunication, claim, onUpdate, displayLinkAsButton) {
  return getICObjectDescription(extractICObject(internalCommunication, claim), true, onUpdate, displayLinkAsButton);
}

const icObjectPropType = PropTypes.shape({
  type: PropTypes.oneOf(['claim', 'document', 'communication']),
  claim: requiredIf(PropTypes.object, (props) => props.icObject && props.icObject.type === 'claim'),
  document: requiredIf(PropTypes.object, (props) => props.icObject && props.icObject.type === 'document'),
  communication: requiredIf(PropTypes.object, (props) => props.icObject && props.icObject.type === 'communication'),
});

function extractICObject(internalCommunication, claim) {
  switch (internalCommunication.type) {
    case 'generic_internal_communication':
      return { type: 'claim', claim };
    case 'document_internal_communication':
      return {
        type: 'document',
        document: internalCommunication.document,
        object_id: internalCommunication.document.id,
      };
    case 'communication_internal_communication':
      return {
        type: 'communication',
        communication: internalCommunication.communication,
        object_id: internalCommunication.communication.id,
      };
    default:
      throw Error(`Unknown Internal Communication type: ${internalCommunication.type}`);
  }
}

function getICObjectDescription(icObject, shouldEnableLink, onUpdate, displayLinkAsButton = false) {
  switch (icObject.type) {
    case 'claim': {
      return undefined;
    }
    case 'document': {
      const document = icObject.document;
      const documentText = `document #${document.claim_internal_id}`;
      return shouldEnableLink ? (
        <DocumentLink
          document={document}
          text={`${displayLinkAsButton ? 'View' : ''} ${documentText}`}
          indicateLinkIfRemoved
          displayLinkAsButton={displayLinkAsButton}
        />
      ) : (
        documentText
      );
    }
    case 'communication': {
      const communication = icObject.communication;
      return shouldEnableLink ? (
        <CommunicationLink
          communication={communication}
          text={`${displayLinkAsButton ? 'View' : ''} communication`}
          onUpdate={onUpdate}
          displayLinkAsButton={displayLinkAsButton}
        />
      ) : (
        'communication'
      );
    }
    default:
      throw Error(`Unknown Internal Communication Object type: ${icObject.type}`);
  }
}

function AddInternalCommunicationContainer(props) {
  const {
    isIconButton,
    icObject,
    onUpdate,
    CustomButtonComponent,
    customButtonComponentProps,
    customButtonComponentBody,
  } = props;
  const [showAddInternalCommunicationDialog, setShowAddInternalCommunicationDialog] = React.useState(false);
  const { user } = useCms();
  const classes = useStyles();

  const [claim, isLoading, isError] = useFetchClaim(getClaimId(icObject));

  const isReady = !(isLoading || isError);
  const tooltipTitle = 'New internal communication';

  if (!isUserClaimPrivileged(user)) {
    return null;
  }

  let ButtonComponent;
  const disableAdding = !isReady || isClaimWriteDisabled(claim, user, { allowOnClosedClaim: true });

  function getClaimId() {
    switch (icObject.type) {
      case 'claim':
        return icObject.claim.id;
      case 'document':
        return icObject.document.claim_id;
      case 'communication':
        return icObject.communication.claim_id;
      default:
        throw Error(`Unknown Internal Communication Object type: ${icObject.type}`);
    }
  }

  if (isIconButton) {
    ButtonComponent = isReady && (
      <Tooltip title={tooltipTitle}>
        <IconButton
          onClick={() => setShowAddInternalCommunicationDialog(true)}
          disabled={disableAdding}
          className={classes.iconButton}
        >
          <BullhornOutline className={classes.iconSize} />
        </IconButton>
      </Tooltip>
    );
  } else if (CustomButtonComponent) {
    ButtonComponent = (
      <CustomButtonComponent
        {...customButtonComponentProps}
        onClick={() => setShowAddInternalCommunicationDialog(true)}
        disabled={disableAdding}
      >
        {customButtonComponentBody}
      </CustomButtonComponent>
    );
  } else {
    ButtonComponent = (
      <InlineIconButton
        defaultColor="inherit"
        onClick={() => setShowAddInternalCommunicationDialog(true)}
        icon={BullhornOutline}
        disabled={disableAdding}
        tooltipTitle={tooltipTitle}
      />
    );
  }

  return (
    <>
      <PermissionsButtonWrapper>{ButtonComponent}</PermissionsButtonWrapper>
      {isReady && (
        <AddInternalCommunicationDialog
          open={showAddInternalCommunicationDialog}
          claim={claim}
          icObject={icObject}
          onCancel={() => setShowAddInternalCommunicationDialog(false)}
          onSubmit={async () => {
            if (onUpdate) {
              await onUpdate();
            }
            setShowAddInternalCommunicationDialog(false);
          }}
        />
      )}
    </>
  );
}

AddInternalCommunicationContainer.propTypes = {
  isIconButton: PropTypes.bool,
  onUpdate: PropTypes.func,
  icObject: icObjectPropType.isRequired,
  CustomButtonComponent: PropTypes.object,
  customButtonComponentProps: PropTypes.object,
  customButtonComponentBody: PropTypes.node,
  showDialog: PropTypes.bool,
};

const AddInternalCommunicationBaseContainer = ({
  icObject,
  onUpdate = noop,
  open = false,
  onClose,
  onMinimized,
  disableMinimized,
}) => {
  const { user } = useCms();
  const [claim, isLoading, isError] = useFetchClaim(getClaimId(icObject));
  const isReady = !(isLoading || isError);

  if (!isUserClaimPrivileged(user)) {
    return null;
  }

  const disableAdding = !isReady || isClaimWriteDisabled(claim, user, { allowOnClosedClaim: true });

  function getClaimId() {
    switch (icObject.type) {
      case 'claim':
        return icObject.claim.id;
      case 'document':
        return icObject.document.claim_id;
      case 'communication':
        return icObject.communication.claim_id;
      default:
        throw Error(`Unknown Internal Communication Object type: ${icObject.type}`);
    }
  }

  return (
    <>
      {!disableAdding && (
        <AddInternalCommunicationDialog
          open={open}
          claim={claim}
          icObject={icObject}
          onCancel={onClose}
          onSubmit={async () => {
            await onUpdate();
            onClose();
          }}
          onMinimized={onMinimized}
          disableMinimized={disableMinimized}
        />
      )}
    </>
  );
};

AddInternalCommunicationBaseContainer.propTypes = {
  onUpdate: PropTypes.func,
  icObject: icObjectPropType.isRequired,
  open: PropTypes.bool,
  onClose: PropTypes.func,
  disableMinimized: PropTypes.bool,
  onMinimized: PropTypes.func,
};

const AddInternalCommunicationDialog = ({
  open,
  onCancel,
  onSubmit,
  claim,
  icObject,
  onMinimized,
  disableMinimized,
}) => {
  const { user } = useCms();
  const classes = useStyles();

  function extractObjectSpecificData() {
    switch (icObject.type) {
      case 'claim': {
        const relatedExposure = claim.exposures.find((exposure) => exposure.handling_adjuster_id === user.id);
        return { exposure_id: relatedExposure ? relatedExposure.id : 0, disableExposure: false };
      }
      case 'document': {
        const document = icObject.document;
        return { exposure_ids: document.exposure_ids, disableExposure: true, objectId: document.id };
      }
      case 'communication': {
        const communication = icObject.communication;
        return { exposure_ids: communication.exposure_ids, disableExposure: true, objectId: communication.id };
      }
      default:
        throw Error(`Unknown Internal Communication Object type: ${icObject.type}`);
    }
  }

  const { exposure_id, exposure_ids, disableExposure, objectId } = extractObjectSpecificData();

  const onClose = (resetForm) => {
    resetForm();
    onCancel();
  };

  return (
    <Formik
      initialValues={{
        recipient_id: '',
        title: '',
        details: '',
        exposure_id,
        exposure_ids,
      }}
      validationSchema={getInternalCommunicationValidationSchema(!disableExposure)}
      enableReinitialize
      onSubmit={async (values, { setSubmitting, resetForm }) => {
        try {
          await axios.post(`/api/v1/claims/${claim.id}/internal_communications`, {
            ...values,
            type: icObject.type,
            object_id: objectId,
          });
          await onSubmit();
          resetForm();
        } catch (error) {
          reportAxiosError(error);
          setSubmitting(false);
        }
      }}
    >
      {(formikProps) => {
        const { isSubmitting, handleSubmit, resetForm } = formikProps;
        const buttonsComponent = (
          <div className={classes.buttonsContainer}>
            <Button variant="contained" color="primary" disabled={isSubmitting} onClick={handleSubmit}>
              Send
            </Button>
          </div>
        );

        return (
          <InternalCommunicationDialogCard
            buttonsComponent={buttonsComponent}
            cardDialogProps={{
              open,
              isDialog: true,
              fullWidth: true,
              maxWidth: 'sm',
              onClose: () => onClose(resetForm),
              title: 'Send Internal Communication',
              onMinimized,
              disableMinimized,
            }}
            claim={claim}
            icObjectDescription={getICObjectDescription(icObject, false)}
            disableExposure={disableExposure}
          />
        );
      }}
    </Formik>
  );
};

AddInternalCommunicationDialog.propTypes = {
  open: PropTypes.bool.isRequired,
  onCancel: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
  claim: PropTypes.object.isRequired,
  icObject: icObjectPropType.isRequired,
  onUpdate: PropTypes.func,
  onMinimized: PropTypes.func,
  disableMinimized: PropTypes.bool,
};

function InternalCommunicationDisplay(props) {
  const {
    open,
    onClose,
    internalCommunication,
    onUpdate,
    showRecipient,
    AdditionalDialogHeader = <React.Fragment />,
  } = props;

  const [showOnly, setShowOnly] = React.useState(true);
  const inputRef = React.useRef();

  const classes = useStyles();
  const [internalCommunicationClaim, isLoading, isError] = useFetchClaim(internalCommunication.claim_id);

  React.useEffect(() => {
    if (!showOnly) {
      inputRef.current.focus();
      inputRef.current.setSelectionRange(0, 0);
    }
  }, [showOnly]);

  const icObject = extractICObject(internalCommunication, internalCommunicationClaim);

  const cardDialogProps = {
    open,
    isDialog: true,
    fullWidth: true,
    maxWidth: 'sm',
    onClose,
    title: 'Internal Communication',
  };

  if (isLoading) {
    return (
      <CardDialog {...cardDialogProps}>
        <LoadingIndicator isError={isError} />
      </CardDialog>
    );
  }

  return (
    <Formik
      initialValues={internalCommunication}
      validationSchema={getInternalCommunicationValidationSchema(false)}
      enableReinitialize
      onSubmit={async (values, { setSubmitting, resetForm }) => {
        try {
          await axios.post(`/api/v1/claims/${internalCommunicationClaim.id}/internal_communications`, {
            ...values,
            type: icObject.type,
            object_id: icObject.object_id,
          });
          await onUpdate();
          onClose();
          resetForm();
        } catch (error) {
          setSubmitting(false);
          reportAxiosError(error);
        }
      }}
    >
      {(formikProps) => {
        const { isSubmitting, handleSubmit, setValues, resetForm, values } = formikProps;
        const buttonsComponent = showOnly ? (
          <div className={classes.buttonsContainer}>
            <Button
              variant="contained"
              color="primary"
              disabled={isSubmitting}
              onClick={() => {
                setValues({
                  ...values,
                  title: `re: ${values.title}`,
                  details: `\n-----\n${values.details}`,
                  recipient_id: values.sender_id,
                  exposure_id: values.exposure_ids[0], // in case of claim_internal_communication, copy the (single) exposure, otherwise this parameter won't be used
                });
                setShowOnly(false);
              }}
            >
              Reply
            </Button>
          </div>
        ) : (
          <div className={classes.buttonsContainer}>
            <CancelButton
              disabled={isSubmitting}
              onClick={() => {
                setShowOnly(true);
                resetForm();
              }}
            />
            <Button variant="contained" color="primary" disabled={isSubmitting} onClick={handleSubmit}>
              Send
            </Button>
          </div>
        );

        return (
          <InternalCommunicationDialogCard
            buttonsComponent={buttonsComponent}
            cardDialogProps={cardDialogProps}
            claim={internalCommunicationClaim}
            showOnly={showOnly}
            showRecipient={showRecipient}
            icObjectDescription={getICObjectDescription(icObject, true, onUpdate)}
            disableExposure // reply should be of the same exposure
            inputRef={inputRef}
            AdditionalDialogHeader={AdditionalDialogHeader}
          />
        );
      }}
    </Formik>
  );
}

InternalCommunicationDisplay.propTypes = {
  internalCommunication: PropTypes.object.isRequired,
  showRecipient: PropTypes.bool.isRequired,
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  onUpdate: PropTypes.func.isRequired,
  AdditionalDialogHeader: PropTypes.node,
};

const ReferMailToUserDialog = ({ onRefer, onClose }) => {
  const classes = useStyles();

  return (
    <Formik
      initialValues={{}}
      enableReinitialize
      onSubmit={async (values, { setSubmitting }) => {
        try {
          await onRefer(values.user_id);
          setSubmitting(false);
          onClose();
        } catch (error) {
          setSubmitting(false);
          reportAxiosError(error);
        }
      }}
    >
      {(formikProps) => {
        const { isSubmitting, handleSubmit } = formikProps;

        return (
          <CardDialog isDialog onClose={onClose} title="Refer email to another user" fullWidth>
            <Grid container spacing={1}>
              <Grid item xs={12}>
                <AdjusterSelectTextFieldFormik
                  id="user_id"
                  label="Recipient"
                  className={classes.textField}
                  fullWidth
                  disabled={isSubmitting}
                  includeDaSpecialist
                />
              </Grid>
            </Grid>
            <div className={classes.buttonsContainer}>
              <CancelButton disabled={isSubmitting} onClick={onClose} />
              <Button variant="contained" color="primary" disabled={isSubmitting} onClick={handleSubmit}>
                Send
              </Button>
            </div>
          </CardDialog>
        );
      }}
    </Formik>
  );
};

ReferMailToUserDialog.propTypes = {
  onRefer: PropTypes.func,
  onClose: PropTypes.func,
};

function InternalCommunicationDialogCard(props) {
  const {
    cardDialogProps,
    buttonsComponent,
    claim,
    showOnly,
    disabled,
    disableExposure,
    icObjectDescription,
    inputRef,
    showRecipient,
    AdditionalDialogHeader,
  } = props;
  const classes = useStyles();

  const { claim: claimInContext } = useClaim();

  const exposureLabels = getExposuresLabels(claim);

  return (
    <CardDialog {...cardDialogProps}>
      {AdditionalDialogHeader}
      <Grid container spacing={1}>
        <Grid item xs={12}>
          <Typography display="block" variant="subtitle1">
            <span style={{ display: 'inline-flex', alignItems: 'center' }}>
              <BullhornOutline /> &nbsp;
              {claimInContext ? (
                claim.claim_id_display
              ) : (
                <ClaimLink claimId={claim.id} linkText={claim.claim_id_display} />
              )}
              {icObjectDescription && (
                <span>
                  &nbsp; <span>regarding</span> {icObjectDescription}
                </span>
              )}
            </span>
          </Typography>
        </Grid>
        {showOnly && (
          <Grid item xs={12}>
            <AdjusterSelectTextFieldFormik
              claim={claim}
              id="sender_id"
              label="Sender"
              className={classes.textField}
              fullWidth
              showOnly={showOnly}
              disabled={disabled}
              includeDaSpecialist
            />
          </Grid>
        )}
        {(!showOnly || showRecipient) && (
          <Grid item xs={12}>
            <AdjusterSelectTextFieldFormik
              id="recipient_id"
              label="Recipient"
              className={classes.textField}
              fullWidth
              showOnly={showOnly}
              disabled={disabled}
              includeDaSpecialist
            />
          </Grid>
        )}
        <Grid item xs={12}>
          <TextFieldFormik
            id="title"
            label="Title"
            className={classes.textField}
            fullWidth
            showOnly={showOnly}
            disabled={disabled}
          />
        </Grid>
        <Grid item xs={12}>
          <TextFieldFormik
            id="details"
            label="Details"
            rows="4"
            className={classes.textField}
            fullWidth
            multiline
            showOnly={showOnly}
            disabled={disabled}
            inputRef={inputRef}
          />
        </Grid>
        <Grid item xs={12}>
          {showOnly || disableExposure ? (
            <ExposureMultiSelectTextFieldFormik claim={claim} showOnly disabled />
          ) : (
            <TextFieldFormik
              id="exposure_id"
              select
              label="Exposure Label"
              className={classes.textField}
              fullWidth
              showOnly={showOnly}
              disabled={disabled || disableExposure}
            >
              {exposureLabels.map((exposure) => (
                <MenuItem key={exposure.id} value={exposure.id}>
                  {exposure.label}
                </MenuItem>
              ))}
            </TextFieldFormik>
          )}
        </Grid>
      </Grid>
      {buttonsComponent}
    </CardDialog>
  );
}

InternalCommunicationDialogCard.propTypes = {
  buttonsComponent: PropTypes.node.isRequired,
  cardDialogProps: PropTypes.object.isRequired,
  claim: PropTypes.object.isRequired,
  showOnly: PropTypes.bool,
  showRecipient: PropTypes.bool,
  disableExposure: PropTypes.bool,
  onEdit: PropTypes.func,
  disabled: PropTypes.bool,
  icObjectDescription: PropTypes.node,
  inputRef: PropTypes.object,
  AdditionalDialogHeader: PropTypes.node,
};

export {
  AddInternalCommunicationBaseContainer,
  AddInternalCommunicationDialog,
  getInternalCommunicationObjectDescription,
  InternalCommunicationDisplay,
  ReferMailToUserDialog,
};
export default AddInternalCommunicationContainer;
