import type { ReactElement } from 'react';
import React, { useState } from 'react';
import { debounce, keyBy } from 'lodash';

import CardDialog from '~/components/CardDialog';
import { useClaim } from '~/components/ClaimContainer';
import type { ClaimNotificationModel, NotificationsType } from '~/components/ClaimNotificationsCard/types';
import LoadingSwitch from '~/components/core/Loading/LoadingSwitch';
import SkeletonTable from '~/components/core/Skeletons/SkeletonTable';
import useNotificationsPreferences from '~/components/hooks/useNotificationsPreferences';
import NotificationsFilters from '~/components/Notifications/NotificationsFilters';
import NotificationsTable from '~/components/Notifications/NotificationsTable';
import type { NotificationsRes, NotificationsTableDisplayOptions } from '~/components/Notifications/types';
import ViewNotificationCard from '~/components/Notifications/ViewNotificationCard';
import type { NotificationsPreferences } from '~/components/SystemConfiguration/NotificationsConfiguration/types';
import { reportAxiosError } from '~/Utils';

import type { ExposureModel } from '../types/exposure-types';

interface NotificationsScreenProps {
  getClaimNotifications: (
    shouldReturnDismissed: boolean,
    shouldReturnFutureReminders: boolean,
    pageNumber: number,
    resultsPerPage: number,
    sortByColumn: string,
    typeSubtypeFilters: NotificationsType[]
  ) => Promise<NotificationsRes>;
  displayOptions: NotificationsTableDisplayOptions;
  disableSort?: boolean;
  actionComponent: ReactElement;
}

const NotificationsScreen: React.FC<NotificationsScreenProps> = ({
  getClaimNotifications,
  displayOptions,
  disableSort,
  actionComponent,
}) => {
  const { configuration, isLoading, isError } = useNotificationsPreferences();
  return (
    <LoadingSwitch isLoading={isLoading} isError={isError}>
      {configuration && (
        <NotificationsScreenInner
          getClaimNotifications={getClaimNotifications}
          displayOptions={displayOptions}
          disableSort={disableSort}
          configuration={configuration}
          actionComponent={actionComponent}
        />
      )}
    </LoadingSwitch>
  );
};

interface NotificationScreenInnerProps extends NotificationsScreenProps {
  configuration: NotificationsPreferences;
}

const NotificationsScreenInner: React.FC<NotificationScreenInnerProps> = ({
  getClaimNotifications,
  displayOptions,
  disableSort,
  configuration,
  actionComponent,
}) => {
  const [notifications, setNotifications] = useState<ClaimNotificationModel[]>([]);
  const [totalNotifications, setTotalNotifications] = useState<number>(0);
  const [exposuresDict, setExposuresDict] = useState<{ [id: string]: ExposureModel }>({});
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [isError, setIsError] = useState<boolean>(false);
  const [sortByColumn, setSortByColumn] = React.useState({ order: 'desc', id: 'due_date' });
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [selectedNotificationTypeIds, setSelectedNotificationTypeIds] = React.useState([]);
  // const sortByColumnStr = sortByColumn && `${sortByColumn.order === 'asc' ? '+' : '-'}${sortByColumn.id}`;
  const [page, setPage] = useState<number>(0);
  const [rowsPerPage, setRowsPerPage] = useState<number>(
    configuration.is_default_notifications_per_page_enabled && configuration.default_notifications_per_page
      ? configuration.default_notifications_per_page
      : 10
  );
  const [notificationToShowId, setNotificationToShowId] = useState<number | undefined>();
  const rowsPerPageOptions = [configuration.default_notifications_per_page || 10];

  const { claim: claimInContext, onAsyncClaimUpdate } = useClaim(); // might not be in claim context

  const callGetClaimNotifications = React.useCallback(async () => {
    try {
      const res = await getClaimNotifications(
        false,
        true,
        page + 1,
        rowsPerPage,
        sortByColumn && `${sortByColumn.order === 'asc' ? '+' : '-'}${sortByColumn.id}`,
        selectedNotificationTypeIds
      );

      setNotifications(res.notifications);
      setTotalNotifications(res.count);
      setExposuresDict(keyBy(res.exposures, 'id'));
      setIsLoading(false);
    } catch (error) {
      await reportAxiosError(error);
      setIsError(true);
    }
  }, [getClaimNotifications, rowsPerPage, sortByColumn, selectedNotificationTypeIds, page]);

  const callGetClaimNotificationsDebounce = React.useMemo(
    () => debounce(callGetClaimNotifications, 3000),
    [callGetClaimNotifications]
  );

  React.useEffect((): void => {
    setPage(0);
  }, [rowsPerPage]);

  React.useEffect((): void => {
    callGetClaimNotifications();
  }, [callGetClaimNotifications]);

  const handleSort = (e: React.MouseEvent<HTMLSpanElement>, { id, order }: { id: string; order: 'desc' | 'asc' }) => {
    setSortByColumn({ id, order });
    setPage(0);
  };
  const handleChangeRowsPerPage = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    setRowsPerPage(Number(e.target.value));
    setPage(0);
  };
  const handleChangePage = (event: React.MouseEvent<HTMLButtonElement> | null, newPage: number) => {
    setPage(newPage);
  };

  const updateClaimIfInContext = () => {
    if (claimInContext) {
      return onAsyncClaimUpdate();
    } else {
      return Promise.resolve();
    }
  };
  const handleUpdate = async () => {
    await updateClaimIfInContext();
    await callGetClaimNotifications();
  };

  const notificationToShow = notifications.find((c) => c.id === notificationToShowId);

  return (
    <CardDialog title="Notifications" action={actionComponent}>
      <NotificationsFilters />
      {!isError && !isLoading && (
        <NotificationsTable
          notifications={notifications}
          displayOptions={displayOptions}
          exposuresDict={exposuresDict}
          pagination={{
            rowsPerPage,
            page,
            rowsPerPageOptions,
            count: totalNotifications,
            onChangePage: handleChangePage,
            onChangeRowsPerPage: handleChangeRowsPerPage,
          }}
          onUpdateNotification={callGetClaimNotificationsDebounce}
          onHandleShowNotification={setNotificationToShowId}
          onSortByColumn={handleSort}
          disableSort={disableSort}
          defaultSortOrder="desc"
        />
      )}
      {notificationToShowId && notificationToShow && (
        <ViewNotificationCard
          onClose={() => setNotificationToShowId(undefined)}
          notification={notificationToShow}
          onUpdate={handleUpdate}
          exposuresDict={exposuresDict}
        />
      )}
      {(isError || isLoading) && <SkeletonTable rowsCount={4} columnsCount={10} isError={isError} />}
    </CardDialog>
  );
};

export default NotificationsScreen;
