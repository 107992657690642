import { useEffect, useState } from 'react';
import { isEmpty } from 'lodash';

import { useCms } from '~/components/hooks/useCms';
import useDataFetcher from '~/components/useDataFetcher';

export enum StoredFileDictTypes {
  IMAGE = 'image',
  VIDEO = 'video',
  DOC = 'doc',
}
export interface StoredFile {
  id: number;
  original_filename: string;
  mime_type: string;
  filename?: string;
}
export interface StoredFilesByType {
  storedFilesDict: Record<StoredFileDictTypes, StoredFile[]>;
}
const useFnolStoredFiles = (storedFilesIds: number[]): StoredFilesByType => {
  const { user } = useCms();
  const [uploadedFilesByTypeDict, setUploadedFilesByTypeDict] = useState(
    {} as Record<StoredFileDictTypes, StoredFile[]>
  );
  const searchParams = new URLSearchParams();
  storedFilesIds?.forEach((id: number) => searchParams.append('stored_file_ids', id.toString()));
  const shouldFetch = storedFilesIds?.length > 0 && isEmpty(uploadedFilesByTypeDict);
  const { data: stored_files_response } = useDataFetcher(
    `/api/v1/organizations/${user.organization_id}/stored_files/files?${searchParams.toString()}`,
    {},
    shouldFetch
  );

  useEffect(() => {
    if (stored_files_response?.stored_files?.length > 0) {
      const dict = {} as Record<StoredFileDictTypes, StoredFile[]>;
      stored_files_response?.stored_files.forEach((file: StoredFile) => {
        const { mime_type } = file;
        if (mime_type.includes('image')) {
          dict[StoredFileDictTypes.IMAGE] = [...(dict[StoredFileDictTypes.IMAGE] || []), file];
        } else if (mime_type.includes('video')) {
          dict[StoredFileDictTypes.VIDEO] = [...(dict[StoredFileDictTypes.VIDEO] || []), file];
        } else {
          dict[StoredFileDictTypes.DOC] = [...(dict[StoredFileDictTypes.DOC] || []), file];
        }
      });
      setUploadedFilesByTypeDict(dict);
    }
  }, [stored_files_response]);
  return { storedFilesDict: uploadedFilesByTypeDict };
};

export default useFnolStoredFiles;
