import React, { useEffect, useState } from 'react';
import { useFormikContext } from 'formik';
import { get, isEmpty, isEqual, set } from 'lodash';

import { useCms } from '../../../hooks/useCms';
import { useIncidentConfiguration } from '../../../hooks/useIncidentConfiguration';
import { PreDefinedField } from '../../../IncidentConfiguration/ConfiguredFields';
import useDataFetcher from '../../../useDataFetcher';

const dateOfLossAfterCatStart = (values, cat) =>
  !cat?.start_date || Date.parse(values?.date_of_loss) >= Date.parse(cat?.start_date);
const dateOfLossBeforeCatEnd = (values, cat) =>
  !cat?.end_date || Date.parse(values?.date_of_loss) <= Date.parse(cat?.end_date);

const CatCodeFieldFormik = () => {
  const { values } = useFormikContext();
  const { userOrganization } = useCms();
  const { incidentConfiguration } = useIncidentConfiguration();
  const preDefinedFields = incidentConfiguration.incident_details.pre_defined_fields;
  const { isLoading, isError, data: catsList } = useDataFetcher(`/api/v1/claims/cats/${userOrganization.id}`);
  const disabled = isLoading || isError || isEmpty(catsList) || !values?.date_of_loss;
  // This state is only used to trigger re-renders
  const [, setRender] = useState({});

  useEffect(() => {
    const catCodeActive = preDefinedFields?.cat_code_name?.active;
    const isCatInDateOfLoss = (cat) => dateOfLossAfterCatStart(values, cat) && dateOfLossBeforeCatEnd(values, cat);
    if (!catCodeActive || !values?.date_of_loss) {
      return;
    }

    if (isLoading || isError || isEmpty(catsList)) {
      return;
    }

    const options = catsList?.filter(isCatInDateOfLoss)?.map((cat) => ({ id: cat.name, desc: cat.name })) || [];

    const shouldRerender = !isEqual(get(preDefinedFields, 'cat_code_name.options'), options);
    set(preDefinedFields, 'cat_code_name.options', options);
    if (shouldRerender) {
      setRender({});
    }
  }, [isLoading, isError, catsList, preDefinedFields, values]);

  return (
    <PreDefinedField
      id="cat_code_name"
      fields={preDefinedFields}
      inline
      disabled={disabled || isEmpty(preDefinedFields?.cat_code_name?.options)}
    />
  );
};

export default CatCodeFieldFormik;
