import React from 'react';

import { JusterImage } from '~/components/AiChat/components/JusterImage';
import CardDialog from '~/components/CardDialog';
import Button from '~/components/core/Atomic/Buttons/Button';

interface CliveAlertBannerProps {
  note: string;
  onCLose?: () => void;
  isDialog?: boolean;
  withDismiss?: boolean;
}

const Title: React.FC = () => (
  <div className="flex items-center gap-8">
    <JusterImage height={32} />
    <span className="text-lg font-bold">Clive - Ai Assistant</span>
  </div>
);

const Content: React.FC<{ note: string; withDismiss?: boolean; isDialog?: boolean; onCLose?: () => void }> = ({
  note,
  withDismiss,
  isDialog,
  onCLose,
}) => (
  <div className="flex flex-col bg-cliveLightGradiant">
    {!isDialog && <Title />}
    <div className="flex items-center">
      <span className="pl-40 text-lg">{note}</span>
      {withDismiss && (
        <span className="ml-auto">
          <Button onClick={onCLose} className="rounded-xl text-blue-700" variant="outlined">
            Dismiss
          </Button>
        </span>
      )}
    </div>
  </div>
);

export const CliveAlertBanner: React.FC<CliveAlertBannerProps> = ({ note, withDismiss, isDialog, onCLose }) => {
  const content = React.useMemo(
    () => <Content note={note} withDismiss={withDismiss} isDialog={isDialog} onCLose={onCLose} />,
    [note, withDismiss, onCLose, isDialog]
  );

  return isDialog ? (
    <CardDialog
      isDialog
      title={<Title />}
      onClose={onCLose}
      maxWidth="lg"
      classes={{
        paper: 'bg-teal-800 bg-cliveLightGradiant',
      }}
    >
      {content}
    </CardDialog>
  ) : (
    <div className="rounded-xl bg-cliveLightGradiant p-24">{content}</div>
  );
};
