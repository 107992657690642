import React from 'react';

import IconWrapperWithDotIndication from '~/components/core/IconWrapperWithDotIndication';

import { getCommunicationChannelIconComponentWithDotIndication } from '../communications/CommunicationUtils';
import {
  ClaimOwnerChangeIcon,
  FinancialRequestIcon,
  FinancialRequestResultIcon,
  InternalCommunicationIcon,
  ReminderIcon,
} from '../icons/notifications';

export function getClaimNotificationIcon(claimNotification, prefix = '', size) {
  switch (claimNotification.type) {
    case 'communication_suggestion_claim_notification_model': {
      const channel = claimNotification.subtype;
      if (!channel) return <></>;
      return getCommunicationChannelIconComponentWithDotIndication({
        communicationChannel: channel,
        claimNotification,
        getIconWrapperWithDotIndication,
        size,
      });
    }
    case 'communication_claim_notification': {
      const channel = claimNotification.subtype;
      if (!channel) return <></>;
      return getCommunicationChannelIconComponentWithDotIndication({
        communicationChannel: channel,
        claimNotification,
        getIconWrapperWithDotIndication,
        size,
      });
    }
    case 'financial_request_decision_claim_notification':
      return getIconWrapperWithDotIndication(
        claimNotification,
        claimNotification.is_request_only ? (
          <FinancialRequestIcon size={size} />
        ) : (
          <FinancialRequestResultIcon size={size} />
        ),
        prefix
      );
    case 'claim_owner_change_claim_notification':
      return getIconWrapperWithDotIndication(claimNotification, <ClaimOwnerChangeIcon size={size} />, prefix);
    case 'reminder_claim_notification':
      return getIconWrapperWithDotIndication(claimNotification, <ReminderIcon size={size} />, prefix);
    case 'internal_communication_claim_notification':
      return getIconWrapperWithDotIndication(claimNotification, <InternalCommunicationIcon size={size} />, prefix);
    default:
      throw Error(`Unknown claim notification type: ${claimNotification.type}`);
  }
}

export function getIconWrapperWithDotIndication(claimNotification, icon, prefix = '') {
  return (
    <IconWrapperWithDotIndication
      title={
        prefix +
        (claimNotification.reassignment_job_id ? 'Reassigned ' : '') +
        notificationTypeToLabelDict[claimNotification.type]
      }
      icon={icon}
      shouldBeMarked={!!claimNotification.reassignment_job_id}
    />
  );
}

export const notificationTypeToLabelDict = {
  communication_suggestion_claim_notification_model: 'Communication Suggestion',
  communication_claim_notification: 'Communication',
  financial_request_decision_claim_notification: 'Financial Request',
  claim_owner_change_claim_notification: 'Claim Owner Change',
  reminder_claim_notification: 'Notification',
  internal_communication_claim_notification: 'Internal Communication',
};
