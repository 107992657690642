import type { ReactElement } from 'react';
import React from 'react';
import type { FormikHelpers } from 'formik';
import { Formik } from 'formik';
import { noop } from 'lodash';

import { useStyles } from '~/assets/styles';
import CardDialog from '~/components/CardDialog';
import MenuItem from '~/components/core/Atomic/MenuItem';
import DialogFooterActions from '~/components/core/DialogFooterActions';
import type { EditConfigurationDialogComponentProps } from '~/components/core/Molecules/ConfigurableOption';
import type { NotificationsPreferences } from '~/components/SystemConfiguration/NotificationsConfiguration/types';
import TextFieldFormik from '~/components/TextFieldFormik';
import { reportErrorInProductionOrThrow } from '~/Utils';

const NotificationsPerPageOptions = [10, 15, 20, 30];

const NotificationsPerPageEditDialog = ({
  configuration,
  onCancel = noop,
  onSubmit,
}: EditConfigurationDialogComponentProps<NotificationsPreferences>): ReactElement => {
  const classes = useStyles();

  const handleSubmit = async (
    values: Partial<NotificationsPreferences>,
    formikHelpers: FormikHelpers<Partial<NotificationsPreferences>>
  ) => {
    try {
      if (!onSubmit) {
        reportErrorInProductionOrThrow('No onSubmit function for NotificationsPerPageDialog');
        return;
      }
      await onSubmit({
        default_notifications_per_page: values.default_notifications_per_page,
        is_default_notifications_per_page_enabled: true,
      });
      onCancel();
    } catch {
      formikHelpers.setSubmitting(false);
    }
  };

  return (
    <Formik
      initialValues={{ default_notifications_per_page: configuration?.default_notifications_per_page || 10 }}
      onSubmit={handleSubmit}
    >
      {({ isSubmitting, handleSubmit }) => {
        return (
          <CardDialog
            title="Default notifications per page"
            isDialog
            maxWidth="sm"
            fullWidth
            onClose={onCancel}
            preventClose={isSubmitting}
            footerActions={
              <DialogFooterActions
                primaryLabel="Save"
                onClickPrimary={handleSubmit}
                onClickSecondary={onCancel}
                disabled={isSubmitting}
              />
            }
          >
            <TextFieldFormik
              id="default_notifications_per_page"
              select
              label="Default notifications per page"
              disabled={isSubmitting}
              className={classes.textField}
              fullWidth
            >
              {/* eslint-disable-next-line @typescript-eslint/ban-ts-comment */}
              {/*@ts-ignore*/}
              {NotificationsPerPageOptions.map((option) => (
                <MenuItem key={option} value={option}>
                  {option}
                </MenuItem>
              ))}
            </TextFieldFormik>
          </CardDialog>
        );
      }}
    </Formik>
  );
};

export default NotificationsPerPageEditDialog;
