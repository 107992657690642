import React from 'react';
import PropTypes from 'prop-types';
import { useFormikContext } from 'formik';

import Grid from '~/components/core/Atomic/Grid/Grid';
import { usePaymentsConfiguration } from '~/components/hooks/usePaymentsConfiguration';

import { getAllOrganizationWideRoles } from '../../../communications/ContactUtils';
import useOrganization from '../../../OrganizationContext';
import PaymentPayeeFormik from '../PaymentPayeeFormik';
import PayToFormikInner from '../PayToFormikInner';

function ExpensesPayeesFormikInner(props) {
  const { readOnly } = props;
  const { organizationContactRolesDict } = useOrganization();
  const { values, setValues } = useFormikContext();
  const { isPayeeEmailRequired, isPayeePhoneNumberRequired } = usePaymentsConfiguration(values.payment_method);

  const vendorPayee = values['vendor_payee'];

  React.useEffect(() => {
    // We wanted to just setFieldValue all fields below. Due to a bug in Formik, we must setValues({...values, fields }), making useEffect depend on values
    // To prevent an infinite loop, we make this hack where we only care if vendorPayee is not in sync with the other fields
    // TODO: once the Formik bug is fixed, replace with multiple setFieldValue calls and remove the if
    if (
      (vendorPayee && values['primary_payee_id'] && values['primary_payee_id'] === vendorPayee.id) ||
      (!vendorPayee && !values['primary_payee_id'])
    ) {
      return;
    }

    setValues({
      ...values,
      vendor_payee: vendorPayee ? { ...vendorPayee } : '',
      request_extra: {
        ...values.request_extra,
        uk_bank: {
          ...values.request_extra?.uk_bank,
          uk_sort_code: vendorPayee ? vendorPayee.uk_sort_code || '' : '',
          uk_bank_account_number: vendorPayee ? vendorPayee.uk_bank_account_number || '' : '',
        },
      },
      primary_payee: vendorPayee || '',
      primary_payee_full_name: vendorPayee ? vendorPayee.full_name : '',
      primary_payee_id: vendorPayee ? vendorPayee.id : '',
      mail_to_contact: vendorPayee || '',
      mail_to_contact_full_name: vendorPayee ? vendorPayee.full_name : '',
      mail_to_contact_id: vendorPayee ? vendorPayee.id : '',
      iban: (vendorPayee ? vendorPayee.iban : '') || '',
      au_bank: {
        ...values.au_bank,
        au_bsb_number: vendorPayee ? vendorPayee.au_bsb_number || '' : '',
        au_bank_account_number: vendorPayee ? vendorPayee.au_bank_account_number || '' : '',
      },
    });
  }, [vendorPayee, values, setValues]);

  return (
    <Grid container spacing={1}>
      <Grid item xs={6}>
        <PaymentPayeeFormik
          contactFieldId="vendor_payee"
          label="Vendor"
          acceptedRoles={getAllOrganizationWideRoles(organizationContactRolesDict)}
          isDisabled={readOnly}
          isEmailRequired={isPayeeEmailRequired}
          isPhoneNumberRequired={isPayeePhoneNumberRequired}
          emailFieldId="vendor_payee_email_id"
          phoneNumberFieldId="vendor_payee_phone_id"
        />
      </Grid>
      <Grid item xs={12}>
        <PayToFormikInner payeeContactsSelected={vendorPayee ? [vendorPayee] : []} showOnly={readOnly} />
      </Grid>
    </Grid>
  );
}
ExpensesPayeesFormikInner.propTypes = {
  claim: PropTypes.object.isRequired,
  exposure: PropTypes.object.isRequired,
  readOnly: PropTypes.bool,
};

export default ExpensesPayeesFormikInner;
