import React from 'react';
import { noop } from 'lodash';

import type { ActionFooterProps } from '~/components/AiChat/SideBarDialog/Actions/Action/ActionTypes';
import { DismissButton } from '~/components/AiChat/SideBarDialog/Actions/Action/DismissButton';
import { ExecuteButton } from '~/components/AiChat/SideBarDialog/Actions/Action/ExecuteButton';
import mixpanel from '~/components/CmsMain/mixpanel';
import { CLIVE_MIXPANEL_EVENTS } from '~/pocs/mixpanel';

export const ActionFooter: React.FC<ActionFooterProps> = ({ onExecute = noop, isDone, type, isDemo = false }) => {
  return (
    <div className="flex items-center justify-between">
      <DismissButton onDismiss={noop} disabled={isDone} />

      <ExecuteButton
        onExecute={() => {
          mixpanel.track(CLIVE_MIXPANEL_EVENTS.ACTION_OPEN_CLICKED, {
            type,
            is_done: isDone,
            isDemo,
          });
          onExecute();
        }}
        isDone={isDone}
      />
    </div>
  );
};
