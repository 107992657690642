/**
 * Handler function called on failure events
 * @callback FailureHandler
 * @returns {void}
 */
type FailureHandler = () => void;

/**
 * Represents a single failure occurrence
 * @interface FailureRecord
 * @property {number} timestamp - Unix timestamp of when the failure occurred
 */
interface FailureRecord {
  timestamp: number;
}

/**
 * Creates a wrapper that tracks function failures within a specified time window
 *
 * @template Args - Array type representing function argument types
 * @template Return - Return type of the wrapped function
 *
 * @param {(...args: Args) => Promise<Return>} fn - Async function to wrap with failure tracking
 * @param maxFailures - Maximum number of failures allowed within time window
 * @param timeWindowMs - Time window in milliseconds to track failures
 * @param {FailureHandler} onFailureLimit - Callback invoked when max failures is reached
 * @param {FailureHandler} onSingleFailure - Callback invoked on each individual failure
 *
 * @returns {(...args: Args) => Promise<Return | null>} Wrapped function that returns null on failure
 */
const withFailureTracking = <Args extends unknown[], Return>(
  fn: (...args: Args) => Promise<Return>,
  maxFailures = 3,
  timeWindowMs = 5000,
  onFailureLimit?: FailureHandler,
  onSingleFailure?: FailureHandler
): ((...args: Args) => Promise<Return | null>) => {
  let failures: FailureRecord[] = [];

  const cleanOldFailures = () => {
    const now = Date.now();
    failures = failures.filter((f) => now - f.timestamp < timeWindowMs);
  };

  return async (...args: Args): Promise<Return | null> => {
    try {
      const result = await fn(...args);
      cleanOldFailures();
      return result;
    } catch (error) {
      cleanOldFailures();
      failures.push({ timestamp: Date.now() });

      if (failures.length >= maxFailures) {
        onFailureLimit && onFailureLimit();
        failures = [];
      }

      onSingleFailure && onSingleFailure();
      return null;
    }
  };
};

export { withFailureTracking };
