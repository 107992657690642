import React, { useState } from 'react';

import { useStyles } from '~/assets/styles';
import CardDialog from '~/components/CardDialog';
import { ClaimContextProvider } from '~/components/ClaimContainer';
import ClaimSearchContainer from '~/components/ClaimSearch';
import UploadDocumentFromTemplate from '~/components/Documents/UploadDocumentFromTemplate';
import { SelectClaim } from '~/components/GenericTemplates/GenericTemplatesConfiguration/GenericTemplateCard/GenericTemplatePreview/GenericTemplatePreviewContainer';
import type { TemplateMetadata } from '~/components/GenericTemplates/types';
import LoadingDialog from '~/components/LoadingDialog';
import { useFetchClaim } from '~/Utils/ClaimUtils';

interface DocumentTemplatePreviewContainerProps {
  onClose: () => void;
  template: TemplateMetadata;
}
type handleResultClick = (claimId: number) => void;

const DocumentTemplatePreviewContainer: React.FC<DocumentTemplatePreviewContainerProps> = ({ onClose, template }) => {
  const [claimId, setClaimId] = useState<null | number>(null);
  const [claim, isLoadingClaim, isErrorClaim] = useFetchClaim(claimId);

  const classes = useStyles();

  const handleResultClick: handleResultClick = (claimIdFromSearch) => {
    setClaimId(claimIdFromSearch);
  };

  if (!claimId) {
    return (
      <CardDialog isDialog title="Choose Claim to generate template example" onClose={onClose} fullWidth maxWidth="md">
        <ClaimSearchContainer
          classes={classes}
          SelectComponent={SelectClaim}
          selectComponentProps={{ handleResultClick }}
          label="Choose claim for example"
          lobs={template.lobs}
          subOrganizationIds={template.sub_organization_ids}
          limit={5}
          filteredOutColumns={[
            'first_party_name_chip',
            'date_of_loss',
            'creation_date',
            'reported_date',
            'date_closed',
          ]}
          coverageKeys={template?.coverage_keys?.includes('general') ? undefined : template?.coverage_keys} // General applies to all claims
        />
      </CardDialog>
    );
  }

  if (!claim || isLoadingClaim || isErrorClaim) {
    return <LoadingDialog isError={isErrorClaim} track="Claim context" text="Loading Claim Context" />;
  }

  if (claim) {
    return (
      <ClaimContextProvider
        claim={claim}
        refreshData={() => null}
        asyncRefreshData={() => null}
        inClaimPage={false}
        reloadingUuid={null}
      >
        <UploadDocumentFromTemplate
          claim={claim}
          documentTemplate={template}
          onCancel={onClose}
          onClose={onClose}
          onSubmitDocument={() => null}
          open
          disableMinimized
          disabledCreateDocument
          withInvolvedInfo={false}
        />
      </ClaimContextProvider>
    );
  }

  return null;
};

export default DocumentTemplatePreviewContainer;
