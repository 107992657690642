import * as Sentry from '@sentry/react';
import axios from 'axios';
import localforage from 'localforage';
import _ from 'lodash';
import querystring from 'query-string';
import StackTrace from 'stacktrace-js';
import { v4 as uuidv4 } from 'uuid';

import { getPermissionDeniedDialogConfig } from '~/components/core/PermissionError/PermissionError';
import COUNTRY_TO_STATE_MAP from '~/server_shared/generated-types/COUNTRY_TO_STATE_MAP';

import { globalDeployEnv, openTopLevelDialog } from '../components/CmsMain/globals';
import { clearTableauAuthTime } from '../tableauAuthenticationUtils';
import {
  AUTO_COVERAGES_DICT,
  CLAIM_TYPES_CONFIGURATION,
  CONFIGURATION_FEATURES_NAMES,
  COUNTRIES_DICT,
  GL_COVERAGE_DICT,
  HOME_COVERAGES_DICT,
  PET_COVERAGE_DICT,
  ROLES_TYPES_DICT,
  TRAVEL_COVERAGE_DICT,
  UK_COUNTRIES_DICT,
  WC_COVERAGE_DICT,
} from '../Types';

export const CACHE_TTL = 60 * 1000 * 60 * 24; // 24 Hours

export function getFrontendVersion() {
  // This global is defined in vite.config.js
  // eslint-disable-next-line no-undef
  return APP_VERSION;
}

export function checkFrontendVersionAndReload() {
  axios.get('/api/v1/app/frontend_version').then((res) => {
    const serverFrontendVersion = res.data;
    if (serverFrontendVersion !== getFrontendVersion()) {
      window.location.reload();
    }
  });
}

function locationToPage(location) {
  return `${location.pathname}${location.search}${location.hash}`;
}

export function trackModal(location, modal) {
  axios.post('/api/v1/app/track_modal', { page: locationToPage(location), modal });
}

export function trackPage(location) {
  axios.post('/api/v1/app/track_page', { page: locationToPage(location) });
}

export function trackLandingPage(location) {
  axios.post('/api/v1/app/track_page', { page: locationToPage(location), is_landing: true });
}

export function trackAction(Location, action) {
  axios.post('/api/v1/app/track_action', { page: locationToPage(location), action });
}

export const wrapAsyncApiCallWithCache = async ({ fn, cacheKey, cacheTtl, shouldCacheResultFunc = () => true }) => {
  const cachedDataCall = async (resolve, reject) => {
    try {
      const cachedData = await localforage.getItem(cacheKey);
      if (cachedData && Date.now() - cachedData.timestamp < cacheTtl) {
        resolve(cachedData.data);
      } else {
        reject('Cache miss');
      }
    } catch (error) {
      reject(error);
    }
  };

  const apiCall = async (resolve, reject) => {
    {
      try {
        const { data } = await fn();
        if (shouldCacheResultFunc && shouldCacheResultFunc(data)) {
          await localforage.setItem(cacheKey, {
            timestamp: Date.now(),
            data,
          });
        }
        resolve(data);
      } catch (error) {
        reject(error);
      }
    }
  };

  const apiPromise = new Promise(apiCall);
  const cachedDataCallPromise = new Promise(cachedDataCall);

  return await Promise.any([apiPromise, cachedDataCallPromise]);
};

export function locationFieldsToLocationFull(
  street_address1,
  street_address2,
  city,
  county,
  state,
  country,
  zipcode,
  zipcode_extension,
  country_in_uk = ''
) {
  let address = [];
  let fields = [
    street_address1,
    street_address2,
    city,
    county,
    state,
    country_in_uk ? UK_COUNTRIES_DICT[country_in_uk] : '',
    zipcode,
    zipcode_extension,
    country ? COUNTRIES_DICT[country] : '',
  ];

  fields.forEach((field) => {
    if (field) {
      address.push(field);
    }
  });

  return address.join(', ');
}

export function isLocationEmpty(location) {
  return (
    !location ||
    !(
      location.street_address1 ||
      location.street_address2 ||
      location.city ||
      location.county ||
      location.state ||
      location.country ||
      location.zipcode
    )
  );
}

export function getClaimTypeCoverageTypesDict(claimType) {
  const createSimpleCoverageDict = (complexDict) => {
    let CoveragesTypesDict = {};
    Object.keys(complexDict).forEach((coverage) => {
      CoveragesTypesDict[coverage] = complexDict[coverage].desc;
    });

    return CoveragesTypesDict;
  };

  if (claimType === 'auto_claim') {
    return createSimpleCoverageDict(AUTO_COVERAGES_DICT);
  } else if (claimType === 'home_claim') {
    return HOME_COVERAGES_DICT;
  } else if (claimType === 'gl_claim') {
    return createSimpleCoverageDict(GL_COVERAGE_DICT);
  } else if (claimType === 'wc_claim') {
    return createSimpleCoverageDict(WC_COVERAGE_DICT);
  } else if (claimType === 'pet_claim') {
    return createSimpleCoverageDict(PET_COVERAGE_DICT);
  } else if (claimType === 'travel_claim') {
    return createSimpleCoverageDict(TRAVEL_COVERAGE_DICT);
  } else {
    throw Error(`Unknown claim type: ${claimType}`);
  }
}

export function getClaimCoverageTypesDict(claim) {
  return getClaimTypeCoverageTypesDict(claim.type);
}

export function getExposuresLabels(claim, shouldIncludeGeneral = true) {
  let exposureLabels = claim.exposures.map((exposure) => {
    return { id: exposure.id, label: exposure.label_text };
  });
  exposureLabels = _.sortBy(exposureLabels, (label) => label.label);
  if (shouldIncludeGeneral) {
    exposureLabels = [{ id: 0, label: 'General' }].concat(exposureLabels);
  }

  return exposureLabels;
}

export function getExposuresArrayFromClaimByIds(claim, exposureIds) {
  return claim ? claim.exposures.filter((exposure) => exposureIds.includes(exposure.id)) : [];
}

export function locationToLocationFullString(location) {
  const { address1, address2, city, county, state, country, country_in_uk, zipcode, zipcode_extension } = location;
  return locationFieldsToLocationFull(
    address1,
    address2,
    city,
    county,
    state,
    country,
    zipcode,
    zipcode_extension,
    country_in_uk
  );
}

export function getMonthNames() {
  const monthNames = [
    'January',
    'February',
    'March',
    'April',
    'May',
    'June',
    'July',
    'August',
    'September',
    'October',
    'November',
    'December',
  ];
  return monthNames;
}

export function monthNumberToMonthName(monthNumber) {
  const monthNames = getMonthNames();
  return monthNames[monthNumber - 1];
}

export const reportTwilioError = (error) => {
  const errorJson = { type: 'twilio_error', ...error };
  // Note: we intentionally don't check for failure of the axios.post since the original exception might have been caused by a network error
  // (and we don't want a weird recursion)
  axios.post('/api/v1/errors', errorJson);
};

export const reportTableauError = (error) => {
  const errorJson = { type: 'tableau_error', ...error };
  axios.post('/api/v1/errors', errorJson);
};

export function isLocalEnv() {
  return globalDeployEnv && globalDeployEnv.toLowerCase() === 'local';
}

export function isStagingEnv() {
  return globalDeployEnv && globalDeployEnv.toLowerCase() === 'sandbox';
}

export function isProductionEnv() {
  return globalDeployEnv && globalDeployEnv.toLowerCase() === 'prod';
}

export function isProductionOrUat() {
  return globalDeployEnv && (globalDeployEnv.toLowerCase() === 'uat' || globalDeployEnv.toLowerCase() === 'prod');
}

export function isUatEnv() {
  return globalDeployEnv && globalDeployEnv.toLowerCase() === 'uat';
}

export function isDevOrLocal() {
  return globalDeployEnv && (globalDeployEnv.toLowerCase() === 'dev' || globalDeployEnv.toLowerCase() === 'local');
}

export function isDevOrLocalOrUat() {
  return (
    globalDeployEnv &&
    (globalDeployEnv.toLowerCase() === 'uat' ||
      globalDeployEnv.toLowerCase() === 'dev' ||
      globalDeployEnv.toLowerCase() === 'local')
  );
}

export function isRemote() {
  return (
    globalDeployEnv &&
    (globalDeployEnv.toLowerCase() === 'uat' ||
      globalDeployEnv.toLowerCase() === 'dev' ||
      globalDeployEnv.toLowerCase() === 'prod')
  );
}

export const isPermissionDeniedError = (error) =>
  error?.response?.status === 403 && error?.response?.data?.message?.permission_id;

const isClaimNotFoundError = (error) =>
  error?.response?.status === 404 && error?.response?.data?.message?.startsWith('Claim not found');

export function reportErrorInProductionOrThrow(errorMsg, doNotThrowInStaging = false) {
  const error = new Error(errorMsg);

  let isProductionOrUAT = true;
  let isStaging = false;
  try {
    isProductionOrUAT = isProductionEnv() || isUatEnv();
    isStaging = isStagingEnv();
  } catch (e) {
    // Unable to determine environment, default to true
  }

  if (isProductionOrUAT || (isStaging && doNotThrowInStaging)) {
    Sentry.captureException(error);
  } else {
    throw error;
  }
}

export const extractError = (error) => {
  // User Authorization Errors
  if (
    error.response &&
    (error?.response?.data?.message === 'User is not authorized to perform this operation' ||
      error?.response?.data?.message?.match(/Role type .* not authorized for function .*/))
  ) {
    return {
      title: 'Authorization Error',
      message: 'User is not authorized to perform this operation.',
      reportError: false,
    };
  }

  // Notify User Specific Errors
  if (error?.response?.data?.message?.startsWith('Notify User:')) {
    return {
      title: 'Error',
      message: error.response.data.message.replace('Notify User:', '').trim(),
      reportError: false,
    };
  }

  // Claim Not Found
  if (isClaimNotFoundError(error)) {
    return {
      title: 'Claim Not Found',
      message: 'Please verify the url',
      reportError: false,
    };
  }

  // Generic Unexpected Error
  return {
    title: 'Unexpected Error',
    message: 'Unfortunately an unexpected error has occurred. We are working to solve it.',
    reportError: true,
  };
};

export const reportAxiosError = async (error, openDialogError = true) => {
  let errorJson = {};

  if (openDialogError) {
    if (isPermissionDeniedError(error)) {
      openTopLevelDialog(getPermissionDeniedDialogConfig(error));
      return; // Don't report error
    }
    const { title, message, reportError } = extractError(error);

    openTopLevelDialog({ title, message });
    if (reportError) {
      Sentry.captureException(error);
    }
  }

  if (error.response) {
    // The request was made and the server responded with a status code
    // that falls out of the range of 2xx
    errorJson = {
      type: 'axios_response',
      status: error.response.status,
      data: error.response.data,
      headers: error.response.headers,
    };
  } else if (error.request) {
    // The request was made but no response was received
    // `error.request` is an instance of XMLHttpRequest
    errorJson = { type: 'axios_request', request: error.request };
  } else {
    // Something happened in setting up the request that triggered an Error
    errorJson = { type: 'Other', name: error.name.toString(), stack: error.stack.toString(), message: error.message };
    // eslint-disable-next-line no-console
    console.log(error.stack.toString());
  }

  errorJson = { ...errorJson, config: error.config };

  let stackTracePromise;
  if (errorJson.type === 'Other') {
    stackTracePromise = StackTrace.fromError(error);
  } else if (errorJson.type !== 'axios_response') {
    stackTracePromise = StackTrace.get();
  }

  if (stackTracePromise) {
    const stackFrames = await stackTracePromise;
    errorJson['stackParsed'] =
      'Error\n' +
      stackFrames.map((sf) => `at ${sf.functionName} (${sf.fileName}:${sf.lineNumber}:${sf.columnNumber})`).join('\n');
  }

  // Note: we intentionally don't check for failure of the axios.post since the original exception might have been caused by a network error
  // (and we don't want a weird recursion)
  const axiosPromiseRet = axios.post('/api/v1/errors', errorJson);

  await axiosPromiseRet;
};

export const reportReactError = (error, info) => {
  const errorJson = { type: 'react_error_boundary', message: error.message, stack: info.componentStack };

  // In production, info.componentStack is unhelpful. We'll use StackTrace.fromError to try reporting something useful

  StackTrace.fromError(error)
    .then((stackFrames) => {
      errorJson['stackParsed'] =
        'Error\n' +
        stackFrames
          .map((sf) => `at ${sf.functionName} (${sf.fileName}:${sf.lineNumber}:${sf.columnNumber})`)
          .join('\n');
    })
    .then(() => {
      // Note: we intentionally don't check for failure of the axios.post since the original exception might have been caused by a network error
      // (and we don't want a weird recursion)
      axios.post('/api/v1/errors', errorJson);
    });
};

export const reportGenericException = (error) => {
  const errorJson = { type: 'react_error_boundary', message: error.message };

  StackTrace.fromError(error)
    .then((stackFrames) => {
      errorJson['stackParsed'] =
        'Error\n' +
        stackFrames
          .map((sf) => `at ${sf.functionName} (${sf.fileName}:${sf.lineNumber}:${sf.columnNumber})`)
          .join('\n');
    })
    .then(() => {
      // Note: we intentionally don't check for failure of the axios.post since the original exception might have been caused by a network error
      // (and we don't want a weird recursion)
      axios.post('/api/v1/errors', errorJson);
    });
};

export function isPolicyNotFound(claim) {
  return claim?.policy.is_manually_created && !claim.policy.is_manually_filled;
}

export function isClaimPolicyManuallyFilled(claim) {
  return isPolicyManuallyFilled(claim.policy);
}

export function isPolicyManuallyFilled(policy) {
  return policy.is_manually_created && policy.is_manually_filled;
}

export function isTscClaim(claim) {
  return isTscPolicy(claim.policy);
}

export function isInshurClaim(claim) {
  return isInshurPolicy(claim.policy);
}

export function isInshurUkClaim(claim) {
  return isInshurUkPolicy(claim.policy);
}

export function isMgmClaim(claim) {
  return claim.organization.name.toLowerCase() === 'mgm';
}

export function isRwClaim(claim) {
  return claim.organization.name.toLowerCase() === 'resorts world';
}

export function isQoverClaim(claim) {
  return (
    claim.organization.name.toLowerCase() === 'qover' ||
    claim.organization.name.toLowerCase() === 'qover migration playground'
  );
}

export function isMarshmallowClaim(claim) {
  return claim.organization.name.toLowerCase() === 'marshmallow';
}

export function isZennerClaim(claim) {
  return claim.organization.name.toLowerCase() === 'zenner';
}

export function isXceedanceClaim(claim) {
  return claim.organization.name.toLowerCase() === 'xceedance';
}

export function isQoverPolicy(policy) {
  return policy.client.toLowerCase() === 'qover' || policy.client.toLowerCase() === 'qover migration playground';
}

export function isMarshmallowPolicy(policy) {
  return policy.client.toLowerCase() === 'marshmallow';
}

export function isZennerPolicy(policy) {
  return policy.client.toLowerCase() === 'zenner';
}

export function isInshurPolicy(policy) {
  return policy.client.toLowerCase() === 'inshur';
}

export function isInshurUkPolicy(policy) {
  return policy.client.toLowerCase().replace(' ', '_') === 'inshur_uk';
}

export function isTscPolicy(policy) {
  return policy.client.toLowerCase() === 'tsc';
}

export function isXceedancePolicy(policy) {
  return policy.client.toLowerCase() === 'xceedance';
}

export function isKinPolicy(policy) {
  return policy.client.toLowerCase() === 'kin';
}

export function isKinClaim(claim) {
  return claim.organization_name.toLowerCase() === 'kin';
}

export function isTscUser(user) {
  return user.organization_name.toLowerCase() === 'tsc';
}

export function isInshurUser(user) {
  return user.organization_name.toLowerCase() === 'inshur';
}

export function isInshurUkUser(user) {
  return user.organization_name.toLowerCase().replace(' ', '_') === 'inshur_uk';
}

export function isQoverUser(user) {
  return (
    user.organization_name.toLowerCase() === 'qover' ||
    user.organization_name.toLowerCase() === 'qover migration playground'
  );
}

export function isMgmUser(user) {
  return user.organization_name.toLowerCase() === 'mgm';
}

export function isRwUser(user) {
  return user.organization_name.toLowerCase() === 'resorts world';
}

export function isHospitalityUser(user) {
  return (
    user.organization_name.toLowerCase().replace(' ', '_') === 'hard_rock' ||
    user.organization_name.toLowerCase().replace(' ', '_') === 'cherokee_nation'
  );
}

export function isBoostUser(user) {
  return user.organization_name.toLowerCase() === 'boost';
}

export function isMarshmallowUser(user) {
  return user.organization_name.toLowerCase() === 'marshmallow';
}

export const isHiddenRole = (roleType) => {
  return !!ROLES_TYPES_DICT[roleType].is_hidden;
};

export const isFeatureEnabled = (organization, featureName) => {
  if (featureName === undefined) {
    return false;
  }
  const enabled = !!organization.features.find((f) => f.name === featureName);
  if (!enabled && !Object.values(CONFIGURATION_FEATURES_NAMES).find((f) => f === featureName)) {
    reportErrorInProductionOrThrow(`Feature ${featureName} does not exist`);
  }
  return enabled;
};

export function isXceedanceOrganization(organization) {
  return organization.name.toLowerCase() === 'xceedance';
}

export function isMgmOrganization(organization) {
  return organization.name.toLowerCase() === 'mgm';
}

export function isHospitalityOrganization(organization) {
  return organization.name.toLowerCase() === 'hard rock' || organization.name.toLowerCase() === 'cherokee nation';
}

export function isRwOrganization(organization) {
  return organization.name.toLowerCase() === 'resorts world';
}

export function isBoostOrganization(organization) {
  return organization.name.toLowerCase() === 'boost';
}

export function isZennerOrganization(organization) {
  return organization.name.toLowerCase() === 'zenner';
}

export function isInshurOrganization(organization) {
  return organization.name.toLowerCase() === 'inshur';
}

export function isInshurUkOrganization(organization) {
  return organization.name.toLowerCase() === 'inshur uk';
}

export function isBoostOrganizationName(organization_name) {
  return organization_name.toLowerCase() === 'boost';
}

export const isExposureFinancialOnly = (organization) =>
  (isBoostOrganization(organization) &&
    !isFeatureEnabled(organization, CONFIGURATION_FEATURES_NAMES.BOOST_COVERAGE_PER_EXPOSURE)) ||
  isMgmOrganization(organization) ||
  isRwOrganization(organization) ||
  isHospitalityOrganization(organization);

export function stringCmp(string1, string2) {
  return string1.toLowerCase().localeCompare(string2.toLowerCase());
}

export function getContactPreferredPhoneNumberOrNull(contact) {
  if (contact.phones.length === 0) {
    return null;
  }

  let preferredPhoneNumber = null;

  contact.phones.forEach((contact_phone) => {
    if (contact_phone.phone_type === 'mobile' && !preferredPhoneNumber) {
      preferredPhoneNumber = contact_phone.phone;
    }
  });

  return preferredPhoneNumber ? preferredPhoneNumber : contact.phones[0].phone;
}

export function getFilenameExtensionWithDot(filename) {
  var re = /(?:\.([^.]+))?$/;

  // (?:         # begin non-capturing group
  //   \.        #   a dot
  //   (         #   begin capturing group (captures the actual extension)
  //     [^.]+   #     anything except a dot, multiple times
  //   )         #   end capturing group
  // )?          # end non-capturing group, make it optional
  // $           # anchor to the end of the string

  return re.exec(filename)[0];
}

export function capitalize(s) {
  if (!s) {
    return '';
  }
  return s.charAt(0).toUpperCase() + s.substring(1);
}

export const allCoveragesWithGeneral = _.merge(
  { general: 'General' },
  HOME_COVERAGES_DICT,
  AUTO_COVERAGES_DICT,
  WC_COVERAGE_DICT,
  GL_COVERAGE_DICT,
  PET_COVERAGE_DICT,
  TRAVEL_COVERAGE_DICT
);

// TODO - should be for the supported organization's claims
// TODO: customClaimTODO add custom claim support
export const allCoveragesListWithGeneralKeys = Object.keys(allCoveragesWithGeneral);

export function isOrganizationUs(organization, locale = 'en-us') {
  return (organization?.configuration?.locale || locale).split('-')[1]?.toUpperCase() === 'US';
}

export const getBasicFnolValues = () => ({
  uuid: uuidv4(),
});

export function isJson(inputString) {
  try {
    return JSON.parse(inputString) && !!inputString;
  } catch (e) {
    return false;
  }
}

const UNSUPPORTED_CAT_CLAIM_TYPES = ['pet_claim', 'travel_claim', 'wc_claim', 'gl_claim'];
const SUPPORTED_CAT_CLAIM_TYPES = ['auto_claim', 'home_claim', 'general_claim'];

export const getIsCatSupportedForOrganization = (organization) =>
  _.intersection(organization.supported_claim_types, UNSUPPORTED_CAT_CLAIM_TYPES).length !==
  organization.supported_claim_types?.length;

export const getIsCatSupportedForClaimType = (claim) => SUPPORTED_CAT_CLAIM_TYPES.includes(claim.type);

export const isReportViewer = (orgAdditionalConfigs, user) =>
  (orgAdditionalConfigs?.report_viewer_user_ids || []).includes(user.id);

export const isDashboardViewer = (orgAdditionalConfigs, user) =>
  (orgAdditionalConfigs?.dashboard_viewer_user_ids || []).includes(user.id);

export const isExtraDashboardViewer = (orgAdditionalConfigs, user) =>
  (orgAdditionalConfigs?.extra_dashboard_viewer_user_ids || []).includes(user.id);

export const getCurrentTabValue = ({ tabs, match }) => {
  const path = location.pathname.substring(match.url.length + 1);
  const currTabValue = Math.max(
    0,
    tabs.findIndex((tab) => path.split('/').includes(tab.url))
  );

  return currTabValue;
};

export const getTabName = () => {
  const path = window.location.pathname;
  const pathParams = path.split('/').filter((path) => !_.isEmpty(path));
  const parentTab = _.get(pathParams, '[1]');
  const tabName = _.get(pathParams, '[2]');

  return {
    parentTab,
    tabName,
  };
};

export const subOrgIdToNameDict = (organization) =>
  organization.sub_organizations_enabled
    ? organization.sub_organizations.reduce((acc, subOrg) => ({ ...acc, [subOrg.id]: subOrg.name }), {})
    : {};

export const subOrgIdToExternalIdDict = (organization) =>
  organization.sub_organizations_enabled
    ? organization.sub_organizations.reduce((acc, subOrg) => ({ ...acc, [subOrg.id]: subOrg.external_id }), {})
    : {};

export const getCurrentDateFormikFormat = () => new Date().toISOString().split('T')[0];

/**
 * performs a depth of 1 conversion from object to array of objects
 * @param {object} obj
 * @param {string} keyProperty
 * @returns {*[]}
 */
export const objectToArray = (obj, keyProperty) => {
  return Object.entries(obj).map(([key, value]) => ({ ...value, [keyProperty]: key }));
};

/**
 * performs a depth of 1 conversion from array of objects to object, taking the key property as the prop key
 * @param arr
 * @param keyProperty
 * @returns {{}}
 */
export const arrayToObject = (arr, keyProperty) => {
  const obj = {};
  arr.forEach((item) => {
    obj[item[keyProperty]] = item;
    delete obj[item[keyProperty]][keyProperty];
  });
  return obj;
};

export const formatSortOrder = (sortByColumn) =>
  sortByColumn && `${sortByColumn.order === 'asc' ? '+' : '-'}${sortByColumn.id}`; // +<colName> for ascending, -<colName> for descending

export const concatRoutes = (...routes) => routes.join('/');

const getKeysRecursive = (obj, path = '') => {
  if (!obj || typeof obj !== 'object') return path;
  return Object.keys(obj).map((key) => getKeysRecursive(obj[key], path ? [path, key].join('.') : key));
};

export const getObjectPaths = (obj) => getKeysRecursive(obj).toString().split(',');

function isEmptyValues(value) {
  return (
    _.isNil(value) ||
    (typeof value === 'object' && Object.keys(value).length === 0) ||
    (typeof value === 'string' && value.trim().length === 0)
  );
}

export const cleanEmptyValues = (values) =>
  !_.isEmpty(values) && Object.fromEntries(Object.entries(values).filter(([_, value]) => !isEmptyValues(value)));

export const nullValuesToEmptyStrings = (values) =>
  Object.fromEntries(Object.entries(values).map(([key, value]) => [key, value === null ? '' : value]));

export const getGaClaimTypes = () => {
  return Object.keys(_.pickBy(CLAIM_TYPES_CONFIGURATION, (value) => value.is_ga));
};

export const getCoveredObjectDisplayName = (coveredObject) => {
  return coveredObject?.display_name || coveredObject?.contact?.full_name || '';
};

export const getIsXactContractor = async (contact, organizationId) => {
  try {
    const { data } = await axios.get(`/api/v1/xactanalysis/${organizationId}/contractors/by_contact_id/${contact.id}`);
    return data;
  } catch (error) {
    reportAxiosError(error);
  }
};

export function compose(...functions) {
  return (comp) => functions.reduceRight((prev, fn) => fn(prev), comp);
}

export const clearUserCache = async () => {
  await localforage.clear();
};

export const logoutCurrentUser = async () => {
  // NGTPA-13621
  await axios.delete('/api/login_support'); // stop_impersonation
  clearTableauAuthTime();
  await clearUserCache();
  window.open('/api/logout', '_self');
};

export const isOrganizationAuOrg = (organization) =>
  !organization?.locale || organization?.locale?.toLowerCase() === 'en-au';

export const isOrganizationUsOrg = (organization) =>
  !organization?.locale || organization?.locale?.toLowerCase() === 'en-us';

export const getOrganizationCountryCode = (organization) => {
  return organization?.locale?.split('-')[1]?.toUpperCase();
};

export const isOrganizationWithStates = (organization) => {
  return isCountryWithStates(getOrganizationCountryCode(organization));
};

export function getAxiosParamsSerializer(arrayFormat) {
  return (params) => querystring.stringify(params, { arrayFormat });
}

export const isCountryWithStates = (country) => {
  return country && COUNTRY_TO_STATE_MAP[country] !== undefined;
};
