import React, { useState } from 'react';
import PropTypes from 'prop-types';
import axios from 'axios';

import { useStyles } from '~/assets/styles';
import { FsButton, Heading } from '~/components/core';
import Grid from '~/components/core/Atomic/Grid/Grid';
import EditCallbackUrls from '~/components/SystemConfiguration/Tabs/DeveloperTools/sections/EventSettings/EditCallbackUrls';
import { ShowOnlyTextField } from '~/components/TextFieldFormik';
import { reportAxiosError } from '~/Utils';

import { useSysconfig } from '../../../../SystemConfigurationScreen';

import WebhooksInnerTable from './WebhooksInnerTable';

import styles from './EventSettings.module.scss';

const WebhookEventCategory = ({ onUpdate, category, heading, webhookEventCategory, eventsData, reloadCategories }) => {
  const [isSubmitting, setIsSubmitting] = useState(false);
  const { organization } = useSysconfig();
  const [isEditingCategoryCallback, setIsEditingCategoryCallback] = useState(false);
  const classes = useStyles();

  const simulateWebhookEvent = async (event_name) => {
    setIsSubmitting(true);
    try {
      await axios.post(`/api/v1/claim_events_webhook/organizations/${organization.id}/test_event`, {
        category,
        event_name,
      });
    } catch (e) {
      await reportAxiosError(e);
    }
    setIsSubmitting(false);
  };

  const handleUpdateCallbackUrls = async (callbackUrls) => {
    setIsSubmitting(true);
    try {
      await onUpdate({ callback_urls: callbackUrls }, category);
      setIsEditingCategoryCallback(false);
    } catch (error) {
      // eslint-disable-next-line no-console
      console.error('Failed to submit callback URLS', error);
    }
    setIsSubmitting(false);
  };

  const areCallbacksExist = webhookEventCategory?.callback_urls?.length;

  return (
    <>
      <Grid container>
        <Grid item xs={12}>
          <Heading variant={Heading.TYPES.H3}>{heading}</Heading>
        </Grid>
        <Grid item xs={8}>
          <ShowOnlyTextField
            classes={classes}
            label="Default Callback URLs"
            showOnlyValueComponent={webhookEventCategory?.callback_urls?.join(', ') || 'None'}
            onEdit={() => setIsEditingCategoryCallback(true)}
          />
        </Grid>
        <Grid item xs={4}>
          <div className={styles.testButtonWrapper}>
            <FsButton
              size="small"
              variant="outlined"
              color="primary"
              disabled={!areCallbacksExist || isSubmitting}
              onClick={() => simulateWebhookEvent(undefined)}
            >
              SEND TEST EVENT
            </FsButton>
          </div>
        </Grid>
        <Grid item xs={12}>
          <WebhooksInnerTable
            webhookEventCategory={webhookEventCategory}
            eventsData={eventsData}
            category={category}
            showOnly={!areCallbacksExist || isSubmitting}
            onUpdate={onUpdate}
            reloadCategories={reloadCategories}
            simulateWebhookEvent={simulateWebhookEvent}
          />
        </Grid>
      </Grid>
      {isEditingCategoryCallback && (
        <EditCallbackUrls
          callbackUrls={webhookEventCategory?.callback_urls}
          onSubmit={handleUpdateCallbackUrls}
          onCancel={() => setIsEditingCategoryCallback(false)}
        />
      )}
    </>
  );
};

WebhookEventCategory.propTypes = {
  onUpdate: PropTypes.func,
  heading: PropTypes.string,
  webhookEventCategory: PropTypes.object,
  eventsData: PropTypes.array,
  category: PropTypes.string,
  reloadCategories: PropTypes.func,
};

export default WebhookEventCategory;
