import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import PropTypes from 'prop-types';
import { Checkbox, FormControlLabel } from '@material-ui/core';
import axios from 'axios';
import { v4 as uuidv4 } from 'uuid';

import { CreateContactDialog } from '~/components/Contacts/UpsertContact/Create/CreateContactDialog';
import Grid from '~/components/core/Atomic/Grid/Grid';
import { FnolAiInboxContainer } from '~/components/Fnol/FnolAiInbox/FnolAiInboxContainer';
import { useFnolAiInbox } from '~/components/Fnol/FnolAiInbox/hooks/useFnolAiInbox';

import { FNOL_MIXPANEL_EVENTS } from '../../pocs/mixpanel';
import { CONFIGURATION_FEATURES_NAMES } from '../../Types';
import { isFeatureEnabled, reportAxiosError } from '../../Utils';
import AutoFnol from '../Auto/AutoFnol';
import CardDialog from '../CardDialog';
import mixpanel from '../CmsMain/mixpanel';
import { useCms } from '../hooks/useCms';
import useCurrentUserRoles from '../hooks/useCurrentUserRoles';
import useOrganization from '../OrganizationContext';
import { PolicyContextProvider } from '../PolicyContainer';
import PolicySearchContainer from '../PolicySearch';
import { ShowOnlyTextField } from '../TextFieldFormik';

import NewFnolScreen from './NewFnolUI/FnolScreen';
import { OrganizationSelectDialog } from './FnolContainer';
import FnolDraftsContainer from './FnolDraft';
import HomeFnolPage from './HomeFnolPage';
import LossWithoutPolicyCard from './LossWithoutPolicyCard';

import { useStyles } from '../../assets/styles';

export const trackOldFnolClick = (policy, fnol_type, reported_date) => {
  mixpanel.track(FNOL_MIXPANEL_EVENTS.CREATE_FNOL_CLICKED, {
    reporting_type: policy.is_manual ? 'reported without policy' : 'reported from existing policy',
    fnol_type,
    reported_date,
  });
};

const SUPPORTED_POLICIES = ['home_policy', 'auto_policy', 'pet_policy', 'general_policy'];
const INITIAL_COMMUNICATION_TEXT =
  'We are terribly sorry to hear about your loss. Dealing with something like this is never easy. We are here to provide you assistance and help you get through this trying time.\n\n We need to advise you that we cannot commit to coverage for your claim until a full investigation has been completed, however you should take reasonable to steps to mitigate any further damage';
const EXPRESSIONS_TEXT = (
  <span>
    I expressed <strong>sympathy</strong>, qualified <strong>coverage</strong> and advised insured to{' '}
    <strong>mitigate</strong> damages
  </span>
);

function FnolScreen() {
  const history = useHistory();
  const classes = useStyles();
  const { user, userOrganization } = useCms();
  const { isPolicyAPIEnabled } = useOrganization();
  const { isAllowedForUser: isFnolAiInboxAllowedForUser } = useFnolAiInbox({});

  const [expressionVerified, setExpressionVerified] = useState(false);
  const [manuallyFillingInsuredDetails, setManuallyFillingInsuredDetails] = useState(false);
  const [manuallyFillingOrganization, setManuallyFillingOrganization] = useState(false);
  const [manualPolicyType, setManualPolicyType] = useState(undefined);
  const [manualPolicyLob, setManualPolicyLob] = useState(undefined);
  const [manualPolicyOrganization, setManualPolicyOrganization] = useState(
    user.is_super_organization ? undefined : userOrganization
  );
  const [fnolDraft, setFnolDraft] = useState(undefined);
  const [savedFnolDraftId, setSavedFnolDraftId] = useState(undefined);
  const [communicationId, setCommunicationId] = useState(undefined);
  const [fnolAiRecommendationId, setFnolAiRecommendationId] = useState(undefined);

  const [chosenPolicyAndClaimValues, setChosenPolicyAndClaimValues] = useState(null);
  const [selectedSubOrgId, setSelectedSubOrgId] = useState(undefined);
  const [showLossWithoutPolicyCard, setShowLossWithoutPolicyCard] = useState(true);
  const [showCliveWarning, setShowCliveWarning] = useState(false);
  const isConfigurableFnolEnabled = isFeatureEnabled(userOrganization, CONFIGURATION_FEATURES_NAMES.CONFIGURABLE_FNOL);

  const { isUserPhoneRep, isUserFromSuperOrganization } = useCurrentUserRoles();

  const handleLossWithoutPolicy = ({ subOrgId, lob }) => {
    setManualPolicyLob(lob);
    if (isConfigurableFnolEnabled) {
      if (lob === 'auto_claim') {
        setManualPolicyType('auto_policy');
      } else {
        setManualPolicyType('general_policy');
      }
    } else {
      setManualPolicyType(lob.replace('_claim', '_policy'));
    }
    setSelectedSubOrgId(subOrgId);
    setManuallyFillingInsuredDetails(true);
    setShowLossWithoutPolicyCard(false);
  };

  const handleFnolSubmitted = (claimId) => {
    if (savedFnolDraftId) {
      // best effort, we don't wait and don't check for the return value
      axios.delete(`/api/v1/fnol_drafts/${savedFnolDraftId}`);
    }
    setChosenPolicyAndClaimValues(null);
    setExpressionVerified(false);
    if (!isUserPhoneRep) {
      history.push(`/claims/${claimId}`);
    }
  };

  const handlePolicyNotFound = (policyType) => {
    setManualPolicyType(policyType);
    setManualPolicyLob(policyType);
    if (isUserFromSuperOrganization) {
      setManuallyFillingOrganization(true);
    } else {
      setManuallyFillingInsuredDetails(true);
    }
  };

  const handleSubmitDraft = async (values) => {
    try {
      if (isPolicyAPIEnabled || isConfigurableFnolEnabled) {
        if (savedFnolDraftId) {
          await axios.put(`/api/v1/fnol_drafts/${savedFnolDraftId}`, values);
        } else {
          const res = await axios.post('/api/v1/fnol_drafts', values);
          setSavedFnolDraftId(res.data.id);
        }
      } else {
        await axios.post(`/api/v1/claims/fnol/drafts/${values['policy_id']}`, values);
      }
    } catch (error) {
      reportAxiosError(error);
    }
  };

  const handleDraftSelected = (fnolDraft, selectionSource) => {
    mixpanel.track(FNOL_MIXPANEL_EVENTS.LOAD_DRAFT_CLICKED, {
      draft_selection_source: selectionSource,
    });
    setFnolDraft(fnolDraft);
    setSavedFnolDraftId(fnolDraft.id);
  };

  const handleFnolAiInboxNewLoss = ({ fnolDraft, communicationId, fnolAiRecommendationId, subOrgId, lob }) => {
    setCommunicationId(communicationId);
    setFnolAiRecommendationId(fnolAiRecommendationId);

    if (fnolDraft) {
      setFnolDraft(fnolDraft);
      setSavedFnolDraftId(fnolDraft.id);
      setShowCliveWarning(false);
    } else {
      setShowCliveWarning(true);
      handleLossWithoutPolicy({ subOrgId, lob });
    }
  };

  useEffect(() => {
    if (fnolDraft) {
      const policy = isConfigurableFnolEnabled ? fnolDraft.draft.policy : fnolDraft.draft;
      setChosenPolicyAndClaimValues({ policy, claimValues: {} });
      setManuallyFillingInsuredDetails(false);
    }
  }, [fnolDraft, isConfigurableFnolEnabled, showLossWithoutPolicyCard]);

  const handleInsuredDetailsFilled = (insuredDetails) => {
    const subOrganization = selectedSubOrgId
      ? userOrganization.sub_organizations.find((subOrganization) => subOrganization.id === selectedSubOrgId)
      : null;

    const lobIncidentConfigurationsDict = subOrganization
      ? subOrganization.lob_incident_configurations_dict
      : userOrganization.lob_incident_configurations_dict;
    const incidentConfiguration = lobIncidentConfigurationsDict[manualPolicyType.replace('_policy', '_claim')];
    const supportedLobs = subOrganization ? subOrganization?.supported_lobs : userOrganization?.supported_lobs;
    const supportedLob = supportedLobs?.find(({ lob }) => lob === manualPolicyLob);
    const isClaimsMade = supportedLob?.is_claims_made || false;

    const newManualPolicy = {
      type: manualPolicyType,
      lob: manualPolicyLob,
      is_manual: true,
      id: uuidv4(),
      contacts: [insuredDetails],
      organization_id: manualPolicyOrganization.id,
      sub_organization: subOrganization,
      incident_configuration: incidentConfiguration,
      client: manualPolicyOrganization.name,
      insured_contact: insuredDetails,
      insured_contact_id: insuredDetails.id,
      insured_contact_full_name: insuredDetails.full_name,
      insured_property_location: {
        address1: insuredDetails.street_address1,
        address2: insuredDetails.street_address2,
        city: insuredDetails.city,
        county: insuredDetails.county,
        state: insuredDetails.state,
        country: insuredDetails.country,
        zipcode: insuredDetails.zipcode,
      },
      is_claims_made: isClaimsMade,
    };

    setChosenPolicyAndClaimValues({ policy: newManualPolicy, claimValues: {} });
    setManuallyFillingInsuredDetails(false);
  };

  if (chosenPolicyAndClaimValues) {
    const chosenPolicy = chosenPolicyAndClaimValues.policy;
    if (!SUPPORTED_POLICIES.includes(chosenPolicy.type)) {
      throw Error(`Unknown policy type: ${chosenPolicy.type}`);
    }

    return (
      <PolicyContextProvider policy={chosenPolicy}>
        {isConfigurableFnolEnabled && ['auto_policy', 'general_policy'].includes(chosenPolicy.type) && (
          <NewFnolScreen
            onSubmitFnol={handleFnolSubmitted}
            claimValues={chosenPolicyAndClaimValues.claimValues}
            onSubmitDraft={handleSubmitDraft}
            fnolDraft={fnolDraft}
            subOrganizationId={selectedSubOrgId}
            communicationId={communicationId}
            fnolAiRecommendationId={fnolAiRecommendationId}
            showCliveWarning={showCliveWarning}
          />
        )}
        {!isConfigurableFnolEnabled && chosenPolicy.type === 'auto_policy' && (
          <AutoFnol
            onSubmitFnol={handleFnolSubmitted}
            claimValues={chosenPolicyAndClaimValues.claimValues}
            onSubmitDraft={handleSubmitDraft}
            fnolDraft={fnolDraft}
            subOrganizationId={selectedSubOrgId}
          />
        )}
        {!isConfigurableFnolEnabled && chosenPolicy.type === 'home_policy' && (
          <HomeFnolPage
            onSubmit={handleFnolSubmitted}
            claimValues={chosenPolicyAndClaimValues.claimValues}
            onSubmitDraft={handleSubmitDraft}
            fnolDraft={fnolDraft}
            subOrganizationId={selectedSubOrgId}
          />
        )}
      </PolicyContextProvider>
    );
  }

  if (manuallyFillingInsuredDetails) {
    return (
      <InsuredDetailsFormDialog
        onCancel={() => {
          setManuallyFillingInsuredDetails(false);
          setShowLossWithoutPolicyCard(true);
        }}
        onInsuredDetailsFilled={handleInsuredDetailsFilled}
      />
    );
  }

  if (manuallyFillingOrganization) {
    return (
      <OrganizationSelectDialog
        isDialog
        onCancel={() => setManuallyFillingOrganization(false)}
        onSelectOrganization={(org) => {
          setManualPolicyOrganization(org);
          setManuallyFillingInsuredDetails(true);
          setManuallyFillingOrganization(false);
        }}
      />
    );
  }

  const handleSelectPolicy = (policy, claimValues) => {
    setChosenPolicyAndClaimValues({ policy, claimValues });
    mixpanel.track(FNOL_MIXPANEL_EVENTS.REPORT_A_NEW_LOSS_CLICKED_WITH_POLICY_FNOL);
  };

  return (
    <>
      <Grid container justify="center">
        {isUserPhoneRep && (
          <Grid item xs={12}>
            <div className={classes.cardDivRow}>
              <CardDialog title="Initial Communication">
                <ShowOnlyTextField
                  label=""
                  classes={classes}
                  showOnlyValueComponent={INITIAL_COMMUNICATION_TEXT}
                  fullWidth
                  multiline
                />
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={expressionVerified}
                      onChange={(event) => setExpressionVerified(event.target.checked)}
                      color="primary"
                    />
                  }
                  label={EXPRESSIONS_TEXT}
                />
              </CardDialog>
            </div>
          </Grid>
        )}
        <Grid item xs={12}>
          <div className={classes.cardDivRow}>
            <PolicySearchContainer
              disabled={!expressionVerified && isUserPhoneRep}
              enableSelectingRelatedClaim
              choosePolicyFromRelatedClaimsText="Report New Loss"
              onSelectPolicy={handleSelectPolicy}
              onPolicyNotFound={(policyType) => handlePolicyNotFound(policyType)}
              policyTypes={userOrganization.supported_claim_types.map((claimType) =>
                claimType.replace('_claim', '_policy')
              )}
              onFnolDraftSelected={(fnolDraft) => handleDraftSelected(fnolDraft, 'related_to_policy')}
              allowSearchByAddress
            />
          </div>
        </Grid>
        {showLossWithoutPolicyCard && (
          <Grid item xs={12}>
            <div className={classes.cardDivRow}>
              <LossWithoutPolicyCard onTakeLoss={handleLossWithoutPolicy} />
            </div>
          </Grid>
        )}
        {isConfigurableFnolEnabled && (
          <Grid item xs={12}>
            <div className={classes.cardDivRow}>
              <FnolDraftsContainer onSelectDraft={(fnolDraft) => handleDraftSelected(fnolDraft, 'all_drafts')} />
            </div>
          </Grid>
        )}
        {isFnolAiInboxAllowedForUser && (
          <Grid item xs={12}>
            <div className={classes.cardDivRow}>
              <FnolAiInboxContainer onNewLoss={handleFnolAiInboxNewLoss} />
            </div>
          </Grid>
        )}
      </Grid>
    </>
  );
}

function InsuredDetailsFormDialog(props) {
  const { onCancel, onInsuredDetailsFilled } = props;

  return (
    <CreateContactDialog
      onClose={onCancel}
      onContactCreate={onInsuredDetailsFilled}
      title="Fill Insured Details"
      acceptedRoles={['insured']}
      editingRoleDisabled
    />
  );
}

InsuredDetailsFormDialog.propTypes = {
  onInsuredDetailsFilled: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
};

export default FnolScreen;
export { InsuredDetailsFormDialog };
