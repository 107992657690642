import React from 'react';

import CardDialog from '~/components/CardDialog';
import { Heading } from '~/components/core';
import AlertBanner from '~/components/core/AlertBanner';
import SubOrganizationConfiguration from '~/components/SystemConfiguration/CliveConfiguration/SubOrganizationConfigurationTable';
import OperationsBreadcrumbs from '~/components/SystemConfiguration/OperationsBreadcrumbs';
import { useSysconfig } from '~/components/SystemConfiguration/SystemConfigurationScreen';
import type { OrganizationModel } from '~/components/types/organization-types';

const CliveConfiguration: React.FC = () => {
  const { organization } = useSysconfig() as { organization: OrganizationModel };

  return (
    <CardDialog noCardTitle>
      <OperationsBreadcrumbs currentTab="Clive Configuration" />
      <div className="grid grid-cols-2 items-center">
        <Heading className="mt-20">Clive Configuration</Heading>
      </div>
      {organization.sub_organizations_enabled ? (
        <SubOrganizationConfiguration />
      ) : (
        <AlertBanner
          className="m-40"
          alertType="warning"
          note="Sub Organization must be enabled."
          title="Failed to configure Clive"
          withIcon
        />
      )}
    </CardDialog>
  );
};

export default CliveConfiguration;
