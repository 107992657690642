import React from 'react';
import { Route, Switch, useRouteMatch } from 'react-router-dom';

import EmptyState from '~/components/core/EmptyState';
import { useCms } from '~/components/hooks/useCms';
import { EmptyReportsIllustration } from '~/components/illustrations';
import { TableauContextProvider } from '~/components/ReportsPage/Context/TableauContext';
import { useTableauApiRoutes } from '~/components/ReportsPage/Hooks/useTableauApiRoutes';
import { WorkbookCreatorPage } from '~/components/ReportsPage/pages/WorkbookCreatorPage';
import { WorkbookEditorPage } from '~/components/ReportsPage/pages/WorkbookEditorPage';
import { WorkbookViewerPage } from '~/components/ReportsPage/pages/WorkbookViewerPage';
import { ReportsTabs } from '~/components/ReportsPage/Tabs/ReportsTabs';
import useDataFetcher from '~/components/useDataFetcher';

export const ReportsPage: React.FC = () => {
  const { setPageTitle } = useCms();
  const match = useRouteMatch();
  const { hasTableauAccountUrl } = useTableauApiRoutes();

  const { data } = useDataFetcher(hasTableauAccountUrl);
  const hasTableauAccount = !!data?.hasTableauAccount;

  React.useEffect(() => {
    setPageTitle('Reports & Dashboards');
  }, [setPageTitle]);

  return (
    <>
      {!hasTableauAccount && (
        <EmptyState
          title="This Feature is available for users with Tableau account access."
          subtitle="Please contact your admin to upgrade"
          illustration={<EmptyReportsIllustration />}
          className="flex h-full flex-col items-center justify-center"
        />
      )}

      {hasTableauAccount && (
        <TableauContextProvider>
          <Switch>
            <Route
              path={`${match.path}/reports-dashboards/:workbookId/edit`}
              render={(routerProps) => <WorkbookEditorPage workbookId={routerProps.match.params.workbookId} />}
            />
            <Route
              path={`${match.path}/my-drafts/:workbookId/edit`}
              render={(routerProps) => <WorkbookEditorPage workbookId={routerProps.match.params.workbookId} />}
            />
            <Route
              path={`${match.path}/reports-dashboards/:workbookId`}
              render={(routerProps) => <WorkbookViewerPage workbookId={routerProps.match.params.workbookId} />}
            />
            <Route
              path={`${match.path}/my-drafts/:workbookId`}
              render={(routerProps) => <WorkbookViewerPage workbookId={routerProps.match.params.workbookId} />}
            />
            <Route path={`${match.path}/create`} render={() => <WorkbookCreatorPage />} />
            <Route path={match.path} render={() => <ReportsTabs />} />
          </Switch>
        </TableauContextProvider>
      )}
    </>
  );
};
