import React from 'react';
import requiredIf from 'react-required-if';
import PropTypes from 'prop-types';
import { capitalize } from 'lodash';

import { withSafeComponent } from '~/components/core/SafeComponent';
import StatusChip from '~/components/core/StatusChip';
import { useLobConfiguration } from '~/components/hooks/useLobConfiguration';

import { isoDateToUs, serverDateToLocal, serverDateToLocalMoment } from '../DateTimeUtils';
import { CONFIGURATION_FEATURES_NAMES, COUNTRIES_DICT, MGM_WC_CAUSE_CODE_DICT, PET_PERILS_DICT } from '../Types';
import {
  isBoostOrganizationName,
  isBoostUser,
  isFeatureEnabled,
  isHospitalityUser,
  isMgmUser,
  isRwUser,
  stringCmp,
} from '../Utils';
import { getLobIcon } from '../Utils/lobUtils';

import { paginationPropsPropTypesShape } from './core/Tables/SortableTable/propTypes';
import { useCms } from './hooks/useCms';
import ClaimLink from './ClaimLink';
import { ContactEntity } from './Contact';
import { SortableTable } from './core';
import useOrganization from './OrganizationContext';

function getIncidentType(claim) {
  // customer-specific LOBs
  if (claim.type === 'gl_claim') return claim.cause_codes_dict[claim.cause_code]?.desc;
  else if (claim.type === 'wc_claim') return MGM_WC_CAUSE_CODE_DICT[claim.cause_code]?.desc;
  else if (claim.type === 'pet_claim' && isBoostOrganizationName(claim.organization_name))
    return PET_PERILS_DICT[claim.incident_type]['desc'];
  else {
    // mainstream standard implementation
    return claim.incident_type_desc;
  }
}

function getIncidentSubType(claim) {
  // customer-specific LOBs
  if (claim.type === 'gl_claim') return claim.source_codes_dict[claim.source_code]?.['desc'];
  else if (
    (claim.type === 'pet_claim' && isBoostOrganizationName(claim.organization_name)) ||
    claim.type === 'wc_claim'
  )
    return '';
  // mainstream standard implementation
  else return claim.incident_sub_type_desc;
}

const getClaimsTableColumnData = (
  trackingInfo,
  onClaimLinkClick = undefined,
  lobConfigurationsDict = {},
  isMultipleCountriesEnabled = false
) => {
  const claimsTableColumns = [
    // stopPropagation from Link, otherwise, Link will be followed but Table onClick will also occur
    {
      id: 'id',
      numeric: false,
      width: '160px',
      disablePadding: false,
      label: 'Claim',
      // eslint-disable-next-line react/display-name
      specialCell: (claim) => (
        <div style={{ display: 'flex', alignItems: 'center', flexWrap: 'nowrap' }}>
          {getLobIcon({ lob: claim?.lob, lobConfigurationsDict })}
          <span style={{ paddingLeft: '6px' }}>
            <ClaimLink
              claimId={claim.id}
              linkText={claim.claim_id_display}
              trackingInfo={trackingInfo}
              onClick={onClaimLinkClick}
            />
          </span>
        </div>
      ),
      specialCmpFunc: (row1, row2) => row1.id - row2.id,
    },
    {
      id: 'insured_contact_full_name',
      numeric: false,
      disablePadding: false,
      label: 'Named Insured',
      // eslint-disable-next-line react/display-name
      specialCell: (row) =>
        row.insured_contact_id ? (
          <ContactEntity contactId={row.insured_contact_id} contactDisplayName={row.insured_contact_full_name} />
        ) : null,
    },
    {
      id: 'peril_or_claim_type',
      numeric: false,
      disablePadding: false,
      label: (
        <>
          <span style={{ whiteSpace: 'nowrap' }}>Type /</span>
          &nbsp;
          <span style={{ whiteSpace: 'nowrap' }}>Peril</span>
        </>
      ),
      specialCell: (claim) => getIncidentType(claim),
      specialCmpFunc: (claim1, claim2) =>
        claim1.type !== claim2.type
          ? stringCmp(claim1.type, claim2.type)
          : stringCmp(getIncidentType(claim1), getIncidentType(claim2)),
    },
    {
      id: 'cause_of_loss_or_claim_sub_type',
      numeric: false,
      disablePadding: false,
      label: (
        <>
          <span style={{ whiteSpace: 'nowrap' }}>Sub-type /</span>
          &nbsp;
          <span style={{ whiteSpace: 'nowrap' }}>Cause of Loss</span>
        </>
      ),
      specialCell: (claim) => getIncidentSubType(claim),
      specialCmpFunc: (claim1, claim2) => {
        if (claim1.type !== claim2.type) return stringCmp(claim1.type, claim2.type);
        else if (getIncidentType(claim1) !== getIncidentType(claim2))
          return stringCmp(getIncidentType(claim1), getIncidentType(claim2));
        else return stringCmp(getIncidentSubType(claim1), getIncidentSubType(claim2));
      },
    },
    {
      id: 'date_of_loss',
      width: '130px',
      numeric: false,
      disablePadding: false,
      label: 'Date of Loss',
      specialCell: (row) => isoDateToUs(row.date_of_loss),
      specialCmpFunc: (row1, row2) => stringCmp(row1.date_of_loss, row2.date_of_loss),
    },
    {
      id: 'creation_date',
      width: '130px',
      numeric: false,
      disablePadding: false,
      label: 'Created Date',
      specialCell: (row) => serverDateToLocalMoment(row.creation_date, false),
      specialCmpFunc: (row1, row2) => stringCmp(row1.creation_date, row2.creation_date),
    },
    {
      id: 'reported_date',
      width: '130px',
      numeric: false,
      disablePadding: false,
      label: 'Date of Report',
      specialCell: (row) => (row?.reported_date ? isoDateToUs(row.reported_date) : ''),
      specialCmpFunc: (row1, row2) =>
        row1?.reported_date && row2?.reported_date && stringCmp(row1.reported_date, row2.reported_date),
    },
    { id: 'handling_adjuster', numeric: false, disablePadding: false, label: 'File Owner' },
    {
      id: 'last_touched',
      width: '135px',
      numeric: false,
      disablePadding: false,
      label: 'Last Touched',
      specialCell: (row) => (row.last_touched ? serverDateToLocal(row.last_touched) : ''),
      specialCmpFunc: (row1, row2) => stringCmp(row1.last_touched || '', row2.last_touched || ''),
    },
    // eslint-disable-next-line react/display-name
    {
      id: 'is_closed',
      width: '70px',
      numeric: false,
      disablePadding: false,
      label: 'Status',
      specialCell: (claim) =>
        claim.is_closed ? (
          <StatusChip status={StatusChip.STATUSES.CLOSED} />
        ) : (
          <StatusChip status={StatusChip.STATUSES.OPEN} />
        ),
    },
    {
      id: 'date_closed',
      width: '135px',
      numeric: false,
      disablePadding: false,
      label: 'Closed Date',
      specialCell: (row) => (row.date_closed ? serverDateToLocal(row.date_closed) : ''),
      specialCmpFunc: (row1, row2) => stringCmp(row1.date_closed || '', row2.date_closed || ''),
    },
  ];

  if (isMultipleCountriesEnabled) {
    const date_of_loss_index = claimsTableColumns.findIndex((column) => column.id === 'date_of_loss');

    claimsTableColumns.splice(date_of_loss_index, 0, {
      id: 'policy_country',
      numeric: false,
      label: 'Policy Country',
      disableSort: true,
      specialCell: (claim) => COUNTRIES_DICT[claim.policy_country],
    });
  }

  return claimsTableColumns;
};

const getMGMClaimsTableColumnData = (trackingInfo, onClaimLinkClick = undefined, organizationPreferences) => {
  const { incidentTypesDict } = organizationPreferences;

  const defaultColumnData = getClaimsTableColumnData(trackingInfo, onClaimLinkClick);

  let mgmColumnData = defaultColumnData.map((column) => {
    switch (column.id) {
      case 'insured_contact_full_name':
        return {
          id: 'claimant_contact_full_name',
          numeric: false,
          disablePadding: false,
          disableSort: true,
          label: 'Claimant',
          // eslint-disable-next-line react/display-name
          specialCell: (claim) =>
            claim.claimant_contact_id ? (
              <ContactEntity
                contactId={claim.claimant_contact_id}
                contactDisplayName={claim.claimant_contact_full_name}
              />
            ) : null,
        };
      case 'peril_or_claim_type':
        return {
          id: 'cause_code',
          numeric: false,
          disablePadding: false,
          label: 'Cause Code',
          specialCell: (claim) => getIncidentType(claim),
          specialCmpFunc: (claim1, claim2) => stringCmp(getIncidentType(claim1), getIncidentType(claim2)),
        };
      case 'cause_of_loss_or_claim_sub_type':
        return {
          id: 'source_code',
          numeric: false,
          disablePadding: false,
          label: 'Source Code',
          specialCell: (claim) => getIncidentSubType(claim),
          specialCmpFunc: (claim1, claim2) => stringCmp(getIncidentSubType(claim1), getIncidentSubType(claim2)),
        };
      default:
        return column;
    }
  });

  incidentTypesDict &&
    mgmColumnData.splice(1, 0, {
      id: 'claim_subtype',
      numeric: false,
      disablePadding: false,
      label: 'Type',
      specialCell: (claim) => capitalize(getClaimType(claim, incidentTypesDict)),
      specialCmpFunc: (claim1, claim2) =>
        stringCmp(getClaimType(claim1, incidentTypesDict), getClaimType(claim2, incidentTypesDict)),
    });

  return mgmColumnData;
};

const getClaimType = (claim, incidentTypesDict) => incidentTypesDict[claim.type]?.[claim.subtype]?.type || '';

const getBoostClaimsTableColumnData = (trackingInfo, onClaimLinkClick = undefined) => {
  const defaultColumnData = getClaimsTableColumnData(trackingInfo, onClaimLinkClick);

  return defaultColumnData.filter((column) => column.id !== 'cause_of_loss_or_claim_sub_type');
};

const getClaimsTableColumnsByUser = (
  user,
  trackingInfo,
  onClaimLinkClick,
  organizationPreferences,
  lobConfigurationDict = {}
) => {
  let columnData;
  if (isMgmUser(user) || isRwUser(user) || isHospitalityUser(user)) {
    columnData = getMGMClaimsTableColumnData(trackingInfo, onClaimLinkClick, organizationPreferences);
  } else if (isBoostUser(user)) {
    columnData = getBoostClaimsTableColumnData(trackingInfo, onClaimLinkClick);
  } else {
    columnData = getClaimsTableColumnData(
      trackingInfo,
      onClaimLinkClick,
      lobConfigurationDict,
      organizationPreferences.isMultipleCountriesEnabled
    );
  }
  return columnData;
};

function ClaimsTable({
  claims,
  hideHandlingAdjuster,
  maxHeight,
  hideReportedDate,
  hideLastTouchedDate,
  paginationProps,
  onSortByColumn,
  trackingInfo,
  onClaimLinkClick,
}) {
  const { user, userOrganization } = useCms();
  const organizationPreferences = useOrganization();
  const { lobConfigurationsDict } = useLobConfiguration();

  const isNewFnolEnabled = isFeatureEnabled(userOrganization, CONFIGURATION_FEATURES_NAMES.CONFIGURABLE_FNOL);

  let columnData = getClaimsTableColumnsByUser(
    user,
    trackingInfo,
    onClaimLinkClick,
    organizationPreferences,
    lobConfigurationsDict
  );

  if (hideHandlingAdjuster) {
    columnData = columnData.filter((column) => column.id !== 'handling_adjuster');
  }

  if (hideReportedDate) {
    columnData = columnData.filter((column) => column.id !== 'creation_date');
  }

  if (hideLastTouchedDate) {
    columnData = columnData.filter((column) => column.id !== 'last_touched');
  }

  if (!isNewFnolEnabled) {
    columnData = columnData.filter((column) => column.id !== 'reported_date');
  }

  return (
    <SortableTable
      rows={claims}
      columns={columnData}
      defaultOrderColumn={columnData.findIndex(
        (column) => column.id === (hideLastTouchedDate ? 'date_of_loss' : 'last_touched')
      )}
      maxHeight={maxHeight}
      order="desc"
      stickyHeader
      paginationProps={paginationProps}
      onSortByColumn={onSortByColumn}
    />
  );
}

ClaimsTable.propTypes = {
  claims: PropTypes.array.isRequired,
  hideReportedDate: PropTypes.bool,
  hideLastTouchedDate: PropTypes.bool,
  hideHandlingAdjuster: PropTypes.bool,
  maxHeight: PropTypes.string,
  paginationProps: paginationPropsPropTypesShape,
  onSortByColumn: requiredIf(PropTypes.func, (props) => props.paginationProps),
  trackingInfo: PropTypes.string,
  onClaimLinkClick: PropTypes.bool,
};

export { getClaimsTableColumnData, getClaimsTableColumnsByUser };
export default withSafeComponent(ClaimsTable, null, {
  criticalityLevel: true,
  location: 'claims_table',
});
