import React from 'react';

import ConfigurationScreenWithTabs from '~/components/SystemConfiguration/ConfigurationScreenWithTabs';
import NotificationsCategories from '~/components/SystemConfiguration/NotificationsConfiguration/NotificationsCategories';
import NotificationsPreferences from '~/components/SystemConfiguration/NotificationsConfiguration/NotificationsPreferences';
import { ADMIN_ROUTES } from '~/routesConstants';

const NotificationsConfiguration: React.FC = () => {
  const tabs = [
    {
      label: 'Categories',
      url: ADMIN_ROUTES.NOTIFICATIONS_CONFIGURATION.CATEGORIES,
      component: NotificationsCategories,
      isEnabled: true,
    },
    {
      label: 'Preferences',
      url: ADMIN_ROUTES.NOTIFICATIONS_CONFIGURATION.PREFERENCES,
      component: NotificationsPreferences,
      isEnabled: true,
    },
  ];

  return (
    <ConfigurationScreenWithTabs
      tabs={tabs}
      title="NOTIFICATIONS CONFIGURATION"
      subtitle="Configure notification categories and manage notification preferences"
      operationTabName="Notifications Configuration"
    />
  );
};

export default NotificationsConfiguration;
