import React from 'react';

import Editor from '~/components/core/Editor';
import Caption from '~/components/core/TextComponents/Caption';

interface GenericTemplateContentContainerProps {
  titleTemplate?: string;
  titleTemplateLabel?: string;
  bodyTemplate: string;
  bodyTemplateLabel?: string;
  className?: string;
  templateComment?: string;
  templateCommentLabel?: string;
}

const GenericTemplateContentContainer: React.FC<GenericTemplateContentContainerProps> = ({
  titleTemplateLabel = 'Subject:',
  titleTemplate,
  bodyTemplateLabel = 'Body:',
  bodyTemplate,
  className,
  templateCommentLabel = 'Comment:',
  templateComment,
}) => {
  return (
    <div className={className}>
      {titleTemplate ? (
        <div className="m-12">
          <Caption variant={Caption.VARIANTS.LABEL}>{titleTemplateLabel}</Caption>
          <Editor disabled content={titleTemplate} containerClassName="border-0" />
        </div>
      ) : null}
      {bodyTemplate ? (
        <div className="m-12">
          <Caption variant={Caption.VARIANTS.LABEL}>{bodyTemplateLabel}</Caption>
          <Editor disabled containerClassName="border-0" content={bodyTemplate} />
        </div>
      ) : null}
      {templateComment ? (
        <div className="m-12">
          <Caption variant={Caption.VARIANTS.LABEL}>{templateCommentLabel}</Caption>
          <Editor disabled containerClassName="border-0" content={templateComment} />
        </div>
      ) : null}
    </div>
  );
};

export default GenericTemplateContentContainer;
