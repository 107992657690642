import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import PropTypes from 'prop-types';
import axios from 'axios';
import { Formik } from 'formik';
import * as Yup from 'yup';

import { useStyles } from '~/assets/styles';
import Button from '~/components/core/Atomic/Buttons/Button';
import { useLobConfiguration } from '~/components/hooks/useLobConfiguration';
import { reportAxiosError, reportErrorInProductionOrThrow } from '~/Utils';
import { useFetchClaim } from '~/Utils/ClaimUtils';
import { getLobIcon } from '~/Utils/lobUtils';

import { COMMUNICATION_CHANNEL_DICT } from '../../Types';
import CardDialog from '../CardDialog';
import { ClaimContextProvider } from '../ClaimContainer';
import ClaimLink from '../ClaimLink';
import ClaimSearchContainer from '../ClaimSearch';
import { ContactEntity } from '../Contact';
import ContactTextFieldFormik from '../ContactTextFieldFormik';
import { Heading, LoadingSwitch, SortableTable } from '../core';
import { ErrorHelperTextFormik } from '../core/Formik/ErrorHelperTextFormik';
import { useCms } from '../hooks/useCms';
import LoadingDialog from '../LoadingDialog';
import useOrganization from '../OrganizationContext';
import RadioButtonFormik from '../RadioButtonFormik';
import useDataFetcher from '../useDataFetcher';

import { getAllSearchableContactRoles } from './ContactUtils';
import ViewCommunicationCardContainer from './ViewCommunicationCardContainer';

function AttachCommunicationPage(props) {
  const history = useHistory();
  const { communicationId } = props;
  const classes = useStyles();

  const handleUpdate = (communication) => {
    if (communication?.claim_id) {
      history.push(`/claims/${communication.claim_id}/communications`);
    }
  };

  return (
    <div className={classes.cardDivRow} style={{ overflow: 'auto' }}>
      <CardDialog trackAlt={`Communication ${communicationId}`}>
        <ViewCommunicationCardContainer communicationId={communicationId} onUpdate={handleUpdate} />
      </CardDialog>
    </div>
  );
}
AttachCommunicationPage.propTypes = {
  communicationId: PropTypes.number.isRequired,
};

const AttachCommunicationContainer = ({ communication, onAttach, onAttachOverride }) => {
  const { emailDomains } = useOrganization();
  const { userOrganization } = useCms();
  const classes = useStyles();

  const {
    isLoading,
    isError,
    data: possibleClaimsToAttach,
  } = useDataFetcher(`/api/v1/communications/${communication.id}/possible_claims_to_attach`);
  const { lobConfigurationsDict } = useLobConfiguration();

  const possibleClaimsColumns = [
    { id: 'type', width: '10px', specialCell: (claim) => getLobIcon({ lob: claim.lob, lobConfigurationsDict }) },
    // stopPropagation from Link, otherwise, Link will be followed but Table onClick will also occur
    {
      id: 'id',
      numeric: false,
      width: '160px',
      disablePadding: false,
      label: 'Claim',
      // eslint-disable-next-line react/display-name
      specialCell: (claim) => <ClaimLink claimId={claim.id} linkText={claim.claim_id_display} />,
      specialCmpFunc: (row1, row2) => row1.id - row2.id,
    },
    {
      id: 'insured_contact_full_name',
      numeric: false,
      disablePadding: false,
      label: 'Named Insured',
      // eslint-disable-next-line react/display-name
      specialCell: (row) =>
        row.insured_contact_id ? (
          <ContactEntity contactId={row.insured_contact_id} contactDisplayName={row.insured_contact_full_name} />
        ) : null,
    },
    // eslint-disable-next-line react/display-name
    {
      id: 'select_button',
      numeric: false,
      label: '',
      specialCell: (row) => (
        <SelectClaimAndAttachCommunication
          claim={row}
          communication={communication}
          onAttach={onAttach}
          onAttachOverride={onAttachOverride}
        />
      ),
    },
  ];

  const subOrganizationIds = userOrganization.is_suborgs_domains_enabled
    ? emailDomains.find((emailDomain) => emailDomain.domain === communication?.email_domain)?.sub_organization_ids
    : [];

  return (
    <LoadingSwitch isError={isError} isLoading={isLoading}>
      <div className={classes.cardDivRowInternal}>
        <ClaimSearchContainer
          classes={classes}
          SelectComponent={SelectClaimAndAttachCommunication}
          selectComponentProps={{ communication, onAttach, onAttachOverride }}
          label="Attach to claim"
          subOrganizationIds={subOrganizationIds}
        />
      </div>
      {possibleClaimsToAttach?.length > 0 && (
        <div className={classes.cardDivRowInternal}>
          <CardDialog noCardTitle>
            <Heading variant={Heading.TYPES.H4}>May be related to the following claims:</Heading>
            <SortableTable rows={possibleClaimsToAttach} columns={possibleClaimsColumns} />
          </CardDialog>
        </div>
      )}
    </LoadingSwitch>
  );
};

AttachCommunicationContainer.propTypes = {
  communication: PropTypes.object.isRequired,
  onAttach: PropTypes.func.isRequired,
  onAttachOverride: PropTypes.func,
};

function SelectClaimAndAttachCommunication(props) {
  const { claim, communication, onAttach, onAttachOverride } = props;
  const [showAttachPhysicalMail, setShowAttachPhysicalMail] = useState(false);
  const [showOtherContactsOptions, setShowOtherContactsOptions] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);

  const handleAttach = async (attachExtraParams = {}) => {
    if (onAttachOverride) {
      await onAttachOverride(claim.id, attachExtraParams);
      return;
    }

    await axios.post(`/api/v1/communications/${communication.id}/attach`, {
      claim_id: claim.id,
      ...attachExtraParams,
    });
    await onAttach(claim.id);
  };

  function handleSelectClaim() {
    setIsSubmitting(true);
    if (['email', 'phone_call', 'sms'].includes(communication.channel)) {
      setShowOtherContactsOptions(true);
      setIsSubmitting(false);
    } else if (communication.channel === 'physical_mail') {
      setShowAttachPhysicalMail(true);
    } else {
      reportErrorInProductionOrThrow(
        `Unknown communication type when attaching communication ${communication.id} to claim ${claim.id}`
      );
    }
  }

  const handleAttachOtherContact = async (values) => {
    try {
      await handleAttach({
        contact_id: values.contact_id,
        should_create_new_contact: values.contact_attachment_choice === 'new_contact',
      });
    } catch (error) {
      reportAxiosError(error);
    } finally {
      setIsSubmitting(false);
    }
  };

  return (
    <>
      <Button color="primary" onClick={handleSelectClaim} disabled={isSubmitting}>
        Attach to claim
      </Button>
      {showOtherContactsOptions && (
        <AttachOtherContacts
          claimId={claim.id}
          communicationId={communication.id}
          communicationChannel={communication.channel}
          onClose={() => {
            setShowOtherContactsOptions(false);
            setIsSubmitting(false);
          }}
          onSubmit={(values) => handleAttachOtherContact(values)}
        />
      )}
      {showAttachPhysicalMail && (
        <AttachPhysicalMail
          claimId={claim.id}
          onClose={() => {
            setShowAttachPhysicalMail(false);
            setIsSubmitting(false);
          }}
          onUpdate={(values) => handleAttach(values)}
        />
      )}
    </>
  );
}

SelectClaimAndAttachCommunication.propTypes = {
  claim: PropTypes.object.isRequired,
  communication: PropTypes.object.isRequired,
  onAttach: PropTypes.func.isRequired,
  onAttachOverride: PropTypes.func,
};

function AttachOtherContacts({ onSubmit, onClose, claimId, communicationChannel, communicationId }) {
  const classes = useStyles();
  const { organizationContactRolesDict } = useOrganization();

  const [claim, isLoadingClaim, isErrorClaim, reloadClaim] = useFetchClaim(claimId);

  const {
    isLoading: isLoadingPossibleContacts,
    isError: isErrorPossibleContacts,
    data: possibleContacts = [],
  } = useDataFetcher(
    `/api/v1/communications/${communicationId}/possible_contacts`,
    { params: { claim_id: claimId } },
    !!(claim && !isLoadingClaim && !isErrorClaim)
  );

  // This can happen when the User is not authorized to access the claim
  if (isErrorPossibleContacts || isErrorClaim) {
    return null;
  }

  if (isLoadingClaim || isLoadingPossibleContacts) {
    return <LoadingDialog isError={!!(isErrorClaim || isErrorPossibleContacts)} track="Attach Other Contacts" />;
  }

  return (
    <Formik
      initialValues={{
        contact_id: '',
        contact_attachment_choice: '',
      }}
      validationSchema={Yup.object().shape({
        contact_attachment_choice: Yup.string().required('Required'),
        contact_id: Yup.number().when('contact_attachment_choice', {
          is: 'existing_contact',
          then: Yup.number().required('Required'),
        }),
      })}
      onSubmit={onSubmit}
    >
      {(formikProps) => {
        const { isSubmitting, handleSubmit, values, setFieldValue } = formikProps;
        const disableActions = isSubmitting;
        const handleAttachmentOptionChange = (contactId, attachmentOptionValue) => {
          setFieldValue('contact_id', contactId);
          setFieldValue('contact_attachment_choice', attachmentOptionValue);
        };

        return (
          <CardDialog
            isDialog
            title={`Attach ${COMMUNICATION_CHANNEL_DICT[communicationChannel]} To Contact`}
            onClose={onClose}
            fullWidth
            preventClose={disableActions}
          >
            <ClaimContextProvider claim={claim} refreshData={reloadClaim}>
              <div className="flex flex-col justify-center space-y-20">
                {possibleContacts?.map((contact) => (
                  <div key={contact.id} className="inline-flex">
                    <RadioButtonFormik
                      id="contact_attachment_choice"
                      label={<ContactEntity contactId={contact.id} contactDisplayName={contact.full_name} />}
                      size="small"
                      optionValue={contact.id}
                      onChange={() => handleAttachmentOptionChange(contact.id, contact.id)}
                    />
                  </div>
                ))}
                <div className="grid grid-cols-2 gap-12">
                  <div>
                    <RadioButtonFormik
                      id="contact_attachment_choice"
                      label="Attach to other existing contact"
                      size="small"
                      optionValue="existing_contact"
                      onChange={() => handleAttachmentOptionChange('', 'existing_contact')}
                    />
                  </div>
                  <div>
                    {values['contact_attachment_choice'] === 'existing_contact' && (
                      <ContactTextFieldFormik
                        id="contact"
                        label="Contact"
                        disabled={isSubmitting}
                        acceptedRoles={getAllSearchableContactRoles(organizationContactRolesDict)}
                        fullWidth
                        fixedSearchResults
                      />
                    )}
                  </div>
                </div>
                <div>
                  <RadioButtonFormik
                    id="contact_attachment_choice"
                    label="Create a new contact"
                    size="small"
                    optionValue="new_contact"
                    onChange={() => handleAttachmentOptionChange('', 'new_contact')}
                  />
                </div>
              </div>

              <ErrorHelperTextFormik id="contact_attachment_choice" />

              <div className={classes.buttonsContainer}>
                <Button variant="contained" color="primary" disabled={isSubmitting} onClick={handleSubmit}>
                  Attach
                </Button>
              </div>
            </ClaimContextProvider>
          </CardDialog>
        );
      }}
    </Formik>
  );
}

AttachOtherContacts.propTypes = {
  claimId: PropTypes.number.isRequired,
  communicationId: PropTypes.number.isRequired,
  onClose: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
  communicationChannel: PropTypes.string.isRequired,
};

function AttachPhysicalMail(props) {
  const { claimId, onClose, onUpdate } = props;
  const classes = useStyles();
  const { organizationContactRolesDict } = useOrganization();

  const [claim, isLoading, isError, reloadClaim] = useFetchClaim(claimId);

  if (isLoading || isError) {
    return <LoadingDialog isError={isError} onClose={onClose} track="AttachPhysicalMail" />;
  }

  return (
    <Formik
      initialValues={{
        exposure_ids: [],
        contact_id: '',
        contact_full_name: '',
      }}
      validationSchema={Yup.object().shape({
        contact_id: Yup.number().required('Required'),
      })}
      onSubmit={onUpdate}
    >
      {({ isSubmitting, handleSubmit }) => (
        <CardDialog isDialog title="Choose contact" onClose={onClose} maxWidth="xl" preventClose={isSubmitting}>
          <ClaimContextProvider claim={claim} refreshData={reloadClaim}>
            <ContactTextFieldFormik
              id="contact"
              label="Contact"
              disabled={isSubmitting}
              acceptedRoles={getAllSearchableContactRoles(organizationContactRolesDict)}
              fullWidth
              fixedSearchResults
            />
            <div className={classes.buttonsContainer}>
              <Button variant="contained" color="primary" disabled={isSubmitting} onClick={handleSubmit}>
                Attach
              </Button>
            </div>
          </ClaimContextProvider>
        </CardDialog>
      )}
    </Formik>
  );
}

AttachPhysicalMail.propTypes = {
  claimId: PropTypes.number.isRequired,
  onClose: PropTypes.func.isRequired,
  onUpdate: PropTypes.func.isRequired,
};

export default AttachCommunicationContainer;
export { AttachCommunicationPage, AttachOtherContacts };
