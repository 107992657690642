import React from 'react';

import EmailCommunicationPopover from '~/components/Contacts/ContactCommunications/Email/EmailCommunicationPopover';
import SmsCommunicationPopover from '~/components/Contacts/ContactCommunications/SMS/SmsCommunicationPopover';
import IconButton from '~/components/core/Atomic/Buttons/IconButton';
import Caption from '~/components/core/TextComponents/Caption';
import Text from '~/components/core/TextComponents/Text';
import { htmlToTextTemplateParser } from '~/components/GenericTemplates/utils/genericTemplatesUtils';
import FilesIcon from '~/components/icons/FilesIcon';
import type { ContactFullModel } from '~/components/types/contact-types';

interface Props {
  claimId: number;
  title: string;
  href: string;
  contact: ContactFullModel;
  templateData: {
    title_email_html: string;
    body_email_html: string;
    sms_html?: string;
  };
}

const LinkShare: React.FC<Props> = ({ claimId, title, href, contact, templateData }) => {
  return (
    <div className="my-20 flex justify-between bg-slate-200 p-12">
      <div className="mr-12 overflow-hidden">
        <Caption>{title}</Caption>
        <Text
          colorVariant={Text.COLOR_VARIANTS.SECONDARY}
          variant={Text.VARIANTS.XS}
          weight={Text.WEIGHTS.REGULAR}
          className="truncate"
        >
          {href}
        </Text>
      </div>

      <div className="flex flex-row flex-nowrap items-center">
        <SmsCommunicationPopover
          contact={contact}
          useIconButton
          messageContent={htmlToTextTemplateParser(templateData.sms_html || '')}
          claimId={claimId}
        />
        <EmailCommunicationPopover
          contact={contact}
          useIconButton
          emailTitle={htmlToTextTemplateParser(templateData.title_email_html)}
          emailText={templateData.body_email_html}
          claimId={claimId}
        />
        <IconButton
          onClick={() => {
            navigator.clipboard.writeText(href);
          }}
          className="p-12 text-slate-900 hover:text-teal-700"
        >
          <FilesIcon iconColor="currentColor" />
        </IconButton>
      </div>
    </div>
  );
};

export default LinkShare;
