import React from 'react';
import { getIn, useFormikContext } from 'formik';

import AddressAutocomplete from '~/components/core/AddressAutocomplete/AddressAutocomplete';
import { SUPPORTED_COUNTRIES } from '~/components/core/AddressAutocomplete/utils';
import { useCms } from '~/components/hooks/useCms';
import TextFieldFormik from '~/components/TextFieldFormik';
import { CONFIGURATION_FEATURES_NAMES } from '~/Types';
import { isFeatureEnabled } from '~/Utils';

interface AddressAutocompleteFormikProps {
  id: string;
  streetAddress1FieldId?: string;
  cityFieldId?: string;
  countyFieldId?: string;
  countryFieldId?: string;
  stateFieldId?: string;
  zipcodeFieldId?: string;
  className?: string;
  label: string;
  disabled?: boolean;
  fullWidth?: boolean;
  helperText?: string;
  country?: string;
}

const AddressAutocompleteFormik: React.FC<AddressAutocompleteFormikProps> = ({
  className = '',
  id,
  label,
  helperText,
  disabled = false,
  fullWidth = false,
  streetAddress1FieldId,
  cityFieldId,
  countyFieldId,
  countryFieldId,
  stateFieldId,
  zipcodeFieldId,
  country,
}) => {
  const { userOrganization } = useCms();
  const { setFieldValue, values, setFieldTouched, errors, touched } = useFormikContext();

  const isCountrySupported =
    (country && SUPPORTED_COUNTRIES.includes(country)) ||
    (countryFieldId && SUPPORTED_COUNTRIES.includes(getIn(values, countryFieldId)));

  if (!isFeatureEnabled(userOrganization, CONFIGURATION_FEATURES_NAMES.ADDRESS_AUTOCOMPLETE) || !isCountrySupported) {
    return (
      <TextFieldFormik
        id={id}
        className={className}
        disabled={disabled}
        label={label}
        fullWidth={fullWidth}
        helperText={helperText}
      />
    );
  }

  return (
    <AddressAutocomplete
      id={id}
      className={className}
      disabled={disabled}
      error={getIn(errors, id) && getIn(touched, id) && getIn(errors, id)}
      helperText={helperText}
      label={label}
      value={getIn(values, id)}
      onBlur={() => setFieldTouched(id, true)}
      fullWidth={fullWidth}
      onInputChange={(_, value) => setFieldValue(id, value)}
      onStreetAddress1Changed={(streetAddress1) =>
        streetAddress1FieldId && setFieldValue(streetAddress1FieldId, streetAddress1)
      }
      onCityChanged={(city) => cityFieldId && setFieldValue(cityFieldId, city)}
      onStateChanged={(state) => stateFieldId && setFieldValue(stateFieldId, state)}
      onCountyChanged={(county) => countyFieldId && setFieldValue(countyFieldId, county)}
      onCountryChanged={(country) => countryFieldId && setFieldValue(countryFieldId, country)}
      onZipcodeChanged={(zipcode) => zipcodeFieldId && setFieldValue(zipcodeFieldId, zipcode)}
      region={countryFieldId && getIn(values, countryFieldId)}
    />
  );
};

export default AddressAutocompleteFormik;
