// these are all the moment supported locales, due to Vite/Rollup limitation in doing dynamic imports with variables
// https://github.com/vitejs/vite/issues/14102
export async function importLocaleModule(localeCode) {
  // Ensure the locale code is lowercase
  const code = localeCode.toLowerCase();

  switch (code) {
    case 'af':
      await import('moment/dist/locale/af.js');
      return;
    case 'ar':
      await import('moment/dist/locale/ar.js');
      return;

    case 'ar-dz':
      await import('moment/dist/locale/ar-dz.js');
      return;

    case 'ar-kw':
      await import('moment/dist/locale/ar-kw.js');
      return;

    case 'ar-ly':
      await import('moment/dist/locale/ar-ly.js');
      return;

    case 'ar-ma':
      await import('moment/dist/locale/ar-ma.js');
      return;

    case 'ar-sa':
      await import('moment/dist/locale/ar-sa.js');
      return;

    case 'ar-tn':
      await import('moment/dist/locale/ar-tn.js');
      return;

    case 'az':
      await import('moment/dist/locale/az.js');
      return;

    case 'be':
      await import('moment/dist/locale/be.js');
      return;

    case 'bg':
      await import('moment/dist/locale/bg.js');
      return;

    case 'ca':
      await import('moment/dist/locale/ca.js');
      return;

    case 'cs':
      await import('moment/dist/locale/cs.js');
      return;

    case 'cy':
      await import('moment/dist/locale/cy.js');
      return;

    case 'da':
      await import('moment/dist/locale/da.js');
      return;

    case 'de':
      await import('moment/dist/locale/de.js');
      return;

    case 'de-at':
      await import('moment/dist/locale/de-at.js');
      return;

    case 'de-ch':
      await import('moment/dist/locale/de-ch.js');
      return;

    case 'dv':
      await import('moment/dist/locale/dv.js');
      return;

    case 'el':
      await import('moment/dist/locale/el.js');
      return;

    case 'en-au':
      await import('moment/dist/locale/en-au.js');
      return;

    case 'en-ca':
      await import('moment/dist/locale/en-ca.js');

      return;
    case 'en-gb':
      await import('moment/dist/locale/en-gb.js');
      return;

    case 'en-ie':
      await import('moment/dist/locale/en-ie.js');
      return;

    case 'en-nz':
      await import('moment/dist/locale/en-nz.js');
      return;

    case 'en-us':
      return; // en-us loaded by default
    case 'eo':
      await import('moment/dist/locale/eo.js');
      return;

    case 'es':
      await import('moment/dist/locale/es.js');
      return;

    case 'es-do':
      await import('moment/dist/locale/es-do.js');
      return;

    case 'es-mx':
      await import('moment/dist/locale/es-mx.js');
      return;

    case 'et':
      await import('moment/dist/locale/et.js');
      return;

    case 'eu':
      await import('moment/dist/locale/eu.js');
      return;

    case 'fa':
      await import('moment/dist/locale/fa.js');
      return;

    case 'fi':
      await import('moment/dist/locale/fi.js');
      return;

    case 'fo':
      await import('moment/dist/locale/fo.js');
      return;

    case 'fr':
      await import('moment/dist/locale/fr.js');
      return;

    case 'fr-ca':
      await import('moment/dist/locale/fr-ca.js');
      return;

    case 'fr-ch':
      await import('moment/dist/locale/fr-ch.js');
      return;

    case 'gl':
      await import('moment/dist/locale/gl.js');
      return;

    case 'gu':
      await import('moment/dist/locale/gu.js');
      return;

    case 'he':
      await import('moment/dist/locale/he.js');
      return;

    case 'hi':
      await import('moment/dist/locale/hi.js');
      return;

    case 'hr':
      await import('moment/dist/locale/hr.js');
      return;

    case 'hu':
      await import('moment/dist/locale/hu.js');
      return;

    case 'hy-am':
      await import('moment/dist/locale/hy-am.js');
      return;

    case 'id':
      await import('moment/dist/locale/id.js');
      return;

    case 'is':
      await import('moment/dist/locale/is.js');
      return;

    case 'it':
      await import('moment/dist/locale/it.js');
      return;

    case 'it-ch':
      await import('moment/dist/locale/it-ch.js');
      return;

    case 'ja':
      await import('moment/dist/locale/ja.js');
      return;

    case 'ka':
      await import('moment/dist/locale/ka.js');
      return;
    case 'kk':
      await import('moment/dist/locale/kk.js');
      return;

    case 'kn':
      await import('moment/dist/locale/kn.js');
      return;

    case 'ko':
      await import('moment/dist/locale/ko.js');
      return;

    case 'ky':
      await import('moment/dist/locale/ky.js');
      return;

    case 'lt':
      await import('moment/dist/locale/lt.js');
      return;

    case 'lv':
      await import('moment/dist/locale/lv.js');
      return;

    case 'mi':
      await import('moment/dist/locale/mi.js');
      return;

    case 'mk':
      await import('moment/dist/locale/mk.js');
      return;

    case 'mn':
      await import('moment/dist/locale/mn.js');
      return;

    case 'mr':
      await import('moment/dist/locale/mr.js');
      return;

    case 'ms':
      await import('moment/dist/locale/ms.js');
      return;

    case 'ms-my':
      await import('moment/dist/locale/ms-my.js');
      return;

    case 'mt':
      await import('moment/dist/locale/mt.js');
      return;

    case 'nb':
      await import('moment/dist/locale/nb.js');
      return;

    case 'nl':
      await import('moment/dist/locale/nl.js');
      return;

    case 'nl-be':
      await import('moment/dist/locale/nl-be.js');
      return;

    case 'pa-in':
      await import('moment/dist/locale/pa-in.js');
      return;

    case 'pl':
      await import('moment/dist/locale/pl.js');
      return;

    case 'pt':
      await import('moment/dist/locale/pt.js');
      return;

    case 'pt-br':
      await import('moment/dist/locale/pt-br.js');
      return;

    case 'ro':
      await import('moment/dist/locale/ro.js');
      return;

    case 'ru':
      await import('moment/dist/locale/ru.js');
      return;

    case 'se':
      await import('moment/dist/locale/se.js');
      return;

    case 'sk':
      await import('moment/dist/locale/sk.js');
      return;

    case 'sl':
      await import('moment/dist/locale/sl.js');
      return;

    case 'sq':
      await import('moment/dist/locale/sq.js');
      return;

    case 'sv':
      await import('moment/dist/locale/sv.js');
      return;

    case 'sw':
      await import('moment/dist/locale/sw.js');
      return;

    case 'ta':
      await import('moment/dist/locale/ta.js');
      return;

    case 'te':
      await import('moment/dist/locale/te.js');
      return;

    case 'th':
      await import('moment/dist/locale/th.js');
      return;

    case 'tl-ph':
      await import('moment/dist/locale/tl-ph.js');
      return;

    case 'tr':
      await import('moment/dist/locale/tr.js');
      return;

    case 'uk':
      await import('moment/dist/locale/uk.js');
      return;

    case 'ur':
      await import('moment/dist/locale/ur.js');
      return;

    case 'uz':
      await import('moment/dist/locale/uz.js');
      return;

    case 'vi':
      await import('moment/dist/locale/vi.js');
      return;

    case 'zh-cn':
      await import('moment/dist/locale/zh-cn.js');
      return;

    case 'zh-hk':
      await import('moment/dist/locale/zh-hk.js');
      return;

    case 'zh-mo':
      await import('moment/dist/locale/zh-mo.js');
      return;

    case 'zh-tw':
      await import('moment/dist/locale/zh-tw.js');
      return;

    default:
      throw new Error(`Locale "${localeCode}" is not supported.`);
  }
}
