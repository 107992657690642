import React from 'react';
import { Formik } from 'formik';
import * as Yup from 'yup';

import PreDefinedNotificationEditDialogInner from '~/components/SystemConfiguration/AutomaticRulesConfiguration/Notifications/NotificationRuleDialog/PreDefinedNotificationEditDialogInner';
import type {
  NotificationConfigurationFormikFields,
  PreDefinedNotification,
} from '~/components/SystemConfiguration/AutomaticRulesConfiguration/Notifications/types';

import {
  convertPreDefinedFormikValuesToServerValues,
  notificationConfigurationValidationSchema,
  preDefinedToInitialValues,
} from '../utils';

interface PreDefinedNotificationEditDialogProps {
  notification: PreDefinedNotification;
  onSubmit: (values: NotificationConfigurationFormikFields, notificationKey: string) => Promise<void>;
  onClose: () => void;
}

export const PreDefinedNotificationEditDialog: React.FC<PreDefinedNotificationEditDialogProps> = ({
  notification,
  onSubmit,
  onClose,
}) => {
  return (
    <Formik<NotificationConfigurationFormikFields>
      initialValues={preDefinedToInitialValues(notification) as NotificationConfigurationFormikFields}
      validationSchema={Yup.object().shape(notificationConfigurationValidationSchema)}
      enableReinitialize
      onSubmit={async (values, { setSubmitting }) => {
        try {
          await onSubmit(convertPreDefinedFormikValuesToServerValues(values), notification.notification_key);
          onClose();
        } catch (error) {
          setSubmitting(false);
        }
      }}
    >
      <PreDefinedNotificationEditDialogInner notification={notification} onClose={onClose} />
    </Formik>
  );
};

export default PreDefinedNotificationEditDialog;
