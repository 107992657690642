const NOTIFICATIONS_SCHEDULES = {
  immediate: {
    desc: 'Immediate',
    should_show_schedule_fields: false,
  },
  custom: {
    desc: 'Custom',
    should_show_schedule_fields: true,
  },
} as const;

export default NOTIFICATIONS_SCHEDULES;
