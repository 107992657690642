import React from 'react';

import type { ClaimNotificationModel } from '~/components/ClaimNotificationsCard/types';
import Typography from '~/components/core/Atomic/Typography';

interface NotificationAssigneeProps {
  notification: ClaimNotificationModel;
}

const NotificationAssignee: React.FC<NotificationAssigneeProps> = ({ notification }) => {
  return (
    <div>
      <Typography display="block" variant="body2">
        {notification.recipient_label}
      </Typography>
      {notification.recipient_sub_label && (
        <Typography display="block" variant="body2" color="textSecondary">
          {notification.recipient_sub_label}
        </Typography>
      )}
    </div>
  );
};

export default NotificationAssignee;
