import React, { Component } from 'react';
import requiredIf from 'react-required-if';
import PropTypes from 'prop-types';
import { Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import _ from 'lodash';

import { stringCmp } from '~/Utils';
import cn from '~/Utils/cn';

import { paginationPropsPropTypesShape } from './propTypes';
import SortableTableHead from './SortableTableHead';
import SortableTableRow from './SortableTableRow';
import TablePaginationSection from './TablePaginationSection';
import { expandActionColumn, PAGINATION_LOCATION } from './utils';

import styles from '../../../../assets/styles';
import sortableTableStyles from './sortableTable.module.scss';

class SortableTable extends Component {
  constructor(props) {
    super(props);
    this.tableRef = React.createRef();

    const { columns, defaultOrderColumn } = props;
    this.state = {
      orderBy: defaultOrderColumn !== undefined ? columns[defaultOrderColumn].id : undefined,
      order: props.order ? props.order : 'asc',
      tableWidth: undefined,
      mouseOverRow: undefined,
      tableWidthTimeRendered: undefined,
      autoPaginationPage: 0,
    };
  }

  static getDerivedStateFromProps(props, state) {
    const { columns, rows, keepRowsOrder, onSortByColumn } = props;

    const { orderBy, order } = state;

    // .slice() makes a copy, otherwise
    let rowsSorted = rows ? rows.slice() : [];

    if (orderBy !== undefined && !keepRowsOrder && !onSortByColumn) {
      let cmpFunc;
      const column = columns.find((column) => column.id === orderBy);
      if (column.specialCmpFunc) {
        cmpFunc = column.specialCmpFunc;
      } else if (column.numeric) {
        // numeric columns
        cmpFunc = (a, b) => a[orderBy] - b[orderBy];
      } else {
        // string columns
        cmpFunc = (a, b) => stringCmp(String(a[orderBy]), String(b[orderBy]));
      }

      let orderedCmpFunc = cmpFunc;
      if (order === 'desc') {
        orderedCmpFunc = (a, b) => -cmpFunc(a, b);
      }
      rowsSorted = rowsSorted.sort(orderedCmpFunc);
    }

    return { rowsSorted };
  }

  handleRequestSort = (e, columnId) => {
    const orderBy = columnId;
    let order = 'desc';

    if (this.state.orderBy === columnId && this.state.order === 'desc') {
      order = 'asc';
    }

    if (this.props.onSortByColumn) {
      this.props.onSortByColumn(e, { id: columnId, order });
    }
    this.setState({ order, orderBy, autoPaginationPage: 0 });
  };

  componentDidMount() {
    this.setState({ tableWidth: this.tableRef.current.clientWidth, tableWidthTimeRendered: new Date() });
  }

  componentDidUpdate() {
    if (this.tableRef.current.clientWidth !== this.state.tableWidth) {
      const now = new Date();
      if ((now.getTime() - this.state.tableWidthTimeRendered.getTime()) / 1000 > 3) {
        this.setState({ tableWidth: this.tableRef.current.clientWidth, tableWidthTimeRendered: now });
      }
    }
  }

  render() {
    const {
      classes,
      columns,
      selectedRowsIds,
      selectedColumnId,
      onRowClick,
      disableSortByUser,
      hideHeader,
      maxHeight,
      stickyHeader,
      paginationProps,
      footerRow,
      autoPaginateRowsPerPage,
      emptyStateComponent,
      boldRowsIds = [],
      greyedOutRowsIds = [],
      highlightOnHover = true,
      paginationLocation = PAGINATION_LOCATION.TOP,
      shouldRenderExpandAsTable = false,
      onExpandColumns,
      isRestrictedPredicate,
      restrictedContentTooltipText = '',
      subRowsPropertyName,
      strikeOutRowIds = [],
    } = this.props;
    const { rowsSorted, orderBy, order, tableWidth, autoPaginationPage } = this.state;

    let combinedPaginationProps = paginationProps;
    let rowsToShow = rowsSorted;
    if (!paginationProps && autoPaginateRowsPerPage && rowsSorted.length >= autoPaginateRowsPerPage * 2) {
      combinedPaginationProps = {
        rowsPerPageOptions: [autoPaginateRowsPerPage],
        count: rowsSorted.length,
        rowsPerPage: autoPaginateRowsPerPage,
        page: autoPaginationPage,
        onChangePage: (_, newPage) => this.setState({ autoPaginationPage: newPage }),
        onChangeRowsPerPage: undefined,
      };

      rowsToShow = rowsSorted.slice(
        autoPaginationPage * autoPaginateRowsPerPage,
        (autoPaginationPage + 1) * autoPaginateRowsPerPage
      );
    }
    const columnsToShow = columns.filter((column) => !column.isHidden);
    const expandableColumns = columns.filter((column) => column.expandable);
    const hasExpandableRows =
      _.findIndex(rowsSorted, (row) => subRowsPropertyName && !_.isEmpty(row[subRowsPropertyName])) !== -1;

    return (
      <Paper>
        {paginationLocation === PAGINATION_LOCATION.TOP && combinedPaginationProps && (
          <TablePaginationSection combinedPaginationProps={combinedPaginationProps} />
        )}
        <TableContainer
          ref={this.tableRef}
          style={{ maxHeight }}
          className={sortableTableStyles.sortableTableContainer}
        >
          <Table stickyHeader={stickyHeader} className={cn(classes.table, sortableTableStyles.sortableTable)}>
            {rowsToShow.length || !emptyStateComponent ? (
              <>
                {!hideHeader && (
                  <TableHead>
                    <TableRow>
                      {(!_.isEmpty(expandableColumns) || hasExpandableRows) && (
                        <SortableTableHead
                          key={expandActionColumn.id}
                          column={expandActionColumn}
                          selectedColumnId={selectedColumnId}
                          orderBy={orderBy}
                          order={order}
                          disableSort={disableSortByUser}
                          handleRequestSort={this.handleRequestSort}
                        />
                      )}
                      {columnsToShow
                        .filter((column) => !column.expandable)
                        .map((column) => (
                          <SortableTableHead
                            key={column.id}
                            column={column}
                            selectedColumnId={selectedColumnId}
                            orderBy={orderBy}
                            order={order}
                            disableSort={disableSortByUser}
                            handleRequestSort={this.handleRequestSort}
                          />
                        ))}
                    </TableRow>
                  </TableHead>
                )}
                <TableBody>
                  {rowsToShow.map((row) => (
                    <SortableTableRow
                      key={row.id}
                      columns={columnsToShow}
                      row={row}
                      subRowsPropertyName={subRowsPropertyName}
                      selectedRowsIds={selectedRowsIds}
                      selectedColumnId={selectedColumnId}
                      order={order}
                      orderBy={orderBy}
                      onRowClick={onRowClick}
                      tableWidth={tableWidth}
                      boldRowsIds={boldRowsIds}
                      greyedOutRowsIds={greyedOutRowsIds}
                      highlightOnHover={highlightOnHover}
                      shouldRenderExpandAsTable={shouldRenderExpandAsTable}
                      onExpandColumns={onExpandColumns}
                      isRestrictedPredicate={isRestrictedPredicate}
                      restrictedContentTooltipText={restrictedContentTooltipText}
                      hasExpandableRows={hasExpandableRows}
                      strikeOutRowIds={strikeOutRowIds}
                    />
                  ))}
                  {footerRow}
                </TableBody>
              </>
            ) : (
              <TableBody>
                <TableRow>
                  <TableCell style={{ border: 'none' }}>{emptyStateComponent}</TableCell>
                </TableRow>
              </TableBody>
            )}
          </Table>
        </TableContainer>
        {paginationLocation === PAGINATION_LOCATION.BOTTOM && combinedPaginationProps && (
          <TablePaginationSection combinedPaginationProps={combinedPaginationProps} />
        )}
      </Paper>
    );
  }
}

SortableTable.propTypes = {
  classes: PropTypes.object.isRequired,
  columns: PropTypes.array.isRequired,
  rows: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
    })
  ).isRequired,
  subRowsPropertyName: PropTypes.string,
  selectedRowsIds: PropTypes.array,
  selectedColumnId: PropTypes.string,
  onRowClick: PropTypes.func,
  order: PropTypes.oneOf(['asc', 'desc']),
  defaultOrderColumn: PropTypes.number, // index of column to orderBy default
  stickyHeader: PropTypes.bool,
  disableSortByUser: PropTypes.bool,
  keepRowsOrder: PropTypes.bool, // technically, we could've assumed this if disableSortByUser, but most time we just pass what the DB returned (random order) so we better sort by defaultOrderColumn unless explicitly asked not to
  hideHeader: PropTypes.bool,
  maxHeight: PropTypes.string,
  paginationProps: paginationPropsPropTypesShape,
  onSortByColumn: requiredIf(PropTypes.func, (props) => props.paginationProps && !props.disableSortByUser), // If paginationProps is given, either onSortByColumn or disableSortByUser should be given as well
  footerRow: PropTypes.node,
  autoPaginateRowsPerPage: PropTypes.number,
  emptyStateComponent: PropTypes.node,
  boldRowsIds: PropTypes.arrayOf(PropTypes.number),
  greyedOutRowsIds: PropTypes.arrayOf(PropTypes.number),
  highlightOnHover: PropTypes.bool,
  paginationLocation: PropTypes.oneOf(Object.values(PAGINATION_LOCATION)),
  shouldRenderExpandAsTable: PropTypes.bool,
  onExpandColumns: PropTypes.func,
  isRestrictedPredicate: PropTypes.func,
  restrictedContentTooltipText: PropTypes.string,
  strikeOutRowIds: PropTypes.arrayOf(PropTypes.number),
};

SortableTable.defaultProps = {
  defaultOrderColumn: 0,
};

export default withStyles(styles)(SortableTable);
