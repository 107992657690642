const USER_LOCALES = [
  {
    code: 'af',
    name: 'Afrikaans',
  },
  {
    code: 'ar',
    name: 'Arabic',
  },
  {
    code: 'ar-DZ',
    name: 'Arabic (Algeria)',
  },
  {
    code: 'ar-KW',
    name: 'Arabic (Kuwait)',
  },
  {
    code: 'ar-LY',
    name: 'Arabic (Libya)',
  },
  {
    code: 'ar-MA',
    name: 'Arabic (Morocco)',
  },
  {
    code: 'ar-SA',
    name: 'Arabic (Saudi Arabia)',
  },
  {
    code: 'ar-TN',
    name: 'Arabic (Tunisia)',
  },
  {
    code: 'az',
    name: 'Azeri (Latin)',
  },
  {
    code: 'be',
    name: 'Belarusian',
  },
  {
    code: 'bg',
    name: 'Bulgarian',
  },
  {
    code: 'ca',
    name: 'Catalan',
  },
  {
    code: 'cs',
    name: 'Czech',
  },
  {
    code: 'cy',
    name: 'Welsh',
  },
  {
    code: 'da',
    name: 'Danish',
  },
  {
    code: 'de',
    name: 'German',
  },
  {
    code: 'de-AT',
    name: 'German (Austria)',
  },
  {
    code: 'de-CH',
    name: 'German (Switzerland)',
  },
  {
    code: 'dv',
    name: 'Divehi',
  },
  {
    code: 'el',
    name: 'Greek',
  },
  {
    code: 'en-AU',
    name: 'English (Australia)',
  },
  {
    code: 'en-CA',
    name: 'English (Canada)',
  },
  {
    code: 'en-GB',
    name: 'English (United Kingdom)',
  },
  {
    code: 'en-IE',
    name: 'English (Ireland)',
  },
  {
    code: 'en-NZ',
    name: 'English (New Zealand)',
  },
  {
    code: 'en-US',
    name: 'English (United States)',
  },
  {
    code: 'eo',
    name: 'Esperanto',
  },
  {
    code: 'es',
    name: 'Spanish',
  },
  {
    code: 'es-DO',
    name: 'Spanish (Dominican Republic)',
  },
  {
    code: 'es-MX',
    name: 'Spanish (Mexico)',
  },
  {
    code: 'et',
    name: 'Estonian',
  },
  {
    code: 'eu',
    name: 'Basque',
  },
  {
    code: 'fa',
    name: 'Farsi',
  },
  {
    code: 'fi',
    name: 'Finnish',
  },
  {
    code: 'fo',
    name: 'Faroese',
  },
  {
    code: 'fr',
    name: 'French',
  },
  {
    code: 'fr-CA',
    name: 'French (Canada)',
  },
  {
    code: 'fr-CH',
    name: 'French (Switzerland)',
  },
  {
    code: 'gl',
    name: 'Galician',
  },
  {
    code: 'gu',
    name: 'Gujarati',
  },
  {
    code: 'he',
    name: 'Hebrew',
  },
  {
    code: 'hi',
    name: 'Hindi',
  },
  {
    code: 'hr',
    name: 'Croatian',
  },
  {
    code: 'hu',
    name: 'Hungarian',
  },
  {
    code: 'hy-AM',
    name: 'Armenian (Armenia)',
  },
  {
    code: 'id',
    name: 'Indonesian',
  },
  {
    code: 'is',
    name: 'Icelandic',
  },
  {
    code: 'it',
    name: 'Italian',
  },
  {
    code: 'it-CH',
    name: 'Italian (Switzerland)',
  },
  {
    code: 'ja',
    name: 'Japanese',
  },
  {
    code: 'ka',
    name: 'Georgian',
  },
  {
    code: 'kk',
    name: 'Kazakh',
  },
  {
    code: 'kn',
    name: 'Kannada',
  },
  {
    code: 'ko',
    name: 'Korean',
  },
  {
    code: 'ky',
    name: 'Kyrgyz',
  },
  {
    code: 'lt',
    name: 'Lithuanian',
  },
  {
    code: 'lv',
    name: 'Latvian',
  },
  {
    code: 'mi',
    name: 'Maori',
  },
  {
    code: 'mk',
    name: 'FYRO Macedonian',
  },
  {
    code: 'mn',
    name: 'Mongolian',
  },
  {
    code: 'mr',
    name: 'Marathi',
  },
  {
    code: 'ms',
    name: 'Malay',
  },
  {
    code: 'ms-MY',
    name: 'Malay (Malaysia)',
  },
  {
    code: 'mt',
    name: 'Maltese',
  },
  {
    code: 'nb',
    name: 'Norwegian (Bokm?l)',
  },
  {
    code: 'nl',
    name: 'Dutch',
  },
  {
    code: 'nl-BE',
    name: 'Dutch (Belgium)',
  },
  {
    code: 'pa-IN',
    name: 'Punjabi (India)',
  },
  {
    code: 'pl',
    name: 'Polish',
  },
  {
    code: 'pt',
    name: 'Portuguese',
  },
  {
    code: 'pt-BR',
    name: 'Portuguese (Brazil)',
  },
  {
    code: 'ro',
    name: 'Romanian',
  },
  {
    code: 'ru',
    name: 'Russian',
  },
  {
    code: 'se',
    name: 'Sami (Northern)',
  },
  {
    code: 'sk',
    name: 'Slovak',
  },
  {
    code: 'sl',
    name: 'Slovenian',
  },
  {
    code: 'sq',
    name: 'Albanian',
  },
  {
    code: 'sv',
    name: 'Swedish',
  },
  {
    code: 'sw',
    name: 'Swahili',
  },
  {
    code: 'ta',
    name: 'Tamil',
  },
  {
    code: 'te',
    name: 'Telugu',
  },
  {
    code: 'th',
    name: 'Thai',
  },
  {
    code: 'tl-PH',
    name: 'Tagalog (Philippines)',
  },
  {
    code: 'tr',
    name: 'Turkish',
  },
  {
    code: 'uk',
    name: 'Ukrainian',
  },
  {
    code: 'ur',
    name: 'Urdu',
  },
  {
    code: 'uz',
    name: 'Uzbek (Latin)',
  },
  {
    code: 'vi',
    name: 'Vietnamese',
  },
  {
    code: 'zh-CN',
    name: 'Chinese (S)',
  },
  {
    code: 'zh-HK',
    name: 'Chinese (Hong Kong)',
  },
  {
    code: 'zh-MO',
    name: 'Chinese (Macau)',
  },
  {
    code: 'zh-TW',
    name: 'Chinese (T)',
  },
] as const;

export default USER_LOCALES;
