import type { ReactNode } from 'react';
import React from 'react';

import Button from '~/components/core/Atomic/Buttons/Button';
import Text from '~/components/core/TextComponents/Text';
import type { FnolAiInboxRecord } from '~/components/Fnol/FnolAiInbox/types';
import { DISABLED_STATUSES_SET } from '~/components/Fnol/FnolAiInbox/utils';
import { PencilIcon, SearchIcon } from '~/components/icons';
import EyeIcon from '~/components/icons/EyeIcon';
import cn from '~/Utils/cn';

export interface PolicySelectionButtonProps {
  inboxItem: FnolAiInboxRecord;
  onClick: (inboxItem: FnolAiInboxRecord) => void;
}

export const PolicySelectionButton: React.FC<PolicySelectionButtonProps> = ({ inboxItem, onClick }) => {
  const policy = inboxItem.policy;
  const disabled = DISABLED_STATUSES_SET.has(inboxItem.status) && !inboxItem.policy;

  const onClickHandler = (e: React.MouseEvent<HTMLSpanElement>) => {
    e.stopPropagation();
    if (disabled) return;
    onClick(inboxItem);
  };

  const Icon = React.useMemo<ReactNode | undefined>(() => {
    return disabled ? (
      policy ? (
        <EyeIcon className="ml-auto" />
      ) : undefined
    ) : policy ? (
      <PencilIcon className="ml-auto" />
    ) : (
      <SearchIcon className="ml-auto stroke-slate-800" size={13} />
    );
  }, [disabled, policy]);

  return (
    <Button
      onClick={onClickHandler}
      className={cn('h-24 w-full select-text rounded-full bg-slate-400 hover:bg-slate-500', {
        'cursor-default hover:bg-slate-300': disabled,
      })}
      disableRipple={disabled}
    >
      <span className="flex w-full items-center gap-8 text-nowrap">
        <Text className="" weight={Text.WEIGHTS.REGULAR} variant={Text.VARIANTS.XS}>
          <>{policy?.policy_number || 'Not Found'}</>
        </Text>
        {Icon}
      </span>
    </Button>
  );
};
